import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { ReportGroup } from "../../../../shared/reporting/api/biClient.types";
import GroupItem from "./GroupItem";

const CustomDragLayer: React.FC = () => {
  const { isDragging, currentOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem<ReportGroup>(),
      itemType: monitor.getItemType(),
    };
  });

  return isDragging && currentOffset ? (
    <GroupItem
      level={item}
      clone
      sx={{
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    />
  ) : null;
};

export default CustomDragLayer;
