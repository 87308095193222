import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { GridCellParams, GridColDef, GridValueGetter, GridValueSetter } from "@mui/x-data-grid-premium";
import { NumericGridTableBlockRow, ScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import { MetricFormatConfiguration } from "../../../../api/portfolioMonitoringTypes";
import { formatMetricNumberValue } from "../../../../utilities/metricFormatters";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import NewMetricExtensionRowTag from "./NewMetricExtensionRowTag";
import { usePortfolioMonitoringInputTableContext } from "./PortfolioMonitoringInputTableContext";
import ScenarioColumnHeader from "./ScenarioColumnHeader";
import { calculateTotals } from "./tableTotalsCalculator";

export type RowModel = NumericGridTableBlockRow & {
  isNew?: boolean;
};

const NameCell = ({ row }: { row: RowModel }) => {
  const { isSubmissionEditable } = useDataSubmissionFormContext();
  const { onAddMetricExtensions } = usePortfolioMonitoringInputTableContext();

  if (row.type === "Metric" || row.type === "MetricExtension") {
    return (
      <>
        <Typography noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        {row.isNew && <NewMetricExtensionRowTag />}
      </>
    );
  }

  if (row.type === "ExtendedMetricSection") {
    return (
      <>
        <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        {isSubmissionEditable && (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => onAddMetricExtensions([row.metricId], row.name)}
            tabIndex={-1}
          >
            Add
          </Button>
        )}
      </>
    );
  }

  if (row.type === "Total" || row.type === "Section") {
    return (
      <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
        {row.name}
      </Typography>
    );
  }

  return null;
};

interface ValuesDiffProps {
  previous: number;
  current: number;
  formatConfig: MetricFormatConfiguration | undefined;
}

const ValuesDiff = ({ previous, current, formatConfig }: ValuesDiffProps) => {
  return (
    <Typography component="span">
      <Typography component="span" bgcolor={red[50]} sx={{ textDecoration: "line-through" }}>
        {formatMetricNumberValue(previous, formatConfig)}
      </Typography>
      <Typography component="span" ml={1} bgcolor={green[50]}>
        {formatMetricNumberValue(current, formatConfig)}
      </Typography>
    </Typography>
  );
};

interface ScenarioCellProps {
  columnId: string;
  row: RowModel;
  value: number | null | undefined;
  formattedValue: string;
}

const ScenarioCellWithValuesDiff = ({ columnId, row, value, formattedValue }: ScenarioCellProps) => {
  if (typeof value !== "number") {
    return formattedValue;
  }

  if (row.type !== "Metric" && row.type !== "MetricExtension") {
    return formattedValue;
  }

  const previousValue = row.previousValues[columnId];
  if (!previousValue || previousValue === value) {
    return formattedValue;
  }

  return <ValuesDiff previous={previousValue} current={value} formatConfig={row.defaultFormat} />;
};

export const getColumns = (
  columnDefinitions: ScenarioTableBlockColumn[],
  isSubmissionEditable: boolean
): GridColDef<RowModel>[] => {
  const nameColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 2,
    minWidth: 300,
    editable: false,
    cellClassName: ({ row }) => {
      if (row.type === "Total") {
        return "total-cell";
      }

      if (row.type === "Section") {
        return "";
      }

      if (row.isSecondary) {
        return "secondary-name-cell";
      }

      return "";
    },
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const valueFormatter = (value: number | null | undefined, row: RowModel) => {
    if (row.type === "Total") {
      return typeof value !== "number" ? "-" : formatMetricNumberValue(value, { numberOfDecimals: 0 });
    }

    const formatConfig = row.type === "Metric" || row.type === "MetricExtension" ? row.defaultFormat : undefined;
    return typeof value === "number" ? formatMetricNumberValue(value, formatConfig) : "";
  };

  const valueGetter: GridValueGetter<RowModel, number | null | undefined> = (_, row, col) => {
    if (row.type === "Total" || row.type === "Metric" || row.type === "MetricExtension") {
      return row.values[col.field];
    }

    return undefined;
  };

  const valueSetter: GridValueSetter<RowModel, number | null | undefined> = (newValue, row, col) => {
    if (row.type === "Metric" || row.type === "MetricExtension") {
      return {
        ...row,
        values: {
          ...row.values,
          [col.field]: newValue ?? undefined,
        },
      } as RowModel;
    }

    return row;
  };

  const editableCellClassName = ({ row }: { row: RowModel }) => (row.type === "Total" ? "total-cell" : "");

  const readonlyCellClassName = ({ row }: { row: RowModel }) =>
    row.type === "Total"
      ? "total-cell"
      : row.type === "Metric" || row.type === "MetricExtension"
        ? "readonly-cell"
        : "";

  const mapScenarioColumn = (
    columnDef: ScenarioTableBlockColumn,
    isSubmissionEditable: boolean
  ): GridColDef<RowModel> => ({
    field: columnDef.id,
    flex: 1,
    minWidth: 160,
    type: "number",
    editable: isSubmissionEditable && columnDef.isEditable,
    colSpan: (_, row) => (row.type === "Section" || row.type === "ExtendedMetricSection" ? 8 : 0),
    renderHeader: () => <ScenarioColumnHeader columnDefinition={columnDef} />,
    headerClassName: columnDef.isEditable ? "" : "readonly-header",
    cellClassName: columnDef.isEditable ? editableCellClassName : readonlyCellClassName,
    valueGetter,
    valueSetter,
    valueFormatter,
    renderCell:
      columnDef.isEditable && columnDef.dateRange === "PeriodShift"
        ? ({ row, formattedValue, value, field }: GridCellParams<RowModel, number, string>) => (
            <ScenarioCellWithValuesDiff
              columnId={field}
              row={row}
              value={value}
              formattedValue={formattedValue ?? ""}
            />
          )
        : undefined,
  });

  const scenarioColumns: GridColDef<RowModel>[] = columnDefinitions.map((columnDef) =>
    mapScenarioColumn(columnDef, isSubmissionEditable)
  );

  return [nameColumn, ...scenarioColumns];
};

export const getRows = (rows: NumericGridTableBlockRow[]): RowModel[] => {
  return calculateTotals(rows);
};
