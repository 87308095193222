import { isObject } from "./objectHelper";

function cloneDeep<T>(value: T): T {
  if (Array.isArray(value)) {
    return value.map((element: unknown) => cloneDeep(element)) as T;
  }

  if (isObject(value)) {
    const result: Partial<T> = {};
    for (const key in value) {
      result[key] = cloneDeep(value[key]);
    }
    return result as T;
  }

  return value;
}

export default cloneDeep;
