import ExportIcon from "@mui/icons-material/ExitToAppRounded";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { saveCsvFile } from "../../../../../../shared/services/downloadFile";
import adminApi from "../../../../../api/adminApi";
import { EmailTrackingItem } from "../../../../../api/types/documentCollectionTypes";
import { createSearchFilter } from "../../searchFilter";
import { emailsToCsv } from "../csv-formatters";
import EmailsFilter, { EmailsFilterValue } from "./EmailsFilter";
import EmailsTable from "./EmailsTable";

interface Props {
  documentCollectionId: string;
}

const applySearch = createSearchFilter<EmailTrackingItem>(3, (e) => [e.recipientName, e.email, e.subject, e.investor]);

const EmailsView = ({ documentCollectionId }: Props) => {
  const fetchEmails = useCallback(
    () => adminApi.getDocumentCollectionEmails(documentCollectionId),
    [documentCollectionId]
  );

  const [emailsResp, fetchError] = useFetch(fetchEmails, (data) => {
    setFilteredItems(data.trackingItems);
  });

  const [filteredItems, setFilteredItems] = useState<EmailTrackingItem[]>([]);
  const [filterValue, setFilterValue] = useState<EmailsFilterValue>("All");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  if (fetchError) {
    logError(fetchError.message, "EmailsView");
    return <DataLoadingFailed title="Loading emails failed" />;
  }

  if (!emailsResp) {
    return <InlineLoader />;
  }

  const updateFilteredItems = (emails: EmailTrackingItem[], searchTerm: string, filterValue: EmailsFilterValue) => {
    let result = applySearch(emails, searchTerm);

    if (filterValue === "Failed") {
      result = result.filter((e) => e.status === "PermanentFailure");
    }

    setFilteredItems(result);
  };

  const handleFilterChange = (newValue: EmailsFilterValue) => {
    updateFilteredItems(emailsResp.trackingItems, currentSearchTerm, newValue);
    setFilterValue(newValue);
  };

  const handleSearch = (searchTerm: string) => {
    updateFilteredItems(emailsResp.trackingItems, searchTerm, filterValue);
    setCurrentSearchTerm(searchTerm);
  };

  const handleExportToCsvClick = () => {
    const csv = emailsToCsv(filteredItems);
    saveCsvFile(csv, "email_deliveries.csv");
  };

  const { trackingItems: emails } = emailsResp;
  const failedCount = emails.filter((email) => email.status === "PermanentFailure").length;

  return (
    <Box height="100%">
      <Box
        mt={2}
        mb={2}
        mr={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap={1}
      >
        <EmailsFilter
          allCount={emails.length}
          failedCount={failedCount}
          value={filterValue}
          onChange={handleFilterChange}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchField onSearch={handleSearch} />
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleExportToCsvClick}
            startIcon={<ExportIcon />}
            disabled={filteredItems.length === 0}
          >
            Export
          </Button>
        </Stack>
      </Box>
      <EmailsTable emails={filteredItems} />
    </Box>
  );
};

export default EmailsView;
