import { Box, Button, Container, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { MetricDataType } from "../../../../shared/api/portfolioMonitoringTypes";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import adminApi from "../../../api/adminApi";
import { Metric } from "../../../api/types/portfolioMonitoringTypes";
import { useClientContext } from "../../../context/ClientContext";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import MetricsGrid from "./MetricsGrid";
import { MetricsPageContextProvider } from "./MetricsPageContext";
import AddMetricDialog from "./metrics-edit/AddMetricDialog";
import ArchiveMetricDialog from "./metrics-edit/ArchiveMetricDialog";
import EditMetricDialog from "./metrics-edit/EditMetricDialog";
import MetricDataTypeMenu from "./metrics-edit/MetricDataTypeMenu";

interface DialogState {
  openDialog?: "select_data_type" | "create_metric" | "edit_metric" | "archive_metric";
  newMetricDataType?: MetricDataType;
  editedMetric?: Metric;
}

const MetricsPage = () => {
  usePageTitle("Metrics");

  const { hasPermissions } = useClientContext();

  const newMetricButtonRef = useRef<HTMLButtonElement>(null);

  const [dialogState, setDialogState] = useState<DialogState>({});
  const [searchTerm, setSearchTerm] = useState("");

  const [metricsResp, fetchError, { isFetching, setData: setMetrics }] = useFetch(adminApi.getAllMetrics);

  if (fetchError) {
    return <DataLoadingFailed title="Could not load metrics" />;
  }

  const hasEditPermissions = hasPermissions(["ManagePortfolioMonitoring"]);

  const isLoading = isFetching || metricsResp === undefined;

  const metrics = metricsResp ?? [];

  const handleSearch = (value: string) => {
    setSearchTerm(value.toLowerCase().trim());
  };

  const handleDataTypeSelect = (newMetricDataType: MetricDataType) => {
    setDialogState({ openDialog: "create_metric", newMetricDataType });
  };

  const handleAddMetric = () => {
    setDialogState({ openDialog: "select_data_type" });
  };

  const handleEditMetric = (editedMetric: Metric) => {
    setDialogState({ openDialog: "edit_metric", editedMetric });
  };

  const handleArchiveMetric = (archivedMetric: Metric) => {
    setDialogState({ openDialog: "archive_metric", editedMetric: archivedMetric });
  };

  const handleMetricCreated = (newMetric: Metric) => {
    setMetrics([newMetric, ...metrics]);
    setDialogState({});
  };

  const handleMetricUpdated = (updatedMetric: Metric) => {
    setMetrics(metrics.map((metric) => (metric.id === updatedMetric.id ? updatedMetric : metric)));
    setDialogState({});
  };

  const handleMetricArchived = (archivedMetric: Metric) => {
    setMetrics(metrics.filter((metric) => metric.id !== archivedMetric.id));
    setDialogState({});
  };

  const filteredMetrics =
    searchTerm.length === 0
      ? metrics
      : metrics.filter(
          (metric) =>
            metric.name.toLowerCase().includes(searchTerm) ||
            metric.entryNo.toLowerCase().includes(searchTerm) ||
            (metric.category && metric.category.toLowerCase().includes(searchTerm))
        );

  return (
    <MetricsPageContextProvider
      hasEditPermissions={hasEditPermissions}
      metrics={metrics}
      onEditMetric={handleEditMetric}
      onArchiveMetric={handleArchiveMetric}
    >
      <GeneralPageHeader title="Metrics" />

      <Container maxWidth="xl" sx={{ py: 2.5, display: "flex", flexDirection: "column", flex: 1 }}>
        <Box display="flex" width="100%" justifyContent="space-between" mb={2.5}>
          <RecordCounter records={filteredMetrics.length} totalRecords={metrics.length} hide={isLoading} />
          <Stack direction="row" spacing={1}>
            <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} />
            {hasEditPermissions && (
              <Button
                ref={newMetricButtonRef}
                variant="contained"
                disabled={dialogState.openDialog !== undefined}
                onClick={handleAddMetric}
              >
                Add Metric
              </Button>
            )}
          </Stack>
        </Box>

        <MetricsGrid metrics={filteredMetrics} isLoading={isLoading} />
      </Container>

      <MetricDataTypeMenu
        anchorEl={newMetricButtonRef.current}
        open={dialogState.openDialog === "select_data_type"}
        onDataTypeSelect={handleDataTypeSelect}
        onClose={() => setDialogState({})}
      />

      {dialogState.openDialog === "create_metric" && dialogState.newMetricDataType && (
        <AddMetricDialog
          dataType={dialogState.newMetricDataType}
          onClose={() => setDialogState({})}
          onCreated={handleMetricCreated}
        />
      )}

      {dialogState.openDialog === "edit_metric" && dialogState.editedMetric && (
        <EditMetricDialog
          metric={dialogState.editedMetric}
          onClose={() => setDialogState({})}
          onUpdated={handleMetricUpdated}
        />
      )}

      {dialogState.openDialog === "archive_metric" && dialogState.editedMetric && (
        <ArchiveMetricDialog
          metric={dialogState.editedMetric}
          onClose={() => setDialogState({})}
          onArchived={handleMetricArchived}
        />
      )}
    </MetricsPageContextProvider>
  );
};

export default MetricsPage;
