import { Stack, Typography } from "@mui/material";
import FieldComponent from "./FieldComponent";
import { useFieldValuesContext } from "./FieldValuesContext";
import { FieldGroupModel } from "./entityFieldTypes";

interface Props {
  group: FieldGroupModel;
  fieldInputIds: Map<string, string>;
}

const FieldGroupSection = ({ group, fieldInputIds }: Props) => {
  const { fieldValuesState } = useFieldValuesContext();

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1" mb={2}>
        {group.name}
      </Typography>

      {group.fields.length === 0 && <Typography color="text.secondary">No fields in this group.</Typography>}

      {group.fields.map((field, index) => (
        <FieldComponent
          key={`entity-field-${field.id}-${index}`}
          field={field}
          fieldValue={fieldValuesState.fieldValues[field.id]}
          fieldInputId={fieldInputIds.get(field.id)}
        />
      ))}
    </Stack>
  );
};

export default FieldGroupSection;
