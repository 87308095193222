import { useCallback } from "react";
import { useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi, { FundDetails } from "../../../../api/adminApi";
import FundDetailsPage from "./FundDetailsPage";

const FundStructureFundPage = () => {
  usePageTitle("Fund");

  const { id } = useParams();

  const getFundDetails = useCallback(() => adminApi.getFundDetails(defined(id)), [id]);
  const [fundDetails, detailsError, { setData: setFundDetails }] = useFetch(getFundDetails);

  const getFundPermissions = useCallback(() => adminApi.getFundPermissions(defined(id)), [id]);
  const [fundPermissions, permissionsError] = useFetch(getFundPermissions);

  const [accessCategories, fetchAccessCategoriesError] = useFetch(adminApi.getAccessCategories);

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition("Fund"), []);
  const [objectDefinitionWithPermissions, fetchDefinitionError] = useFetch(getObjectDefinition);

  if (detailsError || permissionsError || fetchAccessCategoriesError || fetchDefinitionError) {
    logError(
      detailsError || permissionsError || fetchAccessCategoriesError || fetchDefinitionError,
      "[FundStructureFundPage]"
    );
    return <DataLoadingFailed bgColor="none" title="Loading fund details failed" />;
  }

  if (!fundDetails || !fundPermissions || !accessCategories || !objectDefinitionWithPermissions) {
    return <InlineLoader />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions;

  const handleFundDetailsChange = (details: Partial<FundDetails>) => {
    setFundDetails({ ...fundDetails, ...details });
  };

  return (
    <FundDetailsPage
      fundDetails={fundDetails}
      fundPermissions={fundPermissions}
      objectDefinition={objectClassDefinition}
      objectPermissions={objectPermissions}
      accessCategories={accessCategories}
      onFundDetailsChange={handleFundDetailsChange}
    />
  );
};

export default FundStructureFundPage;
