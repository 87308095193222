import { Tooltip, TooltipProps, Typography, TypographyProps } from "@mui/material";
import useHorizontalOverflowDetection from "../hooks/useHorizontalOverflowDetection";

interface Props {
  text: string;
  tooltipProps?: Partial<TooltipProps>;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  typographyProps?: TypographyProps;
}

const TypographyTooltipEllipsis = ({ text, tooltipProps, tooltipPlacement, typographyProps }: Props) => {
  const { hasOverflow, containerRef } = useHorizontalOverflowDetection([]);

  return (
    <Tooltip title={hasOverflow ? text : ""} placement={tooltipPlacement} arrow {...tooltipProps}>
      <Typography {...typographyProps} ref={containerRef} noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TypographyTooltipEllipsis;
