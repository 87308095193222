import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { green, lime, red } from "@mui/material/colors";
import { PropsWithChildren, useState } from "react";
import ColorTag from "../../../../../../shared/components/ColorTag";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../../../shared/utilities/formatters";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import {
  CapCallOrDistribution,
  CapCallOrDistributionTransaction,
  CapCallOrDistributionTransactionImpact,
} from "../../../../../api/adminApi";

interface InvestorCapitalActivityTableProps {
  cashFlows: CapCallOrDistribution[];
  isLoading: boolean;
}

const InvestorCapitalActivityTable = ({ cashFlows, isLoading }: InvestorCapitalActivityTableProps) => {
  const [expandedRowIds, setExpandedRowIds] = useState(Object.fromEntries(cashFlows.map((row) => [row.id, false])));
  const handleRowToggle = (id: string) => setExpandedRowIds((rowIds) => ({ ...rowIds, [id]: !rowIds[id] }));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Fund</TableCell>
          <TableCell width={rowWidths.date}>Due Date</TableCell>
          <TableCell width={rowWidths.amount} align="right">
            Amount
          </TableCell>
          <TableCell width={rowWidths.unfunded} align="right">
            Impact on Unfunded
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cashFlows.length > 0
          ? cashFlows.map((row) => (
              <RowWithDetails
                key={row.id}
                row={row}
                expanded={!!expandedRowIds[row.id]}
                onToggleClick={handleRowToggle}
              />
            ))
          : !isLoading && <EmptyDataRow />}
      </TableBody>
    </Table>
  );
};

interface MasterRowProps {
  row: CapCallOrDistribution;
  expanded: boolean;
  onToggleClick: (rowId: string) => void;
}

const MasterRow = ({ row, expanded, onToggleClick }: MasterRowProps) => {
  const handleToggleButtonClick = () => onToggleClick(row.id);

  const { statusText, statusColor } = defined(statusMap[row.status]);

  const rowCurrency = row.transactions.find((t) => !!t.currency)?.currency || "USD";

  return (
    <TableRow hover={true} sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell padding="checkbox">
        <IconButton onClick={handleToggleButtonClick}>
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </TableCell>
      <TableCell width={rowWidths.name}>{row.name}</TableCell>
      <TableCell width={rowWidths.status}>
        <ColorTag text={statusText} color={statusColor} />
      </TableCell>
      <TableCell width={rowWidths.fund}>
        <TypographyTooltipEllipsis text={row.fundName} />
      </TableCell>
      <TableCell width={rowWidths.date}>{convertISODate(row.date)}</TableCell>
      <TableCell width={rowWidths.amount} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.amount * -1, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.unfunded, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const RowWithDetails = ({ row, expanded, onToggleClick }: MasterRowProps) => (
  <>
    <MasterRow row={row} expanded={expanded} onToggleClick={onToggleClick} />
    <ExpandedWrapper expanded={expanded}>
      {row.transactions.map((transaction) => (
        <DetailRow key={transaction.id} transaction={transaction} />
      ))}
    </ExpandedWrapper>
  </>
);

const DetailRow = ({ transaction }: { transaction: CapCallOrDistributionTransaction }) => {
  const impactTextMap: Record<CapCallOrDistributionTransactionImpact, string> = {
    None: "None",
    Increases: "Increases",
    Decreases: "Decreases",
  };

  return (
    <TableRow
      hover={true}
      sx={(them) => ({
        "&.MuiTableRow-root": {
          borderBottom: `1px dashed ${them.palette.divider}`,
          "&:last-child": {
            borderBottom: 0,
          },
          td: {
            borderBottom: 0,
          },
        },
      })}
    >
      <TableCell width="auto" align="left" sx={{ pl: 2 }}>
        <Typography color="text.secondary">{transaction.detailsDescription}</Typography>
      </TableCell>
      <TableCell width={rowWidths.amount} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">
          {formatMoney(transaction.netAmount * -1, transaction.currency || "USD", { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">{impactTextMap[transaction.impact]}</Typography>
      </TableCell>
    </TableRow>
  );
};

const ExpandedWrapper = ({ expanded, children }: PropsWithChildren<{ expanded: boolean }>) => {
  const cellSx = { p: 0, border: expanded ? "" : "unset" };
  return (
    <TableRow>
      <TableCell sx={cellSx} colSpan={3} />
      <TableCell sx={cellSx} colSpan={8}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={(theme) => ({ bgColor: theme.palette.grey[100], mr: 1, my: 1.5 })}>
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const EmptyDataRow = () => {
  return (
    <TableRow key="empty">
      <TableCell colSpan={8} align="center">
        <Typography color="text.secondary">No data</Typography>
      </TableCell>
    </TableRow>
  );
};

const rowWidths = {
  name: "20%",
  status: 130,
  fund: "auto",
  date: 120,
  amount: 200,
  unfunded: 200,
};

const statusMap: Record<number, { statusText: string; statusColor: string }> = {
  0: { statusText: "Unpaid", statusColor: red[50] },
  1: { statusText: "Partially Paid", statusColor: lime[50] },
  2: { statusText: "Paid", statusColor: green[50] },
};

export default InvestorCapitalActivityTable;
