import TimeIcon from "@mui/icons-material/AccessTimeRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import PublishIcon from "@mui/icons-material/LanguageRounded";
import { Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import ActionsMenuButton from "../../../../shared/components/ActionsMenuButton";
import InlineItemsList from "../../../../shared/components/InlineItemsList";
import { distinct } from "../../../../shared/utilities/arrayHelper";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { DocumentCollection, InvestorPortalMessageSource } from "../../../api/types/documentCollectionTypes";
import UnpublishIcon from "../../../icons/LanguageOffIcon";
import { investorPortalMessageSourcesCaptionsMap } from "../../../utilities/enumCaptions";
import CategoryInlineTags from "../../common/CategoryInlineTags";
import DocumentCollectionStatusTag from "./DocumentCollectionStatusTag";
import { useDocumentCollectionsPageContext } from "./DocumentCollectionsPageContext";

const TotalEmailsCell = ({ row }: { row: DocumentCollection }) => {
  if (row.totalEmails) {
    return (
      <Tooltip title={row.notDeliveredEmails > 0 ? "All Emails / Not Delivered Emails" : "All Emails"} arrow>
        <Stack direction="row">
          <Typography>{row.totalEmails}</Typography>
          {row.notDeliveredEmails > 0 && (
            <>
              <Typography>{" / "}</Typography>
              <Typography color="error">{row.notDeliveredEmails}</Typography>
            </>
          )}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="No Emails" arrow>
      <Typography>-</Typography>
    </Tooltip>
  );
};

const ActionsCell = ({ row }: { row: DocumentCollection }) => {
  const { hasPermissionsToManage, onDownloadDocumentCollection, onChangeDocumentCollectionsStatus } =
    useDocumentCollectionsPageContext();

  const items = [
    {
      icon: <DownloadIcon color="action" />,
      label: "Download",
      onClick: () => onDownloadDocumentCollection(row.id),
    },
  ];

  if (hasPermissionsToManage && row.availableOperations.includes("MakeLive")) {
    items.push({
      icon: <PublishIcon color="action" />,
      label: "Publish",
      onClick: () => onChangeDocumentCollectionsStatus([row.id], "MakeLive"),
    });
  }

  if (hasPermissionsToManage && row.availableOperations.includes("MakePreview")) {
    items.push({
      icon: <UnpublishIcon color="action" />,
      label: "Unpublish",
      onClick: () => onChangeDocumentCollectionsStatus([row.id], "MakePreview"),
    });
  }

  if (hasPermissionsToManage && row.availableOperations.includes("Revoke")) {
    items.push({
      icon: <DeleteIcon color="error" />,
      label: "Delete",
      onClick: () => onChangeDocumentCollectionsStatus([row.id], "Revoke"),
    });
  }

  return <ActionsMenuButton items={items} />;
};

export const columnDefinitions: GridColDef<DocumentCollection>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 3,
    minWidth: 160,
    cellClassName: "highlighted-action",
  },
  {
    field: "funds",
    headerName: "Fund",
    flex: 3,
    minWidth: 160,
    renderCell: ({ row }) =>
      row.funds.length === 0 ? (
        "-"
      ) : (
        <InlineItemsList
          displayCount={1}
          items={distinct(row.funds.map((f) => f.name))}
          propGetter={(name) => name}
          justify="flex-start"
        />
      ),
  },
  {
    field: "categories",
    headerName: "Category",
    flex: 3,
    minWidth: 256,
    renderCell: ({ row }) => <CategoryInlineTags categoryNames={row.categories} maxDisplayedCategories={2} />,
  },
  {
    field: "messageLevel",
    headerName: "Level",
    flex: 1,
    minWidth: 96,
  },
  {
    field: "publishDate",
    headerName: "Publish Date",
    flex: 1.5,
    minWidth: 120,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1.5,
    minWidth: 128,
    renderCell: ({ row }) => (
      <>
        <DocumentCollectionStatusTag status={row.status} />
        {row.isStatusChangeScheduled && (
          <Tooltip arrow title="Status change in progress">
            <TimeIcon color="action" sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </>
    ),
  },
  {
    field: "messageSource",
    headerName: "Source",
    flex: 1.5,
    minWidth: 120,
    valueFormatter: (value: InvestorPortalMessageSource) => investorPortalMessageSourcesCaptionsMap[value],
  },
  {
    field: "numberOfDocuments",
    type: "number",
    headerName: "Documents",
    headerAlign: "center",
    align: "center",
    flex: 1,
    minWidth: 96,
  },
  {
    field: "totalEmails",
    headerName: "Emails",
    headerAlign: "center",
    align: "center",
    flex: 1,
    minWidth: 112,
    renderCell: ({ row }) => <TotalEmailsCell row={row} />,
  },
  {
    field: "actions",
    headerName: "",
    resizable: false,
    width: 40,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
