import { Theme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const getSubmissionDataGridFormStyles = (t: Theme) => ({
  borderColor: t.palette.divider,
  ".MuiDataGrid-columnHeaders": {
    borderTop: `1px solid ${t.palette.divider}`,
    ".MuiDataGrid-columnHeader": {
      ":first-of-type": {
        borderLeft: `1px solid ${t.palette.divider}`,
      },
      "&.MuiDataGrid-columnHeader--last": {
        borderRight: `1px solid ${t.palette.divider}`,
      },
      "&.readonly-header": {
        backgroundColor: grey[50],
      },
    },
  },
  ".MuiDataGrid-columnHeaderTitleContainerContent": {
    width: "100%",
  },
  ".MuiDataGrid-row": {
    ":last-of-type": {
      borderBottom: `1px solid ${t.palette.divider}`,
      ".MuiDataGrid-cell--pinnedLeft": {
        borderBottom: `1px solid ${t.palette.divider}`,
      },
    },
    ".MuiDataGrid-cell": {
      ":first-of-type": {
        borderLeft: `1px solid ${t.palette.divider}`,
      },
      ":last-of-type": {
        borderRight: `1px solid ${t.palette.divider}`,
      },
    },
    "& .readonly-cell": {
      backgroundColor: grey[50],
    },
    "& .total-cell": {
      backgroundColor: grey[200],
      fontWeight: "bold",
    },
    "& .secondary-name-cell": {
      fontStyle: "oblique",
    },
  },
});
