import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { InvoiceDetailsLine } from "../invoiceDetailsState";
import NumberTextField from "../../../../../../../shared/components/NumberTextField.tsx";

interface Props extends Pick<GridRenderCellParams<InvoiceDetailsLine, number | undefined>, "id" | "value" | "field"> {
  valueFormatter?: (value: number) => string;
}

const InvoiceLineAmount = ({ id, value, field, valueFormatter }: Props) => {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: number | undefined) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <NumberTextField
      fullWidth
      numberValue={value}
      onNumberChange={handleChange}
      selectOnFocus
      autoFocus
      valueFormatter={valueFormatter}
      slotProps={{
        htmlInput: { sx: { textAlign: "right" } },
      }}
    />
  );
};

InvoiceLineAmount.displayName = "InvoiceLineAmount";

export default InvoiceLineAmount;
