import { ReportAccessType } from "../../api/biClient.types";

export function getLabelByAccess(access: ReportAccessType) {
  switch (access) {
    case ReportAccessType.Owner:
      return "Owner";
    case ReportAccessType.FullAccess:
      return "Full access";
    case ReportAccessType.Write:
      return "Can edit";
    case ReportAccessType.Read:
      return "Can view";
    case ReportAccessType.NoAccess:
      return "No access";
  }
  return "No access";
}

export function getMaxAccessByPermisions(permissions: string[]): string {
  if (permissions.includes("ShareReports")) {
    return "Full access";
  }
  if (permissions.includes("ManageReports")) {
    return "Can edit";
  }
  if (permissions.includes("ViewReports")) {
    return "Can view";
  }
  return "Can view";
}

export function getAllowedAccessByPermisions(
  permissions: string[],
  currentUserAccess: ReportAccessType
): ReportAccessType[] {
  let allowedAccessList: ReportAccessType[] = [ReportAccessType.NoAccess];
  if (permissions.includes("ShareReports")) {
    allowedAccessList = [ReportAccessType.FullAccess, ReportAccessType.Write, ReportAccessType.Read];
  } else if (permissions.includes("ManageReports")) {
    allowedAccessList = [ReportAccessType.Write, ReportAccessType.Read];
  } else if (permissions.includes("ViewReports")) {
    allowedAccessList = [ReportAccessType.Read];
  }

  if (currentUserAccess === ReportAccessType.Supervisor || currentUserAccess === ReportAccessType.Owner) {
    allowedAccessList = [ReportAccessType.Owner, ...allowedAccessList];
  }
  return allowedAccessList;
}

export function checkAccess(access: ReportAccessType, permission: ReportAccessType): boolean {
  return ((access & permission) as ReportAccessType) === permission;
}
