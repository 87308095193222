const createMemoryStorage = (): Storage => {
  let data: Record<string, string> = {};

  const storage: Storage = {
    getItem: (key: string): string | null => {
      if (data[key] === undefined) {
        return null;
      }
      return data[key];
    },
    setItem: (key: string, value: string) => {
      data[key] = value;
    },
    removeItem: (key: string): void => {
      delete data[key];
    },
    clear: (): void => {
      data = {};
    },
    length: -1,
    key: () => null,
  };

  return storage;
};

const isStorageSupported = (storage: Storage) => {
  try {
    const key = "___storage_supported__";
    storage.setItem(key, "1");
    storage.removeItem(key);
    return true;
  } catch {
    return false;
  }
};

let localStorageIntsance: Storage | undefined;
let sessionStorageInstance: Storage | undefined;

const getLocalStorage = () => {
  if (!localStorageIntsance) {
    localStorageIntsance = isStorageSupported(window.localStorage) ? window.localStorage : createMemoryStorage();
  }
  return localStorageIntsance;
};

const getSessionStorage = () => {
  if (!sessionStorageInstance) {
    sessionStorageInstance = isStorageSupported(window.sessionStorage) ? window.sessionStorage : createMemoryStorage();
  }
  return sessionStorageInstance;
};

class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  getItem = <T>(key: string): NonNullable<T> | undefined => {
    const value = this.storage.getItem(key);
    return value === null ? undefined : (JSON.parse(value) as NonNullable<T>);
  };

  getItemWithFallback = <T>(key: string, fallback: NonNullable<T>): NonNullable<T> => {
    const value = this.storage.getItem(key);
    return value === null ? fallback : (JSON.parse(value) as NonNullable<T>);
  };

  setItem = <T>(key: string, item: NonNullable<T>): void => this.storage.setItem(key, JSON.stringify(item));

  removeItem = (key: string): void => this.storage.removeItem(key);

  clear = (): void => this.storage.clear();
}

export const localStorageService = new StorageService(getLocalStorage());

export const sessionStorageService = new StorageService(getSessionStorage());

export const memoryStorageService = new StorageService(createMemoryStorage());
