import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Stack, Typography } from "@mui/material";
import {
  GridBasicGroupNode,
  GridColDef,
  GridGroupingColDefOverride,
  GridKeyValue,
  GridRowId,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import ExpandCollapseButton from "../../../../../shared/components/ExpandCollapseButton";
import DataGridGroupHeader from "../../../../../shared/components/grid/DataGridGroupHeader";
import { Maybe } from "../../../../../shared/types";
import { formatDateTime } from "../../../../../shared/utilities/dateUtils";
import { ClientInfo } from "../../../../api/types/clientTypes";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import ClientLogo from "../../home/ClientLogo";
import AssignClientMenuButton from "./AssignClientMenuButton";
import { useConfigurationsGridActionsContext } from "./ConfigurationsGridActionsContext";

export interface ConfigurationRow {
  id: string;
  group: string;
  configurationId: string;
  clientCode: string;
  clientTitle: string;
  clientLogoUrl: string | undefined;
  isEmpty?: boolean;
}

const groupingKeySeparator = "|||";

const createGroupingKey = (config: DataModelConfiguration) => {
  const modifiedTimestamp = config.updateAt ? parseISO(config.updateAt).getTime() : 0;
  return [config.id, config.name, config.clientCodes.length, modifiedTimestamp].join(groupingKeySeparator);
};

const parseGroupingKey = (groupingKey: Maybe<GridKeyValue>) => {
  const [id, name, clientCodesLength, modifiedTimestamp] = (groupingKey?.toString() ?? "").split(groupingKeySeparator);
  return {
    id,
    name,
    clientCodesLength: clientCodesLength ? parseInt(clientCodesLength, 10) : 0,
    modifiedTimestamp: modifiedTimestamp ? parseInt(modifiedTimestamp, 10) : 0,
  };
};

export const getRows = (
  configurations: DataModelConfiguration[],
  orgClientCode: string,
  allClients: ClientInfo[]
): ConfigurationRow[] => {
  const companies = allClients.filter((c) => c.type === "FundManager" && c.managedByClients.includes(orgClientCode));
  const companyMap = new Map(companies.map((client) => [client.clientCode, client]));
  return configurations.flatMap((config) => {
    const clients = config.clientCodes.map((clientCode) => companyMap.get(clientCode)).filter(Boolean) as ClientInfo[];
    const group = createGroupingKey(config);
    if (clients.length === 0) {
      return [
        {
          id: `${config.id}_empty`,
          group,
          configurationId: config.id,
          clientCode: "",
          clientTitle: "",
          clientLogoUrl: undefined,
          isEmpty: true,
        },
      ];
    }

    return clients.map((client) => ({
      id: `${config.id}_${client.clientCode}`,
      group,
      configurationId: config.id,
      clientCode: client.clientCode,
      clientTitle: client.title,
      clientLogoUrl: client.branding.logoMarkUrl,
    }));
  });
};

interface ConfigurationNameGroupingCellProps {
  id: GridRowId;
  rowNode: GridBasicGroupNode;
  name: string;
}

const ConfigurationNameGroupingCell = ({ id, rowNode, name }: ConfigurationNameGroupingCellProps) => {
  const apiRef = useGridApiContext();

  const handleToggle = () => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" maxWidth="100%">
      <ExpandCollapseButton
        expanded={rowNode.childrenExpanded === true}
        onToggle={handleToggle}
        tabIndex={-1}
        ExpandIcon={ArrowRightIcon}
        CollapseIcon={ArrowDropDownIcon}
      />
      <Typography variant="subtitle2" noWrap>
        {name}
      </Typography>
    </Stack>
  );
};

const ClientTitleCell = ({ row }: { row: ConfigurationRow }) => (
  <Stack direction="row" spacing={1.5} pl={5} alignItems="center">
    {row.isEmpty && <Typography color="text.secondary">This configuration is not used by any company</Typography>}
    {!row.isEmpty && (
      <>
        <ClientLogo logoSrc={row.clientLogoUrl} size="small" />
        <Typography noWrap>{row.clientTitle}</Typography>
      </>
    )}
  </Stack>
);

const ConfigurationActionsCell = ({ configurationId }: { configurationId: string }) => {
  const { onRenameConfiguration, onDeleteConfiguration } = useConfigurationsGridActionsContext();

  return (
    <ActionsMenuButton
      items={[
        {
          label: "Rename",
          icon: <RenameIcon color="secondary" />,
          onClick: () => onRenameConfiguration(configurationId),
        },
        {
          label: "Delete",
          icon: <DeleteIcon color="error" />,
          onClick: () => onDeleteConfiguration(configurationId),
        },
      ]}
    />
  );
};

export const getGroupingColumnDefinition = (): GridGroupingColDefOverride<ConfigurationRow> => ({
  headerName: "Name",
  sortable: false,
  flex: 2,
  hideDescendantCount: true,
  renderHeader: (params) => <DataGridGroupHeader params={params} />,
  renderCell: ({ id, row, rowNode }) => {
    if (rowNode.type === "group") {
      return (
        <ConfigurationNameGroupingCell
          id={id}
          rowNode={rowNode}
          name={parseGroupingKey(rowNode.groupingKey)?.name ?? ""}
        />
      );
    }

    return <ClientTitleCell row={row} />;
  },
});

export const getColumnDefinitions = ({
  hasEditPermissions,
}: {
  hasEditPermissions: boolean;
}): GridColDef<ConfigurationRow>[] => {
  const columns: GridColDef<ConfigurationRow>[] = [
    {
      field: "clientCodes",
      headerName: "Companies",
      sortable: false,
      flex: 1,
      renderCell: ({ rowNode }) => {
        if (rowNode.type !== "group") {
          return null;
        }

        const { clientCodesLength } = parseGroupingKey(rowNode.groupingKey);
        return <Typography>{clientCodesLength || "None"}</Typography>;
      },
    },
    {
      field: "updateAt",
      headerName: "Modified at",
      sortable: false,
      flex: 1,
      renderCell: ({ rowNode }) => {
        if (rowNode.type !== "group") {
          return null;
        }

        const { modifiedTimestamp } = parseGroupingKey(rowNode.groupingKey);
        return <Typography>{modifiedTimestamp ? formatDateTime(new Date(modifiedTimestamp)) : "-"}</Typography>;
      },
    },
  ];

  if (hasEditPermissions) {
    columns.push({
      field: "actions",
      headerName: "",
      sortable: false,
      width: 80,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row, rowNode }) => {
        if (rowNode.type !== "group") {
          return row.clientCode ? (
            <AssignClientMenuButton
              clientCode={row.clientCode}
              clientTitle={row.clientTitle}
              currentConfigurationId={row.configurationId}
            />
          ) : null;
        }

        const { id } = parseGroupingKey(rowNode.groupingKey);
        return id ? <ConfigurationActionsCell configurationId={id} /> : null;
      },
    });
  }

  return columns;
};
