import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { Link } from "react-router";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";
import SplitButton, { SplitButtonOption } from "../../../../../../shared/components/SplitButton";
import usePageTitle from "../../../../../../shared/hooks/usePageTitle";
import { ReportAccessType, ReportTemplateDto } from "../../../../../../shared/reporting/api/biClient.types";
import { useGroupsContext } from "../../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { useReportTemplatesContext } from "../../../../../../shared/reporting/contexts/ReportTemplatesContext";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import ReportsPageContainer from "../components/ReportsPageContainer";
import ReportTemplates from "../components/ReportTemplates";
import { ReportInfoExt } from "../types/reportTypes";
import { getEditReportUrl } from "../utilities/editReportUrl";
import { useUiReportsContext } from "./contexts/ReportsContext";
import ReportsGridWrapper from "./grid/ReportsGridWrapper";

const CompanyUiReportsContainer = () => {
  usePageTitle("Reports");

  const { clientCode } = useClientContext();
  const { reports } = useUiReportsContext();
  const { groups: reportGroups } = useGroupsContext();
  const { reportTemplates, ui: templatesUi, groupsUi: templateGroupsId } = useReportTemplatesContext();

  const [openTemplates, setOpenTemplate] = useState(false);

  const allReports = useMemo(() => {
    return reports
      .filter((r) => r.authorization.access !== ReportAccessType.NoAccess)
      .map((r): ReportInfoExt => {
        const rep = { ...r } as ReportInfoExt;
        const group = defined(reportGroups[clientCode]).find((rg) => rg.id === rep.groupId);
        rep.group = group?.caption || "";
        rep.groupOrder = group?.order || Number.MAX_VALUE;
        return rep;
      });
  }, [reports, reportGroups, clientCode]);

  const primaryOption = useMemo(() => {
    return {
      title: "Create New",
      handler: () => window.open(getEditReportUrl(clientCode, "new"), "_blank", "noopener noreferrer"),
      icon: AddRoundedIcon,
    } as SplitButtonOption;
  }, [clientCode]);

  const secondaryOptions = useMemo(() => {
    return [
      primaryOption,
      {
        title: "Create from Template",
        handler: () => setOpenTemplate(true),
        icon: DescriptionOutlinedIcon,
        disabled: reportTemplates.length === 0,
      },
    ] as SplitButtonOption[];
  }, [primaryOption, reportTemplates.length]);

  return (
    <ReportsPageContainer
      title="Reports"
      Actions={
        <>
          <Button
            component={Link}
            to={pageRoutes.reportLevels}
            variant="outlined"
            color="secondary"
            startIcon={<FolderOpenRoundedIcon />}
          >
            Group Settings
          </Button>
          <AuthorizedBox permissions={["ManageReports"]}>
            {reportTemplates.length > 0 && (
              <ReportTemplates
                openTemplates={openTemplates}
                clientCode={clientCode}
                onSetTemplateOpen={(v) => setOpenTemplate(v)}
                templates={reportTemplates as ReportTemplateDto[]}
                disabled={
                  !templatesUi.loaded ||
                  !templateGroupsId?.loaded ||
                  templatesUi.error !== undefined ||
                  templateGroupsId?.error !== undefined
                }
                loading={!templatesUi.loaded || !templateGroupsId?.loaded}
              />
            )}
          </AuthorizedBox>
          <AuthorizedBox permissions={["ManageReports"]}>
            <SplitButton
              secondaryOptions={secondaryOptions}
              primaryOption={primaryOption}
              primaryActionStartIcon={<AddRoundedIcon />}
            />
          </AuthorizedBox>
        </>
      }
    >
      <ReportsGridWrapper reports={allReports} />
    </ReportsPageContainer>
  );
};

export default CompanyUiReportsContainer;
