import { Grid2 } from "@mui/material";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import { saveCsvFile } from "../../../../../../shared/services/downloadFile";
import { setAllGroupsExpansion } from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo, InvestorDeliveryInfo } from "../../../../../api/types/documentCollectionTypes";
import { createNestedSearchFilter } from "../../searchFilter";
import { documentsToCsv } from "../csv-formatters";
import DocumentsTableFundLevel from "./DocumentsTableFundLevel";
import DocumentsViewActions, { DocumentsFilterValue } from "./DocumentsViewActions";
import DocumentPreviewContainer from "./document-preview/DocumentPreviewContainer";
import { FundLevelSelectedItems, getSelectedItemsForFundLevel } from "./selectionHelper";

interface Props {
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
  showFundName: boolean;
}

const applySearch = createNestedSearchFilter<DocumentInfo, InvestorDeliveryInfo, "investorDeliveries">(
  3,
  (c) => [c.name, c.category],
  "investorDeliveries",
  (d) => [d.investorTitle]
);

const applyFilter = (documents: DocumentInfo[], filterValue: DocumentsFilterValue) => {
  if (filterValue === "All") {
    return documents;
  }

  const targetIsViewed = filterValue === "Viewed";
  return documents.reduce<DocumentInfo[]>((result, doc) => {
    const investorDeliveries = doc.investorDeliveries.filter((i) => !!i.isViewed === targetIsViewed);
    if (investorDeliveries.length > 0) {
      result.push({ ...doc, investorDeliveries });
    }

    return result;
  }, []);
};

const getFilterValueCounts = (documents: DocumentInfo[]) => {
  let allCount = 0;
  let viewedCount = 0;
  let notViewedCount = 0;

  for (const doc of documents) {
    for (const delivery of doc.investorDeliveries) {
      if (delivery.isViewed) {
        viewedCount++;
      } else {
        notViewedCount++;
      }
      allCount++;
    }
  }

  return { allCount, viewedCount, notViewedCount };
};

const DocumentsViewFundLevel = ({ documents, showPublishingStatus, showFundName }: Props) => {
  const gridApiRef = useGridApiRef();

  const [filteredItems, setFilteredItems] = useState<DocumentInfo[]>(documents);
  const [filterValue, setFilterValue] = useState<DocumentsFilterValue>("All");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<FundLevelSelectedItems>();

  const { allCount, viewedCount, notViewedCount } = useMemo(() => getFilterValueCounts(documents), [documents]);

  const updateFilteredItems = (documents: DocumentInfo[], searchTerm: string, filterValue: DocumentsFilterValue) => {
    const result = applyFilter(applySearch(documents, searchTerm), filterValue);
    setFilteredItems(result);
    if (filterValue !== "All" || searchTerm.length >= 3) {
      setAllGroupsExpansion(gridApiRef, true);
    }
  };

  const handleSearch = (searchTerm: string) => {
    updateFilteredItems(documents, searchTerm, filterValue);
    setCurrentSearchTerm(searchTerm);
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    const newSelectedItems = getSelectedItemsForFundLevel(newSelection, documents);
    setSelectedItems(newSelectedItems);
  };

  const handleFilterChange = (newValue: DocumentsFilterValue) => {
    updateFilteredItems(documents, currentSearchTerm, newValue);
    setFilterValue(newValue);
  };

  const handleExportToCsv = () => {
    const csv = documentsToCsv(filteredItems, "Fund");
    saveCsvFile(csv, "document_views.csv");
  };

  return (
    <Grid2 container height="100%">
      <Grid2 size={7} height="100%" sx={{ pb: 8 }}>
        <DocumentsViewActions
          allCount={allCount}
          viewedCount={viewedCount}
          notViewedCount={notViewedCount}
          filterValue={filterValue}
          onFilterChange={handleFilterChange}
          onSearch={handleSearch}
          onExportToCsv={handleExportToCsv}
          exportDiabled={filteredItems.length === 0}
        />

        <DocumentsTableFundLevel
          apiRef={gridApiRef}
          documents={filteredItems}
          showPublishingStatus={showPublishingStatus}
          showFundName={showFundName}
          onSelectionChange={handleSelectionChange}
        />
      </Grid2>
      <Grid2 size={5} height="100%">
        <DocumentPreviewContainer
          messageLevel="Fund"
          documents={selectedItems?.selectedDocument ? [selectedItems?.selectedDocument] : []}
          investor={selectedItems?.selectedInvestor}
        />
      </Grid2>
    </Grid2>
  );
};

export default DocumentsViewFundLevel;
