import { MetricCellValueUpdate, SaveSubmissionLayoutBlockInputRequest } from "../../../api/dataCollectionTypes";

export type EditedFormValuesState = Record<string, EditedFormBlockValuesState>;

interface EditedFormBlockValuesState {
  cellValueUpdatesMap: Map<string, MetricCellValueUpdate>;
}

const getMapKey = (cellValueUpdate: MetricCellValueUpdate): string =>
  `${cellValueUpdate.metricId}-${cellValueUpdate.columnId}-${cellValueUpdate.metricExtensionId ?? ""}`;

export const getInitialEditedFormValuesState = (): EditedFormValuesState => ({});

// Selectors

export const isEditedFormValuesStateEmpty = (state: EditedFormValuesState): boolean =>
  Object.values(state).every((values) => values.cellValueUpdatesMap.size === 0);

export const getFormUpdatesPerBlock = (
  state: EditedFormValuesState
): [string, SaveSubmissionLayoutBlockInputRequest][] => {
  const updates: [string, SaveSubmissionLayoutBlockInputRequest][] = [];

  for (const [blockId, blockValues] of Object.entries(state)) {
    const { cellValueUpdatesMap } = blockValues;

    if (cellValueUpdatesMap.size > 0) {
      updates.push([blockId, { cellValueUpdates: [...cellValueUpdatesMap.values()] }]);
    }
  }

  return updates;
};

// Actions

type StateAction = (state: EditedFormValuesState) => EditedFormValuesState;

export const addCellValueUpdatesAction =
  (blockId: string, cellValueUpdates: MetricCellValueUpdate[]): StateAction =>
  (state) => {
    const blockCellValueUpdatesMap = state[blockId]?.cellValueUpdatesMap ?? new Map<string, MetricCellValueUpdate>();

    for (const update of cellValueUpdates) {
      blockCellValueUpdatesMap.set(getMapKey(update), update);
    }

    return {
      ...state,
      [blockId]: {
        cellValueUpdatesMap: blockCellValueUpdatesMap,
      },
    };
  };
