type EnvVar =
  | "PORTAL_BACKEND_BASE_URI"
  | "PORTAL_REDIRECT_URI"
  | "APP_INSIGHTS_CONN_STRING"
  | "LOGIN_APP_URI"
  | "APP_VERSION"
  | "PAGE_TITLE";

export const importEnvVariable = (variableName: string): string =>
  (import.meta.env[variableName] as string | undefined) ?? "";

const importVariables = () => ({
  PORTAL_BACKEND_BASE_URI: importEnvVariable("REACT_APP_PORTAL_BACKEND_BASE_URI"),
  PORTAL_REDIRECT_URI: importEnvVariable("REACT_APP_PORTAL_REDIRECT_URI"),
  APP_INSIGHTS_CONN_STRING: importEnvVariable("REACT_APP_APP_INSIGHTS_CONN_STRING"),
  LOGIN_APP_URI: importEnvVariable("REACT_APP_LOGIN_APP_URI"),
  APP_VERSION: importEnvVariable("REACT_APP_VERSION"),
  PAGE_TITLE: importEnvVariable("REACT_APP_PAGE_TITLE"),
});

let variables: Record<EnvVar, string> | undefined = undefined;

export const getSharedVariables = (): Record<EnvVar, string> => {
  if (variables === undefined) {
    variables = importVariables();
  }
  return variables;
};

export const getBackendBaseUrl = (segment: string): string => {
  const localServerUrl =
    importEnvVariable(`REACT_APP_LOCAL_SERVER_${segment.toUpperCase()}`) || importEnvVariable("REACT_APP_LOCAL_SERVER");

  if (localServerUrl) {
    return localServerUrl;
  }

  return `${getSharedVariables().PORTAL_BACKEND_BASE_URI}/${segment}`;
};
