import { GridCellParams } from "@mui/x-data-grid-premium";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { ObjectAccessCategory } from "../../../../api/types/objectTypes";
import { useDataRequestSetupPageContext } from "../data-request-templates/DataRequestSetupPageContext";
import { columnDefinitions } from "./dataRequestCategoriesGridDataProvider";

interface Props {
  onCategoryEdit: (categoryId: string) => void;
}

const DataRequestCategoriesGrid = ({ onCategoryEdit }: Props) => {
  const { categories } = useDataRequestSetupPageContext();

  const handleCellClick = ({ field, row }: GridCellParams<ObjectAccessCategory>) => {
    if (field === "actions") {
      onCategoryEdit(row.id);
    }
  };

  return (
    <DataGrid<ObjectAccessCategory>
      rows={categories}
      columns={columnDefinitions}
      noRowsText="No categories"
      onCellClick={handleCellClick}
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataRequestCategoriesGrid;
