import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { balanceFieldSet, getBalanceFields } from "../../../../../../shared/components/balances/balanceFieldset";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { logError } from "../../../../../../shared/logging";
import { TabularDataCell } from "../../../../../../shared/reporting/api/biClient.types";
import { distinct } from "../../../../../../shared/utilities/arrayHelper";
import { convertISODateShort } from "../../../../../../shared/utilities/dateUtils";
import { stringifyValue } from "../../../../../../shared/utilities/stringHelper";
import { InvestorBalanceResponse } from "../../../../../api/adminApi";

interface InvestorBalanceTableProps {
  balanceResponse: InvestorBalanceResponse | undefined;
  isLoading: boolean;
}

type TabularCell = {
  id: string;
  fields: { [key: string]: TabularDataCell };
  isTotal?: boolean;
};

const InvestorBalanceTable = ({ balanceResponse, isLoading }: InvestorBalanceTableProps) => {
  const balanceFields = useMemo(
    () =>
      getBalanceFields({
        exclude: [balanceFieldSet.investorName.guid, balanceFieldSet.communicationGroupName.guid],
      }),
    []
  );

  const loading = isLoading || !balanceResponse;
  const reportingDates = balanceResponse?.fundReportingDates;
  const balanceData = balanceResponse?.balanceData || [];

  const rows: TabularCell[] = balanceData.map((data, index) => {
    return {
      id: index.toString(),
      fields: data,
    };
  });

  const allCurrencyCodes = distinct(
    rows.map((row) => row.fields[balanceFieldSet.currencyCode.guid]?.value?.toString() ?? "").filter(Boolean)
  );

  if (rows.length > 1 && allCurrencyCodes.length < 2) {
    const totals: { [key: string]: TabularDataCell } = {};

    rows.forEach((row) => {
      balanceFields.forEach((field) => {
        const value = row.fields[field.guid]?.value;
        if (value && field.config?.calculateTotal) {
          const numValue = +value;
          if (isNaN(numValue)) {
            logError(
              `Can't calculate total for non number value ${stringifyValue(value)}. FieldId: ${field.guid}`,
              "[InvestorBalanceTable]"
            );
          }
          const cellTotal = totals[field.guid];
          if (cellTotal && typeof cellTotal.value === "number") {
            cellTotal.value += numValue || 0;
          } else {
            totals[field.guid] = {
              value: numValue,
              formattedValue: "",
            };
          }
        }
      });
    });

    rows.push({
      id: "total",
      fields: totals,
      isTotal: true,
    });
  }

  const columns: GridColDef<TabularCell>[] = [
    {
      field: balanceFieldSet.communicationGroupName.guid,
      headerName: "Name",
      flex: 1.5,
      minWidth: 100,
      sortable: false,
      disableReorder: true,
      align: balanceFieldSet.communicationGroupName.config.align,
      headerAlign: balanceFieldSet.communicationGroupName.config.align,
      renderCell: ({ row }) => {
        const rowValue = row.fields[balanceFieldSet.communicationGroupName.guid]?.value;
        if (!rowValue) {
          return null;
        }
        const value = stringifyValue(rowValue);
        const currencyCode = row.fields[balanceFieldSet.currencyCode.guid]?.value?.toString() ?? "";
        const valueFormatted = balanceFieldSet.communicationGroupName.config?.format
          ? balanceFieldSet.communicationGroupName.config.format(value, currencyCode)
          : value;
        const reportingDate = reportingDates?.[value];
        return (
          <Stack>
            <TypographyTooltipEllipsis
              text={valueFormatted ?? row.fields[balanceFieldSet.communicationGroupName.guid]?.formattedValue}
            />
            {reportingDate && (
              <Typography color="text.secondary" variant="caption">{`as of ${convertISODateShort(
                reportingDate
              )}`}</Typography>
            )}
          </Stack>
        );
      },
    },
  ];

  const visibleFields = balanceFields.filter((field) => !field.config.hidden);

  const balanceColumns: GridColDef<TabularCell>[] = visibleFields.map((field) => {
    const config = field.config;
    return {
      field: field.guid,
      headerName: config.title,
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableReorder: true,
      align: config.align,
      headerAlign: config.align,
      resizable: false,
      availableAggregationFunctions: ["sum"],
      renderCell: ({ row }) => {
        const value = row.fields[field.guid]?.value;
        if (row.isTotal && !value) {
          return null;
        }

        const currencyCode = row.fields[balanceFieldSet.currencyCode.guid]?.value?.toString() ?? "";
        const valueFormatted = config?.format ? config.format(value?.toString(), currencyCode) : value?.toString();
        return (
          <Typography fontWeight={row.isTotal ? 500 : 400}>
            {valueFormatted ?? row.fields[field.guid]?.formattedValue}
          </Typography>
        );
      },
    };
  });

  columns.push(...balanceColumns);

  return (
    <DataGrid<TabularCell>
      getRowId={(row) => row.id}
      columns={columns}
      rows={rows}
      loading={loading}
      noRowsText="No data"
      multilineRows
    />
  );
};

export default InvestorBalanceTable;
