import qs from "qs";
import { ImpersonationTargetType } from "../../shared/api/types";
import { BusinessCentralSettings } from "../api/types/clientTypes";
import { pageRoutes } from "../routes";
import { getVariables } from "../variables";

export interface ImpersonationNavigationParams {
  target: ImpersonationTargetType;
  userEmail?: string;
  fundraisingId?: string;
}

const getSubPathAndTargetParamForImpersonation = (params: ImpersonationNavigationParams) => {
  switch (params.target) {
    case "Investor": {
      return { subPath: "", target: params.target.toLowerCase() };
    }
    case "Fundraising": {
      return {
        subPath: "/fundraising",
        target: params.fundraisingId
          ? `${params.target.toLowerCase()}:${params.fundraisingId}`
          : params.target.toLowerCase(),
      };
    }
    case "DataCollection": {
      return { subPath: "/data-collection", target: params.target.toLowerCase() };
    }
    default: {
      return { subPath: "", target: "investor" };
    }
  }
};

export const getInvestorPortalImpersonationUrl = (
  clientCode: string,
  params: ImpersonationNavigationParams
): string => {
  const { subPath, target } = getSubPathAndTargetParamForImpersonation(params);
  const user = params.userEmail || "any";
  const queryParams = { impersonation: "true", user, target };
  return `${getVariables().IR_PORTAL_URI}/${clientCode}${subPath}?${qs.stringify(queryParams)}`;
};

export const getBusinessCentralUrl = (clientCode: string, bcAppSettings: BusinessCentralSettings | undefined) => {
  if (!bcAppSettings) {
    return undefined;
  }

  const { tenantId, environment, companyName } = bcAppSettings;
  const bcUrl = `https://businesscentral.dynamics.com/${tenantId}/${environment}?${qs.stringify({ company: companyName })}`;
  return `/${clientCode}/${pageRoutes.bcRedirect}?${qs.stringify({ redirectUrl: bcUrl })}`;
};

export const getBusinessCentralTransactionUrl = (
  bcAppSettings: BusinessCentralSettings | undefined,
  businessCentralTransactionNo: string | undefined,
  businessCentralPostedTransactionNo: string | undefined,
  transactionTypeCode: string | undefined
) => {
  if (!bcAppSettings || !businessCentralTransactionNo || !transactionTypeCode) {
    return undefined;
  }

  const pageNo = businessCentralPostedTransactionNo !== undefined ? 50428 : 50425;
  const transactionNo = businessCentralPostedTransactionNo || businessCentralTransactionNo;

  const { tenantId, environment, companyName } = bcAppSettings;
  const queryParams = {
    company: companyName,
    page: pageNo,
    filter: `'No.' IS '${transactionNo}' AND 'Transaction Type Code' IS '${transactionTypeCode}'`,
  };
  return `https://businesscentral.dynamics.com/${tenantId}/${environment}?${qs.stringify(queryParams)}`;
};
