import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

interface Props<T extends string> {
  tabs: readonly T[];
  labels: readonly ReactNode[];
  value: T;
  disabledTabs?: T[];
  onChange: (selected: T) => void;
}

const PageTabs = <T extends string>({
  tabs,
  labels,
  value,
  disabledTabs,
  onChange,
  children,
}: PropsWithChildren<Props<T>>) => (
  <TabContext value={value}>
    <TabList onChange={(_, value: T) => onChange(value)}>
      {tabs.map((tab, i) => (
        <Tab
          sx={{ ml: i === 0 ? 3 : 0 }}
          key={tab}
          label={labels[i]}
          value={tab}
          disabled={disabledTabs?.includes(tab)}
        />
      ))}
    </TabList>
    {children}
  </TabContext>
);

export default PageTabs;
