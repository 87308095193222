import { useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { numberComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";

interface Props {
  pageViews: Record<number, number>;
}

const PageViewsChart = ({ pageViews }: Props) => {
  const theme = useTheme();
  const sortedPageViews = Object.entries(pageViews).sort(numberComparerBy(([key]) => parseInt(key, 10)));
  const xAxisData = sortedPageViews.map(([key]) => key);
  const seriesData = sortedPageViews.map(([, value]) => value);

  return (
    <BarChart
      series={[
        {
          label: "Time spent:",
          data: seriesData,
          valueFormatter: (value) => (value ? formatDurationInSeconds(value) : "None"),
        },
      ]}
      xAxis={[
        {
          id: "page_numbers",
          disableTicks: true,
          scaleType: "band",
          data: xAxisData,
          valueFormatter: (value: string, ctx) => (ctx.location === "tick" ? value : `Page ${value}`),
        },
      ]}
      yAxis={[
        {
          disableTicks: true,
          valueFormatter: (value: number | string) =>
            typeof value === "number" ? formatDurationInSeconds(value) : value,
          colorMap: {
            type: "piecewise",
            thresholds: [],
            colors: [theme.palette.primary.main],
          },
        },
      ]}
      grid={{ vertical: true, horizontal: true }}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default PageViewsChart;
