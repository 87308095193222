import { GridCellParams, GridEventListener } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { ObjectMetricExtension } from "../../../../api/dataCollectionTypes";
import {
  ExtensionBasedBlockRow,
  ExtensionRowCellValue,
  MetricAndScenarioTableBlockColumn,
} from "../../../../api/inputFormTypes";
import { MetricExtensionConfiguration } from "../../../../api/portfolioMonitoringTypes";
import useSingleClickGridEditing from "../../../../hooks/useSingleClickGridEditing";
import { makeNextEditableCellEdited } from "../../../../utilities/editableDataGridHelper";
import { defined } from "../../../../utilities/typeHelper";
import DataGrid from "../../../grid/DataGrid";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { getSubmissionDataGridFormStyles } from "../submissionTableFormStyles";
import AddMetricExtensionDialog from "./AddMetricExtensionDialog";
import { PortfolioMonitoringInputTableContextProvider } from "./PortfolioMonitoringInputTableContext";
import { getColumns, getRows, RowModel } from "./quarterlyDebtInputGridDataProvider";
import { calculateTotals } from "./tableTotalsCalculator";

interface Props {
  blockId: string;
  extensionConfiguration: MetricExtensionConfiguration;
  columnDefinitions: MetricAndScenarioTableBlockColumn[];
  rowDefinitions: ExtensionBasedBlockRow[];
}

interface AddExtensionDialogState {
  metricIds: string[];
  metricName: string;
  addedExtensionIds: string[];
}

const QuarterlyDebtInputForm = ({ blockId, extensionConfiguration, columnDefinitions, rowDefinitions }: Props) => {
  const { isSubmissionEditable, onBlockCellValuesEdit } = useDataSubmissionFormContext();

  const { cellModesModel, setCellModesModel, handleCellClick } = useSingleClickGridEditing();

  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));
  const [dialogState, setDialogState] = useState<AddExtensionDialogState>();

  const columns = useMemo(
    () => getColumns(columnDefinitions, extensionConfiguration, isSubmissionEditable),
    [columnDefinitions, extensionConfiguration, isSubmissionEditable]
  );

  const isCellEditable = useCallback(({ row }: { row: RowModel }) => row.type === "ExtensionOnly", []);

  const handleRowUpdate = useCallback(
    (newRow: RowModel, oldRow: RowModel) => {
      if (newRow.type !== "ExtensionOnly" || oldRow.type !== "ExtensionOnly") {
        return newRow;
      }

      const updatedRow = { ...newRow };

      const updatedValueEntry = Object.entries(updatedRow.values).find(
        ([columnId, currentExtValue]) => oldRow.values[columnId]?.displayValue !== currentExtValue.displayValue
      );

      if (updatedValueEntry !== undefined) {
        const [columnId, newExtValue] = updatedValueEntry;
        const colDef = columnDefinitions.find((col) => col.id === columnId);
        if (colDef !== undefined) {
          const metricId = colDef.metricId;
          const metricExtensionId = newExtValue.extensionId;
          const fallbackValue = colDef.dataType === "Money" || colDef.dataType === "Number" ? 0 : "";
          const value = newExtValue.displayValue ?? fallbackValue;
          onBlockCellValuesEdit(blockId, [{ metricId, metricExtensionId, columnId, value }]);
        }
      }

      setTimeout(
        () =>
          setRows((prevRows) => {
            const newRows = prevRows.map((row) => {
              if (row.id !== updatedRow.id) {
                return row;
              }

              return { ...updatedRow };
            });

            return calculateTotals(newRows);
          }),
        100
      );

      return updatedRow;
    },
    [blockId, columnDefinitions, onBlockCellValuesEdit]
  );

  const handleAddMetricExtensions = useCallback(
    (metricIds: string[], metricName: string) => {
      const addedExtensionIds = rows.flatMap((r) => {
        if (r.type === "ExtensionOnly") {
          const allExtValues = Object.values(r.values);
          return allExtValues.map((v) => v.extensionId);
        }

        return [];
      });

      setDialogState({
        metricIds,
        metricName,
        addedExtensionIds,
      });
    },
    [rows]
  );

  const handleMetricExtensionsAdded = useCallback(
    (addedExtensions: ObjectMetricExtension[], isNew: boolean) => {
      setDialogState(undefined);

      if (addedExtensions.length === 0) {
        return;
      }

      const extensionValue = defined(addedExtensions[0]).value;

      const cellValues = columnDefinitions
        .filter((colDef) => colDef.isEditable && ["Money", "Number", "Text"].includes(colDef.dataType))
        .map((colDef) => {
          const extension = addedExtensions.find((ext) => ext.metricId === colDef.metricId);
          if (extension === undefined) {
            return undefined;
          }

          const emptyValue = colDef.dataType === "Money" || colDef.dataType === "Number" ? 0 : "";
          return [colDef.id, { extensionId: extension.id, displayValue: emptyValue }, colDef.metricId];
        })
        .filter(Boolean) as Array<[string, ExtensionRowCellValue, string]>;

      setRows((prevRows) => {
        const newRow: RowModel = {
          type: "ExtensionOnly",
          isNew,
          id: extensionValue,
          indentationLevel: 0,
          extensionValue,
          values: Object.fromEntries(cellValues) as Record<string, ExtensionRowCellValue>,
        };

        // insert new row before the total row
        const totalRowIndex = prevRows.findIndex((r) => r.type === "Total");
        return [...prevRows.slice(0, totalRowIndex), newRow, ...prevRows.slice(totalRowIndex)];
      });

      onBlockCellValuesEdit(
        blockId,
        cellValues.map(([columnId, extensionCellValue, metricId]) => ({
          metricId,
          metricExtensionId: extensionCellValue.extensionId,
          columnId,
          value: extensionCellValue.displayValue ?? 0,
        }))
      );
    },
    [blockId, columnDefinitions, onBlockCellValuesEdit]
  );

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = useCallback(
    (params: GridCellParams<RowModel>, event) => {
      if (event.code !== "Tab") {
        return;
      }

      event.defaultMuiPrevented = true;
      event.preventDefault();
      const direction = event.shiftKey ? "previous" : "next";
      makeNextEditableCellEdited(direction, rows, columns, params, setCellModesModel, isCellEditable, (r) => r.id);
    },
    [columns, isCellEditable, rows, setCellModesModel]
  );

  return (
    <PortfolioMonitoringInputTableContextProvider onAddMetricExtensions={handleAddMetricExtensions}>
      <DataGrid<RowModel>
        columns={columns}
        rows={rows}
        columnHeaderHeight={110}
        pinnedColumns={{ left: ["name"] }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={setCellModesModel}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        disableColumnReorder
        disableColumnSorting
        showCellVerticalBorder
        showColumnVerticalBorder
        editMode="cell"
        isCellEditable={isCellEditable}
        processRowUpdate={handleRowUpdate}
        noScrollableContainer
        sx={getSubmissionDataGridFormStyles}
      />

      {dialogState && (
        <AddMetricExtensionDialog
          metricIds={dialogState.metricIds}
          metricName={dialogState.metricName}
          addedExtensionIds={dialogState.addedExtensionIds}
          allowCreatingExtensionValues
          onAdded={handleMetricExtensionsAdded}
          onClose={() => setDialogState(undefined)}
        />
      )}
    </PortfolioMonitoringInputTableContextProvider>
  );
};

export default QuarterlyDebtInputForm;
