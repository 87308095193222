import { LogoutData, SignupTokenResponse, ssoApi } from "../api/sso";
import { ApiResponse } from "../api/types";
import RefreshTokenService from "../services/RefreshTokenService";
import { authCookieExists, refreshCookieExists } from "../utilities/cookies";

export async function checkAuthentication(onNotAuthenticated?: () => void): Promise<boolean> {
  if (await userAuthenticated()) {
    return true;
  }

  if (onNotAuthenticated !== undefined) {
    onNotAuthenticated();
  }

  return false;
}

export async function userAuthenticated(): Promise<boolean> {
  if (authCookieExists()) {
    return true;
  }

  if (refreshCookieExists()) {
    return await RefreshTokenService.do();
  }

  return false;
}

export async function requestToken(code: string, userFlow?: string): Promise<void> {
  await ssoApi.requestToken(code, userFlow);
}

export function requestSignupToken(code: string): Promise<SignupTokenResponse | undefined> {
  return ssoApi.requestSignupToken(code);
}

export async function logout(): Promise<ApiResponse<LogoutData>> {
  return await ssoApi.logout();
}
