import { useEffect } from "react";
import { getSharedVariables } from "../variables";

const appPageTitle = getSharedVariables().PAGE_TITLE;

const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = title ? `${title} | ${appPageTitle}` : appPageTitle;

    return () => {
      document.title = appPageTitle;
    };
  }, [title]);
};

export default usePageTitle;
