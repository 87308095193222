import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import ArchiveActionIcon from "../../../../../shared/icons/ArchiveActionIcon";
import { logError } from "../../../../../shared/logging";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../api/adminApi";
import { Metric } from "../../../../api/types/portfolioMonitoringTypes";
import { useMetricsPageContext } from "../MetricsPageContext";
import MetricEditor from "./MetricEditor";
import {
  getInitialMetricEditorFormStateFromMetric,
  mapFormToUpdateMetricRequest,
  MetricEditorFormState,
} from "./metricEditorHelper";

interface Props {
  metric: Metric;
  onClose: () => void;
  onUpdated: (metric: Metric) => void;
}

const updateMetric = withErrorHandling(adminApi.updateMetric);

const EditMetricDialog = ({ metric, onClose, onUpdated }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { metrics, onArchiveMetric } = useMetricsPageContext();

  const [saving, setSaving] = useState(false);

  const [formState, setFormState] = useState<MetricEditorFormState>(getInitialMetricEditorFormStateFromMetric(metric));

  const handleArchiveMetricClick = () => {
    onClose();
    onArchiveMetric(metric);
  };

  const handleUpdateMetric = async () => {
    if (!formState.isFormValid) {
      return;
    }

    setSaving(true);
    const [updatedMetric, error] = await updateMetric(metric.id, mapFormToUpdateMetricRequest(formState));
    setSaving(false);

    if (error) {
      sendNotificationError("Could not update metric");
      logError(error, "[EditMetricDialog] updateMetric");
    } else {
      sendNotification(`Metric '${metric.name}' updated successfully`);
      onUpdated(updatedMetric);
    }
  };

  const otherMetricNames = metrics.filter((m) => m.id !== metric.id).map((m) => m.name);
  const allMetricCategories = distinct(metrics.map((m) => m.category).filter(Boolean)) as string[];

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Metric</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <MetricEditor
          form={formState}
          onChange={setFormState}
          otherMetricNames={otherMetricNames}
          allMetricCategories={allMetricCategories}
          editedMetric={metric}
        />
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleArchiveMetricClick}
          startIcon={<ArchiveActionIcon color="error" />}
        >
          Archive
        </Button>
        <HorizontalFill />
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!formState.isFormValid || formState.touchedFields.length === 0}
          variant="contained"
          loading={saving}
          onClick={handleUpdateMetric}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditMetricDialog;
