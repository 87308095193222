import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../shared/utilities/typeHelper";
import { ValidationResult } from "../../../shared/utilities/validators";
import {
  FieldIdToLookupReferences,
  FieldIdToValueMap,
  ObjectTypeToLookupReferences,
} from "../../api/types/objectTypes";
import { FieldGroupModel } from "./entityFieldTypes";

export interface FieldValuesState {
  fieldValues: FieldIdToValueMap;
  isValid: boolean;
  fieldEditState: "viewing" | "editing" | "canceling";
}

interface Props {
  objectType: string;
  fieldValuesState: FieldValuesState;
  updateFieldValuesState: (state: Partial<FieldValuesState>) => void;
  fieldGroups: FieldGroupModel[];
  lookupObjects: FieldIdToLookupReferences;
  lookupOptionsByObjectType: ObjectTypeToLookupReferences;
  onFieldValueChange: (value: unknown, fieldId: string, validationResult: ValidationResult) => void;
  validateUniqueFieldValue: (fieldId: string, value: unknown) => Promise<ValidationResult>;
  emailFieldIdToEditViaDialog?: string;
  onEmailViaDialogEdit?: () => void;
}

const FieldValuesContext = createContext<Props | undefined>(undefined);

export const FieldValuesContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <FieldValuesContext.Provider value={contextValue}>{children}</FieldValuesContext.Provider>
);

export const useFieldValuesContext = () => {
  const context = useContext(FieldValuesContext);
  return defined(context, "Attempt to use FieldValuesContext outside of provider");
};
