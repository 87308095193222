import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import adminApi from "../../api/adminApi";
import { FieldIdToValueMap, ObjectClassDefinition, ObjectFieldValues } from "../../api/types/objectTypes";
import EntitySection from "./EntitySection";
import FieldValuesManager from "./FieldValuesManager";

interface Props {
  entity: ObjectFieldValues;
  objectDefinition: ObjectClassDefinition;
  hasPermissionsToManageFields: boolean;
  onEntityUpdate: (entity: ObjectFieldValues) => void;
}

const updateObject = withErrorHandling(adminApi.updateObject);

const ObjectCard = ({ entity, objectDefinition, hasPermissionsToManageFields, onEntityUpdate }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [renderKey, setRenderKey] = useState(0);

  const saveFieldValues = async (fieldValues: FieldIdToValueMap) => {
    const [resp, error] = await updateObject(objectDefinition.objectType, entity.id, { fieldValues });
    if (error) {
      sendNotificationError(getErrorMessage(error) || "Failed to save changes");
      logError(error, `[ObjectDetailsSection (${objectDefinition.objectType})] saveFieldValues`);
      return false;
    }

    sendNotification("Changes saved");
    onEntityUpdate(resp);
    setRenderKey((prev) => prev + 1);
    return true;
  };

  return (
    <EntitySection>
      <FieldValuesManager
        key={renderKey}
        objectDefinition={objectDefinition}
        fieldValues={entity.fieldValues}
        lookupObjects={entity.lookupObjects}
        hasPermissionsToManageFields={hasPermissionsToManageFields}
        saveFieldValues={saveFieldValues}
      />
    </EntitySection>
  );
};

export default ObjectCard;
