import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { Button, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import ApplicationStatusTag from "../../common/ApplicationStatusTag";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import InvestorPortalLinks from "../investor-portal/general/InvestorPortalLinks";
import InvestorPortalImpersonateButton from "../investor-portal/InvestorPortalImpersonateButton";
import PortfolioPortalPagesSection from "./PortfolioPortalPagesSection";

const PortfolioPortalPage = () => {
  usePageTitle("Portfolio Portal");

  const { clientCode, portfolioMonitoringApp } = useClientContext();

  return (
    <>
      <GeneralPageHeader title="Portfolio Portal">
        <InvestorPortalImpersonateButton variant="outlined" color="secondary" params={{ target: "DataCollection" }} />
        <Button
          component={Link}
          to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalStyle}`}
          variant="contained"
          startIcon={<SettingsIcon />}
        >
          Configure Style
        </Button>
      </GeneralPageHeader>

      <Container maxWidth="md" disableGutters>
        <Stack spacing={3} py={2.5}>
          <Typography variant="subtitle1">Portal Links</Typography>

          <Stack direction="row" spacing={1}>
            <Typography color="secondary" width="3.5rem">
              Status
            </Typography>
            <ApplicationStatusTag
              appStatus={portfolioMonitoringApp?.dataCollection?.enabled ? "Published" : "NotConfigured"}
            />
          </Stack>

          <InvestorPortalLinks />

          <PortfolioPortalPagesSection />
        </Stack>
      </Container>
    </>
  );
};

export default PortfolioPortalPage;
