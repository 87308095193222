import { useCallback } from "react";
import { Navigate, useSearchParams } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import FullScreenLoader from "../../../../shared/components/fullScreenLoader/FullScreenLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import useSetInterval from "../../../../shared/hooks/useSetInterval";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import useHideLeftSideRootMenu from "../../../hooks/useHideLeftSideRootMenu";

const getJobStatus = withErrorHandling(adminApi.getBusinessCentralAccessJobStatus);

const BusinessCentralRedirectPage = () => {
  const [searchParams] = useSearchParams();

  useHideLeftSideRootMenu();

  const redirect = useCallback(() => {
    const redirectUrl = searchParams.get("redirectUrl");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [searchParams]);

  const [accessResp, accessError] = useFetch(adminApi.ensureBusinessCentralAccessIsEnabled);

  const checkAccessAndRedirect = useCallback(async () => {
    if (accessResp === undefined) {
      return;
    }

    if (!accessResp.jobStarted || !accessResp.jobId) {
      redirect();
      return;
    }

    const [jobResp, jobError] = await getJobStatus(accessResp.jobId);
    if (jobError) {
      logError(jobError, "[BusinessCentralRedirectPage] getJobStatus");
      redirect();
      return;
    }

    if (jobResp.finished) {
      redirect();
    }
  }, [accessResp, redirect]);

  useSetInterval(checkAccessAndRedirect, 1000);

  if (!searchParams.get("redirectUrl")) {
    return <Navigate to="/" />;
  }

  if (accessError) {
    logError(accessError, "[BusinessCentralRedirectPage] ensureAccess");
    redirect();
  }

  return <FullScreenLoader title="Please wait" subtitle="You are being redirected to Fund Operations..." />;
};

export default BusinessCentralRedirectPage;
