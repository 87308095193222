import axios, { AxiosRequestConfig } from "axios";
import { getAuthSettings } from "../auth/settings";
import { getSharedVariables } from "../variables";
import { ApiResponse } from "./types";

export interface SignupTokenData {
  userName: string;
  email: string;
}

export interface SignupTokenResponse {
  data: SignupTokenData;
}

export interface LogoutData {
  redirectToLogoutUrl: string;
}

const requestToken = (code: string, userFlow?: string): Promise<void> => {
  const { clientId, userFlowSignIn } = getAuthSettings();

  return axios.post(`${getSharedVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/codeExchange`, {
    clientId,
    userFlow: userFlow || userFlowSignIn,
    RedirectUri: getSharedVariables().PORTAL_REDIRECT_URI,
    AuthCode: code,
  });
};

const requestSignupToken = async (code: string): Promise<SignupTokenResponse> => {
  const { clientId, userFlowSignUp } = getAuthSettings();

  const { data } = await axios.post<SignupTokenResponse>(
    `${getSharedVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/codeExchange`,
    {
      clientId,
      userFlow: userFlowSignUp,
      RedirectUri: getSharedVariables().PORTAL_REDIRECT_URI,
      AuthCode: code,
    }
  );

  return data;
};

const refreshToken = (): Promise<void> => {
  const { clientId, userFlowSignIn } = getAuthSettings();

  return axios.post(
    `${getSharedVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/refresh`,
    {
      clientId,
      userFlow: userFlowSignIn,
    },
    { skipAuthRefresh: true } as AxiosRequestConfig
  );
};

const logout = async (): Promise<ApiResponse<LogoutData>> => {
  const { userFlowSignIn } = getAuthSettings();

  const { data } = await axios.post<ApiResponse<LogoutData>>(
    `${getSharedVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/logout`,
    {
      userFlow: userFlowSignIn,
    },
    { skipAuthRefresh: true } as AxiosRequestConfig
  );

  return data;
};

export const ssoApi = {
  requestToken,
  requestSignupToken,
  refreshToken,
  logout,
};
