import { useCallback } from "react";
import { useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi, { ContactDetails } from "../../../../api/adminApi";
import ContactDetailsPage from "./ContactDetailsPage";

const FundStructureContactPage = () => {
  usePageTitle("Contact");

  const { id } = useParams();

  const getContactDetails = useCallback(() => adminApi.getContactDetails(defined(id)), [id]);
  const [contactDetails, fetchContactDetailsError, { setData: setContactDetails, fetch: fetchContactDetails }] =
    useFetch(getContactDetails);

  const [accessCategories, fetchAccessCategoriesError] = useFetch(adminApi.getAccessCategories);

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition("Contact"), []);
  const [objectDefinitionWithPermissions, fetchDefinitionError] = useFetch(getObjectDefinition);

  if (fetchContactDetailsError || fetchAccessCategoriesError || fetchDefinitionError) {
    logError(
      fetchContactDetailsError || fetchAccessCategoriesError || fetchDefinitionError,
      "[FundStructureContactPage]"
    );
    return <DataLoadingFailed bgColor="none" title="Loading contact details failed" />;
  }

  if (!contactDetails || !accessCategories || !objectDefinitionWithPermissions) {
    return <InlineLoader />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions;

  const handleContactDetailsChange = (details: Partial<ContactDetails>) => {
    setContactDetails({ ...contactDetails, ...details });
  };

  return (
    <ContactDetailsPage
      categories={accessCategories}
      contactDetails={contactDetails}
      objectDefinition={objectClassDefinition}
      objectPermissions={objectPermissions}
      onContactDetailsChange={handleContactDetailsChange}
      onUpdateContactDetails={fetchContactDetails}
    />
  );
};

export default FundStructureContactPage;
