import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { createApiResponse, createErrorApiResponse, withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../../shared/api/types";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import ImageUploaderWithPreview from "../../../../../shared/components/imageUploader/ImageUploaderWithPreview";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import ImagePlaceholderIcon from "../../../../../shared/icons/ImagePlaceholderIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { RelativeLogoSize } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import InvestorPortalImpersonateButton from "../InvestorPortalImpersonateButton";
import EditColorSection from "./EditColorSection";
import {
  getBrandingUpdateRequest,
  getInitialPortalStyleSettings,
  getSignOnUpdateRequest,
  initFromSavedSettingsAction,
  isBrandingStateDirty,
  isSignOnStateDirty,
  PortalStyleSettings,
  SavedPortalStyleSettings,
  updateStyleSettingAction,
} from "./investorPortalStyleState";
import SectionTitle from "./SectionTitle";

const updateFundPortalCoreBrandingSettings = withErrorHandling(adminApi.updateFundPortalCoreBrandingSettings);
const updateSignOnSettings = withErrorHandling(adminApi.updateSignOnSettings);

const imageBackgroundSizeMap: Record<RelativeLogoSize, number> = {
  Small: 40,
  Medium: 48,
  Large: 56,
};

const fetchSettings = async (): Promise<ApiResponse<SavedPortalStyleSettings>> => {
  const [portalSettingsResp, signOnSettingsResp] = await Promise.all([
    adminApi.getFundPortalCoreBrandingSettings(),
    adminApi.getSignOnSettings(),
  ]);

  if (!portalSettingsResp.success) {
    return createErrorApiResponse(portalSettingsResp.error);
  }

  if (!signOnSettingsResp.success) {
    return createErrorApiResponse(signOnSettingsResp.error);
  }

  return createApiResponse({
    portalSettings: portalSettingsResp.data,
    signOnSettings: signOnSettingsResp.data,
  });
};

const InvestorPortalStylePage = () => {
  usePageTitle("Portal Style");

  const { clientTitle, hasPermissions } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [settings, setSettings] = useState<PortalStyleSettings>(getInitialPortalStyleSettings());
  const [isSaving, setSaving] = useState(false);

  const [savedSettings, fetchError, { setData: setSavedSettings }] = useFetch(fetchSettings, (data) =>
    setSettings(initFromSavedSettingsAction(data))
  );

  if (fetchError) {
    logError(fetchError, "[InvestorPortalStylePage] fetch");
    return <DataLoadingFailed title="Could not to load style settings" />;
  }

  if (!savedSettings) {
    return <InlineLoader />;
  }

  const saveBrandingSettings = async () => {
    if (!isBrandingStateDirty(settings, savedSettings.portalSettings)) {
      return savedSettings.portalSettings;
    }

    const brandingUpdateRequest = getBrandingUpdateRequest(settings, savedSettings.portalSettings);
    const [resp, error] = await updateFundPortalCoreBrandingSettings(brandingUpdateRequest);
    if (error) {
      logError(error, "[InvestorPortalStylePage] saveBrandingSettings");
      sendNotificationError("Failed to save branding settings");
      return undefined;
    }

    setSavedSettings({ ...savedSettings, portalSettings: resp });
    return resp;
  };

  const saveSignOnSettings = async () => {
    if (!isSignOnStateDirty(settings, savedSettings.signOnSettings)) {
      return savedSettings.signOnSettings;
    }

    const signOnUpdateRequest = getSignOnUpdateRequest(settings, savedSettings.signOnSettings);
    const [resp, error] = await updateSignOnSettings(signOnUpdateRequest);
    if (error) {
      logError(error, "[InvestorPortalStylePage] saveSignOnSettings");
      sendNotificationError("Failed to save sign-in settings");
      return undefined;
    }

    setSavedSettings({ ...savedSettings, signOnSettings: resp });
    return resp;
  };

  const handleSave = async () => {
    setSaving(true);
    const signOnSettings = await saveSignOnSettings();
    const portalSettings = await saveBrandingSettings();
    if (signOnSettings && portalSettings) {
      sendNotification("Settings saved successfully");
      setSettings(initFromSavedSettingsAction({ portalSettings, signOnSettings }));
    }

    setSaving(false);
  };

  const handleCancel = () => {
    setSettings(initFromSavedSettingsAction(savedSettings));
  };

  const hasPermissionToEdit = hasPermissions(["ManageInvestorPortalSettings"]);

  const { portalSettings, signOnSettings } = savedSettings;

  const isDirty = isBrandingStateDirty(settings, portalSettings) || isSignOnStateDirty(settings, signOnSettings);

  return (
    <>
      <GeneralPageHeader title="Portal Style">
        <InvestorPortalImpersonateButton variant="outlined" color="secondary" />
        {hasPermissionToEdit && (
          <LoadingButton loading={isSaving} onClick={handleSave} variant="contained" disabled={!isDirty}>
            Save
          </LoadingButton>
        )}
        {hasPermissionToEdit && (
          <Button onClick={handleCancel} variant="text" color="secondary" disabled={!isDirty}>
            Cancel
          </Button>
        )}
      </GeneralPageHeader>

      <ScrollableFlexContainer>
        <Container maxWidth="md" disableGutters>
          <Stack spacing={4} py={2.5}>
            <Stack spacing={2}>
              <SectionTitle
                text="Logotype on Navigation Bar"
                description="PNG, SVG, JPG. Recommended size: 280x56 pixels."
              />
              <Stack spacing={1}>
                <Typography variant="subtitle2">Light or Dark Background</Typography>
                <ImageUploaderWithPreview
                  disabled={!hasPermissionToEdit || isSaving}
                  width={400}
                  height={66}
                  backgroundSize={`auto ${imageBackgroundSizeMap[settings.logoTypeSize]}px`}
                  allowedImageTypes="image/png, image/jpeg, image/svg+xml"
                  originalImageSrc={portalSettings.logoTypeUrl}
                  imageSrc={settings.logoTypeUrl}
                  onImageSelected={(logoTypeFile, logoTypeUrl) => {
                    setSettings(updateStyleSettingAction({ logoTypeFile, logoTypeUrl }));
                  }}
                  uploadButtonName="Upload Logotype"
                  backgroundColor={settings.appBarColor}
                  maxFileSizeBytes={0.5 * 1024 * 1024}
                  buttonsPosition="right"
                  Placeholder={
                    <Stack p={2}>
                      <Typography variant="h5" color="#C7CED4">
                        Logotype
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2">Logotype size</Typography>
                <RadioGroup
                  row
                  value={settings.logoTypeSize}
                  onChange={(e) =>
                    setSettings(updateStyleSettingAction({ logoTypeSize: e.target.value as RelativeLogoSize }))
                  }
                >
                  <FormControlLabel control={<Radio />} value="Small" label="Small" disabled={!hasPermissionToEdit} />
                  <FormControlLabel control={<Radio />} value="Medium" label="Medium" disabled={!hasPermissionToEdit} />
                  <FormControlLabel control={<Radio />} value="Large" label="Large" disabled={!hasPermissionToEdit} />
                </RadioGroup>
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <SectionTitle text="Company logomark" description="PNG, SVG, JPG. Recommended size: 56x56 pixels." />
              <ImageUploaderWithPreview
                disabled={!hasPermissionToEdit || isSaving}
                width={56}
                height={56}
                allowedImageTypes="image/png, image/jpeg, image/svg+xml"
                originalImageSrc={portalSettings.logoMarkUrl}
                imageSrc={settings.logoMarkUrl}
                onImageSelected={(logoMarkFile, logoMarkUrl) =>
                  setSettings(updateStyleSettingAction({ logoMarkFile, logoMarkUrl }))
                }
                uploadButtonName="Upload Logomark"
                maxFileSizeBytes={0.5 * 1024 * 1024}
                buttonsPosition="right"
                border="dashed"
                Placeholder={
                  <Stack p={2}>
                    <ImagePlaceholderIcon />
                  </Stack>
                }
              />
            </Stack>

            <Stack spacing={2}>
              <SectionTitle
                text="Favicon"
                description="PNG, SVG, ICO. Recommended size: 16x16 pixels or 32x32 pixels."
              />
              <ImageUploaderWithPreview
                disabled={!hasPermissionToEdit || isSaving}
                width={32}
                height={32}
                allowedImageTypes="image/png, image/x-icon, image/svg+xml"
                originalImageSrc={portalSettings.faviconUrl}
                imageSrc={settings.faviconUrl}
                onImageSelected={(faviconFile, faviconUrl) =>
                  setSettings(updateStyleSettingAction({ faviconFile, faviconUrl }))
                }
                uploadButtonName="Upload Favicon"
                maxFileSizeBytes={0.5 * 1024 * 1024}
                border="dashed"
                buttonsPosition="right"
                Placeholder={
                  <Stack p="0.3rem">
                    <ImagePlaceholderIcon />
                  </Stack>
                }
              />
            </Stack>

            <Divider flexItem />

            <SectionTitle text="Navigation colors" description="Choose a color scheme for the main horizontal bar." />

            <EditColorSection
              title="Navigation bar color"
              subtitle="Background color of the main navigation bar."
              color={settings.appBarColor}
              onChange={(appBarColor) => setSettings(updateStyleSettingAction({ appBarColor }))}
              disabled={!hasPermissionToEdit}
            />

            <EditColorSection
              title="Navigation text color"
              subtitle="Text color of the main navigation bar."
              color={settings.appBarTextColor}
              onChange={(appBarTextColor) => setSettings(updateStyleSettingAction({ appBarTextColor }))}
              disabled={!hasPermissionToEdit}
            />

            <EditColorSection
              title="Highlights"
              subtitle="Color of buttons and highlight color for the selected navigation item."
              color={settings.mainColor}
              onChange={(mainColor) => setSettings(updateStyleSettingAction({ mainColor }))}
              disabled={!hasPermissionToEdit}
            />

            <Divider flexItem />

            <Stack spacing={3}>
              <SectionTitle
                text="Banner"
                textAppend="(Investor Portal only)"
                description="Banner will be displayed on first page on the portal. PNG, SVG or JPG. Recommended size: 2560x120 pixels."
              />
              <ImageUploaderWithPreview
                disabled={!hasPermissionToEdit || isSaving}
                width="auto"
                height={120}
                allowedImageTypes="image/jpg, image/png, image/svg+xml"
                originalImageSrc={portalSettings.bannerUrl}
                imageSrc={settings.bannerUrl}
                onImageSelected={(bannerFile, bannerUrl) =>
                  setSettings(updateStyleSettingAction({ bannerFile, bannerUrl }))
                }
                uploadButtonName="Upload Banner"
                backgroundSize="cover"
                maxFileSizeBytes={1 * 1024 * 1024}
                buttonsPosition="bottom"
                showPlaceholderOverImage
                Placeholder={
                  <Stack p="2rem">
                    <Typography variant="h5" color={settings.bannerFontColor}>
                      {"{{Contact Name}}"}
                    </Typography>
                    <Typography color={settings.bannerFontColor}>
                      Welcome to the {clientTitle} Investor Portal
                    </Typography>
                  </Stack>
                }
              />
            </Stack>

            <EditColorSection
              title="Text Color"
              subtitle="Text color on the banner."
              color={settings.bannerFontColor}
              onChange={(bannerFontColor) => setSettings(updateStyleSettingAction({ bannerFontColor }))}
              disabled={!hasPermissionToEdit}
            />

            <Divider flexItem />

            <SectionTitle
              text="Sign in page"
              description="Choose how the sign in page looks. Set either color or image as the background."
            />

            <Stack spacing={2}>
              <SectionTitle
                text="Logotype on Sign in page"
                description="PNG or JPG. Recommended size: 280x56 pixels."
              />
              <Stack spacing={1}>
                <Typography variant="subtitle2">Light Background</Typography>
                <ImageUploaderWithPreview
                  disabled={!hasPermissionToEdit || isSaving}
                  width={280}
                  height={56}
                  allowedImageTypes="image/png, image/jpeg"
                  originalImageSrc={signOnSettings.clientLogoUrl}
                  imageSrc={settings.clientLogoUrl}
                  onImageSelected={(clientLogoFile, clientLogoUrl) =>
                    setSettings(updateStyleSettingAction({ clientLogoFile, clientLogoUrl }))
                  }
                  uploadButtonName="Upload Logotype"
                  maxFileSizeBytes={1 * 1024 * 1024}
                  border="dashed"
                  buttonsPosition="right"
                  Placeholder={
                    <Stack p="1rem">
                      <Typography variant="h5" color="#C7CED4">
                        Logotype
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>

            <EditColorSection
              title="Theme color"
              subtitle="The color of buttons and links on login pages."
              color={settings.primaryThemeColor}
              onChange={(primaryThemeColor) => setSettings(updateStyleSettingAction({ primaryThemeColor }))}
              disabled={!hasPermissionToEdit}
            />

            <Stack spacing={2}>
              <SectionTitle text="Background" />
              <FormControl sx={{ mt: 0.5 }}>
                <RadioGroup
                  defaultValue="false"
                  value={settings.useBackgroundImage}
                  sx={{ rowGap: 3 }}
                  onChange={(e) =>
                    setSettings(updateStyleSettingAction({ useBackgroundImage: e.target.value === "true" }))
                  }
                >
                  <FormControlLabel
                    value="false"
                    disabled={!hasPermissionToEdit}
                    control={<Radio />}
                    slotProps={{ typography: { width: "100%" } }}
                    sx={{ mr: 0 }}
                    label={
                      <EditColorSection
                        title="Background color"
                        subtitle="Background color of the sign in page."
                        color={settings.backgroundColor}
                        onChange={(backgroundColor) => setSettings(updateStyleSettingAction({ backgroundColor }))}
                        disabled={!hasPermissionToEdit || settings.useBackgroundImage}
                      />
                    }
                  />

                  <FormControlLabel
                    value="true"
                    disabled={!hasPermissionToEdit}
                    control={<Radio />}
                    slotProps={{ typography: { width: "100%" } }}
                    sx={{ mr: 0 }}
                    label={
                      <Stack spacing={2}>
                        <SectionTitle
                          text="Background image"
                          description="Set an image as a background for the sign in page. PNG or JPG. Recommended size: 2560x1600 pixels."
                        />
                      </Stack>
                    }
                  />
                </RadioGroup>
              </FormControl>

              <ImageUploaderWithPreview
                disabled={!hasPermissionToEdit || isSaving || !settings.useBackgroundImage}
                width={180}
                height={140}
                allowedImageTypes="image/png, image/jpeg"
                originalImageSrc={signOnSettings.backgroundImageUrl}
                imageSrc={settings.backgroundImageUrl}
                onImageSelected={(backgroundImageFile, backgroundImageUrl) =>
                  setSettings(updateStyleSettingAction({ backgroundImageFile, backgroundImageUrl }))
                }
                uploadButtonName="Upload File"
                maxFileSizeBytes={2 * 1024 * 1024}
                buttonsPosition="right"
              />
            </Stack>
          </Stack>
        </Container>
      </ScrollableFlexContainer>
    </>
  );
};

export default InvestorPortalStylePage;
