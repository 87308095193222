import { Theme } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";
import { DataCollectionSubmissionStatus } from "../../api/dataCollectionTypes";
import { dataSubmissionStatusCaptionsMap } from "../../utilities/enumCaptions";
import StatusChip from "../StatusChip";

interface Props {
  status: DataCollectionSubmissionStatus;
}

const colorsMaps: Record<DataCollectionSubmissionStatus, (t: Theme) => string> = {
  Draft: (t) => t.palette.text.secondary,
  Pending: () => blue[700],
  Scheduled: () => deepPurple[700],
  Completed: (t) => t.palette.success.main,
};

const DataSubmissionStatusTag = ({ status }: Props) => {
  return <StatusChip label={dataSubmissionStatusCaptionsMap[status] ?? status} withDot color={colorsMaps[status]} />;
};

export default DataSubmissionStatusTag;
