import deepEqual from "fast-deep-equal";

export const getChangedFields = <T extends object>(oldObj: T, newObj: T): Partial<T> =>
  Object.entries(newObj).reduce<Partial<T>>((result, [key, newValue]) => {
    if (!deepEqual(oldObj[key as keyof T], newValue)) {
      result[key as keyof T] = newValue as T[keyof T];
    }
    return result;
  }, {});

export const isObject = <T>(value: T): boolean => Object.prototype.toString.call(value) === "[object Object]";
