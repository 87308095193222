import { TableBlockRow } from "../../../../api/inputFormTypes";

type ValuePerColumn = [string, number];

interface SourceRow {
  id: string;
  valueFactor: number;
}

const getSourceValuesPerColumn = (row: TableBlockRow): ValuePerColumn[] | undefined => {
  if (row.type === "Metric" || row.type === "MetricExtension") {
    return Object.entries(row.values).map(([columnId, value]) => [columnId, typeof value === "number" ? value : 0]);
  }

  if (row.type === "ExtensionOnly") {
    return Object.entries(row.values).map(([columnId, extValues]) => [
      columnId,
      typeof extValues.displayValue === "number" ? extValues.displayValue : 0,
    ]);
  }

  if (row.type === "SingleMetricExtensionOnly") {
    return Object.entries(row.values).map(([columnId, value]) => [columnId, typeof value === "number" ? value : 0]);
  }

  return undefined;
};

const collectSourceValues = <T extends TableBlockRow>(rows: T[]): Map<string, ValuePerColumn[]> => {
  const result = new Map<string, ValuePerColumn[]>();

  for (const row of rows) {
    const valuesPerColumn = getSourceValuesPerColumn(row);
    if (valuesPerColumn !== undefined) {
      result.set(row.id, valuesPerColumn);
    }
  }

  return result;
};

const getRowIdToReferencedRowsMap = <T extends TableBlockRow>(rows: T[]): Map<string, SourceRow[]> => {
  const result = new Map<string, SourceRow[]>();

  for (const row of rows) {
    if (row.type === "Total") {
      if (row.forAllRowsOfType !== undefined) {
        result.set(
          row.id,
          rows
            .filter((r) => r.type === row.forAllRowsOfType)
            .map((r) => ({ id: r.id, valueFactor: row.invertedRowIds?.includes(r.id) ? -1 : 1 }))
        );
      } else {
        result.set(
          row.id,
          row.rowIds.map((id) => ({ id, valueFactor: row.invertedRowIds?.includes(id) ? -1 : 1 }))
        );
      }
    } else if (row.type === "ExtendedMetricSection") {
      result.set(
        row.id,
        rows
          .filter((r) => r.type === "MetricExtension" && r.metricId === row.metricId)
          .map((r) => ({ id: r.id, valueFactor: 1 }))
      );
    }
  }

  return result;
};

const getSourceRowsForRow = (sourceRow: SourceRow, referenceMap: Map<string, SourceRow[]>): SourceRow[] => {
  const referencedRows = referenceMap.get(sourceRow.id);
  if (referencedRows === undefined) {
    return [sourceRow];
  }

  const result = [];
  for (const referencedRow of referencedRows) {
    const sourceRows = getSourceRowsForRow(referencedRow, referenceMap);
    result.push(...sourceRows.map((r) => ({ id: r.id, valueFactor: sourceRow.valueFactor * r.valueFactor })));
  }

  return result;
};

const mapTotalRowsSourceRows = <T extends TableBlockRow>(rows: T[]): Map<string, SourceRow[]> => {
  const referenceMap = getRowIdToReferencedRowsMap(rows);

  const result = new Map<string, SourceRow[]>();

  for (const row of rows) {
    if (row.type === "Total") {
      const sourceRows = getSourceRowsForRow({ id: row.id, valueFactor: 1 }, referenceMap);
      result.set(row.id, sourceRows);
    }
  }

  return result;
};

export const calculateTotals = <T extends TableBlockRow>(rows: T[]): T[] => {
  const updatedRows: T[] = [];

  const sourceValues = collectSourceValues(rows);
  const totalRowsToSourceRowsMap = mapTotalRowsSourceRows(rows);

  for (const row of rows) {
    if (row.type !== "Total") {
      updatedRows.push({ ...row });
      continue;
    }

    const totalValues: Record<string, number> = {};
    const sourceRows = totalRowsToSourceRowsMap.get(row.id) ?? [];
    for (const sourceRow of sourceRows) {
      const valuesPerColumn = sourceValues.get(sourceRow.id) ?? [];
      for (const [columnId, value] of valuesPerColumn) {
        if (row.enabledForColumnIds === undefined || row.enabledForColumnIds.includes(columnId)) {
          totalValues[columnId] = (totalValues[columnId] ?? 0) + sourceRow.valueFactor * value;
        }
      }
    }

    updatedRows.push({ ...row, values: totalValues });
  }

  return updatedRows;
};
