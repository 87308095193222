import { Container, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { Navigate, useParams } from "react-router";
import { createApiResponse, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../../shared/logging";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import DocumentActivityPageHeader from "../../common/document-activity/DocumentActivityPageHeader";
import DocumentActivitySummary from "../../common/document-activity/DocumentActivitySummary";
import TotalTimePerPageSection from "../../common/document-activity/TotalTimePerPageSection";
import UserDocumentActivitySection from "../../common/document-activity/UserDocumentActivitySection";

const getDocCollectionFileDownloadInfo = withErrorHandling(adminApi.getDocCollectionFileDownloadInfo);

const ContactDocumentActivityPage = () => {
  usePageTitle("Document Activity");

  const { clientCode } = useClientContext();
  const { id, postMessageRequestId, attachmentId } = useParams();

  const getMessageAttachmentInfo = useCallback(
    () =>
      id && attachmentId
        ? adminApi.getMessageAttachmentInfo(attachmentId, { contactId: id })
        : Promise.resolve(createApiResponse(undefined)),
    [attachmentId, id]
  );

  const [messageAttachmentInfo, fetchMessageAttachmentInfoError, { isFetching: isFetchingMessageAttachmentInfo }] =
    useFetch(getMessageAttachmentInfo);

  const getAggregatedDocumentViews = useCallback(
    () =>
      id && attachmentId
        ? adminApi.getAggregatedDocumentViewsByContact(attachmentId, id)
        : Promise.resolve(createApiResponse(undefined)),
    [attachmentId, id]
  );

  const [
    agggregatedDocumentViews,
    fetchAggregatedDocumentViewsError,
    { isFetching: isFetchingAggregatedDocumentViews },
  ] = useFetch(getAggregatedDocumentViews);

  const getAllDocumentViews = useCallback(
    () =>
      id && attachmentId
        ? adminApi.searchDocumentViews({
            paging: { page: 0, size: 200, totals: false },
            contactId: id,
            attachmentIds: [attachmentId],
          })
        : Promise.resolve(createApiResponse(undefined)),
    [attachmentId, id]
  );

  const [allDocumentViews, fetchAllDocumentViewsError, { isFetching: isFetchingAllDocumentViews }] =
    useFetch(getAllDocumentViews);

  const getFileDownloadInfo = useCallback(
    () => getDocCollectionFileDownloadInfo(defined(postMessageRequestId), defined(attachmentId)),
    [attachmentId, postMessageRequestId]
  );

  if (!id || !postMessageRequestId || !attachmentId) {
    return <Navigate to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}`} />;
  }

  const fetchError = fetchMessageAttachmentInfoError || fetchAggregatedDocumentViewsError || fetchAllDocumentViewsError;
  if (fetchError && fetchError.code !== 404) {
    logError(fetchError, "[ContactDocumentActivityPage]");
    return <DataLoadingFailed />;
  }

  if (isFetchingMessageAttachmentInfo || isFetchingAggregatedDocumentViews) {
    return <InlineLoader />;
  }

  const backPath = `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${id}/documents`;

  const documentViewsForAttachment = (agggregatedDocumentViews ?? []).find(
    (view) => view.attachmentId === attachmentId
  );

  return (
    <>
      <DocumentActivityPageHeader
        backPath={backPath}
        fileName={messageAttachmentInfo?.fileName}
        categoryName={messageAttachmentInfo?.category}
        fundNames={messageAttachmentInfo?.fundNames ?? []}
        getFileDownloadInfo={getFileDownloadInfo}
      />
      <Container maxWidth="lg" sx={{ height: "100%" }}>
        <Stack py={2.5} spacing={2} width="100%" height="100%">
          <Typography variant="h6" pt={2}>
            {messageAttachmentInfo?.contactName || "Contact"} activity
          </Typography>
          {documentViewsForAttachment && (
            <DocumentActivitySummary aggregatedDocumentViews={documentViewsForAttachment} />
          )}
          {documentViewsForAttachment && (
            <TotalTimePerPageSection aggregatedDocumentViews={documentViewsForAttachment} />
          )}
          {allDocumentViews && (
            <UserDocumentActivitySection
              documentViews={allDocumentViews?.items ?? []}
              loading={isFetchingAllDocumentViews}
              excludeColumns={["userInfo"]}
            />
          )}
        </Stack>
      </Container>
    </>
  );
};

export default ContactDocumentActivityPage;
