import CopyAllIcon from "@mui/icons-material/CopyAllRounded";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { MetricCellValueUpdate } from "../../../../api/dataCollectionTypes";
import { InternalTableBlockContent, MetricTableBlockRow } from "../../../../api/inputFormTypes";
import useDebounce from "../../../../hooks/useDebounce";
import BlockSection from "../BlockSection";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import InternalTableInputForm from "./InternalTableInputForm";

interface Props {
  blockId: string;
  content: InternalTableBlockContent;
}

const InternalTableFormBlock = ({ blockId, content }: Props) => {
  const { onBlockCellValuesEdit, isSubmissionEditable } = useDataSubmissionFormContext();

  const [rows, setRows] = useState<MetricTableBlockRow[]>(content.table.rows);

  const currentColumnId = content.table.columns.find((col) => col.isEditable && col.dateRange === "Current")?.id;
  const previousColumnId = content.table.columns.find((col) => col.dateRange === "PeriodShift")?.id;

  const onBlockCellValuesEditDebounced = useDebounce(onBlockCellValuesEdit, 500);

  const handleValueChange = (rowId: string, metricId: string, columnId: string, value: string | number | undefined) => {
    setRows((prev) =>
      prev.map((row) =>
        row.id === rowId
          ? {
              ...row,
              values: { ...row.values, [columnId]: value },
            }
          : row
      )
    );

    onBlockCellValuesEditDebounced(blockId, [{ metricId, columnId, value: value ?? 0 }]);
  };

  const handleCopyPreviousValues = () => {
    if (!currentColumnId || !previousColumnId) {
      return;
    }

    const updates = rows.reduce((result, row) => {
      const previousValue = row.values[previousColumnId];
      if (previousValue !== undefined && previousValue !== null) {
        result.push({
          metricId: row.metricId,
          columnId: currentColumnId,
          value: previousValue,
        });
      }

      return result;
    }, [] as MetricCellValueUpdate[]);

    onBlockCellValuesEdit(blockId, updates);

    setRows((prev) =>
      prev.map((row) => ({
        ...row,
        values: { ...row.values, [currentColumnId]: row.values[previousColumnId] ?? row.values[currentColumnId] },
      }))
    );
  };

  return (
    <BlockSection>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{content.table.name}</Typography>
        {isSubmissionEditable && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCopyPreviousValues}
            startIcon={<CopyAllIcon />}
            disabled={!currentColumnId || !previousColumnId}
          >
            Copy Previous Values
          </Button>
        )}
      </Box>
      <InternalTableInputForm
        columnDefinitions={content.table.columns}
        rowDefinitions={rows}
        currencyCode={content.currencyCode}
        onValueChange={handleValueChange}
      />
    </BlockSection>
  );
};

export default InternalTableFormBlock;
