import { DateField } from "@mui/x-date-pickers-pro";

interface Props {
  value: Date | undefined;
  onChange: (newValue: Date | undefined) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricDateField = ({ value, onChange, readOnly, disabled }: Props) => {
  return (
    <DateField<Date>
      fullWidth
      disabled={disabled}
      value={value ?? null}
      onChange={(newDate) => onChange(newDate ?? undefined)}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    />
  );
};

export default MetricDateField;
