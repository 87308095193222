import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import PromptDialogHeader from "../../../components/dialog/PromptDialogHeader";
import { ReportAccessType, ReportInfo } from "../../api/biClient.types";
import AlertReportIcon from "../../icons/AlertReportIcon";

interface RemoveOwnAccessDialogProps {
  report: ReportInfo;
  access: ReportAccessType;
  onCancel: () => void;
  onRemove: () => void;
}
export default function RemoveOwnAccessDialog({ report, access, onRemove, onCancel }: RemoveOwnAccessDialogProps) {
  const [updating, setUpdating] = useState(false);
  const applyButtonText = useMemo(() => {
    switch (access) {
      case ReportAccessType.NoAccess:
        return "Remove Access";
      case ReportAccessType.Read:
      case ReportAccessType.Write:
        return "Reduce Access";
      default:
        return "Remove Access";
    }
  }, [access]);

  const handleRemove = useCallback(() => {
    setUpdating(true);
    onRemove();
  }, [onRemove]);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiDialog-container": { alignItems: "start", pt: 20 } }}
      PaperProps={{ sx: { minHeight: 250 } }}
      onClose={onCancel}
    >
      <PromptDialogHeader text="Access" onClose={onCancel} />
      <DialogContent>
        <Stack alignItems={"center"} spacing={2}>
          <AlertReportIcon sx={{ height: 80, width: 80 }} />
          <LessAccessText reportName={report.name} access={access} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button color="secondary" disabled={updating} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={updating} onClick={handleRemove}>
          {applyButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function LessAccessText({ access, reportName }: { access: ReportAccessType; reportName: string }) {
  if (access === ReportAccessType.NoAccess) {
    return (
      <>
        <Typography variant="h6">Remove access?</Typography>
        <Box sx={{ "& > span": { pr: 0.5 }, textAlign: "center" }}>
          <Typography component={"span"} variant="subtitle2">
            You
          </Typography>
          <Typography component={"span"} variant="body1">
            will lose direct access to
          </Typography>
          <Typography component={"span"} variant="subtitle2">
            {reportName}
          </Typography>
          <Typography component={"span"} variant="body1">
            report. Are you sure?
          </Typography>
        </Box>
      </>
    );
  }

  if (access === ReportAccessType.Read) {
    return (
      <>
        <Typography variant="h6">Reduce access?</Typography>
        <Box sx={{ "& > span": { pr: 0.5 }, textAlign: "center" }}>
          <Typography component={"span"} variant="subtitle2">
            You
          </Typography>
          <Typography component={"span"} variant="body1">
            will lose access to the edit and sharing options of the
          </Typography>
          <Typography component={"span"} variant="subtitle2">
            {reportName}
          </Typography>
          <Typography component={"span"} variant="body1">
            report. Are you sure?
          </Typography>
        </Box>
      </>
    );
  }

  if (access === ReportAccessType.Write) {
    return (
      <>
        <Typography variant="h6">Reduce access?</Typography>
        <Box sx={{ "& > span": { pr: 0.5 }, textAlign: "center" }}>
          <Typography component={"span"} variant="subtitle2">
            You
          </Typography>
          <Typography component={"span"} variant="body1">
            will lose access to the sharing options of the
          </Typography>
          <Typography component={"span"} variant="subtitle2">
            {reportName}
          </Typography>
          <Typography component={"span"} variant="body1">
            report. Are you sure?
          </Typography>
        </Box>
      </>
    );
  }

  return null;
}
