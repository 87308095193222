import { useCallback } from "react";
import { MetricFormatConfiguration } from "../../../../api/portfolioMonitoringTypes";
import { formatMetricNumberValue } from "../../../../utilities/metricFormatters";
import NumberTextField from "../../../NumberTextField";

interface Props {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  formatConfig?: MetricFormatConfiguration;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricNumberField = ({ value, onChange, formatConfig, readOnly, disabled }: Props) => {
  const valueFormatter = useCallback((value: number) => formatMetricNumberValue(value, formatConfig), [formatConfig]);

  return (
    <NumberTextField
      fullWidth
      disabled={disabled}
      numberValue={value}
      onNumberChange={onChange}
      valueFormatter={valueFormatter}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    />
  );
};

export default MetricNumberField;
