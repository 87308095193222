import AddIcon from "@mui/icons-material/AddRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Stack, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { arraysHaveSameItems, stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { reportingPeriodCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import { ObjectAccessCategory } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import InlinePropertyBox from "../../../common/InlinePropertyBox";
import AddCompaniesDialog from "./AddCompaniesDialog";
import DataRequestTemplateCompaniesGrid from "./DataRequestTemplateCompaniesGrid";
import DataRequestTemplatePageTitle from "./DataRequestTemplatePageTitle";

const createTemplateVersion = withErrorHandling(adminApi.createDataRequestTemplateVersion);

const getTemplateCategoryIds = (template: DataCollectionRequestTemplateVersion) => {
  switch (template.auditory) {
    case "PortfolioCompany":
      return template.contactCategories;
    case "Internal":
      return template.internalUserPermissionCategories;
    default:
      return [];
  }
};

const getTemplateCategoryNames = (
  template: DataCollectionRequestTemplateVersion,
  allCategories: ObjectAccessCategory[]
) =>
  [...template.contactCategories, ...template.internalUserPermissionCategories]
    .map((id) => allCategories.find((c) => c.id === id)?.name ?? "")
    .filter(Boolean)
    .join(", ");

const getTemplateSettingsProperties = (
  template: DataCollectionRequestTemplateVersion,
  allCategories: ObjectAccessCategory[]
): Array<[string, string]> => [
  ["Configuration", template.dataSubmissionConfigurationName || "Unknown"],
  ["Scenario", template.isScenarioConfigurable ? "N/A (configurable)" : (template.scenarioName ?? "")],
  ["Period", reportingPeriodCaptionsMap[template.reportingPeriod]],
  ["Recipient Type", template.auditory ? autoFormatCamelCase(template.auditory) : ""],
  ["Access Category", getTemplateCategoryNames(template, allCategories)],
];

const DataRequestTemplatePage = () => {
  usePageTitle("Data Request Template");

  const { templateId } = useParams();
  const { clientCode, hasPermissions } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [name, setName] = useState("");
  const [recipientObjectIds, setRecipientObjectIds] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [isAddCompaniesDialogOpen, setAddCompaniesDialogOpen] = useState(false);

  const getTemplate = useCallback(() => adminApi.getLatestRequestTemplateVersion(defined(templateId)), [templateId]);

  const [template, fetchTemplateError, { setData: setTemplate }] = useFetch(getTemplate, (tpl) => {
    setName(tpl.name);
    setRecipientObjectIds(tpl.recipientObjectIds);
  });

  const [categories, fetchCategoriesError] = useFetch(adminApi.getObjectAccessCategories);

  const getCompanies = useCallback(() => adminApi.searchObjects("PortfolioCompany", { fieldIds: [] }), []);
  const [companies, fetchCompaniesError] = useFetch(getCompanies);

  const selectedCompanies = useMemo(
    () =>
      (companies?.items ?? []).filter((o) => recipientObjectIds.includes(o.id)).sort(stringComparerBy((o) => o.name)),
    [recipientObjectIds, companies?.items]
  );

  const companyOptions = useMemo(
    () =>
      (companies?.items ?? []).filter((o) => !recipientObjectIds.includes(o.id)).sort(stringComparerBy((o) => o.name)),
    [recipientObjectIds, companies?.items]
  );

  const fetchError = fetchTemplateError || fetchCategoriesError || fetchCompaniesError;

  if (fetchError) {
    logError(fetchError, "[DataRequestTemplatePage] fetch");
    return <DataLoadingFailed title="Could not load data request template" />;
  }

  if (template === undefined || categories === undefined || companies === undefined) {
    return <InlineLoader />;
  }

  const hasPermissionsToManage = hasPermissions(["ManagePortfolioMonitoring"]);

  const validationResult = requiredValidator(name);

  const isDirty =
    name.trim() !== template.name || !arraysHaveSameItems(recipientObjectIds, template.recipientObjectIds);

  const handleSave = async () => {
    setSaving(true);

    const [resp, error] = await createTemplateVersion({
      templateId: template.templateId,
      objectType: "PortfolioCompany",
      configurationId: template.dataSubmissionConfigurationId,
      name,
      auditory: template.auditory,
      objectAccessCategoryIds: getTemplateCategoryIds(template),
      recipientObjectIds,
    });

    setSaving(false);

    if (error) {
      logError(fetchError, "[DataRequestTemplatePage] save");
      sendNotificationError("Failed to save template version");
      return;
    }

    if (!resp.success) {
      logError(fetchError, "[DataRequestTemplatePage] save");
      sendNotificationError("Could not save template version:\n" + resp.errors.join("\n"));
      return;
    }

    sendNotification("Template version saved");
    setTemplate(resp.templateVersion);
  };

  const handleReset = () => {
    setName(template.name);
    setRecipientObjectIds(template.recipientObjectIds);
  };

  const handleAddCompanies = (companyIds: string[]) => {
    setRecipientObjectIds((ids) => [...ids, ...companyIds]);
  };

  const handleRemoveCompanies = (companyIds: string[]) => {
    setRecipientObjectIds((ids) => ids.filter((id) => !companyIds.includes(id)));
  };

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<DataRequestTemplatePageTitle template={template} />}
        showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequestSetup}?tab=templates`}
      />

      <Container maxWidth="lg" sx={{ py: 2.5, height: "100%" }}>
        <Stack spacing={3} height="100%">
          <TextField
            disabled={!hasPermissionsToManage}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            error={!validationResult.isValid}
            helperText={validationResult.error}
          />

          <InlinePropertyBox
            title="Template Settings"
            properties={getTemplateSettingsProperties(template, categories)}
          />

          {hasPermissionsToManage && (
            <Box display="flex" justifyContent="space-between">
              <Button variant="text" onClick={() => setAddCompaniesDialogOpen(true)} startIcon={<AddIcon />}>
                Add companies
              </Button>

              <Stack direction="row" spacing={1}>
                <LoadingButton
                  loading={saving}
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isDirty || !validationResult.isValid}
                >
                  Save
                </LoadingButton>
                <Button variant="text" color="secondary" onClick={handleReset} disabled={!isDirty}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}

          <DataRequestTemplateCompaniesGrid
            rows={selectedCompanies}
            hasPermissionsToManage={hasPermissionsToManage}
            onRemoveCompanies={handleRemoveCompanies}
          />
        </Stack>
      </Container>

      {isAddCompaniesDialogOpen && (
        <AddCompaniesDialog
          companyOptions={companyOptions}
          onClose={() => setAddCompaniesDialogOpen(false)}
          onAdd={handleAddCompanies}
        />
      )}
    </>
  );
};

export default DataRequestTemplatePage;
