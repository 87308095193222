import { Box, DialogContent, DialogTitle, Stack, Theme, useMediaQuery } from "@mui/material";
import PoweredBy from "../PoweredBy";
import HtmlPreview from "../htmlEditor/HtmlPreview";
import ConsentAgreementClientTitle from "./ConsentAgreementClientTitle";
import ConsentAgreementDefaultContent from "./ConsentAgreementDefaultContent";

interface ConsentDialogBodyProps {
  clientTitle?: string;
  logoUrl?: string;
  consentContent?: string;
  saveButton: React.ReactNode;
  acceptFormGroup: React.ReactNode;
  showTitle?: boolean;
  title: string;
}

const ConsentDialogBody = ({
  saveButton,
  acceptFormGroup,
  logoUrl,
  clientTitle,
  consentContent,
  showTitle,
  title,
}: ConsentDialogBodyProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      <DialogTitle>
        <ConsentAgreementClientTitle logoUrl={logoUrl} clientTitle={clientTitle} showTitle={showTitle} />
      </DialogTitle>
      <DialogContent dividers={consentContent !== undefined}>
        <Box
          px={1}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minWidth: "27rem",
            },
          })}
        >
          {consentContent === undefined ? (
            <ConsentAgreementDefaultContent title={title} />
          ) : (
            <HtmlPreview htmlContent={consentContent} />
          )}
        </Box>
      </DialogContent>
      <Stack px={3} pb={2} spacing={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          {acceptFormGroup}
          {saveButton}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <PoweredBy />
        </Box>
      </Stack>
    </>
  );
};

export default ConsentDialogBody;
