import { isBefore, isValid, parseISO, startOfToday } from "date-fns";
import { formatDateOnly } from "../../../../../../shared/utilities/dateUtils";
import {
  DataCollectionRequestDetails,
  UpdateDataCollectionRequestRequest,
} from "../../../../../api/types/dataCollectionTypes";

interface EditDataRequestForm {
  name: string;
  reportingDate: Date | null;
  dueDate: Date | null;
  touchedFields: ValidationFieldKey[];
  editedDataRequest: DataCollectionRequestDetails;
}

const validationFieldKeys = ["name", "reportingDate", "dueDate"] as const;
export type ValidationFieldKey = (typeof validationFieldKeys)[number];

interface FormValidationResult {
  isFormValid: boolean;
  validationErrors: Partial<Record<ValidationFieldKey, string | undefined>>;
}

export const getInitialEditDataRequestForm = (
  editedDataRequest: DataCollectionRequestDetails
): EditDataRequestForm => ({
  name: editedDataRequest.name,
  reportingDate: parseISO(editedDataRequest.reportingDate),
  dueDate: parseISO(editedDataRequest.dueDate),
  touchedFields: [],
  editedDataRequest,
});

export const formToUpdateRequestPayload = (
  form: EditDataRequestForm
): UpdateDataCollectionRequestRequest | undefined => {
  if (
    !form.name ||
    !form.reportingDate ||
    !isValid(form.reportingDate) ||
    !form.dueDate ||
    !isValid(form.dueDate) ||
    isBefore(form.dueDate, startOfToday())
  ) {
    return undefined;
  }

  const name = form.name.trim();
  const reportingDate = formatDateOnly(form.reportingDate);
  const dueDate = formatDateOnly(form.dueDate);

  return {
    name: name === form.editedDataRequest?.name ? undefined : name,
    reportingDate: reportingDate === form.editedDataRequest?.reportingDate ? undefined : reportingDate,
    dueDate: dueDate === form.editedDataRequest?.dueDate ? undefined : dueDate,
  };
};

export const validateForm = (form: EditDataRequestForm): FormValidationResult => {
  const validationErrors: Partial<Record<ValidationFieldKey, string | undefined>> = {};

  if (!form.name) {
    validationErrors.name = "Name is required";
  }

  if (!form.reportingDate || !isValid(form.reportingDate)) {
    validationErrors.reportingDate = "Reporting date is required";
  }

  if (!form.dueDate || !isValid(form.dueDate)) {
    validationErrors.dueDate = "Due date is required";
  } else if (isBefore(form.dueDate || new Date(), startOfToday())) {
    validationErrors.dueDate = "Due date must not be in the past";
  }

  const isFormValid = Object.values(validationErrors).every((e) => !e);

  validationFieldKeys.forEach((key) => {
    if (!form.touchedFields.includes(key)) {
      validationErrors[key] = undefined;
    }
  });

  return {
    isFormValid,
    validationErrors,
  };
};
