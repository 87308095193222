import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Checkbox, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { numberComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { Category } from "../../../../api/types/accessTypes";
import createCheckboxColumn from "../../../common/grid/createCheckboxColumn";
import { GroupedRowCell } from "../../../common/grid/GroupedRowCell";
import PortalRoleChip from "../../../common/PortalRoleChip";
import PortalRoleSelector from "../../../common/PortalRoleSelector";
import { CommunicationMatrixModel, orderedPortalRoles, portalRowToArray, resolvePortalRoles } from "./matrixHelper";

interface CommunicationMatrixColumnsParams {
  messageCategories: Category[];
  excludedColumns?: string[];
  onChange: (model: CommunicationMatrixModel) => void;
  onRemove: (ids: string[]) => void;
  readOnly: boolean;
  rowEditingDisabled: (model: CommunicationMatrixModel) => boolean;
  childRowActionsDisabled?: boolean;
  groupRowActionsDisabled?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
}

const MultilineHeader = ({ label }: { label: string }) => {
  return (
    <Tooltip arrow title={label} placement="bottom" disableInteractive enterDelay={500}>
      <Box>
        <TypographyMultilineEllipsis maxLines={2} typographyProps={{ fontWeight: 500, whiteSpace: "normal" }}>
          {label}
        </TypographyMultilineEllipsis>
      </Box>
    </Tooltip>
  );
};

export const getCommunicationMatrixColumns = ({
  messageCategories,
  excludedColumns,
  onChange,
  onRemove,
  readOnly,
  rowEditingDisabled,
  childRowActionsDisabled,
  groupRowActionsDisabled,
  apiRef,
}: CommunicationMatrixColumnsParams): GridColDef<CommunicationMatrixModel>[] => {
  const onCategoryChange = (model: CommunicationMatrixModel, category: Category, checked: boolean) => {
    const categories = model.categories.filter((categoryId) => categoryId !== category.externalId);
    if (checked) {
      categories.push(category.externalId);
    }
    onChange({ ...model, categories });
  };

  const categoriesColumns: GridColDef<CommunicationMatrixModel>[] = [
    {
      sortable: false,
      resizable: false,
      field: "divider",
      width: 1,
      renderCell: ({ rowNode }) =>
        GroupedRowCell(rowNode, <Box sx={{ borderRight: 1, borderColor: "divider", height: "100%" }} />),
      headerName: "",
    },
  ];

  messageCategories.sort(numberComparerBy((c) => c.sortOrder)).forEach((category) => {
    categoriesColumns.push({
      sortable: false,
      field: category.id,
      flex: 1,
      minWidth: 100,
      maxWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row, rowNode }) =>
        rowNode.depth !== 0 && (
          <Checkbox
            size="small"
            checked={row.categories.some((categoryId) => categoryId === category.externalId)}
            onChange={(e) => onCategoryChange(row, category, e.target.checked)}
            disabled={readOnly || rowEditingDisabled(row)}
          />
        ),
      renderHeader: () => <MultilineHeader label={category.name} />,
    });
  });

  const mainColumns: GridColDef<CommunicationMatrixModel>[] = [
    {
      sortable: false,
      resizable: false,
      field: "roles",
      width: 120,
      renderHeader: () => <MultilineHeader label="Portal Role" />,
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          readOnly || rowEditingDisabled(row) ? (
            <PortalRoleChip role={resolvePortalRoles(row.roles)} />
          ) : (
            <PortalRoleSelector
              role={resolvePortalRoles(row.roles)}
              onChange={(role) => onChange({ ...row, roles: portalRowToArray(role) })}
              roleOptions={["None", ...orderedPortalRoles]}
            />
          )
        ),
    },
    {
      sortable: false,
      resizable: false,
      field: "emailNotification",
      width: 90,
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          <Checkbox
            size="small"
            checked={row.emailNotification}
            disabled={readOnly || rowEditingDisabled(row)}
            onChange={(e) => onChange({ ...row, emailNotification: e.target.checked })}
          />
        ),
      renderHeader: () => <MultilineHeader label="Email Notification" />,
    },
    {
      sortable: false,
      resizable: false,
      field: "isPrimary",
      width: 80,
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          <Checkbox
            size="small"
            checked={row.isPrimary}
            disabled={readOnly || rowEditingDisabled(row)}
            onChange={(_e, checked) => onChange({ ...row, isPrimary: checked })}
          />
        ),
      renderHeader: () => <MultilineHeader label="Primary Contact" />,
    },
  ];

  const columns = mainColumns.filter((col) => !(excludedColumns ?? []).includes(col.field)).concat(categoriesColumns);

  columns.push({
    field: "actions",
    headerName: "",
    cellClassName: "grid-row-actions",
    width: 30,
    align: "right",
    renderCell: ({ rowNode }) => {
      const getModelIdsForActions = (): string[] => {
        if (rowNode.type === "group") {
          return groupRowActionsDisabled ? [] : (rowNode.children as string[]);
        }
        if (rowNode.type === "leaf" && rowNode.depth === 0) {
          return groupRowActionsDisabled ? [] : [rowNode.id as string];
        }
        return childRowActionsDisabled ? [] : [rowNode.id as string];
      };

      const ids = getModelIdsForActions();
      if (ids.length === 0) {
        return null;
      }

      return (
        <ActionsMenuButton
          items={[
            {
              icon: <CloseRoundedIcon color="error" />,
              label: "Remove",
              disabled: readOnly,
              onClick: () => onRemove(ids),
            },
          ]}
        />
      );
    },
  });

  return apiRef
    ? [createCheckboxColumn(apiRef, { groupsSelectionOnly: childRowActionsDisabled })].concat(columns)
    : columns;
};
