import { TabPanel } from "@mui/lab";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import storage from "../../../../storage/storage";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import TabLabelWithCount from "../../../common/TabLabelWithCount";
import AllDataSubmissionsList from "./AllDataSubmissionsList";
import MyDataSubmissionsList from "./MyDataSubmissionsList";

const tabValues = ["all", "my"] as const;
type TabValue = (typeof tabValues)[number];

const tabPanelSx = { flex: 1, px: 3, pt: 2.5, width: "100%" };

const DataSubmissionsPage = () => {
  usePageTitle("Data Submissions");

  const { clientCode, hasAnyPermission } = useClientContext();

  const [assignedDataSubmissions, fetchError, { isFetching, fetch: fetchAssignedDataSubmissions }] = useFetch(
    adminApi.getAssignedDataSubmissions
  );

  const hasAccessToPortfolioMonitoringManagement = useMemo(
    () => hasAnyPermission(["ViewPortfolioMonitoring", "ManagePortfolioMonitoring"]),
    [hasAnyPermission]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(
      searchParams,
      "tab",
      tabValues,
      hasAccessToPortfolioMonitoringManagement ? "all" : "my"
    )
  );

  if (fetchError) {
    logError(fetchError, "[DataSubmissionsPage] getAssignedDataSubmissions");
    storage.clearFilterState(`${clientCode}_my_data_submissions_filter_v1`);
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Data Submissions" hideBorderLine />

      <PageTabs
        tabs={hasAccessToPortfolioMonitoringManagement ? tabValues : ["my"]}
        labels={
          hasAccessToPortfolioMonitoringManagement
            ? [
                "All Submissions",
                <TabLabelWithCount
                  key="my"
                  label="My Submissions"
                  count={(assignedDataSubmissions ?? []).filter((ds) => ds.status === "Pending").length}
                  isActive={selectedTab === "my"}
                  hideCounter={!assignedDataSubmissions?.length}
                />,
              ]
            : ["My Submissions"]
        }
        value={selectedTab}
        onChange={handleTabChange}
      >
        {hasAccessToPortfolioMonitoringManagement && (
          <TabPanel value="all" sx={tabPanelSx}>
            <AllDataSubmissionsList />
          </TabPanel>
        )}
        <TabPanel value="my" sx={tabPanelSx}>
          <MyDataSubmissionsList
            assignedDataSubmissions={assignedDataSubmissions ?? []}
            loading={isFetching}
            onRefresh={fetchAssignedDataSubmissions}
          />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default DataSubmissionsPage;
