import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import { Link as RouterLink } from "react-router";
import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { formatDateTime } from "../../../../../shared/utilities/dateUtils";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { DataImportInfo } from "../../../../api/types/dataImportTypes";
import { pageRoutes } from "../../../../routes";
import { dataImportTargetCaptionMap } from "../../../../utilities/enumCaptions";
import { useImportDataContext } from "../ImportDataContext";
import ImportDataStatusTag from "../ImportDataStatusTag";
import ImportSourceLabel from "../ImportSourceLabel";
import { isImportEditable } from "../importDataStateHelper";

const DeleteDataImportButton = ({ row }: { row: DataImportInfo }) => {
  const { onDeleteImport } = useImportDataContext();

  if (row.state === "Applied") {
    return null;
  }

  return (
    <IconButton color="error" onClick={() => onDeleteImport(row)}>
      <DeleteIcon />
    </IconButton>
  );
};

export const columnDefinitions: GridColDef<DataImportInfo>[] = [
  {
    field: "description",
    headerName: "Name",
    minWidth: 200,
    flex: 1,
    cellClassName: ({ row }) => (row.source === "Passthrough" ? "" : "highlighted-action"),
    renderCell: ({ row }) => {
      const name = row.description || row.sourceDescription;
      if (row.source === "Passthrough") {
        return <Typography noWrap>{name}</Typography>;
      }

      const routerPath = isImportEditable(row.state)
        ? `${row.id}/${pageRoutes.importReview}`
        : `${row.id}/${pageRoutes.importDetails}`;

      return (
        <Link component={RouterLink} to={routerPath} underline="none" noWrap sx={{ color: "inherit" }}>
          {name}
        </Link>
      );
    },
  },
  {
    field: "source",
    headerName: "Source",
    minWidth: 160,
    flex: 1,
    renderCell: ({ row }) => <ImportSourceLabel dataImport={row} />,
  },
  {
    field: "targetEntityName",
    headerName: "Entity",
    minWidth: 160,
    flex: 1,
    renderCell: ({ row }) => {
      if (row.targetEntityName) {
        return <Typography noWrap>{row.targetEntityName}</Typography>;
      }

      return (
        <Stack maxWidth="100%">
          {distinctBy(row.files, (f) => f.importObjectTypeTarget).map(({ importObjectTypeTarget }) => (
            <Typography key={importObjectTypeTarget} noWrap>
              {autoFormatCamelCase(importObjectTypeTarget)}
            </Typography>
          ))}
        </Stack>
      );
    },
  },
  {
    field: "importData",
    headerName: "Import Data",
    minWidth: 200,
    flex: 1,
    cellClassName: ({ row }) =>
      row.importData.some((d) => !!d.url && ["AwaitingApplication", "Ready"].includes(row.state))
        ? "highlighted-action"
        : "",
    renderCell: ({ row }) => {
      if (row.importData.length > 0) {
        return (
          <Stack maxWidth="100%">
            {row.importData.map((data, index) =>
              data.url && ["AwaitingApplication", "Ready"].includes(row.state) ? (
                <Link
                  key={index}
                  href={data.url}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "inherit" }}
                >
                  <Typography noWrap>{data.category}</Typography>
                </Link>
              ) : (
                <Typography key={index} noWrap color="text.disabled">
                  {data.category}
                </Typography>
              )
            )}
          </Stack>
        );
      }

      return (
        <Stack maxWidth="100%">
          {distinctBy(row.files, (f) => f.importTypeTarget).map(({ importTypeTarget }) => (
            <Typography key={importTypeTarget} noWrap>
              {dataImportTargetCaptionMap[importTypeTarget]}
            </Typography>
          ))}
        </Stack>
      );
    },
  },
  {
    field: "state",
    headerName: "State",
    minWidth: 180,
    width: 180,
    renderCell: ({ row }) => <ImportDataStatusTag dataImportState={row.state} />,
  },
  {
    field: "createdByUserName",
    headerName: "Created by",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    minWidth: 180,
    width: 180,
    valueGetter: (_, row) => {
      const createdDate = parseISO(row.createdAt);
      return createdDate;
    },
    valueFormatter: (value) => formatDateTime(value),
  },
  {
    field: "actions",
    cellClassName: "grid-row-actions",
    headerName: "",
    minWidth: 64,
    width: 64,
    renderCell: DeleteDataImportButton,
  },
];
