import { endOfMonth, endOfQuarter, endOfYear } from "date-fns";
import { ReportingPeriod } from "../../api/dataCollectionTypes";

const reportingPeriodFormatMap: Record<ReportingPeriod, string> = {
  Month: "MMM yyyy",
  Quarter: "QQQ yyyy",
  Year: "yyyy",
  LastTwelveMonths: "MMM yyyy",
};

export const getReportingPeriodDateFormat = (reportingPeriod: ReportingPeriod): string =>
  reportingPeriodFormatMap[reportingPeriod] ?? "MMM yyyy";

export const getLastDateOfReportingPeriod = (date: Date, reportingPeriod: ReportingPeriod): Date => {
  switch (reportingPeriod) {
    case "Month":
      return endOfMonth(date);
    case "Quarter":
      return endOfQuarter(date);
    case "Year":
      return endOfYear(date);
    case "LastTwelveMonths":
      return endOfMonth(date);
    default:
      return date;
  }
};
