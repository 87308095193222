import { parseISO } from "date-fns";
import { ContactWithFieldValues } from "../../../../../api/adminApi";
import { SearchFilterDefinition, TableFilterDefinition } from "../../../../common/filters/filterTypes";
import { getStringContactStatus } from "../contactsGridDefinitions";

const getCommonDefinitions = (): TableFilterDefinition<ContactWithFieldValues>[] => [
  {
    id: "name",
    name: "Name",
    type: "text",
    getFieldValue: (row) => [row.email, row.name],
  },
  {
    id: "investor",
    name: "Investor",
    type: "multi_select",
    getFieldValue: (row) => row.fundInvestors.map((fi) => fi.investorName),
  },
  {
    id: "fund",
    name: "Fund",
    type: "multi_select",
    getFieldValue: (row) => row.fundInvestors.map((fi) => fi.fundName),
  },
  {
    id: "totalLiveMessages",
    name: "Live Documents",
    type: "number",
    getFieldValue: (row) => row.totalLiveMessages,
  },
];

export const getFilterDefinitionsForAll = (): TableFilterDefinition<ContactWithFieldValues>[] => [
  ...getCommonDefinitions(),
  {
    id: "hasAccessToInvestorPortal",
    name: "Access to Portal",
    type: "boolean",
    getFieldValue: (row) => row.hasAccessToInvestorPortal,
  },
  {
    id: "status",
    name: "Registration Status",
    type: "multi_select",
    getFieldValue: (row) => getStringContactStatus(row.status),
  },
  {
    id: "invitationSentAt",
    name: "Invited to Portal",
    type: "date",
    getFieldValue: (row) => (row.invitationSentAt ? parseISO(row.invitationSentAt) : undefined),
  },
  {
    id: "lastActivity",
    name: "Last Activity",
    type: "date",
    getFieldValue: (row) => (row.lastActivity ? parseISO(row.lastActivity) : undefined),
  },
];

export const getFilterDefinitionsForNotInvited = (): TableFilterDefinition<ContactWithFieldValues>[] =>
  getCommonDefinitions();

export const getSearchDefinition = (): SearchFilterDefinition<ContactWithFieldValues> => ({
  getFieldValues: (row) => [
    row.email,
    row.name,
    getStringContactStatus(row.status),
    ...row.fundInvestors.map((fi) => fi.investorName),
    ...row.fundInvestors.map((fi) => fi.fundName),
  ],
});
