import RefreshIcon from "@mui/icons-material/CachedRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import ActionsMenuButton from "../../../../../../shared/components/ActionsMenuButton";
import FileIcon from "../../../../../../shared/components/FileIcon";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import adminApi from "../../../../../api/adminApi";
import { DataImportInfo } from "../../../../../api/types/dataImportTypes";

interface Props {
  dataImport: DataImportInfo;
  showDownload?: boolean;
  showValidate?: boolean;
  showDelete?: boolean;
  disabled?: boolean;
  onValidate: () => void;
  onDelete: () => void;
}

const getCsvFileDownloadInfo = withErrorHandling(adminApi.getDataImportCsvFileDownloadInfo);

const getValidationSummaryCsvFileDownloadInfo = withErrorHandling(
  adminApi.getDataImportValidationSummaryCsvFileDownloadInfo
);

const ReviewImportActions = ({
  dataImport,
  showDownload,
  showValidate,
  showDelete,
  disabled,
  onValidate,
  onDelete,
}: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [downloading, setDownloading] = useState(false);

  const handleDownloadImportedFiles = async () => {
    setDownloading(true);

    const fileInfoTasks = dataImport.files.map((file) =>
      getCsvFileDownloadInfo(dataImport.id, {
        fileDataCatalogueId: file.sourceFileDataCatalogueId,
        includedActions: "All",
        includeDataImportMetadata: true,
      })
    );

    const results = await Promise.all(fileInfoTasks);

    setDownloading(false);

    const failedResults = results.filter(([, error]) => !!error);

    if (failedResults.length > 0) {
      logError(failedResults[0]?.[1], "[ReviewImportActions] getCsvFileDownloadInfo");
      sendNotificationError(
        `Could not obtain file download information from ${failedResults.length} files of ${dataImport.files.length}.`
      );
      return;
    }

    const downloadUrls = results.map(([resp]) => resp?.downloadUrl ?? "").filter(Boolean);
    downloadUrls.forEach(downloadFileFromUrl);
  };

  const handleDownloadValidationSummary = async () => {
    setDownloading(true);
    const [resp, error] = await getValidationSummaryCsvFileDownloadInfo(dataImport.id);
    setDownloading(false);

    if (error) {
      logError(error, "[ReviewImportActions] getValidationSummaryCsvFileDownloadInfo");
      sendNotificationError("Could not obtain validation summary file download information.");
      return;
    }

    downloadFileFromUrl(resp.downloadUrl);
  };

  return (
    <>
      {showDownload && (
        <ActionsMenuButton
          text="Download"
          icon={<DownloadIcon />}
          variant="outlined"
          color="secondary"
          loading={downloading}
          disabled={disabled}
          items={[
            {
              label: dataImport.files.length > 1 ? `Imported Files (${dataImport.files.length})` : "Imported File",
              icon: <FileIcon fileExtension=".csv" />,
              onClick: handleDownloadImportedFiles,
              disabled: dataImport.files.length === 0,
            },
            {
              label: "Validation Summary CSV",
              icon: <FileIcon fileExtension=".csv" />,
              onClick: handleDownloadValidationSummary,
            },
          ]}
        />
      )}
      {showValidate && (
        <Button variant="outlined" onClick={onValidate} startIcon={<RefreshIcon />} disabled={disabled}>
          Validate
        </Button>
      )}
      {showDelete && (
        <Button variant="outlined" color="error" onClick={onDelete} startIcon={<DeleteIcon />} disabled={disabled}>
          Delete
        </Button>
      )}
    </>
  );
};

export default ReviewImportActions;
