import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { GridColDef } from "@mui/x-data-grid-premium";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { UserConsentInfo } from "../../../../api/types/userConsentTypes";
import UserConsentCategoryTag from "./UserConsentCategoryTag";
import { useUserConsentsSectionContext } from "./UserConsentsSectionContext";

const ActionCell = ({ row }: { row: UserConsentInfo }) => {
  const { hasEditPermissions, onDeleteConsent } = useUserConsentsSectionContext();

  if (!hasEditPermissions) {
    return null;
  }

  return (
    <ActionsMenuButton
      items={[
        {
          icon: <DeleteIcon color="error" />,
          label: "Delete",
          onClick: () => onDeleteConsent(row.name),
        },
      ]}
    />
  );
};

const CategoryCell = ({ row }: { row: UserConsentInfo }) => {
  const { categories } = useUserConsentsSectionContext();
  if (categories.length === 0) {
    return null;
  }

  return <UserConsentCategoryTag categoryId={row.categoryId} categories={categories} />;
};

export const columnDefinitions: GridColDef<UserConsentInfo>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    cellClassName: "highlighted-action",
    renderCell: ({ row }) => <TypographyTooltipEllipsis text={row.name}></TypographyTooltipEllipsis>,
  },
  {
    field: "categoryId",
    headerName: "Category",
    flex: 1,
    minWidth: 250,
    renderCell: ({ row }) => <CategoryCell row={row} />,
  },
  {
    field: "activeFrom",
    headerName: "Activation date",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value) => (value ? convertISODate(value) : "-"),
  },
  {
    field: "lastModified",
    headerName: "Modified at",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value) => (value ? convertISODateTime(value) : "-"),
  },
  {
    field: "actions",
    headerName: "",
    width: 30,
    cellClassName: "grid-row-actions",
    align: "right",
    renderCell: ({ row }) => <ActionCell row={row} />,
  },
];
