import React, { PropsWithChildren } from "react";
import { AnyObject } from "../../../shared/types";
import { FeatureToggle } from "../../api/types/clientTypes";
import { useClientContext } from "../../context/ClientContext";

interface Props {
  featureToggle: FeatureToggle;
}

const FeatureToggleBoundary = ({ featureToggle, children, ...externalProps }: PropsWithChildren<Props>) => {
  const { isPortalFeatureEnabled } = useClientContext();
  if (!isPortalFeatureEnabled(featureToggle)) {
    return null;
  }

  if (Object.keys(externalProps).length === 0) {
    return <>{children}</>;
  }

  return React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { ...externalProps, ...(child.props as AnyObject) }) : child
  );
};

export default FeatureToggleBoundary;
