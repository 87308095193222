import { Button, Grid2, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { AccessPermission } from "../../../../shared/api/types";
import AuthorizedBox from "../../../../shared/components/AuthorizedBox";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import AddGroupDialog from "../../../../shared/reporting/components/reportGroups/AddGroupDialog";
import { useClientContext } from "../../../context/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import GroupsGrid from "./GroupsGrid";
import { GroupType } from "./GroupType";

interface Props {
  permissions: AccessPermission[];
  title: string;
  type: GroupType;
}

export const GroupsPage = ({ permissions, title, type }: Props) => {
  usePageTitle(title);

  const { clientCode } = useClientContext();
  const [showAddLevel, setShowAddLevel] = useState(false);
  const { sendNotification } = useNotificationContext();
  const { groups: locale } = useLocalization();

  return (
    <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
      <GeneralPageHeader showDefaultBackButtonTo={".."} title={title}>
        <AuthorizedBox permissions={[...permissions]}>
          <Button variant="contained" onClick={() => setShowAddLevel(true)}>
            Add Group
          </Button>
        </AuthorizedBox>
      </GeneralPageHeader>
      <Grid2
        container
        sx={{
          display: "flex",
          flex: 1,
          px: 3,
          flexDirection: "column",
          gap: 0.5,
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        <Grid2 pt={1} pl={2}>
          <Typography variant="subtitle2">Name</Typography>
        </Grid2>
        <Grid2 container flex={1} width="100%">
          <GroupsGrid permissions={permissions} type={type} />
        </Grid2>
        {showAddLevel && (
          <AddGroupDialog
            clientCode={clientCode}
            onAdded={() => sendNotification(locale.created)}
            onClose={() => setShowAddLevel(false)}
          />
        )}
      </Grid2>
    </Stack>
  );
};

export default GroupsPage;
