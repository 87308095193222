import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Investor } from "../../../../../api/adminApi";
import MultiselectAutocomplete from "../../../../common/MultiselectAutocomplete";
import SimpleDialog from "../../../../common/SimpleDialog";

interface Props {
  open: boolean;
  options: Investor[];
  title: string;
  description: string;
  onClose: () => void;
  onCreateNew: () => void;
  onSelected: (items: Investor[]) => void;
}

const AssignInvestorsDialog = ({ open, options, title, description, onClose, onCreateNew, onSelected }: Props) => {
  const [selectedInvestors, setSelectedInvestors] = useState<Investor[]>([]);

  const handleSubmit = () => {
    setSelectedInvestors([]);
    onSelected(selectedInvestors);
  };

  const handleDialogClose = () => {
    setSelectedInvestors([]);
    onClose();
  };

  return (
    <SimpleDialog
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      title={title}
      open={open}
      onClose={handleDialogClose}
      onSubmit={handleSubmit}
      submitButtonDisabled={selectedInvestors.length === 0}
      submitButtonText="Add Investor(s)"
    >
      <Stack spacing={2}>
        <Typography color="text.secondary">{description}</Typography>
        <MultiselectAutocomplete
          options={options}
          values={selectedInvestors}
          onSelected={setSelectedInvestors}
          getOptionLabel={(option: Investor) => option.title}
          getOptionValue={(option: Investor) => option.id}
          noItemsText="No investors"
          onCreateOption={onCreateNew}
        />
      </Stack>
    </SimpleDialog>
  );
};

export default AssignInvestorsDialog;
