import BugIcon from "@mui/icons-material/BugReportOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ApiError } from "../../../../../shared/api/types";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { useFilesContext } from "../FilesContext";
import { FilesRowModel } from "../filesState";

interface Props {
  row: FilesRowModel;
}

const StoredFileActionsCell = ({ row }: Props) => {
  const { onDownloadFile, onDeleteFile, hasPermissionsToManage } = useFilesContext();

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Download" arrow>
        <IconButton onClick={() => onDownloadFile(row)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <ActionsMenuButton
        items={[
          {
            disabled: !hasPermissionsToManage,
            label: "Delete",
            icon: <DeleteIcon color="error" />,
            onClick: () => onDeleteFile(row),
          },
        ]}
      />
    </Stack>
  );
};

const UploadInProgressActionsCell = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Uploading
      </Typography>
      <CircularProgress size={20} color="secondary" />
    </Stack>
  );
};

const UploadCompletedActionsCell = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Completed
      </Typography>
      <CheckIcon color="success" />
    </Stack>
  );
};

const InvalidFileActionsCell = ({ fileId, validationError }: { fileId: string; validationError: string }) => {
  const { dispatchFiles } = useFilesContext();

  const handleRemove = () => {
    dispatchFiles({ type: "remove_files", catalogueIds: [fileId] });
  };

  const errorText = validationError || " This file cannot be uploaded";

  return (
    <Stack direction="row" alignItems="center" spacing={1} width="100%">
      <Tooltip title={errorText} arrow>
        <Box>
          <TypographyMultilineEllipsis
            typographyProps={{ variant: "caption", sx: { color: "error.main", whiteSpace: "normal" } }}
            maxLines={3}
          >
            {errorText}
          </TypographyMultilineEllipsis>
        </Box>
      </Tooltip>
      <Tooltip title="Remove" arrow>
        <IconButton color="error" onClick={handleRemove}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const UploadFailedActionsCell = ({ fileId, uploadError }: { fileId: string; uploadError: ApiError | undefined }) => {
  const { dispatchFiles } = useFilesContext();

  const handleRemove = () => {
    dispatchFiles({ type: "remove_files", catalogueIds: [fileId] });
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {uploadError?.type === "MalwareDetected" && (
        <>
          <BugIcon color="error" />
          <Typography variant="caption" color="error">
            Upload failed: malware detected
          </Typography>
        </>
      )}
      {uploadError?.type !== "MalwareDetected" && (
        <Typography variant="caption" color="error">
          Upload failed
        </Typography>
      )}
      <Tooltip title="Remove" arrow>
        <IconButton color="error" onClick={handleRemove}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const FilesGridActionsCell = ({ row }: Props) => {
  if (row.uploadState !== undefined) {
    switch (row.uploadState.status) {
      case "in_progress": {
        return <UploadInProgressActionsCell />;
      }
      case "completed": {
        return <UploadCompletedActionsCell />;
      }
      case "error": {
        return row.uploadState.validationError !== undefined ? (
          <InvalidFileActionsCell fileId={row.catalogueId} validationError={row.uploadState.validationError} />
        ) : (
          <UploadFailedActionsCell fileId={row.catalogueId} uploadError={row.uploadState.uploadError} />
        );
      }
      default: {
        return null;
      }
    }
  }

  return <StoredFileActionsCell row={row} />;
};

export default FilesGridActionsCell;
