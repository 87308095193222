import { TabPanel } from "@mui/lab";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import DataRequestCategoriesView from "../data-request-categories/DataRequestCategoriesView";
import { DataRequestSetupPageContextProvider } from "./DataRequestSetupPageContext";
import DataRequestTemplatesView from "./DataRequestTemplatesView";

const tabValues = ["templates", "categories"] as const;
type TabValue = (typeof tabValues)[number];

const tabPanelSx = { flex: 1, px: 3, pt: 2.5, width: "100%" };

const DataRequestSetupPage = () => {
  usePageTitle("Data Request Setup");

  const { hasPermissions } = useClientContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(searchParams, "tab", tabValues)
  );

  const [categories, fetchCategoriesError, { setData: setCategories }] = useFetch(adminApi.getObjectAccessCategories);

  const getPortfolioCompanyDefinition = useCallback(() => adminApi.getObjectDefinition("PortfolioCompany"), []);
  const [portfolioCompanyDefinition, fetchDefinitionError] = useFetch(getPortfolioCompanyDefinition);

  const [configurations, fetchConfigurationsError] = useFetch(adminApi.getDataSubmissionConfigurations);

  const fetchError = fetchCategoriesError || fetchDefinitionError || fetchConfigurationsError;
  if (fetchError) {
    logError(fetchError, "[DataRequestSetupPage]");
    return <DataLoadingFailed title="Could not load data request templates" />;
  }

  if (categories === undefined || portfolioCompanyDefinition === undefined || configurations === undefined) {
    return <InlineLoader />;
  }

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const objectDefinition = portfolioCompanyDefinition.objectClassDefinition;

  const portfolioCategories = categories.filter(
    (category) =>
      category.type === objectDefinition.objectContactCategoryType ||
      category.type === objectDefinition.internalUserPermissionsCategoryType
  );

  return (
    <DataRequestSetupPageContextProvider
      categories={portfolioCategories}
      onCategoriesUpdated={setCategories}
      configurations={configurations}
      objectDefinition={objectDefinition}
      hasPermissionsToManage={hasPermissions(["ManagePortfolioMonitoring"])}
    >
      <GeneralPageHeader title="Data Request Setup" hideBorderLine />

      <PageTabs tabs={tabValues} labels={["Templates", "Categories"]} value={selectedTab} onChange={handleTabChange}>
        <TabPanel value="templates" sx={tabPanelSx}>
          <DataRequestTemplatesView />
        </TabPanel>
        <TabPanel value="categories" sx={tabPanelSx}>
          <DataRequestCategoriesView />
        </TabPanel>
      </PageTabs>
    </DataRequestSetupPageContextProvider>
  );
};

export default DataRequestSetupPage;
