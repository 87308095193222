import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  isAfter,
  isBefore,
  isValid,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";

export interface DescriptiveDateRange {
  title: string;
  description?: string;
  from: Date | undefined;
  to: Date | undefined;
}

const forAnytime = () => ({
  from: undefined,
  to: undefined,
});

const forToday = () => {
  const date = Date.now();
  return {
    description: format(date, "dd MMM yyyy"),
    from: startOfDay(date),
    to: endOfDay(date),
  };
};

const forYesterday = () => {
  const date = subDays(Date.now(), 1);
  return {
    description: format(date, "dd MMM yyyy"),
    from: startOfDay(date),
    to: endOfDay(date),
  };
};

const forThisQuarter = () => {
  const date = Date.now();
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);

  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter,
    to: endOfTheQuarter,
  };
};

const forThisMonth = () => {
  const date = new Date();
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);

  return {
    description: format(date, "MMM yyyy"),
    from: startOfTheMonth,
    to: endOfTheMonth,
  };
};

const forThisYear = () => {
  const date = new Date();
  const startOfTheYear = startOfYear(date);

  return {
    description: format(date, "yyyy"),
    from: startOfTheYear,
    to: endOfYear(date),
  };
};

const forLastDays = (daysCount: number) => {
  const date = new Date();
  const shiftedDate = endOfDay(subDays(date, daysCount));

  return {
    description: `${format(shiftedDate, "MMM dd")} - ${format(date, "MMM dd")}`,
    from: shiftedDate,
    to: undefined,
  };
};

function forLastMonths(monthsCount: number) {
  const date = new Date();
  const fromDate = startOfMonth(subMonths(date, monthsCount));
  const toDate = endOfMonth(date);

  return {
    description: `${format(fromDate, "MMM yyyy")} - ${format(date, "MMM yyyy")}`,
    from: fromDate,
    to: toDate,
  };
}

function forLastMonth() {
  const date = subMonths(Date.now(), 1);
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);
  return {
    description: format(startOfTheMonth, "MMM yyyy"),
    from: startOfTheMonth,
    to: endOfTheMonth,
  };
}

const forLastQuarter = () => {
  const date = subQuarters(Date.now(), 1);
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);
  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter,
    to: endOfTheQuarter,
  };
};

const forLastYear = () => {
  const date = subYears(Date.now(), 1);
  const startOfTheYear = startOfYear(date);
  const endOfTheYear = endOfYear(date);
  return {
    description: format(startOfTheYear, "yyyy"),
    from: startOfTheYear,
    to: endOfTheYear,
  };
};

const forNextYear = () => {
  const date = addYears(Date.now(), 1);
  const startOfTheYear = startOfYear(date);
  const endOfTheYear = endOfYear(date);
  return {
    description: format(startOfTheYear, "yyyy"),
    from: startOfTheYear,
    to: endOfTheYear,
  };
};

const forNextDays = (daysCount: number) => {
  const date = new Date();
  const shiftedDate = endOfDay(addDays(date, daysCount));
  return {
    description: `${format(date, "MMM dd")} - ${format(shiftedDate, "MMM dd")}`,
    from: date,
    to: shiftedDate,
  };
};

const forNextMonth = () => {
  const date = addMonths(Date.now(), 1);
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);
  return {
    description: format(startOfTheMonth, "MMM yyyy"),
    from: startOfTheMonth,
    to: endOfTheMonth,
  };
};

const forNextQuarter = () => {
  const date = addQuarters(Date.now(), 1);
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);
  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter,
    to: endOfTheQuarter,
  };
};

export const anytime: DescriptiveDateRange = { title: "Any time", ...forAnytime() };

export const today: DescriptiveDateRange = { title: "Today", ...forToday() };
export const yesterday: DescriptiveDateRange = { title: "Yesterday", ...forYesterday() };

export const thisMonth: DescriptiveDateRange = { title: "This month", ...forThisMonth() };
export const thisQuarter: DescriptiveDateRange = { title: "This quarter", ...forThisQuarter() };
export const thisYear: DescriptiveDateRange = { title: "This year", ...forThisYear() };

export const lastSevenDays: DescriptiveDateRange = { title: "Last 7 Days", ...forLastDays(7) };
export const last30Days: DescriptiveDateRange = { title: "Last 30 Days", ...forLastDays(30) };
export const last12Months: DescriptiveDateRange = { title: "Last 12 Months", ...forLastMonths(12) };

export const lastMonth: DescriptiveDateRange = { title: "Last month", ...forLastMonth() };
export const lastQuarter: DescriptiveDateRange = { title: "Last quarter", ...forLastQuarter() };
export const lastYear: DescriptiveDateRange = { title: "Last year", ...forLastYear() };

export const nextSevenDays: DescriptiveDateRange = { title: "Next 7 Days", ...forNextDays(7) };
export const next30Days: DescriptiveDateRange = { title: "Next 30 Days", ...forNextDays(30) };

export const nextMonth: DescriptiveDateRange = { title: "Next month", ...forNextMonth() };
export const nextQuarter: DescriptiveDateRange = { title: "Next quarter", ...forNextQuarter() };
export const nextYear: DescriptiveDateRange = { title: "Next year", ...forNextYear() };

export const dateMatchesDescriptiveDateRange = (date: Date, range: DescriptiveDateRange) =>
  isValid(date) &&
  (range.from === undefined || !isBefore(date, range.from)) &&
  (range.to === undefined || !isAfter(date, range.to));
