import { Box, Divider, Stack, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useReducer, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import { ApiResponse, FileDownloadInfo } from "../../../../shared/api/types";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import FilesDropArea from "../../../../shared/components/FilesDropArea";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../shared/components/inputs/SearchField";
import PreviewFileDialog from "../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import { wait } from "../../../../shared/utilities/promiseHelper";
import { fileToUploadValidator } from "../../../../shared/utilities/validators";
import adminApi from "../../../api/adminApi";
import { FileInfo, GridColumnDefinition } from "../../../api/types/fileTypes";
import DeleteFilesDialog from "./DeleteFilesDialog";
import { EntityInfo, FilesContextProvider } from "./FilesContext";
import FileDetailsPanel from "./fileDetails/FileDetailsPanel";
import FilesGrid from "./filesGrid/FilesGrid";
import { getInitialState, reducer } from "./filesState";
import { UploadingFile, getUploadingFiles } from "./uploadHelper";

const pageSize = 50;

interface Props {
  viewName?: string;
  tagId?: string;
  viewTitle: string;
  columnDefinitions: GridColumnDefinition[];
  hasPermissionsToManage: boolean;
  uploadParams?: UploadParams;
  entityInfo?: EntityInfo;
}

interface UploadParams {
  acceptedFileExtensions: string[];
  maxFileSize: number;
  dropAreaSubtitle: string;
  uploadFileApiCall: (file: File) => Promise<ApiResponse<FileInfo>>;
}

interface DialogState {
  openDialog?: "preview" | "delete";
  previewFileInfo?: FileDownloadInfo;
  deletingFileIds?: string[];
  deletingFilesDescription?: string;
}

const getFileDownloadInfo = withErrorHandling(adminApi.getFileDownloadInfo);
const getEntityFileDownloadInfo = withErrorHandling(adminApi.getEntityFileDownloadInfo);
const getDownloadUrlForMultipleFiles = withErrorHandling(adminApi.getDownloadUrlForMultipleFiles);
const getDownloadUrlForMultipleEntityFiles = withErrorHandling(adminApi.getDownloadUrlForMultipleEntityFiles);

const FilesView = ({
  viewName,
  tagId,
  viewTitle,
  columnDefinitions,
  hasPermissionsToManage,
  uploadParams,
  entityInfo,
}: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const apiRef = useGridApiRef();
  const [filesState, dispatchFiles] = useReducer(reducer, getInitialState());
  const [dialogState, setDialogState] = useState<DialogState>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const selectedFiles = useMemo(
    () => filesState.files.filter((fileInfo) => selectedRowIds.includes(fileInfo.catalogueId)),
    [filesState.files, selectedRowIds]
  );

  const fetchFilesForView = useCallback(() => {
    const filterParams = {
      tagId: tagId,
      viewName: viewName,
      searchTerm: filesState.searchTerm || undefined,
    };

    const pagingParams = {
      page: filesState.page,
      size: pageSize,
      totals: filesState.page === 0,
    };

    return entityInfo
      ? adminApi.getEntityFiles(
          entityInfo.entityType,
          entityInfo.entityId,
          entityInfo.objectType,
          filterParams,
          pagingParams
        )
      : adminApi.getCompanyFiles(filterParams, pagingParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityInfo?.entityId, entityInfo?.entityType, filesState.page, filesState.searchTerm, tagId, viewName]);

  const [, error, { isFetching }] = useFetch(fetchFilesForView, (data) => {
    if (data) {
      dispatchFiles({ type: "load_files", data });
    }
  });

  if (error) {
    logError(error, "FilesView");
    return <DataLoadingFailed title="Could not load files" />;
  }

  const handleSearch = (value: string) => {
    const searchTerm = value.trim();
    if (searchTerm.length === 0 || searchTerm.length > 2) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
      dispatchFiles({ type: "search", searchTerm });
    }
  };

  const handleDownloadFile = async ({ catalogueId }: FileInfo) => {
    const [fileDownloadInfo, error] = entityInfo
      ? await getEntityFileDownloadInfo(entityInfo.entityType, entityInfo.entityId, entityInfo.objectType, catalogueId)
      : await getFileDownloadInfo(catalogueId);

    if (error) {
      sendNotificationError("Could not download the file");
      logError(error, "FilesView");
      return;
    }

    downloadFileFromUrl(fileDownloadInfo.downloadUrl);
  };

  const handleDownloadMultipleFiles = async (catalogueIds: string[]) => {
    const [downloadUrl, error] = entityInfo
      ? await getDownloadUrlForMultipleEntityFiles(
          entityInfo.entityType,
          entityInfo.entityId,
          entityInfo.objectType,
          catalogueIds,
          `${entityInfo.entityName}_files.zip`
        )
      : await getDownloadUrlForMultipleFiles(catalogueIds, "files.zip");

    if (error || !downloadUrl) {
      sendNotificationError("Could not download multiple files as zip");
      logError(error, "FilesView");
      return;
    }

    downloadFileFromUrl(downloadUrl);
  };

  const handlePreviewFile = async ({ catalogueId }: FileInfo) => {
    const [fileDownloadInfo, error] = entityInfo
      ? await getEntityFileDownloadInfo(entityInfo.entityType, entityInfo.entityId, entityInfo.objectType, catalogueId)
      : await getFileDownloadInfo(catalogueId);

    if (error) {
      sendNotificationError("Could not preview the file");
      logError(error, "FilesView");
      return;
    }

    setDialogState({ openDialog: "preview", previewFileInfo: fileDownloadInfo });
  };

  const handleDeleteFile = (fileInfo: FileInfo) => {
    setDialogState({
      openDialog: "delete",
      deletingFileIds: [fileInfo.catalogueId],
      deletingFilesDescription: fileInfo.fileName,
    });
  };

  const handleDeleteMultipleFiles = (catalogueIds: string[]) => {
    setDialogState({
      openDialog: "delete",
      deletingFileIds: catalogueIds,
      deletingFilesDescription: `${catalogueIds.length} files`,
    });
  };

  const handleFilesDeleted = (catalogueIds: string[]) => {
    dispatchFiles({ type: "remove_files", catalogueIds });
    setDialogState({});
  };

  const uploadFile = async (uploadingFile: UploadingFile) => {
    if (!uploadingFile.file || !uploadParams) {
      return;
    }

    const { uploadFileApiCall } = uploadParams;
    const [fileInfo, uploadError] = await withErrorHandling(uploadFileApiCall)(uploadingFile.file);
    if (fileInfo) {
      dispatchFiles({
        type: "update_upload_state",
        fileId: uploadingFile.id,
        uploadingState: { status: "completed" },
      });

      await wait(2000);

      dispatchFiles({ type: "finish_upload", fileId: uploadingFile.id, fileInfo });
    } else {
      dispatchFiles({
        type: "update_upload_state",
        fileId: uploadingFile.id,
        uploadingState: {
          status: "error",
          uploadError,
        },
      });
    }
  };

  const handleFilesAddToUpload = async (files: File[]) => {
    if (!uploadParams) {
      return;
    }

    const { maxFileSize, acceptedFileExtensions } = uploadParams;

    const validateFile = fileToUploadValidator({
      maxFileSize,
      acceptedFileExtensions,
    });

    const uploadingFiles = getUploadingFiles(files, validateFile);

    dispatchFiles({ type: "start_upload", uploadingFileModels: uploadingFiles.map(({ rowModel }) => rowModel) });
    await Promise.all(uploadingFiles.map(uploadFile));
  };

  return (
    <FilesContextProvider
      filesState={filesState}
      dispatchFiles={dispatchFiles}
      hasPermissionsToManage={hasPermissionsToManage}
      entityInfo={entityInfo}
      onDownloadFile={handleDownloadFile}
      onDownloadMultipleFiles={handleDownloadMultipleFiles}
      onPreviewFile={handlePreviewFile}
      onDeleteFile={handleDeleteFile}
      onDeleteMultipleFiles={handleDeleteMultipleFiles}
    >
      <Stack width="100%">
        <Box py={2} px={3}>
          <Typography variant="subtitle1">{viewTitle}</Typography>
        </Box>
        <Divider />
        <Box display="flex" width="100%" height="100%">
          <Stack width="100%" spacing={2.5} py={2.5} flex={1}>
            {hasPermissionsToManage && uploadParams && (
              <Box px={3}>
                <FilesDropArea
                  allowMultiple
                  acceptedFileExtensions={uploadParams.acceptedFileExtensions}
                  subtitle={uploadParams.dropAreaSubtitle}
                  onFilesAdd={handleFilesAddToUpload}
                />
              </Box>
            )}
            <Stack px={3} spacing={2} direction="row" alignItems="center" justifyContent="space-between">
              <RecordCounter
                records={filesState.files.length}
                totalRecords={filesState.totalRecords}
                hide={isFetching || filesState.totalRecords === 0}
              />
              <SearchField debounceTimeMs={300} onSearch={handleSearch} />
            </Stack>
            <FilesGrid
              columnDefinitions={columnDefinitions}
              isLoading={isFetching}
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
              apiRef={apiRef}
            />
          </Stack>
          <Divider flexItem orientation="vertical" />
          <FileDetailsPanel files={selectedFiles} />
        </Box>
      </Stack>

      {dialogState.openDialog === "preview" && dialogState.previewFileInfo && (
        <PreviewFileDialog
          url={dialogState.previewFileInfo.downloadUrl}
          fileName={dialogState.previewFileInfo.fileName}
          onClose={() => setDialogState({})}
        />
      )}
      <DeleteFilesDialog
        catalogueIds={dialogState.deletingFileIds ?? []}
        description={dialogState.deletingFilesDescription ?? ""}
        open={dialogState.openDialog === "delete"}
        onCancel={() => setDialogState({})}
        onSubmit={handleFilesDeleted}
      />
    </FilesContextProvider>
  );
};

export default FilesView;
