import { InputAdornment, TextField, Typography } from "@mui/material";
import { MetricInputSettingsConfiguration } from "../../../../api/portfolioMonitoringTypes";

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  inputSettings?: MetricInputSettingsConfiguration;
  rows?: number;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricTextField = ({ value, onChange, inputSettings, rows, readOnly, disabled }: Props) => {
  return (
    <TextField
      multiline={rows !== undefined}
      rows={rows}
      fullWidth
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
      slotProps={
        inputSettings?.maxNumberOfSymbols && !readOnly
          ? {
              htmlInput: { maxLength: inputSettings.maxNumberOfSymbols },
              input: {
                endAdornment: (
                  <InputAdornment position="end" sx={{ alignSelf: "flex-start" }}>
                    <Typography color="text.secondary" variant="caption">
                      {inputSettings.maxNumberOfSymbols - value.length}
                    </Typography>
                  </InputAdornment>
                ),
              },
            }
          : undefined
      }
    />
  );
};

export default MetricTextField;
