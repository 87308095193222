import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Button } from "@mui/material";
import CustomDataGridHeaderToolbar from "../../../common/grid/CustomDataGridHeaderToolbar";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  onRemoveCompanies: (ids: string[]) => void;
}

const DataRequestTemplateCompaniesGridToolbar = ({ selectedIds, setSelectedIds, onRemoveCompanies }: Props) => {
  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button color="error" variant="text" startIcon={<DeleteIcon />} onClick={() => onRemoveCompanies(selectedIds)}>
        Delete
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default DataRequestTemplateCompaniesGridToolbar;
