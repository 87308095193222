import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../shared/icons/BinIcon";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DataImportInfo } from "../../../api/types/dataImportTypes";

const discardDataImport = withErrorHandling(adminApi.discardDataImport);

interface Props {
  dataImport: DataImportInfo;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteDataImportDialog = ({ dataImport, onClose, onConfirm }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [result, error] = await discardDataImport(dataImport.id);
    setLoading(false);

    if (error || !result) {
      logError(error, "[DeleteDataImportDialog]");
      sendNotificationError("Could not delete data import");
      return;
    }

    sendNotification("Data import deleted successfully");
    onConfirm();
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Delete Data Import</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete data import?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to delete <strong>{dataImport.description || dataImport.sourceDescription}</strong>.
              This action is permanent and can&apos;t be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleSubmit}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDataImportDialog;
