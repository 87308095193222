import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import usePageTitle from "../../../../../../shared/hooks/usePageTitle";
import { useClientContext } from "../../../../../context/ClientContext";
import ReportsPageContainer from "../components/ReportsPageContainer";
import { getEditApiReportUrl } from "../utilities/editReportUrl";
import { useApiReportsContext } from "./contexts/ReportsContext";
import ReportsGridWrapper from "./grid/ReportsGridWrapper";

const CompanyApiReportsContainer = () => {
  usePageTitle("API Reports");

  const { clientCode } = useClientContext();
  const { reports } = useApiReportsContext();

  return (
    <ReportsPageContainer
      title="API Reports"
      Actions={
        <Button
          variant="contained"
          startIcon={<AddRoundedIcon />}
          onClick={() => window.open(getEditApiReportUrl(clientCode, "new"), "_blank", "noopener noreferrer")}
        >
          Create New
        </Button>
      }
    >
      <ReportsGridWrapper reports={reports} />
    </ReportsPageContainer>
  );
};

export default CompanyApiReportsContainer;
