import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import CopyToBufferButton from "../../../../../../shared/components/inputs/CopyToBufferButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";

interface Props {
  contactId: string;
  contactEmail: string;
  onClose: () => void;
}

const resetContactPassword = withErrorHandling(adminApi.resetContactPassword);

const ResetPasswordDialog = ({ contactId, contactEmail, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [resetStatus, setResetStatus] = useState<"prompt" | "success">("prompt");
  const [isLoading, setLoading] = useState(false);
  const [tempPassword, setTempPassword] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    const [resp, error] = await resetContactPassword(contactId);
    setLoading(false);

    if (error) {
      sendNotificationError(`Password reset failed: ${getErrorMessage(error)}`);
      logError(error, "[ResetPasswordDialog] resetContactPassword");
      return;
    }

    if (resp.tempPassword) {
      setTempPassword(resp.tempPassword);
    }
    setResetStatus("success");
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent sx={{ pt: 0 }}>
        <Stack spacing={2.5}>
          <Stack spacing={1.5}>
            <Stack spacing={0.25}>
              <Typography variant="subtitle2">Reset password for:</Typography>
              <Typography color="text.secondary">{contactEmail}</Typography>
            </Stack>
            {resetStatus === "success" && <Alert severity="success">Password has been reset</Alert>}
          </Stack>

          {resetStatus === "prompt" && (
            <Typography color="text.secondary">
              The user will be assigned a temporary password that must be changed on the next sign in. To perform the
              reset and view the temporary password, click “Reset password”.
            </Typography>
          )}

          {resetStatus === "success" && tempPassword && (
            <Stack spacing={1.5} pt={1.5}>
              <Typography color="text.secondary">
                Provide this temporary password to the user so they can sign in.
              </Typography>
              <Box
                px={1.25}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={grey[100]}
                height={50}
                borderRadius={1}
              >
                <Typography variant="subtitle1">{tempPassword}</Typography>
                <CopyToBufferButton textToCopy={tempPassword} color="action" />
              </Box>
            </Stack>
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        {resetStatus === "prompt" && (
          <>
            <Button variant="text" color="secondary" autoFocus onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmit}>
              Reset Password
            </LoadingButton>
          </>
        )}

        {resetStatus === "success" && (
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
