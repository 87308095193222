import { Grid2 } from "@mui/material";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import SaltEdgeIcon from "../../../../icons/SaltEdgeIcon";
import CanoeLogo from "../../../../images/canoe.png";
import CascataLogo from "../../../../images/cascata.png";
import FloatRatesLogo from "../../../../images/floatrates.png";
import HmrcLogo from "../../../../images/hmrc.png";
import IntAppLogo from "../../../../images/intapp.png";
import MxLogo from "../../../../images/mx.png";
import PassthroughLogo from "../../../../images/passthrough.png";
import PlaidLogo from "../../../../images/plaid.png";
import QashqadeLogo from "../../../../images/qashqade.png";
import SalesforceLogo from "../../../../images/salesforce.png";
import SnowflakeLogo from "../../../../images/snowflake.png";
import TwelveDataLogo from "../../../../images/twelvedata.png";
import YodleeLogo from "../../../../images/yodlee.png";
import { pageRoutes } from "../../../../routes";
import IntegrationsCard from "./IntegrationsCard";

const IntegrationsDashboard = () => {
  const {
    clientCode,
    hasAnyPermission,
    reportingPortalApp,
    salesforceApp,
    yodleeApp,
    saltEdgeApp,
    plaidApp,
    passthroughApp,
    dealCloudApp,
  } = useClientContext();
  const [fundAccountingApps, fetchError] = useFetch(adminApi.getFundAccountingAppsStatus);

  if (fetchError) {
    logError(fetchError, "[IntegrationsDashboard], getFundAccountingAppsStatus");
  }

  const hmrcApp = fundAccountingApps?.applications?.find((app) => app.appCode === "HMRC");
  const twelveDataApp = fundAccountingApps?.applications?.find((app) => app.appCode === "TwelveData");
  const floatRatesApp = fundAccountingApps?.applications?.find((app) => app.appCode === "FloatRates");

  const hasAccessToClientIntegrations = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);

  return (
    <Grid2 container spacing={3}>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--snowflake"
          title="Snowflake"
          text="A single, global platform that powers the Data Cloud. Snowflake is uniquely designed to connect businesses globally, across any type or scale of data."
          logoSrc={SnowflakeLogo}
          appStatus={reportingPortalApp?.status ?? "NotConnected"}
          moreInfoLink="https://www.snowflake.com"
          configurationRoute={
            hasAccessToClientIntegrations
              ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.snowflake}`
              : undefined
          }
        />
      </Grid2>
      {salesforceApp?.status && (
        <Grid2>
          <IntegrationsCard
            cardId="integrations-card--salesforce"
            title="Salesforce"
            text="Salesforce is a robust CRM platform designed to help businesses manage and analyze customer interactions and data efficiently."
            logoSrc={SalesforceLogo}
            appStatus={salesforceApp.status}
            moreInfoLink="https://www.salesforce.com"
            configurationRoute={`/${clientCode}/${pageRoutes.integration}/${pageRoutes.salesforce}`}
          />
        </Grid2>
      )}
      {dealCloudApp?.status && (
        <Grid2>
          <IntegrationsCard
            cardId="integrations-card--dealcloud"
            title="DealCloud"
            text="Turn your metrics into informed business decisions."
            logoSrc={IntAppLogo}
            appStatus={dealCloudApp.status}
            moreInfoLink="https://www.intapp.com/dealcloud"
            configurationRoute={`/${clientCode}/${pageRoutes.integration}/${pageRoutes.dealcloud}`}
          />
        </Grid2>
      )}
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--passthrough"
          title="Passthrough"
          text="Intelligent sub docs and KYC/AML for private markets."
          logoSrc={PassthroughLogo}
          appStatus={passthroughApp?.status ?? "NotConnected"}
          moreInfoLink="https://passthrough.com"
          configurationRoute={
            hasAccessToClientIntegrations
              ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.passthrough}`
              : undefined
          }
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--mx"
          title="MX"
          text="MX is a fast-growing fintech provider, partnering with financial institutions and banking providers to provide consolidated data."
          logoSrc={MxLogo}
          appStatus="NotConnected"
          moreInfoLink="https://www.mx.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--yodlee"
          title="Yodlee"
          text="Yodlee provides account aggregation services, consolidating information from multiple sources."
          logoSrc={YodleeLogo}
          appStatus={yodleeApp?.status ?? "NotConnected"}
          moreInfoLink="https://www.yodlee.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--salt-edge"
          title="Salt Edge"
          text="Connect to thousands of banks, neobanks, and EMIs across the world with Salt Edge."
          Icon={SaltEdgeIcon}
          appStatus={saltEdgeApp?.status ?? "NotConnected"}
          moreInfoLink="https://www.saltedge.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--plaid"
          title="Plaid"
          text="Plaid enables seamless and secure access to financial data, allowing to link bank accounts, verify balances, and initiate transactions."
          logoSrc={PlaidLogo}
          appStatus={plaidApp?.status ?? "NotConnected"}
          moreInfoLink="https://plaid.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--qashqade"
          title="qashqade"
          text="qashqade is an enterprise product suite designed to streamline the calculation, allocation, tracking and reporting processes for private markets."
          logoSrc={QashqadeLogo}
          appStatus="NotConnected"
          moreInfoLink="https://www.qashqade.com/?utm_source=partner&utm_medium=website&utm_campaign=entrilia"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--cascata"
          title="Cascata"
          text="Automated distribution waterfall and management solutions for the private equity industry."
          logoSrc={CascataLogo}
          appStatus="Announced"
          moreInfoLink="https://www.cascata-solutions.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--canoe"
          title="Canoe"
          text="Guiding the alts industry forward with smarter data management."
          logoSrc={CanoeLogo}
          appStatus="Announced"
          moreInfoLink="https://canoeintelligence.com"
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--hmrc"
          title="HMRC"
          text="Submit your VAT returns directly to HMRC for seamless tax compliance."
          logoSrc={HmrcLogo}
          appStatus={hmrcApp?.status ?? "NoStatus"}
          moreInfoLink="https://www.gov.uk/government/organisations/hm-revenue-customs"
          configurationLink={hmrcApp?.managementUrl}
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--twelve-data"
          title="Twelve Data"
          text="Access real-time and historical financial market data for better investment insights."
          logoSrc={TwelveDataLogo}
          appStatus={twelveDataApp?.status ?? "NoStatus"}
          moreInfoLink="https://twelvedata.com"
          configurationLink={twelveDataApp?.managementUrl}
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--float-rates"
          title="Float Rates"
          text="Get accurate and up-to-date foreign exchange rates for simplified currency conversions."
          logoSrc={FloatRatesLogo}
          appStatus={floatRatesApp?.status ?? "NoStatus"}
          moreInfoLink="https://www.floatrates.com"
          configurationLink={floatRatesApp?.managementUrl}
        />
      </Grid2>
    </Grid2>
  );
};

export default IntegrationsDashboard;
