import { Box, Divider, Stack, Typography } from "@mui/material";
import { MetricAndScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import HorizontalFill from "../../../HorizontalFill";
import ScenarioAvatar from "./ScenarioAvatar";

interface Props {
  columnDefinition: MetricAndScenarioTableBlockColumn;
}

const MetricAndScenarioColumnHeader = ({ columnDefinition }: Props) => {
  return (
    <Stack width="100%">
      <Box width="100%" height="60px" display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="subtitle2" sx={{ overflow: "hidden", whiteSpace: "normal" }}>
          {columnDefinition.metricName}
        </Typography>
      </Box>

      <Divider flexItem />

      <Stack width="100%" pt={1}>
        <Box display="flex" justifyContent="space-between">
          <ScenarioAvatar scenarioName={columnDefinition.scenarioName} />
          <Typography variant="subtitle2">{columnDefinition.title}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <HorizontalFill />
          <Typography variant="caption" color="text.secondary" height="1rem">
            {columnDefinition.subtitle}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default MetricAndScenarioColumnHeader;
