import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MetricTableBlockRow, ScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import ScenarioColumnHeader from "./ScenarioColumnHeader";
import TableFormCellEditor from "./TableFormCellEditor";

const HeadRow = ({ columnDefinitions }: { columnDefinitions: ScenarioTableBlockColumn[] }) => {
  return (
    <TableRow>
      <TableCell width={400}>Metric</TableCell>
      {columnDefinitions.map((columnDef) => (
        <TableCell key={columnDef.id} width={240}>
          <ScenarioColumnHeader columnDefinition={columnDef} />
        </TableCell>
      ))}
    </TableRow>
  );
};

interface BodyRowProps {
  row: MetricTableBlockRow;
  columnDefinitions: ScenarioTableBlockColumn[];
  currencyCode: string;
  onValueChange: (rowId: string, metricId: string, columnId: string, value: string | number | undefined) => void;
}

const BodyRow = ({ row, columnDefinitions, currencyCode, onValueChange }: BodyRowProps) => {
  if (row.type !== "Metric") {
    return null;
  }

  return (
    <TableRow>
      <TableCell>{row.name}</TableCell>
      {columnDefinitions.map((columnDef) => (
        <TableCell key={columnDef.id}>
          <TableFormCellEditor
            row={row}
            columnDefinition={columnDef}
            currencyCode={currencyCode}
            onValueChange={onValueChange}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

interface Props {
  columnDefinitions: ScenarioTableBlockColumn[];
  rowDefinitions: MetricTableBlockRow[];
  currencyCode: string;
  onValueChange: (rowId: string, metricId: string, columnId: string, value: string | number | undefined) => void;
}

const InternalTableInputForm = ({ columnDefinitions, rowDefinitions, currencyCode, onValueChange }: Props) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table
        sx={(t) => ({
          ".MuiTableCell-head": {
            ":first-of-type": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
          },
          ".MuiTableCell-body": {
            borderBottom: 0,
            ":first-of-type": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
          },
        })}
      >
        <TableHead>
          <HeadRow columnDefinitions={columnDefinitions} />
        </TableHead>
        <TableBody>
          {rowDefinitions.map((row) => (
            <BodyRow
              key={row.id}
              row={row}
              columnDefinitions={columnDefinitions}
              currencyCode={currencyCode}
              onValueChange={onValueChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InternalTableInputForm;
