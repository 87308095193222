import { Box, Paper, SxProps, Theme, Typography } from "@mui/material";
import { Fragment, JSX, PropsWithChildren, useEffect, useState } from "react";
import { DragLayerMonitor, useDrag, useDragLayer, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

type TypeIdentifier = string | symbol;
type TargetType = TypeIdentifier | TypeIdentifier[];

interface DragAndDropListOptions<T> {
  notDraggable?: (item: T) => boolean;
}

interface DraggedItem<T> {
  item: T;
  index: number;
}

interface DragItemProps<T> {
  type: TypeIdentifier;
  accept?: TargetType;
  item: T;
  onHover: (index: number, hoveredItem: T, draggedItem: T) => void;
  onDrag: (draggedItem: T) => void;
  onDragEnd: () => void;
  index: number;
  useCustomDragLayer?: boolean;
  notDraggable?: boolean;
}

const DropPlaceholder = () => (
  <Paper
    sx={{
      border: "1px dashed #00838F",
      backgroundColor: "rgba(0, 131, 143, 0.08)",
      padding: 1,
      marginBottom: 1,
      mx: 1,
    }}
  >
    <Typography variant="caption" sx={{ color: (theme) => theme.palette.primary.main }}>
      Drop here
    </Typography>
  </Paper>
);

const DragItem = <T,>({
  children,
  item,
  type,
  accept,
  index,
  onHover,
  onDrag,
  onDragEnd,
  useCustomDragLayer,
  notDraggable,
}: PropsWithChildren<DragItemProps<T>>) => {
  const [{ isDragging }, drag, preview] = useDrag(() => {
    return {
      type,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        onDragEnd();
      },
    };
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: accept || type,
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true }),
    }),
    hover: (draggedItem: T) => {
      if (isOver) {
        onHover(index, item, draggedItem);
      }
    },
  });

  useEffect(() => {
    if (isDragging) {
      onDrag(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    if (!useCustomDragLayer) {
      return;
    }
    preview(getEmptyImage());
  }, [preview, useCustomDragLayer]);

  if (notDraggable) {
    return <>{children}</>;
  }

  return (
    <div
      ref={(node) => {
        return drag(drop(node));
      }}
    >
      {children}
    </div>
  );
};

interface DragDropListProps<T> {
  type: TypeIdentifier;
  accept?: TargetType;
  items: T[];
  renderItem: (item: T, index: number) => JSX.Element | null;
  onDrop?: (index: number, draggedItem: T | undefined) => void;
  onDrag?: (item: T) => void;
  emptyPlaceholder?: JSX.Element;
  defaultPlaceholderHeight?: number;
  sx?: SxProps<Theme>;
  renderCustomDragItemLayer?: (item: T) => JSX.Element | null;
  options?: DragAndDropListOptions<T>;
}

const DragAndDropList = <TItem,>({
  type,
  accept,
  items,
  onDrop,
  onDrag,
  renderItem,
  emptyPlaceholder,
  defaultPlaceholderHeight,
  sx,
  renderCustomDragItemLayer,
  options,
}: DragDropListProps<TItem>) => {
  const defaultHeight = defaultPlaceholderHeight || 32;
  const acceptType = accept || type;

  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [draggedItem, setDraggedItem] = useState<DraggedItem<TItem> | undefined>(undefined);

  const [{ isOver }, dropRef] = useDrop({
    accept: acceptType,
    drop: (_item: TItem, monitor) => {
      if (!monitor.didDrop() && onDrop) {
        onDrop(
          draggedItem !== undefined && draggedItem.index < hoveredIndex ? hoveredIndex - 1 : hoveredIndex,
          draggedItem?.item
        );
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const resetHoveredIndex = () => {
    setHoveredIndex(-1);
  };

  const handleDrag = (item: TItem, index: number) => {
    setDraggedItem({ item, index });
    onDrag?.(item);
  };

  const handleDragEnd = () => {
    setDraggedItem(undefined);
  };

  return (
    <>
      <Box ref={dropRef} sx={sx} py={draggedItem ? 3 : 1}>
        {items.map((field, index) =>
          draggedItem?.index === index ? null : (
            <Fragment key={`dnd-item-${index}`}>
              {isOver && hoveredIndex === index && <DropPlaceholder />}
              <DragItem
                index={index}
                onHover={setHoveredIndex}
                onDrag={() => handleDrag(field, index)}
                onDragEnd={handleDragEnd}
                key={index}
                type={type}
                accept={accept}
                item={field}
                useCustomDragLayer={!!renderCustomDragItemLayer}
                notDraggable={options?.notDraggable && options.notDraggable(field)}
              >
                {renderItem(field, index)}
              </DragItem>
            </Fragment>
          )
        )}
        {isOver && (
          <Box onDragEnter={resetHoveredIndex} height={defaultHeight}>
            {hoveredIndex === -1 && <DropPlaceholder />}
          </Box>
        )}
        {!isOver && items.length === 0 && emptyPlaceholder}
      </Box>
      {renderCustomDragItemLayer && (
        <DragAndDropCustomDragLayer
          type={type}
          renderElement={() => (draggedItem ? renderCustomDragItemLayer(draggedItem.item) : null)}
        />
      )}
    </>
  );
};

interface DragAndDropCustomDragLayerProps<T> {
  type: TypeIdentifier;
  renderElement: (item: T) => JSX.Element | null;
}

const DragAndDropCustomDragLayer = <T,>({ type, renderElement }: DragAndDropCustomDragLayerProps<T>) => {
  const { isDragging, currentOffset, itemType, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem<T>(),
      itemType: monitor.getItemType(),
    };
  });

  return isDragging && currentOffset && type === itemType ? (
    <Box
      sx={{
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      {renderElement(item)}
    </Box>
  ) : null;
};

export default DragAndDropList;
