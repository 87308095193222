import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { FocusEvent, useRef } from "react";
import { MenuItemStyled } from "../../../../../../../shared/components/MenuItemStyled";
import { GlAccount } from "../../../../../../api/adminApi";
import { InvoiceAccount, InvoiceDetailsLine } from "../invoiceDetailsState";

interface Props {
  params: GridRenderCellParams<InvoiceDetailsLine, InvoiceAccount>;
  glAccounts: GlAccount[];
}

const InvoiceLineAccountSelect = ({ params, glAccounts }: Props) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = async (_: React.SyntheticEvent, newValue: InvoiceAccount | null) => {
    await apiRef.current.setEditCellValue({ id, field, value: newValue || undefined });
  };

  return (
    <Autocomplete
      fullWidth
      options={glAccounts}
      openOnFocus
      value={value || null}
      onChange={handleChange}
      getOptionLabel={({ no, name }) => `${no || ""} ${name || ""}`}
      isOptionEqualToValue={(option, value) => option.no === value.no && option.name === value.name}
      renderOption={(props, option) => (
        <InvoiceLineAccountCategorySelectItem
          key={`${option.no}_${option.name}`}
          renderOptionProps={props}
          option={option}
        />
      )}
      slotProps={{
        popper: {
          style: { minWidth: "max-content" },
        },
      }}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          slotProps={{
            input: {
              ...params.InputProps,
              onFocus: (event: FocusEvent<HTMLInputElement>) => {
                setTimeout(() => {
                  event.target.select();
                });
              },
            },
          }}
          autoFocus
        />
      )}
      sx={{ "& input": { letterSpacing: "0.17px" } }}
    />
  );
};

export default InvoiceLineAccountSelect;

const InvoiceLineAccountCategorySelectItem = ({
  renderOptionProps,
  option: { no, name },
}: {
  renderOptionProps: React.HTMLAttributes<HTMLLIElement>;
  option: InvoiceAccount;
}) => {
  return (
    <MenuItemStyled {...renderOptionProps} key={`${no}_${name}`}>
      <Stack direction="row" spacing={2} py={0.25}>
        <Typography color="secondary">{no ?? ""}</Typography>
        <Typography>{name ?? ""}</Typography>
      </Stack>
    </MenuItemStyled>
  );
};
