import { createSvgIcon } from "@mui/material";

const EmailIcon = createSvgIcon(
  <svg viewBox="0 0 61 45" fill="none">
    <path d="M60.9 45H0V0H60.9V45ZM5 40H55.9V5H5V40Z" fill="#C7DBE5" />
    <path
      d="M31.5 22.7L30.2999 22.1C21.2999 17.4 1.99995 5.10003 1.19995 4.60003L3.89995 0.400024C4.09995 0.500024 22.2 12 31.4 17C37.5999 13.3 56.7999 0.600024 57.0999 0.400024L59.9 4.60003C59 5.20003 37.2 19.6 32.7999 22.1L31.5 22.7Z"
      fill="#C7DBE5"
    />
  </svg>,
  "EmailIcon"
);

export default EmailIcon;
