import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DataImportInfo } from "../../../api/types/dataImportTypes";
import { useClientContext } from "../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
} from "../../../state/paginatedState";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import DeleteDataImportDialog from "./DeleteDataImportDialog";
import { ImportDataContextProvider } from "./ImportDataContext";
import ImportDataList from "./ImportDataList";
import ImportDataPageActions from "./ImportDataPageActions";

export interface DialogState {
  importToDelete?: DataImportInfo;
}

const ImportDataPage = () => {
  usePageTitle("Data Import");

  const { hasAnyPermission } = useClientContext();

  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DataImportInfo>());
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getDataImports = useCallback(
    () =>
      adminApi.searchDataImports({
        paging: getPagingParams(pageState.page),
      }),
    [pageState.page]
  );

  const [, fetchDataImportsError, { isFetching, fetch: fetchDataImports }] = useFetch(getDataImports, (resp) => {
    setPageState(loadItemsAction(resp));
  });

  const [dataImportOptions] = useFetch(adminApi.getDataImportOptions);

  if (fetchDataImportsError) {
    logError(fetchDataImportsError, "ImportDataPage");
    return <DataLoadingFailed title="Could not load imported data" />;
  }

  const handleDeleteImport = (importToDelete: DataImportInfo) => {
    setDialogState({ importToDelete });
  };

  const refreshData = () => {
    if (pageState.page === 0) {
      fetchDataImports();
    } else {
      setPageState(firstPageAction());
    }
  };

  const handleImportDeleted = () => {
    setDialogState({ importToDelete: undefined });
    refreshData();
  };

  return (
    <ImportDataContextProvider
      pageState={pageState}
      setPageState={setPageState}
      refreshData={refreshData}
      onDeleteImport={handleDeleteImport}
    >
      <GeneralPageHeader title="Data Import">
        <ImportDataPageActions
          hasAccessToCsvImport={Boolean(dataImportOptions?.length)}
          hasAccessToPassthroughImport={hasAnyPermission(["ManageInvestorRelationsDataImports"])}
        />
      </GeneralPageHeader>

      <ImportDataList isLoading={isFetching} />

      {dialogState.importToDelete !== undefined && (
        <DeleteDataImportDialog
          dataImport={dialogState.importToDelete}
          onClose={() => setDialogState({ importToDelete: undefined })}
          onConfirm={handleImportDeleted}
        />
      )}
    </ImportDataContextProvider>
  );
};

export default ImportDataPage;
