import { GridColType } from "@mui/x-data-grid-premium";
import { MetricDataType } from "../../../../api/portfolioMonitoringTypes";

export type InputFormGridCellValueType = string | Date | number | null | undefined;

export const metricDataTypeToGridColTypeMap: Record<MetricDataType, GridColType> = {
  Text: "string",
  Number: "number",
  Money: "number",
  Date: "date",
  Select: "singleSelect",
};
