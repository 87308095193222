import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { defined } from "../../shared/utilities/typeHelper";
import { ClientInfo, CompanyInfo } from "../api/types/clientTypes";
import { ClientPermissionSet, UserInfo } from "../api/types/userTypes";
import storage from "../storage/storage";

const UserContext = createContext<ContextValue | undefined>(undefined);

interface Props {
  userInfo: UserInfo;
  permissions: ClientPermissionSet[];
  userClients: ClientInfo[];
  refreshUserData: () => void;
}

interface ContextValue extends UserInfo {
  permissions: ClientPermissionSet[];
  clients: ClientInfo[];
  isBetaAccessAvailable: boolean;
  isBetaAccessEnabled: boolean;
  refreshUserData: () => void;
  updateClientData: (clientCode: string, clientData: CompanyInfo) => void;
  updateBetaAccessEnabled: (value: boolean) => void;
}

export const UserContextProvider = ({
  userInfo,
  permissions,
  userClients,
  refreshUserData,
  children,
}: PropsWithChildren<Props>) => {
  const [clients, setClients] = useState<ClientInfo[]>(userClients);

  const [betaAccessEnabled, setBetaAccessEnabled] = useState(
    storage.getBetaAccessEnabled() ?? userInfo.betaAccess?.enabledByDefault === true
  );

  const updateClientData = useCallback((clientCode: string, clientData: CompanyInfo) => {
    setClients((prev) =>
      prev.map((client) => (client.clientCode === clientCode ? { ...client, ...clientData } : client))
    );
  }, []);

  const updateBetaAccessEnabled = useCallback((enabled: boolean) => {
    setBetaAccessEnabled(enabled);
    storage.setBetaAccessEnabled(enabled);
  }, []);

  const isBetaAccessAvailable = userInfo.betaAccess?.available === true;
  const isBetaAccessEnabled = betaAccessEnabled && isBetaAccessAvailable;

  return (
    <UserContext.Provider
      value={{
        ...userInfo,
        permissions,
        clients,
        isBetaAccessAvailable,
        isBetaAccessEnabled,
        refreshUserData,
        updateClientData,
        updateBetaAccessEnabled,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => defined(useContext(UserContext), "Attempt to use UserContext without provider");
