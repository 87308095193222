import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, SxProps, Tab, Theme } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { DocumentCollectionOperations } from "../../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import ChangeDocumentCollectionsStatusDialog from "../ChangeDocumentCollectionsStatusDialog";
import { DocumentCollectionContextProvider } from "./DocumentCollectionContext";
import DocumentCollectionHeader from "./DocumentCollectionHeader";
import DocumentsViewFundLevel from "./documents-view/DocumentsViewFundLevel";
import DocumentsViewInvestorLevel from "./documents-view/DocumentsViewInvestorLevel";
import EmailsView from "./emails-view/EmailsView";

const tabPanelStyle: SxProps<Theme> = () => ({
  height: "100%",
  p: 0,
});

const DocumentCollectionPage = () => {
  usePageTitle("Document Collection");

  const { id } = useParams();
  const navigate = useNavigate();
  const { hasPermissions } = useClientContext();

  const fetchDocumentCollectionDetails = useCallback(() => adminApi.getDocumentCollectionDetails(defined(id)), [id]);
  const [documentCollectionDetails, fetchError, { isFetching, fetch }] = useFetch(fetchDocumentCollectionDetails);

  const [sentInvites, setSentInvites] = useState<Record<string, Date>>({});
  const [selectedTab, setSelectedTab] = useState("Documents");
  const [operationPrompt, setOperationPrompt] = useState<DocumentCollectionOperations>();

  if (fetchError) {
    if (fetchError.type === "NotFound") {
      setTimeout(() => navigate(".."));
      return null;
    }

    logError(fetchError, "[DocumentCollection]");
    return <DataLoadingFailed title="Loading documents failed" />;
  }

  if (!documentCollectionDetails || isFetching) {
    return <InlineLoader />;
  }

  const handleChangeDocumentCollectionStatus = (operation: DocumentCollectionOperations) => {
    setOperationPrompt(operation);
  };

  const handleStatusChange = () => {
    fetch();
  };

  const showPublishingStatus = documentCollectionDetails.status === "PartiallyLive";
  const showFundName = documentCollectionDetails.funds.length > 1;

  return (
    <DocumentCollectionContextProvider
      hasPermissionsToManage={hasPermissions(["ManageDocumentCollections"])}
      sentInvites={sentInvites}
      setSentInvites={setSentInvites}
      onChangeDocumentCollectionStatus={handleChangeDocumentCollectionStatus}
    >
      <Container maxWidth={false} sx={{ height: "100%", py: 1.5, px: 3, overflowY: "hidden" }}>
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          {documentCollectionDetails && (
            <DocumentCollectionHeader documentCollectionDetails={documentCollectionDetails} />
          )}
          <TabContext value={selectedTab}>
            <TabList onChange={(_, tab: string) => setSelectedTab(tab)}>
              <Tab label="Documents" value="Documents" />
              {documentCollectionDetails.showEmailsView && <Tab label="Emails" value="Emails" />}
            </TabList>
            <TabPanel value="Documents" sx={tabPanelStyle}>
              {documentCollectionDetails.messageLevel === "Fund" && (
                <DocumentsViewFundLevel
                  documents={documentCollectionDetails.documents}
                  showPublishingStatus={showPublishingStatus}
                  showFundName={showFundName}
                />
              )}
              {documentCollectionDetails.messageLevel !== "Fund" && (
                <DocumentsViewInvestorLevel
                  documents={documentCollectionDetails.documents}
                  showPublishingStatus={showPublishingStatus}
                  showFundName={showFundName}
                />
              )}
            </TabPanel>
            {documentCollectionDetails.showEmailsView && (
              <TabPanel value="Emails" sx={tabPanelStyle}>
                <EmailsView documentCollectionId={defined(id)} />
              </TabPanel>
            )}
          </TabContext>
        </Box>

        {operationPrompt && (
          <ChangeDocumentCollectionsStatusDialog
            onClose={() => setOperationPrompt(undefined)}
            documentCollectionIds={[documentCollectionDetails.id]}
            operation={operationPrompt}
            documentCollectionsInfo={documentCollectionDetails.name}
            onApply={handleStatusChange}
          />
        )}
      </Container>
    </DocumentCollectionContextProvider>
  );
};

export default DocumentCollectionPage;
