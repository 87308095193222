import { GridCellParams } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import { columnDefinitions } from "./dataRequestTemplatesGridDataProvider";

interface Props {
  rows: DataCollectionRequestTemplateVersion[];
  isLoading: boolean;
}

const DataRequestTemplatesGrid = ({ rows, isLoading }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const handleCellClick = ({ row }: GridCellParams<DataCollectionRequestTemplateVersion>) => {
    navigate(`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequestTemplates}/${row.templateId}`);
  };

  return (
    <DataGrid<DataCollectionRequestTemplateVersion>
      rows={rows}
      columns={columnDefinitions}
      noRowsText="No request templates"
      loading={isLoading}
      onCellClick={handleCellClick}
      selectableRows
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataRequestTemplatesGrid;
