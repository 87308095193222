import { createSvgIcon } from "@mui/material";

const ScheduleIcon = createSvgIcon(
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1665 4.99935V9.99935H15.4998V8.33268H3.83317V16.666H9.6665V18.3327H3.83317C2.9165 18.3327 2.1665 17.5827 2.1665 16.666V4.99935C2.1665 4.08268 2.9165 3.33268 3.83317 3.33268H4.6665V2.49935C4.6665 2.04102 5.0415 1.66602 5.49984 1.66602C5.95817 1.66602 6.33317 2.04102 6.33317 2.49935V3.33268H12.9998V2.49935C12.9998 2.04102 13.3748 1.66602 13.8332 1.66602C14.2915 1.66602 14.6665 2.04102 14.6665 2.49935V3.33268H15.4998C16.4165 3.33268 17.1665 4.08268 17.1665 4.99935ZM11.7186 18.9473C12.5311 19.7598 13.5137 20.166 14.6665 20.166C15.8193 20.166 16.8019 19.7598 17.6144 18.9473C18.4269 18.1348 18.8332 17.1521 18.8332 15.9993C18.8332 14.8466 18.4269 13.8639 17.6144 13.0514C16.8019 12.2389 15.8193 11.8327 14.6665 11.8327C13.5137 11.8327 12.5311 12.2389 11.7186 13.0514C10.9061 13.8639 10.4998 14.8466 10.4998 15.9993C10.4998 17.1521 10.9061 18.1348 11.7186 18.9473ZM15.0832 15.8327V13.916C15.0832 13.8049 15.0415 13.7077 14.9582 13.6243C14.8748 13.541 14.7776 13.4993 14.6665 13.4993C14.5554 13.4993 14.4582 13.541 14.3748 13.6243C14.2915 13.7077 14.2498 13.8049 14.2498 13.916V15.8118C14.2498 15.923 14.2707 16.0306 14.3123 16.1348C14.354 16.2389 14.4165 16.3327 14.4998 16.416L15.7498 17.666C15.8332 17.7493 15.9304 17.791 16.0415 17.791C16.1526 17.791 16.2498 17.7493 16.3332 17.666C16.4165 17.5827 16.4582 17.4855 16.4582 17.3743C16.4582 17.2632 16.4165 17.166 16.3332 17.0827L15.0832 15.8327Z"
      fill="currentColor"
    />
  </svg>,
  "ScheduleIcon"
);

export default ScheduleIcon;
