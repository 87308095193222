export const allMetricDataTypes = ["Text", "Number", "Money", "Date", "Select"] as const;
export type MetricDataType = (typeof allMetricDataTypes)[number];

export const allMetricAggegationTypes = ["EndOfPeriod", "Sum"] as const;
export type MetricAggregationType = (typeof allMetricAggegationTypes)[number];

export interface MetricFormatConfiguration {
  numberDisplayStyle?: NumberDisplayStyle;
  numberOfDecimals?: number;
}

export interface MetricInputSettingsConfiguration {
  maxNumberOfSymbols?: number;
}

export interface MetricExtensionConfiguration {
  extensionName: string;
  staticExtensions: StaticMetricExtension[];
}

interface StaticMetricExtension {
  id: string;
  value: string;
}

export interface MetricValueSourceConfiguration {
  allowedValues?: string[];
}

export const allNumberDisplayStyles = ["Percentage", "Thousands", "Millions", "Billions", "Multiple"] as const;
export type NumberDisplayStyle = (typeof allNumberDisplayStyles)[number];
