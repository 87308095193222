import ExportIcon from "@mui/icons-material/ExitToAppRounded";
import FilterIcon from "@mui/icons-material/FilterListRounded";
import { Stack } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useEffect, useReducer, useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import ActionsMenuButton from "../../../../../../shared/components/ActionsMenuButton";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import { formatDate } from "../../../../../../shared/utilities/dateUtils";
import { formatNumber } from "../../../../../../shared/utilities/formatters";
import adminApi from "../../../../../api/adminApi";
import { DocumentViews } from "../../../../../api/types/documentActivityTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
} from "../../../../../state/paginatedState";
import storage from "../../../../../storage/storage";
import { FilterContextProvider } from "../../../../common/filters/FilterContext";
import FiltersPanel from "../../../../common/filters/FiltersPanel";
import { createReducer, getInitialState } from "../../../../common/filters/filterState";
import { emptySearchFilterDefinition } from "../../../../common/filters/handlers/filterHandlers";
import { filterDefinitions, getRequestFilters } from "../../document-activity/documentViewsFilterDefinitions";
import DocumentViewsGrid from "../../document-activity/DocumentViewsGrid";
import { getSelectOptionsForDocumentViews } from "../../document-activity/documentViewsSelectOptionsProvider";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const exportDocumentViewsToExcel = withErrorHandling(adminApi.exportDocumentViewsToExcel);

const FundraisingDocumentActivity = () => {
  const { clientCode } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { fundraising } = useFundraisingDetailsPageContext();

  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DocumentViews>());
  const [isExporting, setExporting] = useState(false);

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DocumentViews>(),
    getInitialState(
      `${clientCode}_fundraising_document_activity_filter_v1`,
      filterDefinitions,
      emptySearchFilterDefinition(),
      []
    )
  );

  const [updateTrigger, setUpdateTrigger] = useState(false);

  const resetPageOnFiltersChange = useCallback(() => {
    if (pageState.page !== 0) {
      setPageState(firstPageAction());
    } else {
      setUpdateTrigger((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectHash(getRequestFilters(filterState))]);

  useEffect(() => {
    resetPageOnFiltersChange();
  }, [resetPageOnFiltersChange]);

  const getDocumentViews = useCallback(
    () =>
      adminApi.searchDocumentViews({
        paging: getPagingParams(pageState.page),
        ...getRequestFilters(filterState),
        fundraisingId: fundraising.id,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.page, updateTrigger, fundraising.id]
  );

  const [, fetchError, { isFetching, fetch: fetchDocumentViews }] = useFetch(getDocumentViews, (resp) => {
    setPageState(loadItemsAction(resp));
  });

  if (fetchError) {
    logError(fetchError, "[FundraisingDocumentActivity]");
    storage.clearFilterState(`${clientCode}_fundraising_document_activity_filter_v1`);
    return <DataLoadingFailed title="Could not load document activity" />;
  }

  const handleRefresh = () => {
    if (pageState.page === 0) {
      fetchDocumentViews();
    } else {
      setPageState(firstPageAction());
    }
  };

  const handleExport = async (withFilters: boolean) => {
    const fileName = `${fundraising.name}_Document_Activity_${formatDate(new Date(), "yyyyMMdd")}.xlsx`;

    setExporting(true);

    const filters = withFilters ? getRequestFilters(filterState) : {};
    const [resp, error] = await exportDocumentViewsToExcel(
      { paging: {}, ...filters, fundraisingId: fundraising.id },
      fileName
    );

    setExporting(false);

    if (error) {
      sendNotificationError("Failed to export document activity");
      logError(error, "[FundraisingDocumentActivity] exportDocumentViewsToExcel");
      return;
    }

    downloadFileFromUrl(resp.downloadUrl);

    if (pageState.totalRecords > 10_000) {
      sendNotification(
        `Exported first ${formatNumber(10_000)} records. Please use filters to reduce the dataset and export additional records.`
      );
    }
  };

  const handleRowsScrollEnd = () => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  };

  return (
    <Stack spacing={2.5} py={2.5} flex={1}>
      <FilterContextProvider
        filterState={filterState}
        dispatchFilters={dispatchFilters}
        getSelectOptions={getSelectOptionsForDocumentViews}
      >
        <FiltersPanel
          totalCount={pageState.totalRecords}
          recordCount={pageState.items.length}
          isLoading={isFetching}
          onRefresh={handleRefresh}
          withoutSearch
        >
          <ActionsMenuButton
            text="Export to Excel"
            variant="outlined"
            color="secondary"
            icon={<ExportIcon />}
            disabled={!filterState.initialized || isFetching || pageState.totalRecords === 0}
            loading={isExporting}
            items={[
              {
                label: "All records",
                icon: <ExportIcon />,
                onClick: () => handleExport(false),
              },
              {
                label: "Only filtered records",
                icon: <FilterIcon />,
                onClick: () => handleExport(true),
              },
            ]}
          />
        </FiltersPanel>
      </FilterContextProvider>
      <DocumentViewsGrid isLoading={isFetching} rows={pageState.items} onRowsScrollEnd={handleRowsScrollEnd} />
    </Stack>
  );
};

export default FundraisingDocumentActivity;
