import { pageRoutes } from "../../../routes";
import ObjectsListPage from "../../entityFields/grid/ObjectsListPage";

const ScenariosPage = () => {
  return (
    <ObjectsListPage
      objectType="PortfolioMonitoringScenario"
      objectDetailsPathPattern={`/:clientCode/${pageRoutes.portfolio}/${pageRoutes.scenarios}/:id`}
      objectsTitle="Scenarios"
    />
  );
};

export default ScenariosPage;
