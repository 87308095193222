import SendIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import { ListItem, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../shared/api/axiosHelper";
import AutocompleteCreatable from "../../../../../shared/components/AutocompleteCreatable";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { combineValidators, emailValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { TestInvitationContact } from "../../../../api/types/investorPortalTypes";
import { useUserContext } from "../../../../context/UserContext";

interface Props {
  testInvitationContacts: TestInvitationContact[];
  disabled?: boolean;
}

const sendTestInvitationEmail = withErrorHandling(adminApi.sendTestInvitationEmail);

const validateEmail = combineValidators(requiredValidator, emailValidator);

const SendTestInvitationEmail = ({ testInvitationContacts, disabled }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { email: currentUserEmail } = useUserContext();

  const [testEmailAddress, setTestEmailAddress] = useState(currentUserEmail);
  const [selectedContact, setSelectedContact] = useState<TestInvitationContact>();
  const [isSending, setSending] = useState(false);

  const handleButtonClick = async () => {
    if (!selectedContact) {
      return;
    }

    setSending(true);

    const [, error] = await sendTestInvitationEmail({
      recipientEmail: testEmailAddress,
      contactId: selectedContact.id,
    });

    setSending(false);

    if (error) {
      logError(error, "[SendTestInvitationEmail]");
      sendNotificationError(`Could not send test invitation email: ${getErrorMessage(error)}`);
      return;
    }

    sendNotification(`Test invitation email sent to ${testEmailAddress}`);
  };

  const { isValid: isEmailValid } = validateEmail(testEmailAddress);

  return (
    <Stack spacing={2} width="100%">
      <Typography variant="subtitle1">Test Email</Typography>

      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          value={testEmailAddress}
          onChange={({ target }) => setTestEmailAddress(target.value)}
        />

        <AutocompleteCreatable
          withLazyRendering
          noItemsText="No contacts found"
          openOnFocus
          fullWidth
          options={testInvitationContacts}
          getOptionLabel={(o) => `${o.fullName} <${o.email}>`}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">{option.fullName}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.email}
                </Typography>
              </Stack>
            </ListItem>
          )}
          isOptionEqualToValue={(opt, val) => opt.email === val.email}
          renderInput={(params) => <TextField {...params} label="Preview invitation as" />}
          value={selectedContact ?? null}
          onChange={(_, newValue) => {
            setSelectedContact(newValue ?? undefined);
          }}
        />
      </Stack>

      <LoadingButton
        variant="outlined"
        color="primary"
        loading={isSending}
        endIcon={<SendIcon />}
        disabled={!!disabled || !isEmailValid || !selectedContact}
        onClick={handleButtonClick}
        sx={{ width: "7.5rem" }}
      >
        Send Email
      </LoadingButton>
    </Stack>
  );
};

export default SendTestInvitationEmail;
