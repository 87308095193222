import { Theme } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";
import StatusChip from "../../../../../shared/components/StatusChip";
import { DataCollectionRequestStatus } from "../../../../api/types/dataCollectionTypes";
import { dataCollectionRequestStatusCaptionsMap } from "../../../../utilities/enumCaptions";

interface Props {
  status: DataCollectionRequestStatus;
}

const colorsMaps: Record<DataCollectionRequestStatus, (t: Theme) => string> = {
  Draft: (t) => t.palette.text.secondary,
  InProgress: () => blue[700],
  Scheduled: () => deepPurple[700],
  Completed: (t) => t.palette.success.main,
};

const DataRequestStatusTag = ({ status }: Props) => {
  return (
    <StatusChip label={dataCollectionRequestStatusCaptionsMap[status] ?? status} withDot color={colorsMaps[status]} />
  );
};

export default DataRequestStatusTag;
