import { createSvgIcon } from "@mui/material";

const CompaniesIcon = createSvgIcon(
  <svg viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6228 3.59814C13.5283 3.57364 13.4149 3.56969 13.1967 3.56969H7.95474C7.85387 3.56969 7.573 3.5697 7.29207 3.57605C7.15109 3.57923 7.01601 3.58389 6.90651 3.59046C6.8284 3.59514 6.78429 3.59977 6.76559 3.60173C6.75283 3.60307 6.7519 3.60317 6.76006 3.6012L6.75688 3.60197C6.69187 3.61736 6.63248 3.65067 6.58545 3.69812C6.53843 3.74557 6.50565 3.80526 6.49085 3.8704C6.48918 3.87777 6.4874 3.8851 6.4855 3.89241C6.461 3.9869 6.45705 4.10029 6.45705 4.31853V17.0489H13.9455V4.31853C13.9455 4.10327 13.942 3.98488 13.9155 3.87861L13.9132 3.86953C13.8978 3.80452 13.8645 3.74512 13.8171 3.69809C13.7696 3.65107 13.7099 3.61829 13.6448 3.60349C13.6374 3.60182 13.6301 3.60003 13.6228 3.59814ZM13.9877 2.14558C13.6951 2.07155 13.4072 2.07181 13.2217 2.07198C13.2132 2.07198 13.2048 2.07199 13.1967 2.07199H7.95394C7.85188 2.07199 7.55689 2.07199 7.25826 2.07874C7.10906 2.0821 6.95303 2.08728 6.81681 2.09545C6.70248 2.10231 6.53735 2.11425 6.40864 2.14532L6.58435 2.87326L6.41182 2.14456C6.07424 2.22448 5.76584 2.39748 5.52164 2.64389C5.28012 2.88761 5.11099 3.19349 5.03294 3.52746C4.95891 3.82008 4.95917 4.10798 4.95934 4.29348C4.95934 4.30205 4.95935 4.31041 4.95935 4.31853V17.7978C4.95935 18.2114 5.29462 18.5466 5.7082 18.5466H14.6944C15.1079 18.5466 15.4432 18.2114 15.4432 17.7978V4.31853C15.4432 4.31096 15.4432 4.3032 15.4432 4.29527C15.4434 4.10621 15.4436 3.81876 15.3695 3.51981C15.2891 3.18405 15.1166 2.87735 14.8713 2.63428C14.6276 2.39276 14.3217 2.22363 13.9877 2.14558Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18552 9.56026C4.19409 9.56027 4.20245 9.56027 4.21058 9.56027H5.70827C6.12185 9.56027 6.45712 9.89554 6.45712 10.3091V17.7976C6.45712 18.2112 6.12185 18.5464 5.70827 18.5464H4.21058C2.97327 18.5464 1.96404 17.5372 1.96404 16.2999V11.8195C1.95571 11.5576 1.97864 11.2957 2.03236 11.0392C2.03478 11.0277 2.03746 11.0162 2.04042 11.0048C2.12009 10.6975 2.27496 10.3844 2.52784 10.1316C2.78575 9.87365 3.09736 9.71735 3.40859 9.63666C3.70495 9.55982 3.99771 9.56009 4.18552 9.56026ZM4.21058 11.058C3.99233 11.058 3.87894 11.0619 3.78446 11.0864C3.69131 11.1106 3.62849 11.149 3.58687 11.1906C3.55688 11.2206 3.51959 11.2771 3.49434 11.3654C3.46733 11.5018 3.45621 11.6408 3.46124 11.7797C3.46157 11.7888 3.46173 11.7978 3.46173 11.8068V16.2999C3.46173 16.7101 3.80042 17.0487 4.21058 17.0487H4.95942V11.058H4.21058Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1793 7.31416C16.4412 7.30584 16.7031 7.32877 16.9596 7.38249C16.9711 7.38491 16.9826 7.38759 16.994 7.39055C17.3013 7.47022 17.6143 7.62508 17.8672 7.87797C18.1251 8.13588 18.2814 8.44749 18.3621 8.75872C18.4389 9.05496 18.4387 9.34047 18.4385 9.53495C18.4385 9.54372 18.4385 9.55231 18.4385 9.56071V16.3003C18.4385 16.8962 18.2018 17.4676 17.7805 17.8889C17.3592 18.3102 16.7878 18.5469 16.192 18.5469H14.6943C14.2807 18.5469 13.9454 18.2116 13.9454 17.798V8.06301C13.9454 7.64944 14.2807 7.31416 14.6943 7.31416H16.1793ZM16.6333 8.84447C16.497 8.81745 16.358 8.80634 16.2191 8.81137C16.21 8.8117 16.201 8.81186 16.192 8.81186H15.4431V17.0492H16.192C16.3906 17.0492 16.5811 16.9703 16.7215 16.8298C16.8619 16.6894 16.9408 16.4989 16.9408 16.3003V9.56071C16.9408 9.33585 16.9369 9.22936 16.9124 9.13458C16.8882 9.04144 16.8498 8.97862 16.8082 8.937C16.7782 8.90701 16.7216 8.86971 16.6333 8.84447Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95468 5.81672C7.95468 5.40314 8.28995 5.06787 8.70353 5.06787H11.6989C12.1125 5.06787 12.4478 5.40314 12.4478 5.81672C12.4478 6.2303 12.1125 6.56557 11.6989 6.56557H8.70353C8.28995 6.56557 7.95468 6.2303 7.95468 5.81672Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95468 8.8111C7.95468 8.39753 8.28995 8.06226 8.70353 8.06226H11.6989C12.1125 8.06226 12.4478 8.39753 12.4478 8.8111C12.4478 9.22468 12.1125 9.55995 11.6989 9.55995H8.70353C8.28995 9.55995 7.95468 9.22468 7.95468 8.8111Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95468 11.8071C7.95468 11.3935 8.28995 11.0582 8.70353 11.0582H11.6989C12.1125 11.0582 12.4478 11.3935 12.4478 11.8071C12.4478 12.2207 12.1125 12.5559 11.6989 12.5559H8.70353C8.28995 12.5559 7.95468 12.2207 7.95468 11.8071Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95468 14.803C7.95468 14.3895 8.28995 14.0542 8.70353 14.0542H11.6989C12.1125 14.0542 12.4478 14.3895 12.4478 14.803C12.4478 15.2166 12.1125 15.5519 11.6989 15.5519H8.70353C8.28995 15.5519 7.95468 15.2166 7.95468 14.803Z"
      fill="currentColor"
    />
  </svg>,
  "CompaniesIcon"
);

export default CompaniesIcon;
