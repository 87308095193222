import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Link, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import TypographyTooltipEllipsis from "../../../shared/components/TypographyTooltipEllipsis";
import { Maybe } from "../../../shared/types";
import { formatMoney, formatNumber, formatPercentage } from "../../../shared/utilities/formatters";
import { stringifyValue } from "../../../shared/utilities/stringHelper";
import { tryGetUrlWithProtocol } from "../../../shared/utilities/urlHelper";
import { EntityFieldConfiguration, EntityFieldType, FieldIdToLookupReferences } from "../../api/types/objectTypes";
import { useClientContext } from "../../context/ClientContext";
import { getDictionaryDisplayNameFromCode } from "../../utilities/dictionariesHelper";
import { formatDateFieldValue, getMultiselectOptionsFromValue } from "./helpers";

interface Props {
  type: EntityFieldType;
  fieldId: string;
  configuration: EntityFieldConfiguration | undefined;
  lookupObjects: FieldIdToLookupReferences;
  value: unknown;
}

const EntityValueGridCell = ({ type, fieldId, configuration, lookupObjects, value }: Props) => {
  const { dictionaries } = useClientContext();

  switch (type) {
    case "Email": {
      return (
        <Link
          href={`mailto:${stringifyValue(value)}`}
          underline="hover"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <TypographyTooltipEllipsis text={stringifyValue(value)} />
        </Link>
      );
    }
    case "Url": {
      const url = value ? tryGetUrlWithProtocol(stringifyValue(value)) : undefined;
      return url ? (
        <Link href={url.href} underline="hover" target="_blank" color="primary" onClick={(e) => e.stopPropagation()}>
          {stringifyValue(value)}
        </Link>
      ) : null;
    }
    case "Select": {
      if (!value) {
        return null;
      }
      const optionFieldType = configuration?.$type === "Select" && configuration.fieldType;
      const resolvedValue =
        (optionFieldType && getDictionaryDisplayNameFromCode(stringifyValue(value), optionFieldType, dictionaries)) ||
        stringifyValue(value);
      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    case "UserDefinedOptionsSelect": {
      if (!value) {
        return null;
      }
      const configurationOptions =
        (configuration?.$type === "UserDefinedOptionsSelect" && configuration.userDefinedOptions) || [];
      const resolvedValue =
        configurationOptions.find((option) => option.value === value)?.label || stringifyValue(value);
      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    case "MultiSelect": {
      const optionValues = getMultiselectOptionsFromValue(value as Maybe<string | string[]>);
      return <TypographyTooltipEllipsis text={optionValues.join(", ")} />;
    }
    case "UserDefinedOptionsMultiSelect": {
      const optionValues = getMultiselectOptionsFromValue(value as Maybe<string | string[]>);
      const configurationOptions =
        (configuration?.$type === "UserDefinedOptionsSelect" && configuration.userDefinedOptions) || [];
      const displayValues = optionValues.map(
        (optionValue) =>
          configurationOptions.find((configOption) => configOption.value === optionValue)?.label ?? optionValue
      );
      return <TypographyTooltipEllipsis text={displayValues.join(", ")} />;
    }
    case "Date": {
      if (!value) {
        return null;
      }

      const dateFieldConfiguration = configuration?.$type === "Date" ? configuration : undefined;
      const dateValue = parseISO(stringifyValue(value));
      return <Typography>{formatDateFieldValue(dateValue, dateFieldConfiguration)}</Typography>;
    }
    case "Number": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const maximumFractionDigits = (configuration?.$type === "Number" && configuration.precision) || 0;
      return <Typography>{formatNumber(value, { maximumFractionDigits })}</Typography>;
    }
    case "Money": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const currencyCode = (configuration?.$type === "Money" && configuration.currencyCode) || "USD";
      const maximumFractionDigits = (configuration?.$type === "Money" && configuration.precision) || 0;
      return <Typography>{formatMoney(value, currencyCode, { maximumFractionDigits })}</Typography>;
    }
    case "Percent": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const maximumFractionDigits = (configuration?.$type === "Percent" && configuration.precision) || 0;
      return <Typography>{formatPercentage(0.01 * value, { maximumFractionDigits })}</Typography>;
    }
    case "Checkbox": {
      return value ? <CheckRoundedIcon color="primary" /> : null;
    }
    case "Lookup": {
      const objectId = value;
      if (typeof objectId !== "string") {
        return null;
      }

      const lookupReference = lookupObjects[fieldId]?.find((l) => l.objectId === objectId);
      if (!lookupReference) {
        return null;
      }

      const resolvedValue = lookupReference.secondaryFieldValue
        ? `${lookupReference.primaryFieldValue} (${lookupReference.secondaryFieldValue})`
        : lookupReference.primaryFieldValue;

      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    default: {
      return value ? <TypographyTooltipEllipsis text={stringifyValue(value)} /> : null;
    }
  }
};

export default EntityValueGridCell;
