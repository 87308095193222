import { parseISO } from "date-fns";
import { localStorageService } from "../../shared/services/storage";
import { DescriptiveDateRange } from "../../shared/utilities/dateFilterHelper";
import { FilterType, FilterValue } from "../components/common/filters/filterTypes";

const RECENT_CLIENTS_KEY = "recent_clients";
const MAX_RECENT_CLIENTS = 3;

const getRecentClients = () => localStorageService.getItemWithFallback<string[]>(RECENT_CLIENTS_KEY, []);

const updateRecentClients = (clientCode: string) => {
  const recentClients = getRecentClients();
  recentClients.unshift(clientCode);

  const newRecentClients = recentClients.reduce<string[]>((result, code) => {
    if (!result.includes(code) && result.length < MAX_RECENT_CLIENTS) {
      result.push(code);
    }
    return result;
  }, []);

  localStorageService.setItem(RECENT_CLIENTS_KEY, newRecentClients);
};

const BETA_ACCESS_KEY = "beta";
const getBetaAccessEnabled = () => localStorageService.getItem<boolean>(BETA_ACCESS_KEY);
const setBetaAccessEnabled = (value: boolean) => localStorageService.setItem(BETA_ACCESS_KEY, value);

export interface PersistedFilterState {
  filters: PersistedFilter[];
  columns: PersistedColumn[];
}

interface PersistedFilter {
  id: string;
  type: FilterType;
  value: FilterValue;
}

interface PersistedColumn {
  id: string;
}

const getFilterState = (filterId: string) => localStorageService.getItem<PersistedFilterState>(filterId);

const saveFilterState = (filterId: string, filterState: PersistedFilterState) =>
  localStorageService.setItem(filterId, filterState);

const clearFilterState = (filterId: string) => localStorageService.removeItem(filterId);

const CLOSED_MAIN_MENU_ITEMS_KEY = "closed_main_menu_items";
const getClosedMainMenuItems = () => localStorageService.getItemWithFallback<string[]>(CLOSED_MAIN_MENU_ITEMS_KEY, []);
const saveClosedMainMenuItems = (items: string[]) => localStorageService.setItem(CLOSED_MAIN_MENU_ITEMS_KEY, items);

interface StoredDescriptiveDate extends Omit<DescriptiveDateRange, "from" | "to"> {
  from: string | undefined;
  to: string | undefined;
}

const DOC_COLLECTIONS_DATE_RANGE_KEY = "doc_collections_date_range";

const getDocumentCollectionsDateRange = (clientCode: string): DescriptiveDateRange | undefined => {
  const storedDateRange = localStorageService.getItem<StoredDescriptiveDate>(
    `${clientCode}_${DOC_COLLECTIONS_DATE_RANGE_KEY}`
  );

  if (!storedDateRange) {
    return undefined;
  }

  return {
    ...storedDateRange,
    from: storedDateRange.from ? parseISO(storedDateRange.from) : undefined,
    to: storedDateRange.to ? parseISO(storedDateRange.to) : undefined,
  };
};

const saveDocumentCollectionsDateRange = (clientCode: string, dateRange: DescriptiveDateRange) => {
  const storedDateRange: StoredDescriptiveDate = {
    ...dateRange,
    from: dateRange.from ? dateRange.from.toISOString() : undefined,
    to: dateRange.to ? dateRange.to.toISOString() : undefined,
  };

  localStorageService.setItem(`${clientCode}_${DOC_COLLECTIONS_DATE_RANGE_KEY}`, storedDateRange);
};

const storage = {
  getRecentClients,
  updateRecentClients,
  getBetaAccessEnabled,
  setBetaAccessEnabled,
  getFilterState,
  saveFilterState,
  clearFilterState,
  getClosedMainMenuItems,
  saveClosedMainMenuItems,
  getDocumentCollectionsDateRange,
  saveDocumentCollectionsDateRange,
};

export default storage;
