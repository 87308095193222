import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Container } from "@mui/material";
import { useState } from "react";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import { useDataRequestSetupPageContext } from "../data-request-templates/DataRequestSetupPageContext";
import DataRequestCategoriesGrid from "./DataRequestCategoriesGrid";
import EditDataRequestCategoryDialog from "./EditDataRequestCategoryDialog";

interface DialogState {
  open: boolean;
  editedCategoryId?: string;
}

const DataRequestCategoriesView = () => {
  const { categories, hasPermissionsToManage } = useDataRequestSetupPageContext();

  const [dialogState, setDialogState] = useState<DialogState>({ open: false });

  return (
    <>
      <Container maxWidth="xl" sx={{ py: 2.5, height: "100%" }}>
        <Box display="flex" width="100%" justifyContent="space-between" mb={2.5}>
          <RecordCounter records={categories.length} />
          {hasPermissionsToManage && (
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setDialogState({ open: true })}>
              Add New
            </Button>
          )}
        </Box>

        <DataRequestCategoriesGrid
          onCategoryEdit={(editedCategoryId) => setDialogState({ open: true, editedCategoryId })}
        />
      </Container>

      {dialogState.open && (
        <EditDataRequestCategoryDialog
          editedCategoryId={dialogState.editedCategoryId}
          onClose={() => setDialogState({ open: false })}
        />
      )}
    </>
  );
};

export default DataRequestCategoriesView;
