import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Alert, Button, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { combineComparers, stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import adminApi, { ContactDetails, Investor } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import CommunicationMatrix from "../../common/CommunicationMatrix";
import {
  cloneContactModelWithoutChildren,
  CommunicationMatrixModel,
  createContactModelsForAddedInvestors,
  getContactChangesWhereInvitationWouldBeSent,
  getContactMatrixTreeDataPath,
} from "../../common/matrixHelper";
import InvestorCreateDialog from "../../investors/InvestorCreateDialog";
import AssignInvestorsDialog from "./AssignInvestorsDialog";

interface Props {
  categories: Category[];
  contactDetails: ContactDetails;
  investors: Investor[];
}

const sortModelFunc = combineComparers<CommunicationMatrixModel>(
  stringComparerBy((m) => m.investorName),
  stringComparerBy((m) => m.fundName)
);

const ContactCommunicationMatrix = ({ categories, contactDetails, investors }: Props) => {
  const originalModels = useMemo(() => {
    const investorsThatContactHasPermissionsFor = investors.filter((investor) =>
      contactDetails.permissions.some((cp) => investor.funds.map((f) => f.fundInvestorId).includes(cp.fundInvestorId))
    );
    return createContactModelsForAddedInvestors(investorsThatContactHasPermissionsFor, contactDetails).sort((a, b) =>
      a.investorName.localeCompare(b.investorName)
    );
  }, [contactDetails, investors]);

  const [initialModels, setInitialModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [models, setModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [showAssignInvestorsDialog, setShowAssignInvestorsDialog] = useState(false);
  const [showInvestorCreateDialog, setShowInvestorCreateDialog] = useState(false);

  const investorsForOptions = useMemo(
    () =>
      investors
        .filter((investor) => !models.some((model) => model.investorName === investor.title))
        .filter((investor) => investor.funds.length > 0) //filter out investors without funds ?
        .sort((a, b) => a.title.localeCompare(b.title)),
    [investors, models]
  );

  const getChangesWhereInvitationWouldBeSent = (
    initialModels: CommunicationMatrixModel[],
    modifiedModels: CommunicationMatrixModel[]
  ) => getContactChangesWhereInvitationWouldBeSent([contactDetails.contact], initialModels, modifiedModels);

  const handleAddInvestorClick = () => {
    setShowAssignInvestorsDialog(true);
  };

  const handleInvestorsSelected = (selectedInvestors: Investor[]) => {
    setModels((currentModels) => [
      ...currentModels,
      ...createContactModelsForAddedInvestors(selectedInvestors, contactDetails),
    ]);

    setShowAssignInvestorsDialog(false);
  };

  return (
    <Stack spacing={2} sx={{ height: "100%" }}>
      {!contactDetails.contact.email && (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" href={`../${contactDetails.contact.id}/main`} target={"_blank"}>
              Add Email
            </Button>
          }
        >
          To start assigning accesses, please provide an email address for this contact.
        </Alert>
      )}
      <CommunicationMatrix
        entityType="Contact"
        noItemsMessage={"This contact has not been added to any investor."}
        renderGroupCellElement={(value: unknown) => <GroupInvestorCell value={value as string} />}
        renderGridToolbarComponent={(isLoading: boolean) => (
          <Button
            startIcon={<AddRoundedIcon />}
            disabled={isLoading || !contactDetails.contact.email}
            onClick={handleAddInvestorClick}
            variant="contained"
            color="primary"
          >
            Add Investor
          </Button>
        )}
        categories={categories}
        initialModels={initialModels}
        setInitialModels={setInitialModels}
        models={models}
        setModels={setModels}
        getChangesWhereInvitationWouldBeSent={getChangesWhereInvitationWouldBeSent}
        sortModelFunc={sortModelFunc}
        getTreeDataPath={getContactMatrixTreeDataPath}
        cloneModelWithoutChildren={cloneContactModelWithoutChildren}
        renderGroupedRowCell={(model: CommunicationMatrixModel) => (
          <TypographyTooltipEllipsis text={model.fundName} typographyProps={{ ml: 8 }} />
        )}
        childRowActionsDisabled
        updateInvestorCommunicationMatrix={adminApi.updateInvestorCommunicationMatrix}
        excludedColumns={["isPrimary"]}
      />
      <AssignInvestorsDialog
        open={showAssignInvestorsDialog}
        onClose={() => setShowAssignInvestorsDialog(false)}
        title={`Add investor(s) to ${contactDetails.contact.name}`}
        description="Select the investor(s) you wish to associate with this contact."
        onCreateNew={() => {
          setShowAssignInvestorsDialog(false);
          setShowInvestorCreateDialog(true);
        }}
        options={investorsForOptions}
        onSelected={handleInvestorsSelected}
      />
      <InvestorCreateDialog
        open={showInvestorCreateDialog}
        onClose={() => setShowInvestorCreateDialog(false)}
        investors={investors}
        onCreated={() => {
          setShowInvestorCreateDialog(false);
        }}
      />
    </Stack>
  );
};

export default ContactCommunicationMatrix;

const GroupInvestorCell = ({ value }: { value: string }) => (
  <TypographyTooltipEllipsis key={value} text={value} typographyProps={{ variant: "subtitle1" }} />
);
