import { Stack } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { Investor, InvestorCapitalActivityRequest } from "../../../../../api/adminApi";
import InvestorCapitalActivityFilters from "./InvestorCapitalActivityFilters";
import InvestorCapitalActivityTable from "./InvestorCapitalActivityTable";

interface Props {
  investor: Investor;
}

const InvestorDetailsCapitalActivityTab = ({ investor }: Props) => {
  const [request, setRequest] = useState<InvestorCapitalActivityRequest>({
    paging: { page: 0, size: 200, totals: false }, // no paging
    isInvestorPortalMode: false,
  });

  const fetchCapitalActivity = useCallback(
    () => adminApi.getInvestorCapitalActivity(investor.id, request),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [investor.id, objectHash(request)]
  );

  const [cashFlows, fetchError, { isFetching }] = useFetch(fetchCapitalActivity);

  if (fetchError) {
    logError(fetchError.message, "InvestorCapitalActivityPage");
    return <DataLoadingFailed bgColor="none" title="Loading investor capital activity failed" />;
  }

  if (cashFlows === undefined) {
    return <InlineLoader />;
  }

  return (
    <Stack spacing={2.5} height="100%">
      <InvestorCapitalActivityFilters
        onChange={(update) => setRequest((prev) => ({ ...prev, ...update }))}
        fundNames={investor.funds.map((fund) => fund.fundName)}
        disabled={isFetching}
      />
      <InvestorCapitalActivityTable isLoading={isFetching} cashFlows={cashFlows} />
    </Stack>
  );
};

export default InvestorDetailsCapitalActivityTab;
