import ObjectIcon from "@mui/icons-material/DataObjectRounded";
import { Button, Popover, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { ValidationResult } from "../../../../shared/utilities/validators";
import EmailSnippets, { EmailSnippet } from "./EmailSnippets";

interface Props {
  snippets: EmailSnippet[];
  settings: EmailTemplateSettings;
  onChange: (update: Partial<EmailTemplateSettings>) => void;
  disabled?: boolean;
  validationResults: Partial<Record<PropKey, ValidationResult>>;
}

export interface EmailTemplateSettings {
  subject: string;
  body: string;
  cc: string;
  bcc: string;
}

type PropKey = keyof EmailTemplateSettings;

type InputSelection = [PropKey | undefined, number | null, number | null];

const EmailTemplateEditor = ({ snippets, settings, onChange, disabled, validationResults }: Props) => {
  const [inputSelection, setInputSelection] = useState<InputSelection>([undefined, 0, 0]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const subjectInputRef = useRef<HTMLInputElement>();
  const bodyInputRef = useRef<HTMLInputElement>();

  const handleInputSelectionChange = (input: HTMLInputElement | undefined, propKey: PropKey) => {
    if (input === undefined) {
      return;
    }

    const { selectionStart, selectionEnd } = input;
    setInputSelection([propKey, selectionStart, selectionEnd]);
  };

  const handleSnippetSelected = (snippetValue: string) => {
    setAnchorEl(null);
    const [propName, start, end] = inputSelection;

    if (!propName || start === null || end === null) {
      return;
    }

    const text = settings[propName];
    const textStart = text.slice(0, start);
    const textEnd = text.slice(end);

    onChange({ ...settings, [propName]: textStart + snippetValue + textEnd });
  };

  return (
    <Stack spacing={3}>
      <TextField
        fullWidth
        variant="outlined"
        label="CC Recipients (optional)"
        placeholder="Enter one or more email addresses separated by comma"
        disabled={disabled}
        value={settings.cc}
        onChange={({ target }) => onChange({ cc: target.value })}
        error={validationResults?.cc?.isValid === false}
        helperText={validationResults?.cc?.error}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="BCC Recipients (optional)"
        placeholder="Enter one or more email addresses separated by comma"
        disabled={disabled}
        value={settings.bcc}
        onChange={({ target }) => onChange({ bcc: target.value })}
        error={validationResults?.bcc?.isValid === false}
        helperText={validationResults?.bcc?.error}
      />
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          variant="outlined"
          color="secondary"
          disabled={disabled}
          startIcon={<ObjectIcon />}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Snippet
        </Button>
        <Typography color="text.secondary">
          You can add a Snippet to the Email Subject and Email Body. Select a field and add a Snippet.
        </Typography>
      </Stack>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <EmailSnippets snippets={snippets} onSelected={handleSnippetSelected} />
      </Popover>
      <TextField
        spellCheck
        inputRef={subjectInputRef}
        fullWidth
        variant="outlined"
        label="Subject"
        disabled={disabled}
        value={settings.subject}
        error={validationResults?.subject?.isValid === false}
        onChange={({ target }) => onChange({ subject: target.value })}
        onSelect={() => handleInputSelectionChange(subjectInputRef.current, "subject")}
      />
      <Stack spacing={1}>
        <Typography variant="subtitle1">Email Body</Typography>
        <TextField
          spellCheck
          inputRef={bodyInputRef}
          fullWidth
          multiline
          minRows={8}
          disabled={disabled}
          value={settings.body}
          error={validationResults?.body?.isValid === false}
          onChange={({ target }) => onChange({ body: target.value })}
          onSelect={() => handleInputSelectionChange(bodyInputRef.current, "body")}
        />
      </Stack>
    </Stack>
  );
};

export default EmailTemplateEditor;
