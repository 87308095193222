import { Box, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectClassDefinitionInfo } from "../../../api/types/objectTypes";
import { useClientContext } from "../../../context/ClientContext";
import ObjectsListPage from "./ObjectsListPage";
import ObjectsPageNavigation from "./ObjectsPageNavigation";

const ObjectsPage = () => {
  const { hasAnyPermission } = useClientContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedObjectType, setSelectedObjectType] = useState<string | undefined>(
    searchParams.get("type") ?? undefined
  );

  const [availableCustomObjectDefinitions, setAvailableCustomObjectDefinitions] = useState<ObjectClassDefinitionInfo[]>(
    []
  );

  const [objectDefinitions, fetchError, { isFetching }] = useFetch(adminApi.getAvailableObjectDefinitions, (data) => {
    const availableCustomObjectDefinitions = data
      .filter(
        ({ objectClassDefinition, objectPermissions }) =>
          objectClassDefinition.source === "Custom" && hasAnyPermission(objectPermissions.objectDataReadPermissions)
      )
      .map(({ objectClassDefinition }) => objectClassDefinition);

    setAvailableCustomObjectDefinitions(availableCustomObjectDefinitions);

    if (!selectedObjectType || !availableCustomObjectDefinitions.some((def) => def.objectType === selectedObjectType)) {
      setSelectedObjectType(availableCustomObjectDefinitions[0]?.objectType);
    }
  });

  if (fetchError) {
    logError(fetchError, "[ProductObjectsPage]");
    return <DataLoadingFailed title="Loading objects definitions failed" />;
  }

  if (isFetching || objectDefinitions === undefined) {
    return <InlineLoader />;
  }

  const handleObjectTypeChange = (objectType: string) => {
    setSelectedObjectType(objectType);
    setSearchParams({ type: objectType });
  };

  if (availableCustomObjectDefinitions.length === 0) {
    return (
      <Box display="flex" justifyContent="center" pt={2.5}>
        <Typography color="text.secondary">No records available for this client.</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" overflow="hidden" height="100%">
      <ObjectsPageNavigation
        selectedObjectType={selectedObjectType}
        onSelectedObjectTypeChange={handleObjectTypeChange}
        objectDefinitions={availableCustomObjectDefinitions}
      />
      <Divider flexItem orientation="vertical" />
      {selectedObjectType && (
        <Box display="flex" flexDirection="column" height="100%" flex={1}>
          <ObjectsListPage
            key={selectedObjectType}
            objectType={selectedObjectType}
            objectDetailsPathPattern=":type/:id"
          />
        </Box>
      )}
    </Box>
  );
};

export default ObjectsPage;
