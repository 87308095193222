import { TabPanel } from "@mui/lab";
import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../../api/adminApi";
import { Fundraising, FundraisingInfo } from "../../../../api/types/fundraisingTypes";
import { useClientContext } from "../../../../context/ClientContext";
import PageTabs from "../../../common/PageTabs";
import FundraisingDialogs from "./dialogs/FundraisingDialogs";
import FundraisingsList from "./FundraisingsList";
import { FundraisingsPageContextProvider } from "./FundraisingsPageContext";
import FundraisingsPageHeader from "./FundraisingsPageHeader";
import {
  DialogState,
  FundraisingsFilter,
  fundraisingsFilters,
  FundraisingStatusTransition,
} from "./fundraisingsPageTypes";

const FundraisingsPage = () => {
  usePageTitle("Fundraising");

  const { hasPermissions } = useClientContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<FundraisingsFilter>(
    parseSearchParamWithFallback(searchParams, "tab", fundraisingsFilters)
  );

  const [dialogState, setDialogState] = useState<DialogState>({});

  const getFundraisings = useCallback(async () => {
    const statuses: FundraisingStatus[] = selectedTab === "in_progress" ? ["Draft", "Preview", "Live"] : ["Completed"];
    return adminApi.getFundraisings(statuses);
  }, [selectedTab]);

  const [fundraisings, fetchFundraisingsError, { isFetching: isFetchingFundraisings, fetch: fetchData }] =
    useFetch(getFundraisings);

  const [portalSettings, fetchSettingsError, { isFetching: isFetchingSettings }] = useFetch(
    adminApi.getInvestorPortalSettings
  );

  const fetchError = fetchFundraisingsError || fetchSettingsError;
  const isFetching = isFetchingFundraisings || isFetchingSettings;

  if (fetchError) {
    logError(fetchError, "[FundraisingsPage] fetch");
    return <DataLoadingFailed title="Failed to load fundraisings" />;
  }

  const handleTabChange = (tab: FundraisingsFilter) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const handleCreate = () => setDialogState({ openDialog: "create" });

  const handleRename = (id: string, name: string) =>
    setDialogState({
      openDialog: "rename",
      fundraisingId: id,
      fundraisingName: name,
    });

  const handleStatusChange = (fundraising: FundraisingInfo, statusTransition: FundraisingStatusTransition) => {
    setDialogState({
      openDialog: "change_status",
      statusTransition,
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
      fundId: fundraising.fundId,
      areNotificationsSetUp: fundraising.areNotificationsSetUp,
    });
  };

  const handleDelete = (id: string, name: string) =>
    setDialogState({
      openDialog: "delete",
      fundraisingId: id,
      fundraisingName: name,
    });

  const handleDialogClose = () => setDialogState({});

  const handleCreated = (fundraising: Fundraising) => {
    handleDialogClose();
    navigate(`${fundraising.id}`);
  };

  const handleUpdated = () => {
    handleDialogClose();
    fetchData();
  };

  const isPageDeactivatedOnPortal =
    portalSettings !== undefined &&
    !portalSettings.settings.enabledFeatures?.includes("Fundraising") &&
    !portalSettings.settings.enabledFeatures?.includes("All");

  const displayedFundraisings =
    selectedTab === "completed" ? (fundraisings ?? []).filter((f) => f.status === "Completed") : (fundraisings ?? []);

  return (
    <FundraisingsPageContextProvider
      hasEditPermissions={hasPermissions(["ManageFundraisings"])}
      onCreate={handleCreate}
      onRename={handleRename}
      onStatusChange={handleStatusChange}
      onDelete={handleDelete}
    >
      <FundraisingsPageHeader />
      <PageTabs
        tabs={fundraisingsFilters}
        labels={["In Progress", "Completed"]}
        value={selectedTab}
        onChange={handleTabChange}
      >
        {fundraisingsFilters.map((filter) => (
          <TabPanel key={filter} value={filter} sx={{ height: "100%", px: 3, py: 2.5 }}>
            <FundraisingsList
              fundraisings={displayedFundraisings}
              isLoading={isFetching}
              showPageDeactivatedWarning={isPageDeactivatedOnPortal}
            />
          </TabPanel>
        ))}
      </PageTabs>

      <FundraisingDialogs
        dialogState={dialogState}
        onCreated={handleCreated}
        onUpdated={handleUpdated}
        onDeleted={handleUpdated}
        onClose={handleDialogClose}
      />
    </FundraisingsPageContextProvider>
  );
};

export default FundraisingsPage;
