import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import ConsentAgreementLabel from "../../../../../shared/components/consentAgreement/ConsentAgreementLabel";
import ConsentDialogBody from "../../../../../shared/components/consentAgreement/ConsentDialogBody";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";

interface Props {
  open: boolean;
  logoUrl: string | undefined;
  consentName: string;
  consentContent: string;
  customAgreementText: string | undefined;
  onClose: () => void;
}

const UserConsentPreviewDialog = ({
  open,
  logoUrl,
  consentName,
  consentContent,
  customAgreementText,
  onClose,
}: Props) => {
  return (
    <Dialog open={open} scroll="paper" maxWidth="md" fullWidth PaperProps={{ sx: { minHeight: "60vh" } }}>
      <DialogTitle>
        <Stack spacing={1}>
          <Typography variant="h6">Preview</Typography>
          <Typography>Example of how the consent would look like for a portal user. </Typography>
        </Stack>
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <Divider />

      <ConsentDialogBody
        title="Welcome to Investor Portal"
        showTitle
        logoUrl={logoUrl}
        consentContent={consentContent}
        acceptFormGroup={
          <FormGroup sx={{ my: "1rem" }}>
            <FormControlLabel
              control={<Checkbox color="primary" disabled />}
              label={<ConsentAgreementLabel consentName={consentName} customAgreementText={customAgreementText} />}
            />
          </FormGroup>
        }
        saveButton={
          <Button disabled variant="contained" sx={{ height: "2.25rem", width: "7rem" }}>
            Continue
          </Button>
        }
      />

      <Divider />

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserConsentPreviewDialog;
