import { Stack, Typography } from "@mui/material";
import { MonthlyFinancialsBlockContent } from "../../../../api/inputFormTypes";
import BlockSection from "../BlockSection";
import MonthlyFinancialsInputForm from "./MonthlyFinancialsInputForm";

interface Props {
  blockId: string;
  content: MonthlyFinancialsBlockContent;
}

const MonthlyFinancialsFormBlock = ({ blockId, content }: Props) => {
  return (
    <Stack flex={1} spacing={2.5}>
      {content.tables.map((tableBlock, index) => (
        <BlockSection key={index}>
          <Typography variant="h6">{tableBlock.name}</Typography>
          <MonthlyFinancialsInputForm
            blockId={blockId}
            columnDefinitions={tableBlock.columns}
            rowDefinitions={tableBlock.rows}
          />
        </BlockSection>
      ))}
    </Stack>
  );
};

export default MonthlyFinancialsFormBlock;
