import { Box, Stack, Typography } from "@mui/material";
import { ScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import { convertISODate } from "../../../../utilities/dateUtils";
import HorizontalFill from "../../../HorizontalFill";
import { getReportingPeriodDateFormat } from "../../dataCollectionUtils";
import ScenarioAvatar from "./ScenarioAvatar";

interface Props {
  columnDefinition: ScenarioTableBlockColumn;
}

const ScenarioColumnHeader = ({ columnDefinition }: Props) => {
  return (
    <Stack width="100%">
      <Box display="flex" justifyContent="space-between">
        <ScenarioAvatar scenarioName={columnDefinition.scenarioName} />
        <Typography variant="subtitle2">
          {convertISODate(columnDefinition.titleAsDate, getReportingPeriodDateFormat(columnDefinition.period))}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <HorizontalFill />
        <Typography variant="caption" color="text.secondary" height="1rem">
          {columnDefinition.subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ScenarioColumnHeader;
