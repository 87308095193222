import { Box, Grid2, Stack, Typography } from "@mui/material";
import {
  ContainerBlockAttributes,
  InfoSectionBlockContent,
  InternalTableBlockContent,
  LayoutBlock,
  LayoutBlockType,
  MonthlyFinancialsBlockContent,
  QuarterlyDebtTableBlockContent,
  SingleExtendedMetricTableBlockContent,
} from "../../../api/inputFormTypes";
import { useDataSubmissionFormContext } from "./DataSubmissionFormContext";
import InfoSectionFormBlock from "./InfoSectionFormBlock";
import InternalTableFormBlock from "./portfolioMonitoring/InternalTableFormBlock";
import MonthlyFinancialsFormBlock from "./portfolioMonitoring/MonthlyFinancialsFormBlock";
import QuarterlyDebtFormBlock from "./portfolioMonitoring/QuarterlyDebtFormBlock";
import SingleExtendedMetricTableFormBlock from "./portfolioMonitoring/SingleExtendedMetricTableFormBlock";

interface Props {
  block: LayoutBlock;
}

const UnsupportedBlock = ({ type }: { type: LayoutBlockType }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="4rem"
      width="100%"
      sx={(t) => ({ border: `1px dashed ${t.palette.divider}` })}
    >
      <Typography color="warning.main">Unsupported block type: {type}</Typography>
    </Box>
  );
};

const ContainerFormBlock = ({ attributes }: { attributes: ContainerBlockAttributes }) => {
  const size =
    attributes.relativeWidth !== undefined && attributes.relativeWidth > 0
      ? Math.min(12, Math.round(attributes.relativeWidth * 12))
      : attributes.fixedWidth !== undefined && attributes.fixedWidth > 0
        ? "grow"
        : "auto";

  return (
    <Grid2 size={size} sx={{ width: attributes.fixedWidth }}>
      <Stack spacing={2.5}>
        {attributes.children.map((child) => (
          <DataSubmissionFormBlock key={child.id} block={child} />
        ))}
      </Stack>
    </Grid2>
  );
};

const DataSubmissionFormBlock = ({ block }: Props) => {
  const { getBlockContent } = useDataSubmissionFormContext();

  const content = getBlockContent(block.id);

  if (block.type === "Container") {
    return <ContainerFormBlock attributes={block.attributes} />;
  }

  if (block.type === "InfoSection") {
    return content ? (
      <InfoSectionFormBlock attributes={block.attributes} content={content as InfoSectionBlockContent} />
    ) : null;
  }

  if (block.type === "MonthlyFinancials") {
    return content ? (
      <MonthlyFinancialsFormBlock blockId={block.id} content={content as MonthlyFinancialsBlockContent} />
    ) : null;
  }

  if (block.type === "SingleExtendedMetricTable") {
    return content ? (
      <SingleExtendedMetricTableFormBlock
        blockId={block.id}
        content={content as SingleExtendedMetricTableBlockContent}
      />
    ) : null;
  }

  if (block.type === "QuarterlyDebtTable") {
    return content ? (
      <QuarterlyDebtFormBlock blockId={block.id} content={content as QuarterlyDebtTableBlockContent} />
    ) : null;
  }

  if (block.type === "InternalTable") {
    return content ? (
      <InternalTableFormBlock blockId={block.id} content={content as InternalTableBlockContent} />
    ) : null;
  }

  return <UnsupportedBlock type={block.type} />;
};

export default DataSubmissionFormBlock;
