import { Grid2 } from "@mui/material";
import { Outlet } from "react-router";
import GlobalNotificationErrorSnackbar from "../../../../../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../../../../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../../../../../shared/contexts/NotificationContext";
import biClient from "../../../../../../shared/reporting/api/biClient";
import {
  BiApiClientBase,
  BiApiClientProvider,
} from "../../../../../../shared/reporting/contexts/BiApiClientProviderContext";
import biApi from "../../../../../api/biApi";
import useReports from "../../../reporting/hooks/useReports";
import { ApiReportsContextProvider } from "../../../reporting/reports/api-reports/contexts/ReportsContext";
import ApiReportsManager from "../../../reporting/reports/api-reports/contexts/ReportsManager";

const ApiOrganizationReportsPageContainer = () => {
  const { reports, ui, actions } = useReports(biApi.apiOrganizationReports.getOrganizationReports, "Api");

  return (
    <Grid2 container flex={1} width="100%">
      <BiApiClientProvider<BiApiClientBase>
        api={{
          validateName: biClient.apiReports.validateReportName,
          getReportSharing: biClient.apiReports.getReportSharing,
          updateReportSharing: biClient.apiReports.updateReportSharing,
        }}
      >
        <NotificationContextProvider>
          <ApiReportsContextProvider reports={reports} ui={{ loaded: ui.loaded, error: ui.error }} actions={actions}>
            <ApiReportsManager
              onDeleted={actions.reloadReports}
              reports={reports}
              deleteReport={actions.deleteReports}
              duplicate={actions.duplicateReport}
            >
              <Outlet />
              <GlobalNotificationSnackbar />
              <GlobalNotificationErrorSnackbar />
            </ApiReportsManager>
          </ApiReportsContextProvider>
        </NotificationContextProvider>
      </BiApiClientProvider>
    </Grid2>
  );
};

export default ApiOrganizationReportsPageContainer;
