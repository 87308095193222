import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectAccessCategory, ObjectClassDefinition } from "../../../api/types/objectTypes";
import AddContactAccessDialog from "./AddContactAccessDialog";
import EditContactAccessDrawer from "./EditContactAccessDrawer";
import RemoveContactAccessDialog from "./RemoveContactAccessDialog";
import UserAccessMatrix from "./UserAccessMatrix";
import { mapObjectContactToUserAccess, User, UserAccess, UserAccessMatrixRow } from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  categories: ObjectAccessCategory[];
  hasPermissionsToManage: boolean;
}

interface DialogState {
  openDialog?: "add" | "edit" | "remove";
  editedRows?: UserAccessMatrixRow[];
}

const ContactAccessMatrix = ({ objectId, objectDefinition, categories, hasPermissionsToManage }: Props) => {
  const [accessItems, setAccessItems] = useState<UserAccess[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getObjectContacts = useCallback(
    () => adminApi.getObjectContacts(objectDefinition.objectType, objectId),
    [objectDefinition.objectType, objectId]
  );

  const [objectContacts, fetchObjectContactsError] = useFetch(getObjectContacts, (data) => {
    setAccessItems(data.map(mapObjectContactToUserAccess));
  });

  const getAllContacts = useCallback(
    () => adminApi.getAllContactsForObjectType(objectDefinition.objectType),
    [objectDefinition.objectType]
  );

  const [allContactsResp, fetchAllContactsError] = useFetch(getAllContacts, (data) => {
    setAllUsers(data);
  });

  const handleAddUsers = useCallback(() => {
    setDialogState({ openDialog: "add" });
  }, []);

  const handleEditUsers = useCallback((editedRows: UserAccessMatrixRow[]) => {
    setDialogState({ openDialog: "edit", editedRows });
  }, []);

  const handleRemoveUsers = useCallback((editedRows: UserAccessMatrixRow[]) => {
    setDialogState({ openDialog: "remove", editedRows });
  }, []);

  const fetchError = fetchObjectContactsError || fetchAllContactsError;

  if (fetchError) {
    logError(fetchError, "[ContactAccessMatrix]");
    return <DataLoadingFailed title="Could not load contacts" />;
  }

  return (
    <>
      <UserAccessMatrix
        categories={categories}
        hasPermissionsToManage={hasPermissionsToManage}
        accessItems={accessItems}
        allUsers={allUsers}
        onAddUsers={handleAddUsers}
        onEditUsers={handleEditUsers}
        onRemoveUsers={handleRemoveUsers}
        isLoading={objectContacts === undefined || allContactsResp === undefined}
      />

      {dialogState.openDialog === "add" && (
        <AddContactAccessDialog
          objectId={objectId}
          objectDefinition={objectDefinition}
          categories={categories}
          currentAccessItems={accessItems}
          allUsers={allUsers}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
          onUserCreated={(user) => setAllUsers((prev) => [...prev, user])}
        />
      )}

      {dialogState.openDialog === "edit" && dialogState.editedRows?.length && (
        <EditContactAccessDrawer
          objectId={objectId}
          objectDefinition={objectDefinition}
          categories={categories}
          currentAccessItems={accessItems}
          editedRows={dialogState.editedRows}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
        />
      )}

      {dialogState.openDialog === "remove" && dialogState.editedRows?.length && (
        <RemoveContactAccessDialog
          objectId={objectId}
          objectType={objectDefinition.objectType}
          removedRows={dialogState.editedRows}
          currentAccessItems={accessItems}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
        />
      )}
    </>
  );
};

export default ContactAccessMatrix;
