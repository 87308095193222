import { GridCellParams, GridEventListener } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { ObjectMetricExtension } from "../../../../api/dataCollectionTypes";
import { NumericGridTableBlockRow, ScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import useSingleClickGridEditing from "../../../../hooks/useSingleClickGridEditing";
import { makeNextEditableCellEdited } from "../../../../utilities/editableDataGridHelper";
import { defined } from "../../../../utilities/typeHelper";
import DataGrid from "../../../grid/DataGrid";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { getSubmissionDataGridFormStyles } from "../submissionTableFormStyles";
import AddMetricExtensionDialog from "./AddMetricExtensionDialog";
import { getColumns, getRows, RowModel } from "./monthlyFinancialsInputGridDataProvider";
import { PortfolioMonitoringInputTableContextProvider } from "./PortfolioMonitoringInputTableContext";
import { calculateTotals } from "./tableTotalsCalculator";

interface Props {
  blockId: string;
  columnDefinitions: ScenarioTableBlockColumn[];
  rowDefinitions: NumericGridTableBlockRow[];
}

interface AddExtensionDialogState {
  metricIds: string[];
  metricName: string;
  addedExtensionIds: string[];
}

const MonthlyFinancialsInputForm = ({ blockId, columnDefinitions, rowDefinitions }: Props) => {
  const { isSubmissionEditable, onBlockCellValuesEdit } = useDataSubmissionFormContext();

  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));
  const [dialogState, setDialogState] = useState<AddExtensionDialogState>();

  const columns = useMemo(
    () => getColumns(columnDefinitions, isSubmissionEditable),
    [columnDefinitions, isSubmissionEditable]
  );

  const { cellModesModel, setCellModesModel, handleCellClick } = useSingleClickGridEditing();

  const isCellEditable = useCallback(
    ({ row }: { row: RowModel }) => row.type === "Metric" || row.type === "MetricExtension",
    []
  );

  const handleRowUpdate = useCallback(
    (newRow: RowModel, oldRow: RowModel) => {
      if (
        (newRow.type !== "Metric" && newRow.type !== "MetricExtension") ||
        (oldRow.type !== "Metric" && oldRow.type !== "MetricExtension")
      ) {
        return newRow;
      }

      const updatedRow = { ...newRow };

      const updatedValueEntry = Object.entries(updatedRow.values).find(
        ([columnId, currentValue]) => oldRow.values[columnId] !== currentValue
      );

      if (updatedValueEntry !== undefined) {
        const metricId = updatedRow.metricId;
        const [columnId, newValue] = updatedValueEntry;
        const metricExtensionId = updatedRow.type === "MetricExtension" ? updatedRow.extensionId : undefined;

        onBlockCellValuesEdit(blockId, [{ metricId, metricExtensionId, columnId, value: newValue ?? 0 }]);
      }

      setTimeout(
        () =>
          setRows((prevRows) => {
            const newRows = prevRows.map((row) => {
              if (row.id !== updatedRow.id) {
                return row;
              }

              return { ...updatedRow };
            });

            return calculateTotals(newRows);
          }),
        100
      );

      return updatedRow;
    },
    [blockId, onBlockCellValuesEdit]
  );

  const handleAddMetricExtensions = useCallback(
    (metricIds: string[], metricName: string) => {
      const addedExtensionIds = rows
        .map((r) => (r.type === "MetricExtension" && metricIds.includes(r.metricId) ? r.extensionId : ""))
        .filter(Boolean);

      setDialogState({
        metricIds,
        metricName,
        addedExtensionIds,
      });
    },
    [rows]
  );

  const handleMetricExtensionAdded = useCallback(
    (addedExtensions: ObjectMetricExtension[], isNew: boolean) => {
      setDialogState(undefined);

      if (addedExtensions.length === 0) {
        return;
      }

      const addedExtension = defined(addedExtensions[0]);

      const cellValues: Array<[string, number]> = columnDefinitions
        .filter((colDef) => colDef.isEditable)
        .map((colDef) => [colDef.id, 0]);

      setRows((prevRows) =>
        prevRows.reduce<RowModel[]>((result, row) => {
          if (row.id !== addedExtension.metricId) {
            return [...result, row];
          }

          const newExtensionRow: RowModel = {
            type: "MetricExtension",
            isNew,
            id: `${addedExtension.metricId}_${addedExtension.value}`,
            extensionId: addedExtension.id,
            metricId: addedExtension.metricId,
            name: addedExtension.value,
            indentationLevel: row.indentationLevel + 1,
            values: Object.fromEntries(cellValues),
            previousValues: {},
            dataType: "Money", // TODO get from metric configuration
          };

          return [...result, row, newExtensionRow];
        }, [])
      );

      onBlockCellValuesEdit(
        blockId,
        cellValues.map(([columnId, value]) => ({
          metricId: addedExtension.metricId,
          metricExtensionId: addedExtension.id,
          columnId,
          value,
        }))
      );
    },
    [blockId, columnDefinitions, onBlockCellValuesEdit]
  );

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = useCallback(
    (params: GridCellParams<RowModel>, event) => {
      if (event.code !== "Tab") {
        return;
      }

      event.defaultMuiPrevented = true;
      event.preventDefault();
      const direction = event.shiftKey ? "previous" : "next";
      makeNextEditableCellEdited(direction, rows, columns, params, setCellModesModel, isCellEditable, (r) => r.id);
    },
    [columns, isCellEditable, rows, setCellModesModel]
  );

  return (
    <PortfolioMonitoringInputTableContextProvider onAddMetricExtensions={handleAddMetricExtensions}>
      <DataGrid<RowModel>
        columns={columns}
        rows={rows}
        columnHeaderHeight={52}
        pinnedColumns={{ left: ["name"] }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={setCellModesModel}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        disableColumnReorder
        disableColumnSorting
        showCellVerticalBorder
        showColumnVerticalBorder
        editMode="cell"
        isCellEditable={isCellEditable}
        processRowUpdate={handleRowUpdate}
        noScrollableContainer
        sx={getSubmissionDataGridFormStyles}
      />

      {dialogState && (
        <AddMetricExtensionDialog
          metricIds={dialogState.metricIds}
          metricName={dialogState.metricName}
          addedExtensionIds={dialogState.addedExtensionIds}
          allowCreatingExtensionValues
          onAdded={handleMetricExtensionAdded}
          onClose={() => setDialogState(undefined)}
        />
      )}
    </PortfolioMonitoringInputTableContextProvider>
  );
};

export default MonthlyFinancialsInputForm;
