import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DataSubmissionConfiguration } from "../../../../api/types/dataCollectionTypes";
import { ObjectAccessCategory, ObjectClassDefinition } from "../../../../api/types/objectTypes";

interface Props {
  categories: ObjectAccessCategory[];
  onCategoriesUpdated: (categories: ObjectAccessCategory[]) => void;
  configurations: DataSubmissionConfiguration[];
  objectDefinition: ObjectClassDefinition;
  hasPermissionsToManage: boolean;
}

const DataRequestSetupPageContext = createContext<Props | undefined>(undefined);

export const DataRequestSetupPageContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataRequestSetupPageContext.Provider value={contextValue}>{children}</DataRequestSetupPageContext.Provider>
);

export const useDataRequestSetupPageContext = () =>
  defined(useContext(DataRequestSetupPageContext), "Attempt to use DataRequestSetupPageContext without provider");
