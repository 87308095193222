import { GRID_CHECKBOX_SELECTION_FIELD, GridCellParams } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useNavigate } from "react-router";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import { getCheckedGridSx } from "../../../../common/grid/gridStyles";
import { useDataRequestPageContext } from "./DataRequestPageContext";
import { columnDefinitions } from "./dataRequestSubmissionsGridDataProvider";
import DataRequestSubmissionsGridToolbar from "./DataRequestSubmissionsGridToolbar";

interface Props {
  requestId: string;
  rows: RequestSubmissionInfo[];
}

const DataRequestSubmissionsGrid = ({ requestId, rows }: Props) => {
  const { clientCode } = useClientContext();
  const { hasPermissionsToManage } = useDataRequestPageContext();
  const navigate = useNavigate();

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const handleCellClick = ({ row, field }: GridCellParams<RequestSubmissionInfo>) => {
    if (field !== "auditoryList" && field !== "actions" && field !== GRID_CHECKBOX_SELECTION_FIELD) {
      navigate(
        `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataSubmissions}/${row.id}?ref=request&refid=${requestId}`
      );
    }
  };

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<RequestSubmissionInfo>
      rows={rows}
      columns={columnDefinitions}
      selectableRows
      onCellClick={handleCellClick}
      checkboxSelection={hasPermissionsToManage}
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      noRowsText="No data submissions"
      disableColumnReorder
      disableColumnSorting
      slots={{
        toolbar: () =>
          showToolbar ? (
            <DataRequestSubmissionsGridToolbar
              allSubmissions={rows}
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
            />
          ) : null,
      }}
    />
  );
};

export default DataRequestSubmissionsGrid;
