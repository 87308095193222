import { Button, Link, Stack } from "@mui/material";
import qs from "qs";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { useClientContext } from "../../../../context/ClientContext";
import { getVariables } from "../../../../variables";

const getInvestorPortalImpersonationDebugUrl = (clientCode: string) =>
  `${getVariables().IR_PORTAL_URI}/${clientCode}?${qs.stringify({
    impersonation: true,
    user: "any",
    debug: true,
  })}`;

const InvestorPortalDevToolsPage = () => {
  usePageTitle("Investor Portal Dev Tools");

  const { clientCode, clientTitle } = useClientContext();

  const investorPortalUrl = getInvestorPortalImpersonationDebugUrl(clientCode);

  return (
    <Stack spacing={2}>
      <h2>Investor Portal Dev Tools - {clientTitle}</h2>

      <Button
        component={Link}
        href={investorPortalUrl}
        sx={{ width: 300 }}
        variant="outlined"
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        Inspect Investor Portal (debug)
      </Button>
    </Stack>
  );
};

export default InvestorPortalDevToolsPage;
