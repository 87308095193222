import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";

// Find the input element from the next row - it should appear when the cell acquires Edit mode
const selectInputElementInRow = (rowId: string) => {
  const rowElement = document.querySelector(`[data-id="${rowId}"]`);
  if (rowElement) {
    const input = rowElement.querySelector("input");
    if (input) {
      input.select();
    }
  }
};

export const makeNextEditableCellEdited = <RowModel extends GridValidRowModel>(
  direction: "next" | "previous",
  rows: RowModel[],
  columns: GridColDef<RowModel>[],
  cellParams: GridCellParams<RowModel>,
  setCellModesModel: React.Dispatch<React.SetStateAction<GridCellModesModel>>,
  isCellEditable: ({ row, field }: { row: RowModel; field: string }) => boolean,
  getRowId: (row: RowModel) => string
) => {
  const findNextEditableCellColumnIdInRow = (row: RowModel, startColIndex: number): string | undefined => {
    for (
      let colIndex = startColIndex;
      direction === "next" ? colIndex < columns.length : colIndex >= 0;
      colIndex = direction === "next" ? colIndex + 1 : colIndex - 1
    ) {
      const column = columns[colIndex];
      if (column?.editable && isCellEditable({ row, field: column.field })) {
        return column.field;
      }
    }

    return undefined;
  };

  const findNextEditableCellIds = (startRowIndex: number, startColIndex: number): [string, string] | undefined => {
    for (
      let rowIndex = startRowIndex;
      direction === "next" ? rowIndex < rows.length : rowIndex >= 0;
      rowIndex = direction === "next" ? rowIndex + 1 : rowIndex - 1
    ) {
      const row = rows[rowIndex];
      if (!row) {
        continue;
      }

      const startColumnIndex =
        rowIndex === startRowIndex ? startColIndex : direction === "next" ? 0 : columns.length - 1;

      const colId = findNextEditableCellColumnIdInRow(row, startColumnIndex);
      if (colId !== undefined) {
        return [getRowId(row), colId];
      }
    }

    return undefined;
  };

  const currentRowId = getRowId(cellParams.row);
  const currentColId = cellParams.colDef.field;

  const currentRowIndex = rows.findIndex((row) => getRowId(row) === currentRowId);
  const currentColIndex = columns.findIndex((col) => col.field === currentColId);
  if (currentRowIndex < 0 || currentColIndex < 0) {
    return;
  }

  const startColIndex = direction === "next" ? currentColIndex + 1 : currentColIndex - 1;

  const ids = findNextEditableCellIds(currentRowIndex, startColIndex);
  if (!ids) {
    return;
  }

  const [nextRowId, nextColId] = ids;

  setCellModesModel((prev) => {
    let result = { ...prev, [currentRowId]: { ...prev[currentRowId], [currentColId]: { mode: GridCellModes.View } } };
    result = { ...result, [nextRowId]: { ...result[nextRowId], [nextColId]: { mode: GridCellModes.Edit } } };
    return result;
  });

  setTimeout(() => selectInputElementInRow(nextRowId), 100);
};
