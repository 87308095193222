import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import { Box, Button, Stack } from "@mui/material";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import MultiSelectFilter, { MultiSelectOption } from "../../../../../shared/components/MultiSelectFilter";
import CompaniesIcon from "../../../../../shared/icons/CompaniesIcon";
import VisibilityIcon from "../../../../icons/VisibleIcon";
import { fundraisingStatusCaptionMap } from "../../../../utilities/enumCaptions";
import { FundraisingDocumentsFilterValue } from "./fundraisingDocumentsFiltering";

interface Props {
  categoryOptions: SelectOption[];
  fundOptions?: SelectOption[];
  investorOptions?: SelectOption[];
  filters: FundraisingDocumentsFilterValue;
  onChange: (newFilters: FundraisingDocumentsFilterValue) => void;
  onClear: () => void;
  isLoading?: boolean;
  recordsCount: number;
  totalRecordsCount: number;
}

interface SelectOption {
  value: string;
  label: string;
}

const FundStructureDocumentFilters = ({
  categoryOptions,
  fundOptions,
  investorOptions,
  filters,
  onChange,
  onClear,
  isLoading,
  recordsCount,
  totalRecordsCount,
}: Props) => {
  const statusOptions: MultiSelectOption<FundraisingStatus>[] = Object.entries(fundraisingStatusCaptionMap).map(
    ([value, label]) => ({ value, label })
  );

  const handleSearch = (searchTerm: string) => onChange({ ...filters, searchTerm });

  const handleInvestorsChange = (investorIds: string[]) => onChange({ ...filters, investorIds });

  const handleFundsChange = (fundIds: string[]) => onChange({ ...filters, fundIds });

  const handleCategoriesChange = (categoryIds: string[]) => onChange({ ...filters, categoryIds });

  const handleStatusesChange = (statuses: FundraisingStatus[]) => onChange({ ...filters, statuses });

  const showClearButton =
    filters.categoryIds.length + filters.fundIds.length + filters.investorIds.length + filters.statuses.length > 0;

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" flexWrap="wrap" rowGap={2}>
      <Stack alignItems="center" direction="row" spacing={1.5} mr={1}>
        <Box width="8rem">
          <RecordCounter records={recordsCount} totalRecords={totalRecordsCount} />
        </Box>
        {investorOptions && (
          <MultiSelectFilter
            sortOptions
            placeholder="Investors"
            options={investorOptions}
            selectedValues={filters.investorIds}
            onChange={handleInvestorsChange}
            StartIcon={PersonIcon}
            disabled={isLoading}
          />
        )}
        {fundOptions && (
          <MultiSelectFilter
            sortOptions
            placeholder="Funds"
            options={fundOptions}
            selectedValues={filters.fundIds}
            onChange={handleFundsChange}
            StartIcon={CompaniesIcon}
            disabled={isLoading}
          />
        )}
        <MultiSelectFilter
          placeholder="Category"
          options={categoryOptions}
          selectedValues={filters.categoryIds}
          onChange={handleCategoriesChange}
          StartIcon={DescriptionIcon}
          disabled={isLoading}
        />
        <MultiSelectFilter
          placeholder="Status"
          options={statusOptions}
          selectedValues={filters.statuses}
          onChange={handleStatusesChange}
          StartIcon={VisibilityIcon}
          disabled={isLoading}
        />
        {showClearButton && (
          <Button variant="text" onClick={onClear}>
            Clear All
          </Button>
        )}
      </Stack>
      <SearchField onSearch={handleSearch} debounceTimeMs={500} />
    </Stack>
  );
};

export default FundStructureDocumentFilters;
