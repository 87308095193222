import { useCallback, useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { Investor } from "../../../../../api/adminApi";
import { FieldIdToValueMap, ObjectClassDefinition } from "../../../../../api/types/objectTypes";
import EntitySection from "../../../../entityFields/EntitySection";
import FieldValuesManager from "../../../../entityFields/FieldValuesManager";

interface Props {
  investor: Investor;
  objectDefinition: ObjectClassDefinition;
  onInvestorDetailsChange: (details: Partial<Investor>) => void;
  hasPermissionsToManageFields: boolean;
}

const updateFieldValues = withErrorHandling(adminApi.updateInvestorFieldValues);

const InvestorCard = ({ investor, objectDefinition, onInvestorDetailsChange, hasPermissionsToManageFields }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [renderKey, setRenderKey] = useState(0);

  const getFieldValues = useCallback(() => adminApi.getInvestorFieldValues(investor.id), [investor.id]);
  const [fieldValuesResp, fieldValuesError, { setData: setFieldValues, isFetching }] = useFetch(getFieldValues);

  const saveFieldValues = async (fieldValues: FieldIdToValueMap) => {
    const values = Object.entries(fieldValues).map(([fieldId, value]) => ({ fieldId, value }));
    const [resp, error] = await updateFieldValues(investor.id, { values });
    if (error) {
      sendNotificationError(getErrorMessage(error) || "Failed to save changes");
      logError(error, `[InvestorDetailsSection] saveFieldValues`);
      return false;
    }

    sendNotification("Changes saved");
    setFieldValues(resp);
    setRenderKey((prev) => prev + 1);

    const nameFieldValue = resp.values.find(({ fieldId }) => fieldId.toLowerCase() === "name");
    const title = nameFieldValue?.value?.toString() || investor.title;
    onInvestorDetailsChange({
      title,
      updateAt: new Date().toISOString(),
    });

    return true;
  };

  if (fieldValuesError) {
    logError(fieldValuesError, "[InvestorDetailsSection]");
    return <DataLoadingFailed bgColor="none" title="Loading investor details" />;
  }

  const fieldValues = fieldValuesResp
    ? Object.fromEntries(fieldValuesResp.values.map(({ fieldId, value }) => [fieldId, value]))
    : {};

  return (
    <EntitySection isLoading={isFetching}>
      {fieldValuesResp && (
        <FieldValuesManager
          key={renderKey}
          objectDefinition={objectDefinition}
          fieldValues={fieldValues}
          hasPermissionsToManageFields={hasPermissionsToManageFields}
          saveFieldValues={saveFieldValues}
        />
      )}
    </EntitySection>
  );
};

export default InvestorCard;
