import { TabPanel } from "@mui/lab";
import { useCallback, useReducer, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import adminApi, { Contact, ContactWithFieldValues } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import TabLabelWithCount from "../../../common/TabLabelWithCount";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import { createReducer, getInitialState } from "../../../common/filters/filterState";
import ContactsActionsToolbar from "./ContactsActionsToolbar";
import ContactsGrid from "./ContactsGrid";
import DeleteContactDialog from "./DeleteContactDialog";
import InviteContactsDialog from "./InviteContactsDialog";
import { ContactsTab, contactsTabs } from "./contactsGridDefinitions";
import { getFilterDefinitionsForAll, getSearchDefinition } from "./filters/filterDefinitions";
import { getNotInvitedContacts } from "./filters/filtering";

interface DialogState {
  contactIdsToInvite?: string[];
  contactToDelete?: Contact;
  openDialog: "invite_contacts" | "delete_contact" | undefined;
}

const tabPanelSx = { height: "100%", width: "100%", m: 0, px: 3, py: 2.5 };

const FundStructureContactsPage = () => {
  usePageTitle("Contacts");

  const { clientCode } = useClientContext();

  const [dialogState, setDialogState] = useState<DialogState>({ openDialog: undefined });

  const [tab, setTab] = useState<ContactsTab>("all");

  const [filterStateAll, dispatchFiltersAll] = useReducer(
    createReducer<ContactWithFieldValues>(),
    getInitialState(`${clientCode}_contacts_filter_v2`, getFilterDefinitionsForAll(), getSearchDefinition(), [])
  );

  const [filterStateNotInvited, dispatchFiltersNotInvited] = useReducer(
    createReducer<ContactWithFieldValues>(),
    getInitialState(
      `${clientCode}_not_invited_contacts_filter_v2`,
      getFilterDefinitionsForAll(),
      getSearchDefinition(),
      []
    )
  );

  const getContacts = useCallback(
    () => adminApi.searchContacts({ fieldIds: [], includeFundInvestors: true, includeInboxData: true }),
    []
  );

  const [contactsResp, contactsFetchError, { isFetching, fetch: fetchContacts, setData: setContactsResp }] =
    useFetch(getContacts);

  if (contactsFetchError) {
    logError(contactsFetchError, "[FundStructureContactsPage]");
    return <DataLoadingFailed title="Failed to load contacts" />;
  }

  const contacts = contactsResp?.items ?? [];
  const isLoading = isFetching || contactsResp === undefined;

  const handleDeleteContact = (contact: Contact) => {
    setDialogState({ openDialog: "delete_contact", contactToDelete: contact });
  };

  const handleContactDeleted = (contactId: string) => {
    setDialogState({ openDialog: undefined });

    if (contactsResp) {
      setContactsResp({ items: contacts.filter(({ id }) => id !== contactId) });
    }
  };

  const handleTabChange = (newTab: ContactsTab) => setTab(newTab);

  const notInvitedContacts = getNotInvitedContacts(contacts);

  const labels = [
    "All Contacts",
    <TabLabelWithCount
      key="not_invited"
      label="Not Invited"
      count={notInvitedContacts.length}
      total={notInvitedContacts.length}
      isActive={tab === "notInvited"}
      hideCounter={isLoading}
    />,
  ];

  const handleInviteContacts = (contactIds: string[]) => {
    setDialogState({ openDialog: "invite_contacts", contactIdsToInvite: contactIds });
  };

  const handleContactsInvitationSent = () => {
    setDialogState({ openDialog: undefined });
    fetchContacts();
  };

  return (
    <>
      <GeneralPageHeader title="Contacts" hideBorderLine>
        <ContactsActionsToolbar contacts={contacts} isLoading={isLoading} />
      </GeneralPageHeader>
      <PageTabs tabs={contactsTabs} labels={labels} value={tab} onChange={handleTabChange}>
        <TabPanel value="all" sx={tabPanelSx}>
          <FilterContextProvider
            filterState={filterStateAll}
            dispatchFilters={dispatchFiltersAll}
            allRowsForSelectOptions={contacts}
          >
            <ContactsGrid
              allRows={contacts}
              isLoading={isLoading}
              onInviteContacts={handleInviteContacts}
              onDeleteContact={handleDeleteContact}
            />
          </FilterContextProvider>
        </TabPanel>
        <TabPanel value="notInvited" sx={tabPanelSx}>
          <FilterContextProvider
            filterState={filterStateNotInvited}
            dispatchFilters={dispatchFiltersNotInvited}
            allRowsForSelectOptions={notInvitedContacts}
          >
            <ContactsGrid
              allRows={notInvitedContacts}
              excludedColumns={["invitationSentAt", "lastActivity"]}
              isLoading={isLoading}
              onInviteContacts={handleInviteContacts}
              onDeleteContact={handleDeleteContact}
            />
          </FilterContextProvider>
        </TabPanel>
      </PageTabs>
      <InviteContactsDialog
        open={dialogState.openDialog === "invite_contacts"}
        onClose={() => setDialogState({ openDialog: undefined })}
        onSubmit={handleContactsInvitationSent}
        contactIds={dialogState.contactIdsToInvite ?? []}
      />
      {dialogState.contactToDelete && (
        <DeleteContactDialog
          open={dialogState.openDialog === "delete_contact"}
          onClose={() => setDialogState({ openDialog: undefined })}
          onDeleted={handleContactDeleted}
          contact={dialogState.contactToDelete}
        />
      )}
    </>
  );
};

export default FundStructureContactsPage;
