import SendIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import { Button, ListItem, Stack, TextField, Typography } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useCallback, useState } from "react";
import { createApiResponse, getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import AutocompleteCreatable from "../../../../../../shared/components/AutocompleteCreatable";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import {
  combineValidators,
  emailValidator,
  requiredValidator,
  validResult,
} from "../../../../../../shared/utilities/validators";
import adminApi from "../../../../../api/adminApi";
import { Fundraising, TestEmailContact } from "../../../../../api/types/fundraisingTypes";
import { useUserContext } from "../../../../../context/UserContext";
import EmailTemplateEditor, { EmailTemplateSettings } from "../../../../common/email-templates/EmailTemplateEditor";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);
const sendTestFundraisingEmail = withErrorHandling(adminApi.sendTestFundraisingEmail);

const snippets = [{ value: "{{RecipientName}}", displayName: "Recipient Name" }];

const defaultSubject = "You've been invited to access the Fundraising Data Room";

const getInitialSettings = (fundraising: Fundraising) => ({
  subject: fundraising.notificationEmailSettings?.subject ?? defaultSubject,
  body: fundraising.notificationEmailSettings?.body ?? "",
  cc: fundraising.notificationEmailSettings?.recipientsCC ?? "",
  bcc: fundraising.notificationEmailSettings?.recipientsBCC ?? "",
});

const validateEmail = combineValidators(requiredValidator, emailValidator);

const splitCommaSeparatedString = (str: string) =>
  str
    .split(",")
    .map((s) => s.trim())
    .filter(Boolean);

const validateCommaSeparatedEmailString = (str: string) => {
  const emails = splitCommaSeparatedString(str);
  for (const email of emails) {
    const result = emailValidator(email);
    if (!result.isValid) {
      return result;
    }
  }

  return validResult();
};

const validateSettings = (settings: EmailTemplateSettings) => {
  return {
    subject: requiredValidator(settings.subject),
    body: requiredValidator(settings.body),
    cc: validateCommaSeparatedEmailString(settings.cc),
    bcc: validateCommaSeparatedEmailString(settings.bcc),
  };
};

const FundraisingNotificationsConfig = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { isContentEditable, hasEditPermissions, fundraising, onUpdated } = useFundraisingDetailsPageContext();
  const { email: currentUserEmail } = useUserContext();

  const getTestEmailContactsForFundraising = useCallback(
    () =>
      hasEditPermissions
        ? adminApi.getTestEmailContactsForFundraising(fundraising.id)
        : Promise.resolve(createApiResponse({ contacts: [] })),
    [fundraising.id, hasEditPermissions]
  );

  const [testInvitationContactsResp, fetchTestContactsError] = useFetch(getTestEmailContactsForFundraising);

  const [currentSettings, setCurrentSettings] = useState<EmailTemplateSettings>(getInitialSettings(fundraising));
  const [isSaving, setSaving] = useState(false);
  const [testEmailAddress, setTestEmailAddress] = useState(currentUserEmail);
  const [selectedContact, setSelectedContact] = useState<TestEmailContact>();
  const [isSending, setSending] = useState(false);

  if (fetchTestContactsError) {
    logError(fetchTestContactsError, "[FundraisingNotificationsConfig] getTestEmailContactsForFundraising");
    return <DataLoadingFailed bgColor="none" title="Failed to load test email contacts" />;
  }

  if (testInvitationContactsResp === undefined) {
    return <InlineLoader />;
  }

  const handleSettingsChange = (update: Partial<EmailTemplateSettings>) =>
    setCurrentSettings((prev) => ({
      ...prev,
      ...update,
    }));

  const handleSave = async () => {
    setSaving(true);

    const [updatedFundraising, error] = await updateFundraising(fundraising.id, {
      notificationEmailSettings: {
        subject: currentSettings.subject.trim(),
        body: currentSettings.body.trim(),
        recipientsCC: splitCommaSeparatedString(currentSettings.cc).join(","),
        recipientsBCC: splitCommaSeparatedString(currentSettings.bcc).join(","),
      },
    });

    setSaving(false);

    if (error) {
      logError(error, "[FundraisingNotificationsConfig]");
      sendNotificationError("Could not update notification settings");
      return;
    }

    onUpdated(updatedFundraising);
    sendNotification("Notification settings updated successfully");
  };

  const handleReset = () => setCurrentSettings(getInitialSettings(fundraising));

  const handleSendTestEmailButtonClick = async () => {
    if (!selectedContact) {
      return;
    }

    setSending(true);

    const [, error] = await sendTestFundraisingEmail(fundraising.id, {
      recipientEmail: testEmailAddress,
      contactId: selectedContact.id,
    });

    setSending(false);

    if (error) {
      logError(error, "[SendTestInvitationEmail]");
      sendNotificationError(`Failed to send test email: ${getErrorMessage(error)}`);
      return;
    }

    sendNotification(`Test invitation email sent to ${testEmailAddress}`);
  };

  const isDirty = !deepEqual(getInitialSettings(fundraising), currentSettings);

  const settingsValidationResults = validateSettings(currentSettings);

  const areSettingsValid = Object.values(settingsValidationResults).every((result) => result.isValid);

  const testEmailValidationResult = validateEmail(testEmailAddress);

  const testEmailContacts = testInvitationContactsResp.contacts;

  return (
    <Stack spacing={2.5} flex={1} pt={2.5}>
      <Stack spacing={0.5} pt={2}>
        <Typography variant="subtitle1">Notifications</Typography>
        <Typography variant="caption" color="text.secondary">
          Send notification emails when the fundraising goes live.
        </Typography>
      </Stack>

      <Stack spacing={2} pt={2}>
        <Typography variant="subtitle1">Email</Typography>

        <EmailTemplateEditor
          snippets={snippets}
          settings={currentSettings}
          onChange={handleSettingsChange}
          disabled={!isContentEditable}
          validationResults={isDirty ? settingsValidationResults : {}}
        />

        <Stack direction="row" spacing={1} pt={1.5}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!isDirty || !areSettingsValid || !isContentEditable}
            loading={isSaving}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
          <Button variant="text" color="secondary" disabled={!isDirty || !isContentEditable} onClick={handleReset}>
            Cancel
          </Button>
        </Stack>
      </Stack>

      {testEmailContacts.length > 0 && (
        <Stack spacing={2} width="100%">
          <Typography variant="subtitle1" pt={2}>
            Test Email
          </Typography>

          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              value={testEmailAddress}
              onChange={({ target }) => setTestEmailAddress(target.value)}
            />

            <AutocompleteCreatable
              withLazyRendering
              noItemsText="No contacts found"
              openOnFocus
              fullWidth
              options={testEmailContacts}
              getOptionLabel={(o) => `${o.fullName} <${o.email}>`}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">{option.fullName}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {option.email}
                    </Typography>
                  </Stack>
                </ListItem>
              )}
              isOptionEqualToValue={(opt, val) => opt.email === val.email}
              renderInput={(params) => <TextField {...params} label="Preview notification as" />}
              value={selectedContact ?? null}
              onChange={(_, newValue) => {
                setSelectedContact(newValue ?? undefined);
              }}
            />
          </Stack>

          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isSending}
            endIcon={<SendIcon />}
            disabled={isDirty || !areSettingsValid || !testEmailValidationResult.isValid || !selectedContact}
            onClick={handleSendTestEmailButtonClick}
            sx={{ width: "7.5rem" }}
          >
            Send Email
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
};

export default FundraisingNotificationsConfig;
