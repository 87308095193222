import CheckIcon from "@mui/icons-material/Check";
import { ListItemIcon, MenuItem, Select, Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { OrganizationRole } from "../../../../../api/types/userManagementTypes";
import OrganizationRoleStatusTag from "../OrganizationRoleStatusTag";
import { organizationRolesDataMap } from "../definitions";
import { useInviteMembersContext } from "./InviteMembersContext";

interface Option {
  value: OrganizationRole;
  text: string;
  description: string;
}

interface RoleItemProps {
  isSelected: boolean;
  value: OrganizationRole;
  description: string;
}

const options: Option[] = Object.entries(organizationRolesDataMap).map(([key, value]) => ({
  value: key as OrganizationRole,
  text: value.label,
  description: value.description,
}));

const RoleItem = ({ value, description, isSelected }: RoleItemProps) => (
  <Stack spacing={1} sx={{ mt: 1, width: "100%" }}>
    <Stack justifyContent="space-between" alignItems="center" direction="row">
      <OrganizationRoleStatusTag role={value} />
      {isSelected && (
        <ListItemIcon sx={{ minWidth: "unset", color: green[500] }}>
          <CheckIcon />
        </ListItemIcon>
      )}
    </Stack>
    <Typography sx={{ pl: 0.25, color: (theme) => `${theme.palette.text.secondary} !important` }} variant="caption">
      {description}
    </Typography>
  </Stack>
);

const OrganizationRoleSelector = () => {
  const { organizationRole, updateOrganizationRole, allowedOrganizationRoles } = useInviteMembersContext();

  const renderValue = (selected: string) => {
    const selectedOption = options.find((option) => option.value === selected);
    return selectedOption ? (
      <Typography>{selectedOption.text}</Typography>
    ) : (
      <Typography color="text.secondary">Select user role</Typography>
    );
  };

  const roleOptions = options.filter((op) => allowedOrganizationRoles.includes(op.value));

  return (
    <Select
      fullWidth
      displayEmpty
      value={organizationRole || ""}
      onChange={(e) => updateOrganizationRole(e.target.value as OrganizationRole)}
      renderValue={renderValue}
    >
      {roleOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <RoleItem key={option.value} {...option} isSelected={organizationRole === option.value} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrganizationRoleSelector;
