import { Stack, Typography } from "@mui/material";
import { JSX, useState } from "react";
import BadgeDetached from "./BadgeDetached";
import ItemsListPopover from "./ItemsListPopover";
import TypographyTooltipEllipsis from "./TypographyTooltipEllipsis";

interface Props<T> {
  items: T[];
  propGetter: (item: T) => string;
  listItemPropGetter?: (item: T, index?: number) => string | JSX.Element;
  popupTitle?: string;
  displayCount?: number;
  justify?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";
  sliceFirst?: boolean;
  sortComparer?: (a: string, b: string) => number;
}

const renderProp = (prop: string | JSX.Element, index: number) => {
  if (typeof prop === "string") {
    return prop ? (
      <Typography noWrap key={index}>
        {prop}
      </Typography>
    ) : (
      "_"
    );
  }
  return prop;
};

const InlineItemsList = <T,>({
  items: initialItems,
  popupTitle,
  displayCount,
  listItemPropGetter,
  propGetter,
  justify,
  sliceFirst = true,
  sortComparer = (a, b) => a.localeCompare(b),
}: Props<T>) => {
  const [popUpAnchorEl, setPopUpAnchorEl] = useState<HTMLElement | undefined>();

  const items = [...initialItems].sort((a, b) => sortComparer(propGetter(a), propGetter(b)));

  if (items.length === 0) {
    return <Typography>-</Typography>;
  }

  const toggleShowPopover = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setPopUpAnchorEl((prev) => (prev ? undefined : event.currentTarget));
  };

  const displayedCount = displayCount || 1;
  const displayItemTexts = items.slice(0, displayedCount).map(propGetter);
  const showCounter = items.length > displayedCount;

  return (
    <Stack
      justifyContent={justify ?? "space-between"}
      direction="row"
      alignItems="center"
      gap={1}
      sx={{ width: "100%", flexWrap: "nowrap" }}
    >
      <TypographyTooltipEllipsis text={displayItemTexts.join(", ")} />
      {showCounter && (
        <BadgeDetached
          color="secondary"
          badgeContent={`${sliceFirst ? "+" : ""}${sliceFirst ? items.length - displayedCount : items.length}`}
          onClick={toggleShowPopover}
          sx={{ cursor: "pointer" }}
        />
      )}
      <ItemsListPopover anchorEl={popUpAnchorEl} onClose={() => setPopUpAnchorEl(undefined)}>
        <Stack spacing={1} sx={{ p: 1 }} maxWidth="25rem">
          {popupTitle && (
            <Typography color="textSecondary" variant="subtitle2">
              {popupTitle}
            </Typography>
          )}
          {items
            .slice(sliceFirst ? displayCount : 0)
            .map((prop, index) => (listItemPropGetter ? listItemPropGetter(prop, index) : propGetter(prop)))
            .map((prop, index) => renderProp(prop, index))}
        </Stack>
      </ItemsListPopover>
    </Stack>
  );
};

export default InlineItemsList;
