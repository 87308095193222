import { ObjectClassDefinition } from "../../../../api/types/objectTypes";

export const getRecipientCategoryTypes = (objectDefinition: ObjectClassDefinition) =>
  [objectDefinition.objectContactCategoryType, objectDefinition.internalUserPermissionsCategoryType].filter(
    Boolean
  ) as string[];

export const formatRecipientCategoryType = (type: string, objectDefinition: ObjectClassDefinition) => {
  const { objectContactCategoryType, internalUserPermissionsCategoryType } = objectDefinition;

  if (type === objectContactCategoryType) {
    return "Portfolio Contacts";
  }

  if (type === internalUserPermissionsCategoryType) {
    return "Internal Team";
  }

  return type;
};
