import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import HorizontalFill from "../../../../shared/components/HorizontalFill";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../shared/logging";
import { defined } from "../../../../shared/utilities/typeHelper";
import adminApi from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import GeneralPageFooter from "../../common/GeneralPageFooter";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import DeleteDataImportDialog from "./DeleteDataImportDialog";
import FullPageStatusProgress from "./FullPageStatusProgress";
import StartImportConfirmationDialog from "./StartImportConfirmationDialog";
import ReviewImport from "./csv-import/review-import/ReviewImport";
import ReviewImportActions from "./csv-import/review-import/ReviewImportActions";
import { useApplyDataImport, useValidateDataImport } from "./dataImportOperations";
import ImportDataDetailsTitle from "./details/ImportDataDetailsTitle";
import { OperationStatus, StartDataImportOptions } from "./importDataPagesTypes";
import { getPermissionsToManageImport, isImportApplicationAllowed, isImportEditable } from "./importDataStateHelper";

interface DialogState {
  openDialog?: "start_import" | "delete_import";
  errorsCount?: number;
}

interface OperationError {
  title: string;
  details: string;
}

const getDataImportChangesTotals = withErrorHandling(adminApi.getDataImportChangesTotals);

const ImportDataReviewPage = () => {
  usePageTitle("Data Import");

  const { id } = useParams();
  const { clientCode, hasPermissions } = useClientContext();
  const { sendNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [validateImportStatus, setValidateImportStatus] = useState<OperationStatus>();
  const [applyImportStatus, setApplyImportStatus] = useState<OperationStatus>();
  const [operationError, setOperationError] = useState<OperationError>();
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getImportDetails = useCallback(() => adminApi.getDataImportDetails(defined(id)), [id]);
  const getMetadata = useCallback(() => adminApi.getDataImportMetadata(defined(id)), [id]);

  const validateDataImportWithTimeout = useValidateDataImport((status) => {
    setValidateImportStatus(status);
  });

  const applyDataImportWithTimeout = useApplyDataImport((status) => {
    setApplyImportStatus(status);
  });

  const [dataImport, fetchImportError, { isFetching: isFetchingImportDetails, setData: setDataImport }] =
    useFetch(getImportDetails);

  const [
    dataImportMetadata,
    fetchMetadataError,
    { isFetching: isFetchingImportMetadata, setData: setDataImportMetadata },
  ] = useFetch(getMetadata);

  if (fetchImportError || fetchMetadataError) {
    logError(fetchImportError || fetchMetadataError, "[ImportCsvDataReviewPage]");
    return <DataLoadingFailed title="Loading data import details failed" />;
  }

  if (
    dataImport === undefined ||
    dataImportMetadata === undefined ||
    isFetchingImportDetails ||
    isFetchingImportMetadata
  ) {
    return <InlineLoader />;
  }

  if (validateImportStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="We are validating the changes. This may take some time."
        operationStatus={validateImportStatus}
      />
    );
  }

  if (applyImportStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="We are applying the changes. This may take some time."
        operationStatus={applyImportStatus}
      />
    );
  }

  const validateImport = async () => {
    const [result, error] = await validateDataImportWithTimeout(defined(id));
    setValidateImportStatus(undefined);

    if (result !== undefined) {
      setOperationError(undefined);
      setDataImport(result.dataImport);
      setDataImportMetadata(result.dataImportMetadata);
    } else if (!error.isCanceledRequest) {
      logError(error, "[ImportDataReviewPage] validateDataImportWithTimeout");
      setOperationError({ title: "Validation Error", details: error.message });
    }
  };

  const applyImport = async (options: StartDataImportOptions) => {
    const [success, error] = await applyDataImportWithTimeout(defined(id), options);
    setApplyImportStatus(undefined);

    if (success) {
      setOperationError(undefined);
      sendNotification("Data import has been applied successfully");
      navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`);
    } else if (!error.isCanceledRequest) {
      logError(error, "[ImportDataReviewPage] applyDataImportWithTimeout");
      setOperationError({ title: "Apply Import Error", details: error.message });
    }
  };

  const handleStartImportButtonClick = async () => {
    const [resp, totalsFetchError] = await getDataImportChangesTotals(dataImport.id);
    if (totalsFetchError) {
      logError(totalsFetchError, "[ImportDataReviewPage] getDataImportChangesTotals");
      setOperationError({ title: "Apply Import Error", details: "Could not determine records' statuses" });
      return;
    }

    setDialogState({ openDialog: "start_import", errorsCount: resp.errorsCount });
  };

  const handleConfirmImport = async (options: StartDataImportOptions) => {
    setDialogState({});
    await applyImport(options);
  };

  const handleDeleteImport = () => {
    setDialogState({ openDialog: "delete_import" });
  };

  const handleImportDeleted = () => {
    setDialogState({});
    navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`);
  };

  const backButtonPath = isImportEditable(dataImport.state)
    ? `/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`
    : `/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}/${dataImport.id}/${pageRoutes.importDetails}`;

  const hasPermissionsToManage = hasPermissions(getPermissionsToManageImport(dataImport.entriliaProductAreas));

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<ImportDataDetailsTitle dataImportDetails={dataImport} />}
        showDefaultBackButtonTo={backButtonPath}
      >
        <ReviewImportActions
          dataImport={dataImport}
          showDownload
          showValidate={hasPermissionsToManage && isImportEditable(dataImport.state)}
          showDelete={hasPermissionsToManage}
          onValidate={validateImport}
          onDelete={handleDeleteImport}
        />
      </GeneralPageHeader>

      {operationError && (
        <Box pt={2} pb={1} px={2.5} width="30rem">
          <Alert severity="error" onClose={() => setOperationError(undefined)}>
            <AlertTitle>{operationError?.title}</AlertTitle>
            <Typography>{operationError?.details}</Typography>
          </Alert>
        </Box>
      )}

      <ReviewImport dataImport={dataImport} dataImportMetadata={dataImportMetadata} />

      {hasPermissionsToManage && (
        <GeneralPageFooter>
          <HorizontalFill />
          <Button
            variant="contained"
            onClick={handleStartImportButtonClick}
            disabled={applyImportStatus || !isImportApplicationAllowed(dataImport.state)}
          >
            Start Import
          </Button>
        </GeneralPageFooter>
      )}

      {dialogState.openDialog === "start_import" && (
        <StartImportConfirmationDialog
          dataImport={dataImport}
          onClose={() => setDialogState({})}
          onConfirm={handleConfirmImport}
          errorsCount={dialogState.errorsCount ?? 0}
        />
      )}

      {dialogState.openDialog === "delete_import" && (
        <DeleteDataImportDialog
          dataImport={dataImport}
          onClose={() => setDialogState({})}
          onConfirm={handleImportDeleted}
        />
      )}
    </>
  );
};

export default ImportDataReviewPage;
