/* eslint-disable no-console */
import { IDependencyInitializerDetails } from "@microsoft/applicationinsights-dependencies-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, IConfig } from "@microsoft/applicationinsights-web";
import { ApiError } from "./api/types";
import { getSharedVariables } from "./variables";

let appInsights: ApplicationInsights | undefined = undefined;

export const appInsightsReactPlugin = new ReactPlugin();

export const initAppInsights = (
  config: IConfig,
  dependencyInitializerHandler?: (details: IDependencyInitializerDetails) => boolean | void
) => {
  if (appInsights) {
    return;
  }

  const appInsightsConnString = getSharedVariables().APP_INSIGHTS_CONN_STRING;

  if (!appInsightsConnString) {
    console.error("Missing app insights connection string");
    return;
  }

  appInsights = new ApplicationInsights({
    config: {
      ...config,
      connectionString: appInsightsConnString,
      enableAutoRouteTracking: false,
      extensions: [appInsightsReactPlugin],
      extensionConfig: {
        ["AppInsightsCfgSyncPlugin"]: {
          cfgUrl: "", // Prevent loading config from default CDN (js.monitor.azure.com/scripts/b/ai.config.1.cfg.json)
        },
      },
    },
  });

  if (dependencyInitializerHandler) {
    appInsights.addDependencyInitializer(dependencyInitializerHandler);
  }

  try {
    appInsights.loadAppInsights();
  } catch (error) {
    console.error("Failed to load app insights", error);
  }
};

const getErrorInstance = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  if (typeof error === "string") {
    return new Error(error);
  }
  return new Error(JSON.stringify(error));
};

const excludeErrorFromTracking = (error: unknown) =>
  typeof error === "object" &&
  ((error as ApiError)?.isCanceledRequest || (error as Error)?.message?.includes("Network Error"));

export const logError = (error: unknown, context: string) => {
  if (excludeErrorFromTracking(error)) {
    console.warn(context, error);
    return;
  }

  console.error(context, error);

  if (appInsights) {
    appInsights.trackException(
      {
        exception: getErrorInstance(error),
      },
      {
        context,
        browserUrl: window.location.href,
      }
    );
  }
};
