import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiResponse } from "../../../../../shared/api/types";
import useFetch from "../../../../../shared/hooks/useFetch";
import sharedBiClient from "../../../../../shared/reporting/api/biClient";
import {
  ChangeReportGroupRequest,
  DeleteReportItem,
  RenameReportRequest,
  ReportAuthorizationInfo,
  ReportInfo,
  ReportUsage,
  ValidateReportNameRequest,
} from "../../../../../shared/reporting/api/biClient.types";
import { FromReport } from "../../../../../shared/reporting/components/reports/FromReport";
import biClient, { ApiReportsApiClient, UiReportsApiClient } from "../../../../api/biApi";

export default function useReports<T extends ReportInfo>(
  loadReports: () => Promise<ApiResponse<T[]>>,
  reportUsage: ReportUsage
) {
  const [reports, reportsError, { fetch: reloadReports }] = useFetch(loadReports);
  const [allReports, setAllReports] = useState<ReportInfo[]>([]);
  const apiClient = useMemo((): UiReportsApiClient | ApiReportsApiClient => getClient(reportUsage), [reportUsage]);

  useEffect(() => {
    if (reports) {
      setAllReports(reports);
    }
  }, [reports]);

  const duplicateReport = useCallback(
    async (report: FromReport, name: string, groupId: string | undefined) => {
      const resp = await apiClient.duplicateReport({
        clientCode: report.clientCode,
        reportId: report.reportId,
        name,
        groupId,
      });
      if (resp.success) {
        await reloadReports();
        return resp.data;
      }
      throw new Error(resp.error?.message);
    },

    [reloadReports, apiClient]
  );

  const deleteReports = useCallback(
    async (reports: DeleteReportItem[]) => {
      const resp = await apiClient.deleteReports(reports);
      if (resp.success) {
        await reloadReports();
        return resp;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReports, apiClient]
  );

  const renameReport = useCallback(
    async (request: RenameReportRequest) => {
      const resp = await apiClient.renameReport(request);
      if (resp.success) {
        await reloadReports();
        return resp.data;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReports, apiClient]
  );

  const changeReportGroup = useCallback(
    async (request: ChangeReportGroupRequest) => {
      const resp = await apiClient.changeReportGroup(request);
      if (resp.success) {
        await reloadReports();
        return resp.data;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReports, apiClient]
  );

  const updateReportAuthorizationInfo = useCallback(async (reportId: string, info: ReportAuthorizationInfo) => {
    await Promise.resolve();
    setAllReports((reports) =>
      reports.map((report) => {
        if (report.reportId === reportId) {
          report.authorization = info;
        }
        return report;
      })
    );
  }, []);

  const generateApiReport = useCallback(
    async (report: FromReport, name: string) => {
      if (isUiReportsApiClient(apiClient)) {
        const resp = await apiClient.generateApiReport({
          clientCode: report.clientCode,
          reportId: report.reportId,
          name,
        });
        if (resp.success) {
          return resp.data;
        }
        throw new Error(resp.error?.message);
      } else {
        throw new Error("This client does not support generating API reports");
      }
    },

    [apiClient]
  );

  const checkUniqueReportNameByUsage = useCallback(async (request: ValidateReportNameRequest, usage: ReportUsage) => {
    const response = await sharedBiClient.validateUniqueReportNameByUsage(request, usage);
    if (response.success) {
      return response.data.nameExists !== true;
    }
    throw new Error(response.error?.message);
  }, []);

  return {
    reports: allReports ?? [],
    ui: {
      error: reportsError ? "Loading reports failed" : undefined,
      loaded: reports !== undefined || reportsError !== undefined,
    },
    actions: {
      duplicateReport,
      deleteReports,
      renameReport,
      reloadReports,
      changeReportGroup,
      updateReportAuthorizationInfo,
      generateApiReport,
      checkUniqueReportNameByUsage,
    },
  };
}

const isUiReportsApiClient = (client: UiReportsApiClient | ApiReportsApiClient): client is UiReportsApiClient => {
  return "generateApiReport" in client;
};

const getClient = (reportUsage: ReportUsage) => {
  return reportUsage === "Api" ? biClient.apiReports : biClient.uiReports;
};
