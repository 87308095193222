import EditIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ObjectAccessCategory } from "../../../../api/types/objectTypes";
import { useDataRequestSetupPageContext } from "../data-request-templates/DataRequestSetupPageContext";
import { formatRecipientCategoryType } from "./objectCategoriesFormatter";

const RecipientTypeCell = ({ row }: { row: ObjectAccessCategory }) => {
  const { objectDefinition } = useDataRequestSetupPageContext();
  return <>{formatRecipientCategoryType(row.type, objectDefinition)}</>;
};

const ActionsCell = () => {
  const { hasPermissionsToManage } = useDataRequestSetupPageContext();

  if (!hasPermissionsToManage) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" arrow>
        <IconButton>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const columnDefinitions: GridColDef<ObjectAccessCategory>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Recipient Type",
    flex: 1,
    renderCell: ({ row }) => <RecipientTypeCell row={row} />,
  },
  {
    field: "actions",
    headerName: "",
    width: 32,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: () => <ActionsCell />,
  },
];
