import { useCallback, useState } from "react";
import { useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import FullPageStatusProgress from "../FullPageStatusProgress";
import { useRollbackDataImport } from "../dataImportOperations";
import { OperationStatus } from "../importDataPagesTypes";
import { getPermissionsToManageImport } from "../importDataStateHelper";
import ImportDataDetailsActions from "./ImportDataDetailsActions";
import ImportDataDetailsSection from "./ImportDataDetailsSection";
import ImportDataDetailsTitle from "./ImportDataDetailsTitle";

const ImportDataDetailsPage = () => {
  usePageTitle("Data Import");

  const { id } = useParams();
  const { clientCode, hasPermissions } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [rollbackStatus, setRollbackStatus] = useState<OperationStatus>();
  const rollbackImportWithTimeout = useRollbackDataImport((status) => setRollbackStatus(status));

  const getDetails = useCallback(() => adminApi.getDataImportDetails(defined(id)), [id]);
  const getSourceFiles = useCallback(() => adminApi.getDataImportSourceFiles(defined(id)), [id]);

  const [dataImportDetails, fetchDetailsError, { fetch: fetchDetails, isFetching }] = useFetch(getDetails);
  const [sourceFiles, fetchFilesError] = useFetch(getSourceFiles);

  if (fetchDetailsError || fetchFilesError) {
    logError(fetchDetailsError || fetchFilesError, "[ImportDataDetailsPage]");
    return <DataLoadingFailed title="Loading data import details failed" />;
  }

  if (dataImportDetails === undefined || sourceFiles === undefined || isFetching) {
    return <InlineLoader />;
  }

  if (rollbackStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="We are applying the rollback. This may take some time."
        operationStatus={rollbackStatus}
      />
    );
  }

  const handleRollback = async () => {
    const [success, error] = await rollbackImportWithTimeout(dataImportDetails.id);
    setRollbackStatus(undefined);

    if (success) {
      sendNotification("Data import rollback has been completed successfully");
      await fetchDetails();
    } else if (!error.isCanceledRequest) {
      logError(error, "[ImportDataDetailsPage] rollbackImport");
      sendNotificationError(`Error occurred when applying data import rollback: ${error.message}`);
    }
  };

  const hasPermissionsToManage = hasPermissions(getPermissionsToManageImport(dataImportDetails.entriliaProductAreas));

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<ImportDataDetailsTitle dataImportDetails={dataImportDetails} />}
        showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`}
      >
        <ImportDataDetailsActions
          showRollback={
            hasPermissionsToManage && dataImportDetails.rollbackCreated && dataImportDetails.state === "Applied"
          }
          onRollback={handleRollback}
        />
      </GeneralPageHeader>
      <ImportDataDetailsSection dataImport={dataImportDetails} sourceFiles={sourceFiles} onRefresh={fetchDetails} />
    </>
  );
};

export default ImportDataDetailsPage;
