import { formatDateTimeShort } from "../../../../../shared/utilities/dateUtils";
import { InvestorPortalWatermarkSettings, WatermarkSegment } from "../../../../api/types/investorPortalTypes";

export interface WatermarkSettingsState {
  isDirty: boolean;
  watermarkEnabled: boolean;
  categoriesOption: WatermarkCategoriesOption;
  externalCategoryIds: string[];
  items: {
    customText: WatermarkItem;
    contactName: WatermarkItem;
    contactEmail: WatermarkItem;
    investorName: WatermarkItem;
    dateAndTime: WatermarkItem;
  };
}

export interface WatermarkItem {
  id: WatermarkSegment;
  value: string;
  visibility: boolean;
}

export type WatermarkCategoriesOption = "all_categories" | "selected_categories";

export type WatermarkItemKey = keyof WatermarkSettingsState["items"];

export interface WatermarkSettingsStateValidationResult {
  isValid: boolean;
  customTextError?: string;
  enabledItemsError?: string;
  documentCategoriesError?: string;
}

export const getWatermarkSettingsStateFromSavedSettings = (
  settings: InvestorPortalWatermarkSettings
): WatermarkSettingsState => ({
  isDirty: false,
  watermarkEnabled: settings.isEnabled,
  categoriesOption: Array.isArray(settings.externalCategoryIds) ? "selected_categories" : "all_categories",
  externalCategoryIds: settings.externalCategoryIds ?? [],
  items: {
    customText: {
      id: "CustomText",
      value: settings.customText ?? "Confidential",
      visibility: settings.segments.includes("CustomText"),
    },
    contactName: {
      id: "ContactName",
      value: "John Doe",
      visibility: settings.segments.includes("ContactName"),
    },
    contactEmail: {
      id: "ContactEmail",
      value: "john.doe@example.com",
      visibility: settings.segments.includes("ContactEmail"),
    },
    investorName: {
      id: "InvestorName",
      value: "Investor ABC",
      visibility: settings.segments.includes("InvestorName"),
    },
    dateAndTime: {
      id: "DateAndTime",
      value: formatDateTimeShort(new Date(2025, 0, 24, 10, 25)),
      visibility: settings.segments.includes("DateAndTime"),
    },
  },
});

export const mapWatermarkSettingsStateToUpdateRequest = (
  state: WatermarkSettingsState
): InvestorPortalWatermarkSettings => {
  const selectedSegments = Object.values(state.items)
    .filter((item) => item.visibility)
    .map((item) => item.id);

  return {
    isEnabled: state.watermarkEnabled,
    segments: selectedSegments,
    customText: state.items.customText.value,
    externalCategoryIds: state.categoriesOption === "selected_categories" ? state.externalCategoryIds : undefined,
  };
};

export const validateWatermarkSettingsState = (
  settingsState: WatermarkSettingsState
): WatermarkSettingsStateValidationResult => {
  const customTextError =
    settingsState.watermarkEnabled &&
    settingsState.items.customText.visibility &&
    !settingsState.items.customText.value.trim()
      ? "This text is required"
      : undefined;

  const enabledItemsError =
    settingsState.watermarkEnabled && Object.values(settingsState.items).every((item) => !item.visibility)
      ? "At least one segment must be selected"
      : undefined;

  const documentCategoriesError =
    settingsState.watermarkEnabled &&
    settingsState.categoriesOption === "selected_categories" &&
    settingsState.externalCategoryIds.length === 0
      ? "At least one category must be selected"
      : undefined;

  const isValid = !customTextError && !enabledItemsError && !documentCategoriesError;

  return {
    isValid,
    customTextError,
    enabledItemsError,
    documentCategoriesError,
  };
};
