import { DescriptiveDateRange } from "../../../../../shared/utilities/dateFilterHelper";
import { SentEmail } from "../../../../api/types/emailsTypes";
import { getInitialPaginatedItemsState, PaginatedItemsState } from "../../../../state/paginatedState";

export interface EmailsState extends PaginatedItemsState<SentEmail> {
  dateRange?: DescriptiveDateRange | undefined;
  statusFilter: string;
}

export const getInitialEmailsState = (): EmailsState => ({
  ...getInitialPaginatedItemsState(),
  statusFilter: "",
});

// Actions

type StateAction = (state: EmailsState) => EmailsState;

export const updateStatusFilterAction =
  (statusFilter: string): StateAction =>
  (state) => ({ ...state, page: 0, statusFilter });

export const updateDatesFilterAction =
  (dateRange: DescriptiveDateRange): StateAction =>
  (state) => ({ ...state, page: 0, dateRange });

export const clearFiltersAction = (): StateAction => (state) => ({
  ...state,
  page: 0,
  dateRange: undefined,
  statusFilter: "",
});
