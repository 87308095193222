import { Box, Grid2 } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { isAutoGeneratedRowNode } from "../../../../../../shared/components/grid/gridHelper";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import usePageTitle from "../../../../../../shared/hooks/usePageTitle";
import { OrganizationReportInfo } from "../../../../../api/biApi";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";
import createCheckboxColumn from "../../../../common/grid/createCheckboxColumn";
import { useUiReportsContext } from "../../../reporting/reports/ui-reports/contexts/ReportsContext";
import { useUiReportsManagerContext } from "../../../reporting/reports/ui-reports/contexts/ReportsUiManagerContext";
import ReportMenuAction from "../../../reporting/reports/ui-reports/ReportMenuAction";
import { getViewReportUrl } from "../../../reporting/reports/utilities/editReportUrl";
import CustomHeader from "../CustomHeader";
import {
  accessColumn,
  createdByColumn,
  getModifiedAtColumn,
  modifiedByColumn,
  reportTypeColumn,
} from "../grid/GridHelper";
import OrganizationReportsGridWrapper from "../grid/OrganizationReportsGridWrapper";

const OrganizationReportsPage = () => {
  usePageTitle("Reports");

  const { copyReports, deleteReports } = useUiReportsManagerContext();
  const { reports, ui } = useUiReportsContext<OrganizationReportInfo>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [currentReport, setCurrentReport] = useState<OrganizationReportInfo>();

  const reportsToShow = useMemo(() => {
    if (!reports) return [];
    if (!search) return reports;
    return reports.filter((r) => r.name.toLowerCase().includes(search.toLowerCase()));
  }, [reports, search]);

  const handleSearch = useDebounce((value: string) => setSearch(value), 300);

  const selectedReports = useMemo(
    () => reports.filter((r) => selectedIds.includes(r.reportId)),
    [reports, selectedIds]
  );

  const getColumns = useCallback((apiRef: React.MutableRefObject<GridApiPremium>): GridColDef[] => {
    return [
      createCheckboxColumn(apiRef),
      accessColumn,
      reportTypeColumn,
      createdByColumn,
      modifiedByColumn,
      getModifiedAtColumn(apiRef),
      {
        field: "actions",
        sortable: false,
        resizable: false,
        headerName: "",
        width: 80,
        cellClassName: "grid-row-actions",
        align: "right",
        renderCell: (params) => {
          if (isAutoGeneratedRowNode(params.rowNode)) {
            return null;
          }
          return <ReportMenuAction report={params.row as OrganizationReportInfo} onReportSelected={setCurrentReport} />;
        },
      },
    ];
  }, []);

  return (
    <Grid2 container sx={{ flex: 1, flexDirection: "column", width: "100%" }}>
      <GeneralPageHeader title="Reports" />
      <Grid2 container sx={{ flex: 1, px: 3, py: 2.5, gap: 2, flexDirection: "column", width: "100%" }}>
        <Box ml={"auto"}>
          <SearchField onSearch={handleSearch} />
        </Box>
        <ScrollableFlexContainer>
          <OrganizationReportsGridWrapper
            getViewReportUrl={getViewReportUrl}
            reports={reportsToShow}
            loading={!ui.loaded}
            error={undefined}
            getColumns={getColumns}
            getTreeDataPath={getTreeDataPath}
            Toolbar={
              <CustomHeader
                selectedIds={selectedIds}
                selectedReports={selectedReports}
                copyReports={copyReports}
                deleteReports={deleteReports}
              />
            }
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
            currentReport={currentReport}
            gridKey="ORGANIZATION_UI_REPORTS_GRID"
          />
        </ScrollableFlexContainer>
      </Grid2>
    </Grid2>
  );
};

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row: OrganizationReportInfo) => [
  row.clientCode,
  row.groupName,
  row.name,
];

export default OrganizationReportsPage;
