import { Box, Stack, Typography } from "@mui/material";
import { DocumentInfo } from "../../../../../../api/types/documentCollectionTypes";
import CategoryInlineTags from "../../../../../common/CategoryInlineTags";

interface Props {
  document: DocumentInfo;
}

const DocumentPreviewHeader = ({ document }: Props) => (
  <Stack spacing={1} mb={2} ml={1.5}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="subtitle1">{document.name}</Typography>
      <CategoryInlineTags categoryNames={[document.category]} />
    </Box>

    <Stack direction="row" spacing={1}>
      {document.investorTitle && <Typography>{document.investorTitle}</Typography>}
      {document.fundName && <Typography>•</Typography>}
      {document.fundName && <Typography>{document.fundName}</Typography>}
    </Stack>
  </Stack>
);

export default DocumentPreviewHeader;
