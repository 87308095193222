import { Stack, Typography } from "@mui/material";
import { QuarterlyDebtTableBlockContent } from "../../../../api/inputFormTypes";
import BlockSection from "../BlockSection";
import QuarterlyDebtInputForm from "./QuarterlyDebtInputForm";

interface Props {
  blockId: string;
  content: QuarterlyDebtTableBlockContent;
}

const QuarterlyDebtFormBlock = ({ blockId, content }: Props) => {
  return (
    <Stack flex={1} spacing={2.5}>
      <BlockSection>
        <Typography variant="h6">{content.table.name}</Typography>
        <QuarterlyDebtInputForm
          blockId={blockId}
          extensionConfiguration={content.extensionConfiguration}
          columnDefinitions={content.table.columns}
          rowDefinitions={content.table.rows}
        />
      </BlockSection>
    </Stack>
  );
};

export default QuarterlyDebtFormBlock;
