import { GridCellParams, GridEventListener } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { ObjectMetricExtension } from "../../../../api/dataCollectionTypes";
import { ScenarioTableBlockColumn, SingleExtensionBasedBlockRow } from "../../../../api/inputFormTypes";
import {
  MetricDataType,
  MetricExtensionConfiguration,
  MetricFormatConfiguration,
} from "../../../../api/portfolioMonitoringTypes";
import useSingleClickGridEditing from "../../../../hooks/useSingleClickGridEditing";
import { makeNextEditableCellEdited } from "../../../../utilities/editableDataGridHelper";
import { defined } from "../../../../utilities/typeHelper";
import DataGrid from "../../../grid/DataGrid";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { getSubmissionDataGridFormStyles } from "../submissionTableFormStyles";
import AddMetricExtensionDialog from "./AddMetricExtensionDialog";
import { PortfolioMonitoringInputTableContextProvider } from "./PortfolioMonitoringInputTableContext";
import { getColumns, getRows, RowModel } from "./singleExtendedMetricInputGridDataProvider";
import { calculateTotals } from "./tableTotalsCalculator";

interface Props {
  blockId: string;
  metricId: string;
  metricName: string;
  dataType: MetricDataType;
  defaultFormat?: MetricFormatConfiguration;
  extensionConfiguration: MetricExtensionConfiguration;
  columnDefinitions: ScenarioTableBlockColumn[];
  rowDefinitions: SingleExtensionBasedBlockRow[];
}

interface AddExtensionDialogState {
  metricIds: string[];
  metricName: string;
  addedExtensionIds: string[];
}

const SingleExtendedMetricTableInputForm = ({
  blockId,
  metricId,
  metricName,
  dataType,
  defaultFormat,
  extensionConfiguration,
  columnDefinitions,
  rowDefinitions,
}: Props) => {
  const { isSubmissionEditable, onBlockCellValuesEdit } = useDataSubmissionFormContext();

  const { cellModesModel, setCellModesModel, handleCellClick } = useSingleClickGridEditing();

  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));
  const [dialogState, setDialogState] = useState<AddExtensionDialogState>();

  const columns = useMemo(
    () =>
      getColumns(
        columnDefinitions,
        metricId,
        metricName,
        extensionConfiguration,
        dataType,
        defaultFormat,
        isSubmissionEditable
      ),
    [columnDefinitions, dataType, defaultFormat, extensionConfiguration, isSubmissionEditable, metricId, metricName]
  );

  const isCellEditable = useCallback(({ row }: { row: RowModel }) => row.type === "SingleMetricExtensionOnly", []);

  const handleRowUpdate = useCallback(
    (newRow: RowModel, oldRow: RowModel) => {
      if (newRow.type !== "SingleMetricExtensionOnly" || oldRow.type !== "SingleMetricExtensionOnly") {
        return newRow;
      }

      const updatedRow = { ...newRow };

      const updatedValueEntry = Object.entries(updatedRow.values).find(
        ([columnId, currentCellValue]) => oldRow.values[columnId] !== currentCellValue
      );

      if (updatedValueEntry !== undefined) {
        const [columnId, newCellValue] = updatedValueEntry;
        const colDef = columnDefinitions.find((col) => col.id === columnId);
        if (colDef !== undefined) {
          const metricExtensionId = updatedRow.extensionId;
          const fallbackValue = dataType === "Money" || dataType === "Number" ? 0 : "";
          const value = newCellValue ?? fallbackValue;
          onBlockCellValuesEdit(blockId, [{ metricId, metricExtensionId, columnId, value }]);
        }
      }

      setTimeout(
        () =>
          setRows((prevRows) => {
            const newRows = prevRows.map((row) => {
              if (row.id !== updatedRow.id) {
                return row;
              }

              return { ...updatedRow };
            });

            return calculateTotals(newRows);
          }),
        100
      );

      return updatedRow;
    },
    [blockId, columnDefinitions, dataType, metricId, onBlockCellValuesEdit]
  );

  const handleAddMetricExtensions = useCallback(
    (metricIds: string[], metricName: string) => {
      const addedExtensionIds = rows
        .map((r) => (r.type === "SingleMetricExtensionOnly" ? r.extensionId : ""))
        .filter(Boolean);

      setDialogState({
        metricIds,
        metricName,
        addedExtensionIds,
      });
    },
    [rows]
  );

  const handleMetricExtensionAdded = useCallback((addedExtensions: ObjectMetricExtension[]) => {
    setDialogState(undefined);

    if (addedExtensions.length === 0) {
      return;
    }

    const extensionId = defined(addedExtensions[0]).id;
    const extensionValue = defined(addedExtensions[0]).value;

    setRows((prevRows) => {
      const newRow: RowModel = {
        type: "SingleMetricExtensionOnly",
        id: extensionValue,
        indentationLevel: 0,
        extensionId,
        extensionValue,
        values: {},
      };

      return [newRow, ...prevRows];
    });
  }, []);

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = useCallback(
    (params: GridCellParams<RowModel>, event) => {
      if (event.code !== "Tab") {
        return;
      }

      event.defaultMuiPrevented = true;
      event.preventDefault();
      const direction = event.shiftKey ? "previous" : "next";
      makeNextEditableCellEdited(direction, rows, columns, params, setCellModesModel, isCellEditable, (r) => r.id);
    },
    [columns, isCellEditable, rows, setCellModesModel]
  );

  return (
    <PortfolioMonitoringInputTableContextProvider onAddMetricExtensions={handleAddMetricExtensions}>
      <DataGrid<RowModel>
        columns={columns}
        rows={rows}
        columnHeaderHeight={52}
        pinnedColumns={{ left: ["name"] }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={setCellModesModel}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        disableColumnReorder
        disableColumnSorting
        showCellVerticalBorder
        showColumnVerticalBorder
        editMode="cell"
        isCellEditable={isCellEditable}
        processRowUpdate={handleRowUpdate}
        noScrollableContainer
        sx={getSubmissionDataGridFormStyles}
      />

      {dialogState && (
        <AddMetricExtensionDialog
          metricIds={dialogState.metricIds}
          metricName={dialogState.metricName}
          extensionConfigurationsPerMetricId={{ [metricId]: extensionConfiguration }}
          addedExtensionIds={dialogState.addedExtensionIds}
          allowCreatingExtensionValues={false}
          onAdded={handleMetricExtensionAdded}
          onClose={() => setDialogState(undefined)}
        />
      )}
    </PortfolioMonitoringInputTableContextProvider>
  );
};

export default SingleExtendedMetricTableInputForm;
