import { useCallback, useReducer } from "react";
import { createApiResponse } from "../../shared/api/axiosHelper";
import { ApiError, PaginatedList } from "../../shared/api/types";
import useFetch from "../../shared/hooks/useFetch";
import adminApi from "../api/adminApi";
import {
  Field,
  ObjectClassDefinitionSource,
  ObjectFeature,
  ObjectFieldValues,
  ObjectPermissions,
} from "../api/types/objectTypes";
import {
  FilterAction,
  FilterState,
  createReducer,
  filterRecords,
  getEmptyState,
  getVisibleColumnsAndFiltersIds,
} from "../components/common/filters/filterState";
import { getEntityFilterDefinitions } from "../components/entityFields/entityFieldFilterDefinitions";
import { useClientContext } from "../context/ClientContext";

interface Result {
  isLoading: boolean;
  entities: ObjectFieldValues[];
  filteredEntities: ObjectFieldValues[];
  objectName: string;
  objectSource: ObjectClassDefinitionSource | undefined;
  objectFields: Field[];
  supportedFeatures: ObjectFeature[];
  objectPermissions: ObjectPermissions;
  hasPermissionsForDataImport: boolean;
  filterState: FilterState<ObjectFieldValues>;
  dispatchFilters: React.Dispatch<FilterAction<ObjectFieldValues>>;
}

export const useObjectListWithFilters = (objectType: string): [Result, undefined] | [undefined, ApiError] => {
  const { clientCode, dictionaries } = useClientContext();

  const [filterState, dispatchFilters] = useReducer(
    createReducer<ObjectFieldValues>(),
    getEmptyState(`${clientCode}_${objectType}_filter_v2`)
  );

  const getObjects = useCallback(async () => {
    if (!filterState.initialized) {
      return createApiResponse<PaginatedList<ObjectFieldValues>>({ items: [] });
    }

    const fieldIds = getVisibleColumnsAndFiltersIds(filterState);
    return adminApi.searchObjects(objectType, { fieldIds, includeLookupObjects: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.initialized, filterState.visibleColumns, filterState.visibleFilters, objectType]);

  const [objectsResp, objectsError, { isFetching: isFetchingObjects }] = useFetch(getObjects);

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition(objectType), [objectType]);

  const [objectDefinitionWithPermissions, objectDefinitionError, { isFetching: isFetchingObjectDefinition }] = useFetch(
    getObjectDefinition,
    ({ objectClassDefinition }) => {
      dispatchFilters({
        type: "init",
        ...getEntityFilterDefinitions(
          objectClassDefinition.fields,
          objectClassDefinition.defaultGridFieldIds,
          dictionaries
        ),
      });
    }
  );

  const [dataImportOptions, dataImportOptionsError, { isFetching: isFetchingDataImportOptions }] = useFetch(
    adminApi.getDataImportOptions
  );

  const error = objectsError || objectDefinitionError || dataImportOptionsError;
  if (error) {
    return [undefined, error];
  }

  const isLoading =
    isFetchingObjects ||
    isFetchingObjectDefinition ||
    objectDefinitionWithPermissions === undefined ||
    isFetchingDataImportOptions;

  const entities = objectsResp?.items ?? [];
  const filteredEntities = filterRecords(filterState, entities);
  const objectName = objectDefinitionWithPermissions?.objectClassDefinition.objectName ?? "";
  const objectSource = objectDefinitionWithPermissions?.objectClassDefinition.source;
  const objectFields = objectDefinitionWithPermissions?.objectClassDefinition.fields ?? [];
  const supportedFeatures = objectDefinitionWithPermissions?.objectClassDefinition.supportedFeatures ?? [];
  const objectPermissions = objectDefinitionWithPermissions?.objectPermissions ?? {
    objectDataReadPermissions: [],
    objectDataWritePermissions: [],
    filesReadPermissions: [],
    filesWritePermissions: [],
    notesReadPermissions: [],
    notesWritePermissions: [],
    objectContactsReadPermissions: [],
    objectContactsWritePermissions: [],
    objectInternalUsersReadPermissions: [],
    objectInternalUsersWritePermissions: [],
  };

  const hasPermissionsForDataImport = (dataImportOptions ?? []).some((option) => option.objectType === objectType);

  return [
    {
      isLoading,
      entities,
      filteredEntities,
      objectName,
      objectSource,
      objectFields,
      supportedFeatures,
      objectPermissions,
      hasPermissionsForDataImport,
      filterState,
      dispatchFilters,
    },
    undefined,
  ];
};
