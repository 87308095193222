import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, FormControl, InputLabel, Select, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { MenuItemStyled } from "../../../../components/MenuItemStyled";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../components/dialog/PromptDialogHeader";
import TextFieldExt from "../../../../components/inputs/TextFieldExt";
import useRequest from "../../../../hooks/useRequest";
import { formatString } from "../../../../utilities/stringFormatter";
import { combineValidators, minCharactersValidator, requiredValidator } from "../../../../utilities/validators";
import biClient from "../../../api/biClient";
import { ReportGroup, ReportTemplate } from "../../../api/biClient.types";
import { useSharedReportingLocalization } from "../../../hooks/useLocalization";

interface Props {
  organization: string;
  template: ReportTemplate;
  groups: ReportGroup[];
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

export default function DuplicateTemplateDialog({ organization, template, onChanged, onClose, groups }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const [name, setName] = useState(template.name);
  const [groupId, setGroupId] = useState(template.groupId || "");
  const [isFormValid, setFormValidity] = useState(true);
  const [error, setError] = useState<string>();

  const validateTemplates = useCallback(
    () =>
      biClient.validateTemplateName({
        templateId: undefined,
        name: name,
        organization: organization,
      }),
    [name, organization]
  );

  const duplicateTemplate = useCallback(
    () => biClient.duplicateReportTemplate({ organization, templateId: template.reportId, name, groupId }),
    [template.reportId, name, organization, groupId]
  );

  const { request: duplicateRequest, isLoading: isDuplicating } = useRequest(duplicateTemplate);
  const { request: validateTemplateNameRequest, isLoading: isValidating } = useRequest(validateTemplates);

  const validateTemplateNameAsync = useCallback(async () => {
    const [response, error] = await validateTemplateNameRequest();
    if (error) {
      setError(locale.duplicating_error);
      return false;
    }
    if (response && response.nameExists === true) {
      setError(formatString(locale.name_exist, name));
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTemplateNameRequest, name]);

  const onSaveClicked = useCallback(async () => {
    const isValid = await validateTemplateNameAsync();
    if (!isValid || !isFormValid) return;

    const [response, error] = await duplicateRequest();
    if (!error) {
      onChanged(response?.reportId || "");
      onClose();
      return;
    }
    setError(locale.duplicating_error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid, onChanged, onClose, validateTemplateNameAsync, duplicateRequest]);

  return (
    <PromptDialog open={true} sx={{ p: 0, minHeight: 224 }}>
      <PromptDialogHeader text="Duplicate Template" disabled={isDuplicating} onClose={onClose} />
      {error && (
        <Alert sx={{ mx: 3, mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Stack sx={{ gap: 3, px: 3, pt: 1, pb: 1, flex: 1 }}>
        <TextFieldExt
          name="name"
          textFieldProps={{
            size: "small",
            label: "Name",
            variant: "outlined",
            value: name,
            placeholder: locale.template_form.main_info_placeholder,
            disabled: isDuplicating,
          }}
          doValidate={combineValidators(requiredValidator, minCharactersValidator(2))}
          onValidated={(_, result) => setFormValidity(result.isValid)}
          onValueChanged={(value) => setName(value)}
        />
        <FormControl fullWidth disabled={isDuplicating}>
          <InputLabel>{locale.template_form.group_title}</InputLabel>
          <Select
            value={groupId}
            label={locale.template_form.group_title}
            onChange={(evt) => setGroupId(evt.target.value)}
            disabled={isDuplicating}
          >
            {groups.map((rg) => {
              return (
                <MenuItemStyled key={rg.id} value={rg.id}>
                  <Typography>{rg.caption}</Typography>
                </MenuItemStyled>
              );
            })}
          </Select>
        </FormControl>
      </Stack>

      <DialogActions sx={{ px: 3, py: 1.5 }}>
        <Button variant="text" color="secondary" disabled={isDuplicating} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={isDuplicating || isValidating}
          loading={isDuplicating}
          onClick={onSaveClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
}
