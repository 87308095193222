import { DateView } from "@mui/x-date-pickers-pro";
import { ReportingPeriod } from "../../../../../../shared/api/dataCollectionTypes";
import { getReportingPeriodDateFormat } from "../../../../../../shared/components/dataCollection/dataCollectionUtils";
import { formatDate } from "../../../../../../shared/utilities/dateUtils";
import { reportingPeriodCaptionsMap } from "../../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../../shared/utilities/stringHelper";
import { DataCollectionRequestTemplateVersion } from "../../../../../api/types/dataCollectionTypes";

export const getTemplateSettingsProperties = (
  template: DataCollectionRequestTemplateVersion | undefined
): Array<[string, string]> => [
  ["Period", template?.reportingPeriod ? reportingPeriodCaptionsMap[template.reportingPeriod] : ""],
  ["Recipient Type", template?.auditory ? autoFormatCamelCase(template.auditory) : ""],
  ["Companies", template?.recipientObjectIds.length.toLocaleString() ?? ""],
];

export const reportingDatePickerViewsMap: Record<ReportingPeriod, DateView[]> = {
  Month: ["year", "month"],
  Quarter: ["year", "month"],
  Year: ["year"],
  LastTwelveMonths: ["year", "month"],
};

export const getDefaultDataRequestName = (
  template: DataCollectionRequestTemplateVersion | undefined,
  reportingDate: Date | null
) => {
  if (!template || !reportingDate) {
    return "";
  }

  return `${template.name} - ${formatDate(reportingDate, getReportingPeriodDateFormat(template.reportingPeriod))}`;
};
