import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { subYears } from "date-fns";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { getLastDateOfReportingPeriod } from "../../../../../../shared/components/dataCollection/dataCollectionUtils";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import {
  DataCollectionRequestDetails,
  DataCollectionRequestTemplateVersion,
} from "../../../../../api/types/dataCollectionTypes";
import InlinePropertyBox from "../../../../common/InlinePropertyBox";
import {
  getDefaultDataRequestName,
  getTemplateSettingsProperties,
  reportingDatePickerViewsMap,
} from "./dataRequestDialogHelper";
import { formToUpdateRequestPayload, getInitialEditDataRequestForm, validateForm } from "./editDataRequestForm";

interface Props {
  editedDataRequest: DataCollectionRequestDetails;
  requestTemplate: DataCollectionRequestTemplateVersion;
  onClose: () => void;
  onSaved: (dataRequest: DataCollectionRequestDetails) => void;
}

const updateDataCollectionRequest = withErrorHandling(adminApi.updateDataCollectionRequest);

const EditDataRequestDialog = ({ editedDataRequest, requestTemplate, onClose, onSaved }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState(getInitialEditDataRequestForm(editedDataRequest));

  const handleUpdate = async () => {
    const payload = formToUpdateRequestPayload(form);
    if (!payload) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateDataCollectionRequest(defined(editedDataRequest?.id), payload);
    setSaving(false);

    if (error) {
      logError(error, "[EditDataRequestDialog] updateDataCollectionRequest");
      sendNotificationError("Failed to update data request");
      return;
    }

    sendNotification("Data request updated successfully");
    onSaved(resp);
  };

  const handleNameChange = (name: string) => {
    setForm((prev) => ({ ...prev, name, touchedFields: [...prev.touchedFields, "name"] }));
  };

  const handleReportingDateChange = (date: Date | null) => {
    setForm((prev) => ({
      ...prev,
      reportingDate: date ? getLastDateOfReportingPeriod(date, requestTemplate.reportingPeriod) : date,
      name: form.touchedFields.includes("name") ? prev.name : getDefaultDataRequestName(requestTemplate, date),
      touchedFields: [...prev.touchedFields, "reportingDate"],
    }));
  };

  const handleDueDateChange = (date: Date | null) => {
    setForm((prev) => ({ ...prev, dueDate: date, touchedFields: [...prev.touchedFields, "dueDate"] }));
  };

  const { isFormValid, validationErrors } = validateForm(form);

  const reportingDatePickerViews = reportingDatePickerViewsMap[requestTemplate.reportingPeriod];

  const defaultReportingDatePickerView = reportingDatePickerViews?.includes("month") ? "month" : undefined;

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "40rem" } }}>
      <DialogTitle>Edit Data Request</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" color="secondary">
                Request Template:
              </Typography>
              <Typography variant="subtitle2">{editedDataRequest.templateName}</Typography>
            </Stack>

            <InlinePropertyBox title="Template Settings" properties={getTemplateSettingsProperties(requestTemplate)} />
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Scenario:
            </Typography>
            <Typography variant="subtitle2">{editedDataRequest.scenarioName}</Typography>
          </Stack>

          <Stack direction="row" spacing={3}>
            <DatePicker
              value={form.reportingDate}
              onChange={handleReportingDateChange}
              views={reportingDatePickerViews}
              openTo={defaultReportingDatePickerView}
              minDate={subYears(new Date(), 10)}
              shouldDisableMonth={
                requestTemplate.reportingPeriod === "Quarter" ? (date) => date.getMonth() % 3 !== 2 : undefined
              }
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Reporting Date",
                  error: !!validationErrors.reportingDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />

            <DatePicker
              disablePast
              value={form.dueDate}
              onChange={handleDueDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Due Date",
                  error: !!validationErrors.dueDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />
          </Stack>

          <TextField
            fullWidth
            label="Name"
            value={form.name}
            onChange={(e) => handleNameChange(e.target.value)}
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={saving} onClick={handleUpdate} disabled={!isFormValid}>
          Save & update submissions
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDataRequestDialog;
