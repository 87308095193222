import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import { Contact, ContactDetails } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import FeatureToggleBoundary from "../../../common/FeatureToggleBoundary";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import ObjectTitle from "../../../common/ObjectTitle";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import { getDataCatalogueEntityType } from "../../../entityFields/entityTypesHelper";
import EntityCardFiles from "../../../entityFields/files/EntityCardFiles";
import InvestorPortalImpersonateButton from "../../investor-portal/InvestorPortalImpersonateButton";
import DeleteContactDialog from "./DeleteContactDialog";
import InviteContactsDialog from "./InviteContactsDialog";
import ContactCommunicationMatrixTab from "./communication-matrix/ContactCommunicationMatrixTab";
import ContactDetailsDocumentsTab from "./documents/ContactDetailsDocumentsTab";
import ContactDetailsEmailsTab from "./emails/ContactDetailsEmailsTab";
import ContactFundraisingDocumentsTab from "./fundraising-documents/ContactFundraisingDocumentsTab";
import ContactDetailsMainTab from "./mainInfo/ContactDetailsMainTab";

interface Props {
  categories: Category[];
  contactDetails: ContactDetails;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onContactDetailsChange: (details: Partial<ContactDetails>) => void;
  onUpdateContactDetails: () => void;
}

interface DialogState {
  contactIdsToInvite?: string[];
  contactToDelete?: Contact;
  openDialog: "invite_contacts" | "delete_contact" | undefined;
}

const tabPanelSx = { height: "100%", m: 0, px: 3, pt: 2.5, pb: 0 };

const ContactDetailsPage = ({
  categories,
  contactDetails,
  objectDefinition,
  objectPermissions,
  onContactDetailsChange,
  onUpdateContactDetails,
}: Props) => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const { hasAnyPermission, isPortalFeatureEnabled } = useClientContext();

  const [selectedTab, setSelectedTab] = useState(tab || "main");
  const [dialogState, setDialogState] = useState<DialogState>({ openDialog: undefined });

  const hasPermissionsToManageContacts = hasAnyPermission(objectPermissions.objectDataWritePermissions);
  const hasPermissionsToViewFiles = hasAnyPermission(objectPermissions.filesReadPermissions);
  const hasPermissionsToManageFiles = hasAnyPermission(objectPermissions.filesWritePermissions);
  const hasPermissionsToViewFundraisings = hasAnyPermission(["ViewFundraisings", "ManageFundraisings"]);

  const { id, name, email, hasAccessToInvestorPortal, registrationDate } = contactDetails.contact;

  const onTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    window.history.replaceState({}, document.title, window.location.pathname.replace(/\/[^/]+$/, `/${newTab}`));
  };

  const handleInviteContacts = (contactIds: string[]) => {
    setDialogState({ openDialog: "invite_contacts", contactIdsToInvite: contactIds });
  };

  const handleContactsInvitationSent = () => {
    setDialogState({ openDialog: undefined });
    onUpdateContactDetails();
  };

  const handleDeleteContact = () => {
    setDialogState({ openDialog: "delete_contact", contactToDelete: contactDetails.contact });
  };

  const handleContactDeleted = () => {
    setDialogState({ openDialog: undefined });
    navigate("..");
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <FullHeightFlexPage>
          <GeneralPageHeader
            TitleComponent={<ObjectTitle title={name} secondaryTitle={email} typeName={objectDefinition.objectName} />}
            hideBorderLine
            showDefaultBackButtonTo=".."
          >
            {hasAccessToInvestorPortal && (
              <InvestorPortalImpersonateButton
                params={{ target: "Investor", userEmail: email }}
                variant="outlined"
                color="secondary"
              />
            )}
            {!registrationDate && (
              <Button
                disabled={!hasPermissionsToManageContacts || !hasAccessToInvestorPortal}
                onClick={() => handleInviteContacts([id])}
                color="secondary"
                variant="outlined"
                startIcon={<ForwardToInboxRoundedIcon />}
              >
                Send Invitation
              </Button>
            )}
            <ActionsMenuButton
              items={[
                {
                  icon: <DeleteOutlineIcon color="error" />,
                  label: "Delete",
                  onClick: handleDeleteContact,
                  disabled: !hasPermissionsToManageContacts,
                },
              ]}
            />
          </GeneralPageHeader>
          <TabList onChange={(_, tab: string) => onTabChange(tab)}>
            <Tab sx={{ ml: 3 }} label="Main Info" value="main" />
            <Tab label="Investor Documents" value="documents" />
            {hasPermissionsToViewFundraisings && <Tab label="Fundraising Documents" value="fundraising-documents" />}
            {hasPermissionsToViewFiles && isPortalFeatureEnabled("EntityFilesEnabled") && (
              <Tab label="Files" value="files" />
            )}
            <Tab label="Emails" value="emails" disabled={!email} />
            <Tab label="Communication Matrix" value="communication-matrix" />
          </TabList>
          <StretchedScrollableContent>
            <TabPanel value="main" sx={{ ...tabPanelSx, backgroundColor: "background.grey" }}>
              <ContactDetailsMainTab
                contactDetails={contactDetails}
                objectDefinition={objectDefinition}
                objectPermissions={objectPermissions}
                onContactDetailsChange={onContactDetailsChange}
                onUpdateContactDetails={onUpdateContactDetails}
              />
            </TabPanel>
            <TabPanel value="documents" sx={tabPanelSx}>
              <ContactDetailsDocumentsTab
                contactId={contactDetails.contact.id}
                categories={categories}
                contactPermissions={contactDetails.permissions}
              />
            </TabPanel>
            {hasPermissionsToViewFundraisings && (
              <TabPanel value="fundraising-documents" sx={tabPanelSx}>
                <ContactFundraisingDocumentsTab
                  contactId={contactDetails.contact.id}
                  categories={categories}
                  contactPermissions={contactDetails.permissions}
                />
              </TabPanel>
            )}
            {hasPermissionsToViewFiles && (
              <FeatureToggleBoundary featureToggle="EntityFilesEnabled">
                <TabPanel value="files" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
                  <EntityCardFiles
                    entityType={getDataCatalogueEntityType(objectDefinition.objectType)}
                    entityId={contactDetails.contact.id}
                    entityName={contactDetails.contact.name}
                    hasPermissionsToManage={hasPermissionsToManageFiles}
                  />
                </TabPanel>
              </FeatureToggleBoundary>
            )}
            <TabPanel value="emails" sx={tabPanelSx}>
              <ContactDetailsEmailsTab contactId={contactDetails.contact.id} />
            </TabPanel>
            <TabPanel value="communication-matrix" sx={tabPanelSx}>
              <ContactCommunicationMatrixTab contactId={contactDetails.contact.id} categories={categories} />
            </TabPanel>
          </StretchedScrollableContent>
        </FullHeightFlexPage>
      </TabContext>
      <InviteContactsDialog
        open={dialogState.openDialog === "invite_contacts"}
        onClose={() => setDialogState({ openDialog: undefined })}
        onSubmit={handleContactsInvitationSent}
        contactIds={dialogState.contactIdsToInvite ?? []}
      />
      {dialogState.contactToDelete && (
        <DeleteContactDialog
          open={dialogState.openDialog === "delete_contact"}
          onClose={() => setDialogState({ openDialog: undefined })}
          onDeleted={handleContactDeleted}
          contact={dialogState.contactToDelete}
        />
      )}
    </>
  );
};

export default ContactDetailsPage;
