import { Box, MenuItem, Select, SelectChangeEvent, SxProps, Theme, Typography } from "@mui/material";
import Arrows from "../../../common/Arrows";
import { MappingType } from "./fieldMappingsState";

interface Props {
  mappingType: MappingType;
  onChange: (mappingType: MappingType) => void;
}

const FromCrmItemContent = () => <Arrows width={5} left />;

const FromCrmIdItemContent = () => (
  <Box>
    <Arrows width={5} left />
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      width={20}
      height={20}
      left={24}
      top="calc(50% - 10px)"
      borderRadius={64}
      bgcolor="primary.main"
    >
      <Typography variant="caption" sx={{ color: "#fff !important" }}>
        Id
      </Typography>
    </Box>
  </Box>
);

const menuItemProps: SxProps<Theme> = (theme) => ({ py: theme.spacing(1.5) });

const MappingTypeEdit = ({ mappingType, onChange }: Props) => {
  if (mappingType === "None") {
    return <Box sx={(theme) => ({ width: theme.spacing(13) })} />;
  }

  const handleChange = (e: SelectChangeEvent<MappingType>) => {
    const { value } = e.target;
    if (value === "FromCrm" || value === "FromCrmId") {
      onChange(value);
    }
  };

  return (
    <Select<MappingType>
      value={mappingType}
      onChange={handleChange}
      sx={(theme) => ({
        height: theme.spacing(4.5),
        width: theme.spacing(13),
        ".MuiSelect-select": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      })}
    >
      <MenuItem value="FromCrm" sx={menuItemProps}>
        <FromCrmItemContent />
      </MenuItem>
      <MenuItem value="FromCrmId" sx={menuItemProps}>
        <FromCrmIdItemContent />
      </MenuItem>
    </Select>
  );
};

export default MappingTypeEdit;
