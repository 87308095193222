import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import GridViewRoundedIcon from "../../icons/GridViewRoundedIcon";

interface Props {
  view: ViewType;
  onChange: (view: ViewType) => void;
}

export type ViewType = "Grid" | "List";

const ViewSelector = ({ view, onChange }: Props) => (
  <ToggleButtonGroup value={view} exclusive onChange={(_e, view: ViewType) => view && onChange(view)}>
    <ToggleButton value="Grid">
      <GridViewRoundedIcon />
    </ToggleButton>
    <ToggleButton value="List">
      <ReorderRoundedIcon />
    </ToggleButton>
  </ToggleButtonGroup>
);

export default ViewSelector;
