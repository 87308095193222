import { Avatar, Box, Color, Tooltip, Typography } from "@mui/material";
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  orange,
  pink,
  purple,
  teal,
} from "@mui/material/colors";
import { sumBy } from "../../../../utilities/arrayHelper";

interface Props {
  scenarioName: string;
}

const otherColors: Color[] = [
  amber,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  indigo,
  lightBlue,
  lightGreen,
  green,
  pink,
  purple,
  teal,
];

const getColorFromNameHash = (scenarioName: string): Color => {
  if (scenarioName.toLowerCase() === "actual") {
    return blue;
  }

  if (scenarioName.toLowerCase() === "budget") {
    return orange;
  }

  const stringHash = sumBy(scenarioName.split(""), (ch) => ch.charCodeAt(0));
  return otherColors[stringHash % otherColors.length] || grey;
};

const getScenarioAbbreviation = (scenarioName: string): string => {
  const words = scenarioName.split(/[\s-_]/);
  return words
    .slice(0, 2)
    .map((w) => w[0]?.toLocaleUpperCase())
    .join("");
};

const ScenarioAvatar = ({ scenarioName }: Props) => {
  if (!scenarioName) {
    return <Box width={16} height={16} />;
  }

  const color = getColorFromNameHash(scenarioName);

  return (
    <Tooltip arrow title={scenarioName}>
      <Avatar sx={{ width: 16, height: 16, bgcolor: color[50], color: color[700] }}>
        <Typography variant="caption" fontWeight={500}>
          {getScenarioAbbreviation(scenarioName)}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

export default ScenarioAvatar;
