import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { subYears } from "date-fns";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { getLastDateOfReportingPeriod } from "../../../../../../shared/components/dataCollection/dataCollectionUtils";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../api/adminApi";
import {
  DataCollectionRequestDetails,
  DataCollectionRequestTemplateVersion,
} from "../../../../../api/types/dataCollectionTypes";
import { ObjectFieldValues } from "../../../../../api/types/objectTypes";
import InlinePropertyBox from "../../../../common/InlinePropertyBox";
import {
  getDefaultDataRequestName,
  getTemplateSettingsProperties,
  reportingDatePickerViewsMap,
} from "./dataRequestDialogHelper";
import { formToCreateRequestPayload, getInitialNewDataRequestForm, validateForm } from "./newDataRequestForm";

interface Props {
  templates: DataCollectionRequestTemplateVersion[];
  scenarios: ObjectFieldValues[];
  onClose: () => void;
  onSaved: (dataRequest: DataCollectionRequestDetails) => void;
}

const createDataCollectionRequest = withErrorHandling(adminApi.createDataCollectionRequest);

const NewDataRequestDialog = ({ templates, scenarios, onClose, onSaved }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState(getInitialNewDataRequestForm());

  const scenarioOptions = useMemo(
    () => scenarios.map((s) => ({ value: s.id, label: s.name })).sort(stringComparerBy(({ label }) => label)),
    [scenarios]
  );

  const handleCreate = async () => {
    const payload = formToCreateRequestPayload(form);
    if (!payload) {
      return;
    }

    setSaving(true);
    const [resp, error] = await createDataCollectionRequest(payload);
    setSaving(false);

    if (error) {
      logError(error, "[NewDataRequestDialog] createDataCollectionRequest");
      sendNotificationError("Failed to create data request");
      return;
    }

    sendNotification("Data request created successfully");
    onSaved(resp);
  };

  const selectedTemplate = templates.find((t) => t.templateId === form.templateId);

  const handleNameChange = (name: string) => {
    setForm((prev) => ({ ...prev, name, touchedFields: [...prev.touchedFields, "name"] }));
  };

  const handleTemplateChange = (templateId: string) => {
    const newSelectedTemplate = templates.find((t) => t.templateId === templateId);

    setForm((prev) => ({
      ...prev,
      templateId,
      name: form.touchedFields.includes("name")
        ? prev.name
        : getDefaultDataRequestName(
            templates.find((t) => t.templateId === templateId),
            form.reportingDate
          ),
      reportingDate:
        prev.reportingDate && newSelectedTemplate
          ? getLastDateOfReportingPeriod(prev.reportingDate, newSelectedTemplate.reportingPeriod)
          : prev.reportingDate,
      scenarioId: newSelectedTemplate?.scenarioId ?? "",
      isScenarioConfigurable: Boolean(newSelectedTemplate?.isScenarioConfigurable),
      touchedFields: [...prev.touchedFields, "templateId"],
    }));
  };

  const handleScenarioChange = (scenarioId: string) => {
    setForm((prev) => ({
      ...prev,
      scenarioId: scenarioId,
      touchedFields: [...prev.touchedFields, "scenarioId"],
    }));
  };

  const handleReportingDateChange = (date: Date | null) => {
    setForm((prev) => ({
      ...prev,
      reportingDate:
        date && selectedTemplate ? getLastDateOfReportingPeriod(date, selectedTemplate.reportingPeriod) : date,
      name: form.touchedFields.includes("name") ? prev.name : getDefaultDataRequestName(selectedTemplate, date),
      touchedFields: [...prev.touchedFields, "reportingDate"],
    }));
  };

  const handleDueDateChange = (date: Date | null) => {
    setForm((prev) => ({ ...prev, dueDate: date, touchedFields: [...prev.touchedFields, "dueDate"] }));
  };

  const { isFormValid, validationErrors } = validateForm(form);

  const reportingDatePickerViews = selectedTemplate
    ? reportingDatePickerViewsMap[selectedTemplate.reportingPeriod]
    : undefined;

  const defaultReportingDatePickerView = reportingDatePickerViews?.includes("month") ? "month" : undefined;

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "40rem" } }}>
      <DialogTitle>New Data Request</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="template-select-label">Template</InputLabel>
              <Select
                fullWidth
                label="Template"
                value={form.templateId}
                onChange={(e) => handleTemplateChange(e.target.value)}
                error={!!validationErrors.templateId}
              >
                {templates.sort(stringComparerBy((t) => t.name)).map(({ templateId, name }) => (
                  <MenuItem key={templateId} value={templateId}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.templateId && <FormHelperText error>{validationErrors.templateId}</FormHelperText>}
            </FormControl>

            <InlinePropertyBox title="Template Settings" properties={getTemplateSettingsProperties(selectedTemplate)} />
          </Stack>

          <FormControl fullWidth>
            <InputLabel id="scenario-select-label">Scenario</InputLabel>
            <Select
              fullWidth
              label="Scenario"
              value={form.scenarioId}
              disabled={selectedTemplate === undefined || !selectedTemplate.isScenarioConfigurable}
              onChange={(e) => handleScenarioChange(e.target.value)}
              error={!!validationErrors.scenarioId}
            >
              {scenarioOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {validationErrors.templateId && <FormHelperText error>{validationErrors.templateId}</FormHelperText>}
          </FormControl>

          <Stack direction="row" spacing={3}>
            <DatePicker
              value={form.reportingDate}
              onChange={handleReportingDateChange}
              disabled={selectedTemplate === undefined}
              views={reportingDatePickerViews}
              openTo={defaultReportingDatePickerView}
              minDate={subYears(new Date(), 10)}
              shouldDisableMonth={
                selectedTemplate?.reportingPeriod === "Quarter" ? (date) => date.getMonth() % 3 !== 2 : undefined
              }
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Reporting Date",
                  error: !!validationErrors.reportingDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />

            <DatePicker
              disablePast
              value={form.dueDate}
              onChange={handleDueDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Due Date",
                  error: !!validationErrors.dueDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />
          </Stack>

          <TextField
            fullWidth
            label="Name"
            value={form.name}
            onChange={(e) => handleNameChange(e.target.value)}
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={saving} onClick={handleCreate} disabled={!isFormValid}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewDataRequestDialog;
