import { Stack } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridCellParams } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import approvalsApi from "../../../api/mock/approvals/approvalsMockApi";
import { ApprovalRequest, ApprovalType } from "../../../api/mock/approvals/approvalsTypes";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import { getCheckedGridSx } from "../../common/grid/gridStyles";
import { ApprovalsGridActionsContextProvider } from "./ApprovalsGridActionsContext";
import ApprovalsGridHeaderToolbar from "./ApprovalsGridHeaderToolbar";
import { getGenericColumnDefinitions, getPaymentColumnDefinitions } from "./approvalsListsDataProvider";
import { ApprovalsFilter } from "./approvalsPageHelper";
import ApprovalConfirmationDialog from "./dialogs/ApprovalConfirmationDialog";
import RejectionConfirmationDialog from "./dialogs/RejectionConfirmationDialog";
import { approvalRequestSearchMatch } from "./searchHelper";

interface Props {
  filter: ApprovalsFilter;
  approvalType?: ApprovalType;
}

interface DialogState {
  approvalRequests: ApprovalRequest[];
  openDialog: "approve" | "reject" | undefined;
}

const ApprovalsList = ({ filter, approvalType }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const [dialogState, setDialogState] = useState<DialogState>({ approvalRequests: [], openDialog: undefined });
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [filteredApprovals, setFilteredApprovals] = useState<ApprovalRequest[]>();

  const columns = useMemo(
    () => (approvalType === "Payments" ? getPaymentColumnDefinitions() : getGenericColumnDefinitions()),
    [approvalType]
  );

  const getApprovals = useCallback(() => {
    const type = approvalType;
    const status = filter === "pending" || filter === "my" ? "Pending" : undefined;
    const onlyForCurrentUser = filter === "my";
    return approvalsApi.getApprovals({ type, status, onlyForCurrentUser });
  }, [approvalType, filter]);

  const [approvals, error, { isFetching, fetch: fetchApprovals }] = useFetch(getApprovals);

  if (error) {
    return <DataLoadingFailed title="Failed to load approvals" />;
  }

  if (isFetching || approvals === undefined) {
    return <InlineLoader />;
  }

  const handleSearch = (value: string) => {
    const searchTerm = value.trim().toLowerCase();
    if (searchTerm.length < 2) {
      setFilteredApprovals(undefined);
      return;
    }

    const filtered = approvals.filter((approval) => approvalRequestSearchMatch(approval, searchTerm));
    setFilteredApprovals(filtered);
  };

  const handleCellClick = ({ row, field }: GridCellParams<ApprovalRequest>) => {
    if (field === "name") {
      navigate(`/${clientCode}/${pageRoutes.workflows}/${pageRoutes.approvals}/${row.id}`, {
        state: { tab: filter, view: approvalType ?? "All" },
      });
    }
  };

  const handleApproveClick = (ids: string[]) => {
    const approvalRequests = approvals.filter((approval) => ids.includes(approval.id));
    if (approvalRequests.length > 0) {
      setDialogState({ approvalRequests, openDialog: "approve" });
    }
  };

  const handleRejectClick = (ids: string[]) => {
    const approvalRequests = approvals.filter((approval) => ids.includes(approval.id));
    if (approvalRequests.length > 0) {
      setDialogState({ approvalRequests, openDialog: "reject" });
    }
  };

  const handleStatusUpdated = () => {
    fetchApprovals();
  };

  const rows = filteredApprovals ?? approvals;
  const pinnedColumns =
    approvalType === "Payments" ? { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "actions", "name"] } : undefined;
  const showToolbar = selectedRowIds.length > 0;

  return (
    <ApprovalsGridActionsContextProvider onApproveClick={handleApproveClick} onRejectClick={handleRejectClick}>
      <Stack spacing={2} height="100%">
        <Stack px={1} spacing={2.5} direction="row" alignItems="center" justifyContent="space-between">
          <RecordCounter records={rows.length} totalRecords={approvals.length} hide={isFetching} />
          <SearchField debounceTimeMs={300} onSearch={handleSearch} />
        </Stack>
        <DataGrid<ApprovalRequest>
          sx={(theme) => getCheckedGridSx(theme, showToolbar)}
          rows={rows}
          columns={columns}
          pinnedColumns={pinnedColumns}
          loading={isFetching}
          selectableRows
          disableColumnReorder
          onCellClick={handleCellClick}
          checkboxSelection
          rowSelectionModel={selectedRowIds}
          onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
          isRowSelectable={({ row }) => row.allowUpdateForCurrentUser && row.status === "Pending"}
          noRowsText="No approvals yet"
          slots={{
            toolbar: () =>
              showToolbar ? (
                <ApprovalsGridHeaderToolbar
                  selectedIds={selectedRowIds}
                  setSelectedIds={setSelectedRowIds}
                  disabled={isFetching}
                />
              ) : null,
          }}
        />
      </Stack>
      <ApprovalConfirmationDialog
        requests={dialogState.approvalRequests}
        open={dialogState.openDialog === "approve"}
        onClose={() => setDialogState({ approvalRequests: [], openDialog: undefined })}
        onConfirmed={handleStatusUpdated}
      />
      <RejectionConfirmationDialog
        requests={dialogState.approvalRequests}
        open={dialogState.openDialog === "reject"}
        onClose={() => setDialogState({ approvalRequests: [], openDialog: undefined })}
        onConfirmed={handleStatusUpdated}
      />
    </ApprovalsGridActionsContextProvider>
  );
};

export default ApprovalsList;
