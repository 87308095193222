import { useLayoutEffect } from "react";

const useHideLeftSideRootMenu = () => {
  useLayoutEffect(() => {
    const leftSideRootMenu = document.getElementById("left-side-root-menu");
    if (leftSideRootMenu) {
      leftSideRootMenu.style.display = "none";
    }

    return () => {
      if (leftSideRootMenu) {
        leftSideRootMenu.style.display = "block";
      }
    };
  }, []);
};

export default useHideLeftSideRootMenu;
