import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { formatNumber } from "../../../../../../../shared/utilities/formatters";
import { GlAccount, InvoiceDeal } from "../../../../../../api/adminApi";
import {
  InvoiceAccount,
  InvoiceDetailsConfidenceWarning,
  InvoiceDetailsLine,
  InvoiceDetailsLineError,
} from "../invoiceDetailsState";
import InvoiceLineAccountSelect from "./InvoiceLineAccountSelect";
import InvoiceLineAmount from "./InvoiceLineAmount";
import InvoiceLineDealSelect from "./InvoiceLineDealSelect";
import InvoiceLineEditTextarea from "./InvoiceLineEditTextarea";
import InvoicesLineWarningCell from "./InvoicesLineWarningCell";

export const getInvoiceLinesColumns = (
  glAccounts: GlAccount[],
  validationErrors: Record<string, InvoiceDetailsLineError> | undefined,
  confidenceWarnings: InvoiceDetailsConfidenceWarning,
  deals: InvoiceDeal[],
  disabled?: boolean
): GridColDef<InvoiceDetailsLine>[] => {
  const columns: GridColDef<InvoiceDetailsLine>[] = [];
  if (!disabled) {
    columns.push({
      field: "warning",
      headerName: "",
      sortable: false,
      width: 44,
      minWidth: 44,
      align: "center",
      cellClassName: "grid-cell-warning",
      renderCell: ({ row }) => {
        const lineError = validationErrors?.[row.id];
        const lineConfidence = confidenceWarnings.lines.find((l) => l.id === row.id);

        return (
          <InvoicesLineWarningCell
            account={{
              validationError: lineError?.account,
            }}
            amount={{
              validationError: lineError?.amount,
              isLowConfident: lineConfidence?.amount,
            }}
            description={{
              validationError: lineError?.description,
              isLowConfident: lineConfidence?.description,
            }}
          />
        );
      },
    });
  }

  const amountValueFormatter = (value: number) =>
    formatNumber(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const editableColumns: GridColDef<InvoiceDetailsLine>[] = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: false,
      minWidth: 160,
      editable: !disabled,
      cellClassName: ({ row }) => {
        const descriptionError = validationErrors?.[row.id]?.description;
        const isLowConfident = confidenceWarnings.lines.find((l) => l.id === row.id)?.description;
        return getEditableCellClassName(descriptionError, isLowConfident);
      },
      renderEditCell: (params) => <InvoiceLineEditTextarea params={params} />,
      renderCell: ({ row }) => <Typography noWrap>{row.description}</Typography>,
    },
    {
      field: "account",
      headerName: "Account",
      sortable: false,
      flex: 1,
      minWidth: 160,
      editable: !disabled,
      type: "singleSelect",
      cellClassName: ({ row }) => getEditableCellClassName(validationErrors?.[row.id]?.account),
      renderEditCell: (params: GridRenderCellParams<InvoiceDetailsLine, InvoiceAccount>) => (
        <InvoiceLineAccountSelect params={params} glAccounts={glAccounts} />
      ),
      renderCell: ({ row }) => (
        <Typography noWrap>
          {row.account?.no} {row.account?.name}
        </Typography>
      ),
    },
    {
      field: "dealId",
      headerName: "Deal",
      sortable: false,
      flex: 1,
      minWidth: 160,
      editable: !disabled,
      type: "singleSelect",
      cellClassName: "grid-cell-editable",
      renderEditCell: (params: GridRenderCellParams<InvoiceDetailsLine, string>) => (
        <InvoiceLineDealSelect params={params} deals={deals} />
      ),
      renderCell: ({ row }) => (
        <Typography noWrap>{deals.find((deal) => deal.id === row.dealId)?.description}</Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      flex: 0.5,
      minWidth: 120,
      align: "right",
      headerAlign: "right",
      editable: !disabled,
      cellClassName: ({ row }) => {
        const amountError = validationErrors?.[row.id]?.amount;
        const isLowConfident = confidenceWarnings.lines.find((l) => l.id === row.id)?.amount;
        return getEditableCellClassName(amountError, isLowConfident);
      },
      renderEditCell: (params: GridRenderEditCellParams<InvoiceDetailsLine, number | undefined>) => (
        <InvoiceLineAmount
          value={params.value}
          id={params.id}
          field={params.field}
          valueFormatter={amountValueFormatter}
        />
      ),
      valueFormatter: (value) => (value === undefined ? value : amountValueFormatter(value)),
      valueGetter: (_, row) => row.amount,
      renderCell: ({ formattedValue }) => <Typography noWrap>{formattedValue}</Typography>,
    },
  ];
  columns.push(...editableColumns);

  if (!disabled) {
    columns.push({
      field: "actions",
      headerName: "",
      sortable: false,
      width: 44,
      minWidth: 44,
      align: "right",
      cellClassName: "grid-row-actions grid-cell-no-right-border",
      headerClassName: "grid-cell-no-right-border",
      renderCell: ({ api }) => (
        <>
          {api.getRowsCount() > 1 && (
            <Tooltip arrow title="Delete row">
              <IconButton sx={{ width: "100%" }}>
                <ClearRoundedIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    });
  }
  return columns;
};

const getEditableCellClassName = (validationError?: string, isLowConfident?: boolean) => {
  return `grid-cell-editable ${validationError ? "grid-cell-error" : isLowConfident ? "grid-cell-low-confident" : ""}`;
};
