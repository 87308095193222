import CheckIcon from "@mui/icons-material/CheckRounded";
import {
  GridColDef,
  GridColType,
  GridRenderCellParams,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import { ReactNode } from "react";
import { formatDate, formatDateTime } from "../../../../../shared/utilities/dateUtils";
import { formatFileSize } from "../../../../../shared/utilities/fileHelper";
import { FileInfo, GridColumnAttributes, GridColumnDefinition, GridValueType } from "../../../../api/types/fileTypes";
import FileNameCell from "./FileNameCell";
import FilesGridActionsCell from "./FilesGridActionsCell";

const valueTypeMap: Record<GridValueType, GridColType> = {
  String: "string",
  Number: "number",
  Date: "date",
  DateTime: "dateTime",
  Boolean: "boolean",
};

const createValueFormatter = (
  valueType: GridValueType,
  attributes: GridColumnAttributes | undefined
): GridValueFormatter<FileInfo> | undefined => {
  switch (valueType) {
    case "Date": {
      return (value) => formatDate(value);
    }
    case "DateTime": {
      return (value) => formatDateTime(value);
    }
    case "Number": {
      if (attributes?.valueFormatter === "FileSize") {
        return (value) => (value ? formatFileSize(Number(value)) : "");
      }

      return undefined;
    }
    default: {
      return undefined;
    }
  }
};

const createValueGetter = (valueType: GridValueType): GridValueGetter<FileInfo> | undefined => {
  switch (valueType) {
    case "Date": {
      return (value) => {
        const date = parseISO(value);
        return date;
      };
    }
    case "DateTime": {
      return (value) => {
        const date = parseISO(value);
        return date;
      };
    }
    default: {
      return undefined;
    }
  }
};

const createCellRenderer = (
  valueType: GridValueType,
  attributes: GridColumnAttributes | undefined
): ((p: GridRenderCellParams<FileInfo>) => ReactNode) | undefined => {
  if (attributes?.cellComponent === "FileNameCell") {
    // eslint-disable-next-line react/display-name
    return ({ value, row }: { value?: unknown; row: FileInfo }) =>
      typeof value === "string" ? <FileNameCell row={row} fileName={value} /> : null;
  }

  switch (valueType) {
    case "Boolean": {
      // eslint-disable-next-line react/display-name
      return ({ value }: { value?: unknown }) => (value ? <CheckIcon color="primary" /> : null);
    }
    default: {
      return undefined;
    }
  }
};

const mapColumnDefinition = (columnDefinition: GridColumnDefinition): GridColDef<FileInfo> => ({
  field: columnDefinition.fieldName,
  headerName: columnDefinition.headerName,
  sortable: false,
  flex: 1,
  ...columnDefinition.layout,
  type: valueTypeMap[columnDefinition.valueType],
  valueFormatter: createValueFormatter(columnDefinition.valueType, columnDefinition.attributes),
  valueGetter: createValueGetter(columnDefinition.valueType),
  renderCell: createCellRenderer(columnDefinition.valueType, columnDefinition.attributes),
});

const actionsColumn: GridColDef<FileInfo> = {
  field: "actions",
  headerName: "",
  sortable: false,
  width: 144,
  align: "right",
  cellClassName: "grid-row-actions",
  renderCell: ({ row }) => <FilesGridActionsCell row={row} />,
};

export const getColumns = (columnDefinitions: GridColumnDefinition[]): GridColDef[] => [
  ...columnDefinitions.map(mapColumnDefinition),
  actionsColumn,
];
