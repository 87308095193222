import { useState } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { ObjectFieldValues } from "../../../../api/types/objectTypes";
import { getCheckedGridSx } from "../../../common/grid/gridStyles";
import { columnDefinitions } from "./dataRequestTemplateCompaniesGridDataProvider";
import DataRequestTemplateCompaniesGridToolbar from "./DataRequestTemplateCompaniesGridToolbar";

interface Props {
  rows: ObjectFieldValues[];
  hasPermissionsToManage: boolean;
  onRemoveCompanies: (ids: string[]) => void;
}

const DataRequestTemplateCompaniesGrid = ({ rows, hasPermissionsToManage, onRemoveCompanies }: Props) => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<ObjectFieldValues>
      rows={rows}
      columns={columnDefinitions}
      checkboxSelection={hasPermissionsToManage}
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      noRowsText="No companies"
      disableColumnReorder
      disableColumnSorting
      slots={{
        toolbar: () =>
          showToolbar ? (
            <DataRequestTemplateCompaniesGridToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              onRemoveCompanies={onRemoveCompanies}
            />
          ) : null,
      }}
    />
  );
};

export default DataRequestTemplateCompaniesGrid;
