import { logError } from "../../../../../shared/logging";
import { AnyRecord } from "../../../../../shared/types";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import {
  FilterHandler,
  MultiSelectFieldValue,
  MultiSelectFilter,
  MultiSelectFilterDefinition,
  MultiSelectFilterOperator,
  MultiSelectFilterValue,
  OperatorOption,
  SelectOption,
} from "../filterTypes";

export const multiSelectFilterOperatorOptions: readonly OperatorOption<MultiSelectFilterOperator>[] = [
  { label: "is", value: "one_of" },
  { label: "is not", value: "not_one_of" },
  { label: "is empty", value: "empty" },
  { label: "is not empty", value: "not_empty" },
] as const;

export const multiSelectFilterHandler: FilterHandler<
  MultiSelectFilterValue,
  MultiSelectFieldValue,
  MultiSelectFilterDefinition
> = {
  getDefaultValue: (definition: MultiSelectFilterDefinition) => ({
    operator: definition.operatorOptions?.[0] ?? "one_of",
    selectedValues: [],
  }),

  isActive: ({ operator, selectedValues }: MultiSelectFilterValue) => {
    switch (operator) {
      case "empty": {
        return true;
      }
      case "not_empty": {
        return true;
      }
      case "one_of": {
        return !!selectedValues && selectedValues.length > 0;
      }
      case "not_one_of": {
        return !!selectedValues && selectedValues.length > 0;
      }
    }
  },

  formatValue: ({ operator, selectedValues, selectedLabels }: MultiSelectFilterValue) => {
    switch (operator) {
      case "empty": {
        return "is empty";
      }
      case "not_empty": {
        return "is not empty";
      }
      case "one_of": {
        if (!selectedValues) {
          return "";
        }

        return `is "${(selectedLabels ?? selectedValues).join(", ")}"`;
      }
      case "not_one_of": {
        if (!selectedValues) {
          return "";
        }

        return `is not "${(selectedLabels ?? selectedValues).join(", ")}"`;
      }
    }
  },

  matchesFieldValue: ({ operator, selectedValues }: MultiSelectFilterValue, fieldValue: MultiSelectFieldValue) => {
    switch (operator) {
      case "empty": {
        return !fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0);
      }

      case "not_empty": {
        return !!fieldValue && (!Array.isArray(fieldValue) || fieldValue.length > 0);
      }

      case "one_of": {
        if (!selectedValues || selectedValues.length === 0) {
          return true;
        }
        if (!fieldValue) {
          return false;
        }
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((value) => !!value && selectedValues.includes(value));
        }
        return selectedValues.includes(fieldValue);
      }

      case "not_one_of": {
        if (!selectedValues || selectedValues.length === 0) {
          return true;
        }
        if (!fieldValue) {
          return true;
        }
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((value) => !value || !selectedValues.includes(value));
        }
        return !selectedValues.includes(fieldValue);
      }
    }
  },
};

export const getSortedDistinctOptions = <R extends AnyRecord>(
  filter: MultiSelectFilter<R>,
  allRows: R[]
): SelectOption[] => {
  const { getFieldValue, predefinedOptions } = filter;
  if (predefinedOptions) {
    return predefinedOptions;
  }

  if (!getFieldValue) {
    logError(
      "Either predefinedOptions or getFieldValue must be set for non-async multi-select filter",
      `Filter: '${filter.id}'`
    );
    return [];
  }

  const options = allRows
    .flatMap((row) => {
      const fieldValue = getFieldValue(row);
      return Array.isArray(fieldValue) ? fieldValue : [fieldValue];
    })
    .filter(Boolean) as string[];

  return distinct(options)
    .sort((a, b) => a.localeCompare(b))
    .map((value) => ({ value, label: value }));
};
