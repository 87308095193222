import axios from "axios";
import { clientCodeHeader } from "../../shared/api/clientApiContext";
import { ApiResponse } from "../../shared/api/types";
import sharedBiClient, { apiReports, uiReports } from "../../shared/reporting/api/biClient";
import {
  DuplicateReportRequest,
  GenerateApiReportRequest,
  ReportInfo,
} from "../../shared/reporting/api/biClient.types";
import { getBackendBaseUrl } from "../../shared/variables";

export interface CancellationToken {
  cancel: () => void;
}

interface ReportValidationResultBase {
  name: NameValidationResult;
  group: GroupValidationResult;
  conditions?: ConditionValidationResult[] | null;
}

export interface PivotReportValidationResponse extends ReportValidationResultBase {
  reportType: "Pivot";
  rows?: DimensionValidationResult[] | null;
  columns?: DimensionValidationResult[] | null;
  measures?: MeasuresValidationResult[] | null;
}

export interface TabularReportValidationResponse extends ReportValidationResultBase {
  reportType: "Tabular";
  fields?: TabularFieldValidationResult[] | null;
}

export interface ChartReportValidationResponse extends ReportValidationResultBase {
  reportType: "Chart";
  arguments?: DimensionValidationResult[] | null;
  measures?: MeasuresValidationResult[] | null;
}

interface TabularFieldValidationResult {
  guid: string;
  dimension?: DimensionValidationResult;
  measure?: MeasuresValidationResult;
}

interface GroupValidationResult {
  id?: string;
  name: string;
  exist: boolean;
}

interface NameValidationResult {
  exist: boolean;
}

export interface DimensionValidationResult {
  name: string;
  caption: string;
  valid: boolean;
  isCustom: boolean;
  message?: string;
}

export interface ConditionValidationResult extends DimensionValidationResult {
  missedValues?: null[] | null;
}

export interface MeasuresValidationResult {
  guid: string;
  caption: string;
  valid: boolean;
  isCustom: boolean;
  measureUnits?: null[] | null;
  customConditions: ConditionValidationResult[];
  message?: string;
}

interface ValidateReportRequest {
  reportId: string;
  fromCompanyCode: string;
  toCompanyCode: string;
}

export type ReportValidationResponse =
  | PivotReportValidationResponse
  | TabularReportValidationResponse
  | ChartReportValidationResponse;

interface CopyReportRequest extends ValidateReportRequest {
  copyConditionValues: boolean;
  override: boolean;
  groupIdSelectedByUser?: string;
}

interface CopyReportResponse {
  reportId: string;
}

export interface CreateReportGroup {
  name: string | undefined;
  toCompanyCode: string;
}

export interface OrganizationReportInfo extends ReportInfo {
  groupName: string;
}

export interface UiReportsApiClient {
  renameReport: typeof sharedBiClient.uiReports.renameReport;
  changeReportGroup: typeof sharedBiClient.uiReports.changeReportGroup;
  deleteReports: typeof sharedBiClient.uiReports.deleteReports;
  generateApiReport: typeof generateApiReport;
  duplicateReport: (request: DuplicateReportRequest) => Promise<ApiResponse<ReportInfo>>;
}

export interface ApiReportsApiClient {
  renameReport: typeof sharedBiClient.apiReports.renameReport;
  changeReportGroup: typeof sharedBiClient.apiReports.changeReportGroup;
  deleteReports: typeof sharedBiClient.apiReports.deleteReports;
  duplicateReport: (request: DuplicateReportRequest) => Promise<ApiResponse<ReportInfo>>;
}

const baseUri = getBackendBaseUrl("reporting");

const validateReport = (
  body: ValidateReportRequest,
  done: (data: ApiResponse<ReportValidationResponse>) => void,
  error: (reason: string) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<ReportValidationResponse>>(`${baseUri}/${uiReports}/validate-copying`, body, {
      headers: { ...clientCodeHeader() },
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => error(reason.message));
  return { cancel: () => controller.abort() };
};

const copyReport = (
  body: CopyReportRequest,
  done: (data: ApiResponse<CopyReportResponse>) => void,
  error: (reason: string) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<CopyReportResponse>>(`${baseUri}/${uiReports}/copy`, body, {
      headers: { ...clientCodeHeader() },
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => error(reason.message));
  return { cancel: () => controller.abort() };
};

const createReportGroups = async (groups: CreateReportGroup[]) => {
  const { data } = await axios.post<ApiResponse<ReportInfo[]>>(
    `${baseUri}/${uiReports}/groups/create`,
    { groups },
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const generateApiReport = async (request: GenerateApiReportRequest) => {
  const { data } = await axios.post<ApiResponse<ReportInfo>>(`${baseUri}/${uiReports}/generate-api-report`, request, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getReportsApi = (endpoint: typeof uiReports | typeof apiReports) => {
  const duplicateReport = async (request: DuplicateReportRequest) => {
    const { data } = await axios.post<ApiResponse<ReportInfo>>(`${baseUri}/${endpoint}/duplicate`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  return {
    duplicateReport,
  };
};

const getOrganizationReportsApi = (endpoint: typeof uiReports | typeof apiReports) => {
  const getOrganizationReports = async () => {
    const { data } = await axios.get<ApiResponse<OrganizationReportInfo[]>>(
      `${baseUri}/${endpoint}/organization-reports`,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  return {
    getOrganizationReports,
  };
};

const biApi = {
  uiOrganizationReports: {
    getOrganizationReports: getOrganizationReportsApi(uiReports).getOrganizationReports,
  },
  apiOrganizationReports: {
    getOrganizationReports: getOrganizationReportsApi(apiReports).getOrganizationReports,
  },
  validateReport,
  copyReport,
  addReportGroup: sharedBiClient.reportGroup.addReportGroup,
  updateReportGroup: sharedBiClient.reportGroup.updateReportGroup,
  deleteReportGroup: sharedBiClient.reportGroup.deleteReportGroup,
  replaceReportGroup: sharedBiClient.reportGroup.replaceReportGroup,
  reorderReportGroups: sharedBiClient.reportGroup.reorderReportGroups,
  getReportGroups: sharedBiClient.reportGroup.getReportGroups,
  createReportGroups,
  uiReports: {
    renameReport: sharedBiClient.uiReports.renameReport,
    changeReportGroup: sharedBiClient.uiReports.changeReportGroup,
    deleteReports: sharedBiClient.uiReports.deleteReports,
    generateApiReport: generateApiReport,
    ...getReportsApi(uiReports),
  } as UiReportsApiClient,
  apiReports: {
    renameReport: sharedBiClient.apiReports.renameReport,
    changeReportGroup: sharedBiClient.apiReports.changeReportGroup,
    deleteReports: sharedBiClient.apiReports.deleteReports,
    ...getReportsApi(apiReports),
  },
};

export default biApi;
