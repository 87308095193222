import { useEffect } from "react";

interface IntercomSettings {
  api_base: string;
  app_id: string;
  name: string;
  email: string;
  created_at: string;
  user_hash: string;
  user_id: string;
}

declare global {
  interface Window {
    intercomSettings: IntercomSettings;
    Intercom?: (arg: "reattach_activator" | "update", settings?: IntercomSettings) => void;
  }
}

interface Props {
  fullName: string;
  email: string;
  settings: {
    intercomUserId: string;
    intercomUserHash: string;
    intercomUserSignedUp: string;
    intercomAppId: string;
  };
}

const INTERCOM_API_BASE_URL = "https://api-iam.intercom.io";

const Intercom = ({ fullName, email, settings }: Props) => {
  window.intercomSettings = {
    api_base: INTERCOM_API_BASE_URL,
    app_id: settings.intercomAppId,
    name: fullName,
    email,
    created_at: settings.intercomUserSignedUp,
    user_hash: settings.intercomUserHash,
    user_id: settings.intercomUserId,
  };

  useEffect(() => {
    if (typeof window.Intercom === "function") {
      window.Intercom("reattach_activator");
      window.Intercom("update", window.intercomSettings);
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}`;
    document.body.appendChild(script);
    return () => {
      if (!window.Intercom) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default Intercom;
