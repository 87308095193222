import { Box, Typography } from "@mui/material";
import { GridApi, GridGroupingColDefOverride, GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import BadgeDetached from "../../../../shared/components/BadgeDetached";
import ExpandCollapseAllButton from "../../../../shared/components/ExpandCollapseAllButton";
import { ExpandCollapseButton } from "../../../../shared/reporting/components/reports/ExpandCollapseButton";
import { ClientInfo } from "../../../api/types/clientTypes";
import CompanyTypeBadge from "../../common/CompanyTypeBadge";
import { DataGridGroup } from "../../common/grid/gridTypes";
import ClientLogo from "./ClientLogo";

export const getNameGroupingColumn = (companies: DataGridGroup<ClientInfo>[]): GridGroupingColDefOverride => {
  return {
    hideDescendantCount: true,
    headerName: "Name",
    sortable: true,
    aggregable: false,
    hideable: false,
    pinnable: false,
    filterable: false,
    flex: 1,
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      const direction = (cellParams1.api as GridApi).getSortModel()?.[0]?.sort ?? "asc";
      if (cellParams1.rowNode.type === "group" && cellParams2.rowNode.type === "group") {
        const firstGroupOrder = companies.find((r) => r.group === cellParams1.value)?.groupOrder || 0;
        const secondGroupOrder = companies.find((r) => r.group === cellParams2.value)?.groupOrder || 0;
        return direction === "asc" ? firstGroupOrder - secondGroupOrder : secondGroupOrder - firstGroupOrder;
      }
      const firstTemplateName = companies.find((c) => c.clientCode === cellParams1.rowNode.id)?.title || "";
      const secondTemplateName = companies.find((c) => c.clientCode === cellParams2.rowNode.id)?.title || "";
      return firstTemplateName.localeCompare(secondTemplateName);
    },
    renderHeader: (params) => <GroupHeader headerName={params.colDef.headerName} />,
    renderCell: (params: GridRenderCellParams<ClientInfo, unknown, unknown>) => {
      if (params.rowNode.type === "group") {
        return <GroupName params={params} />;
      }
      return <NameColumn company={params.row} />;
    },
  };
};

function GroupHeader({ headerName }: { headerName: string | undefined }) {
  return (
    <>
      <ExpandCollapseAllButton />
      <Typography variant="subtitle2" sx={{ pl: 0.75 }}>
        {headerName}
      </Typography>
    </>
  );
}
function GroupName({ params }: { params: GridRenderCellParams }) {
  const apiRef = useGridApiContext();
  const expanded = params.rowNode.type === "group" && params.rowNode.childrenExpanded === true;

  return (
    <>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => {
          apiRef.current?.setRowChildrenExpansion(params.id, value);
        }}
      />
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, pl: 1 }}>
        <Typography variant="subtitle2">{params.value || "Unmanaged"}</Typography>
        <BadgeDetached
          badgeContent={params.rowNode.type === "group" && params.rowNode.children.length}
          color="secondary"
          sx={(t) => ({ color: t.palette.secondary.main })}
        />
      </Box>
    </>
  );
}

function NameColumn({ company }: { company: ClientInfo }) {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center", pl: 6, maxWidth: "100%" }}>
      {company.branding.logoMarkUrl && <ClientLogo size="small" logoSrc={company.branding.logoMarkUrl} />}
      <Typography
        variant="subtitle2"
        noWrap
        sx={(theme) => ({
          "&:hover": {
            color: theme.palette.primary.main,
            cursor: "pointer",
          },
        })}
      >
        {company.title}
      </Typography>
      {company.companyType && <CompanyTypeBadge type={company.companyType} />}
    </Box>
  );
}
