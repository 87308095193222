import { Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Link as RouterLink } from "react-router";
import { ObjectFieldValues } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";

const NameCell = ({ row }: { row: ObjectFieldValues }) => {
  const { clientCode } = useClientContext();

  return (
    <Link
      component={RouterLink}
      to={`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.portfolioCompanies}/${row.id}?tab=portfoliocontacts`}
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      underline="none"
      sx={(t) => ({
        "&:hover": {
          textDecoration: "underline",
          color: t.palette.primary.main,
        },
      })}
    >
      {row.name}
    </Link>
  );
};

export const columnDefinitions: GridColDef<ObjectFieldValues>[] = [
  {
    field: "name",
    headerName: "Company",
    flex: 1,
    renderCell: ({ row }) => <NameCell row={row} />,
  },
];
