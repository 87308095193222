import { isBefore, isValid, startOfToday } from "date-fns";
import { formatDateOnly } from "../../../../../../shared/utilities/dateUtils";
import { CreateDataCollectionRequestRequest } from "../../../../../api/types/dataCollectionTypes";

interface NewDataRequestForm {
  templateId: string;
  name: string;
  reportingDate: Date | null;
  dueDate: Date | null;
  scenarioId: string;
  isScenarioConfigurable: boolean;
  touchedFields: ValidationFieldKey[];
}

const validationFieldKeys = ["templateId", "name", "reportingDate", "dueDate", "scenarioId"] as const;
export type ValidationFieldKey = (typeof validationFieldKeys)[number];

interface FormValidationResult {
  isFormValid: boolean;
  validationErrors: Partial<Record<ValidationFieldKey, string | undefined>>;
}

export const getInitialNewDataRequestForm = (): NewDataRequestForm => ({
  templateId: "",
  name: "",
  reportingDate: null,
  dueDate: null,
  scenarioId: "",
  isScenarioConfigurable: false,
  touchedFields: [],
});

export const formToCreateRequestPayload = (
  form: NewDataRequestForm
): CreateDataCollectionRequestRequest | undefined => {
  if (
    !form.templateId ||
    !form.name ||
    !form.reportingDate ||
    !isValid(form.reportingDate) ||
    !form.dueDate ||
    !isValid(form.dueDate) ||
    isBefore(form.dueDate, startOfToday())
  ) {
    return undefined;
  }

  return {
    templateId: form.templateId,
    name: form.name.trim(),
    reportingDate: formatDateOnly(form.reportingDate),
    dueDate: formatDateOnly(form.dueDate),
    scenarioId: form.isScenarioConfigurable ? form.scenarioId : undefined,
  };
};

export const validateForm = (form: NewDataRequestForm): FormValidationResult => {
  const validationErrors: Partial<Record<ValidationFieldKey, string | undefined>> = {};

  if (!form.templateId) {
    validationErrors.templateId = "Template is required";
  }

  if (!form.name) {
    validationErrors.name = "Name is required";
  }

  if (!form.reportingDate || !isValid(form.reportingDate)) {
    validationErrors.reportingDate = "Reporting date is required";
  }

  if (!form.dueDate || !isValid(form.dueDate)) {
    validationErrors.dueDate = "Due date is required";
  } else if (isBefore(form.dueDate || new Date(), startOfToday())) {
    validationErrors.dueDate = "Due date must not be in the past";
  }

  if (form.isScenarioConfigurable && !form.scenarioId) {
    validationErrors.scenarioId = "Scenario is required";
  }

  const isFormValid = Object.values(validationErrors).every((e) => !e);

  validationFieldKeys.forEach((key) => {
    if (!form.touchedFields.includes(key)) {
      validationErrors[key] = undefined;
    }
  });

  return {
    isFormValid,
    validationErrors,
  };
};
