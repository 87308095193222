import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import CreateDataRequestTemplateDialog from "./CreateDataRequestTemplateDialog";
import { useDataRequestSetupPageContext } from "./DataRequestSetupPageContext";
import DataRequestTemplatesGrid from "./DataRequestTemplatesGrid";

const DataRequestTemplatesView = () => {
  const { hasPermissionsToManage } = useDataRequestSetupPageContext();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [templates, fetchTemplatesError, { setData: setTemplates }] = useFetch(adminApi.getDataRequestTemplates);

  const [isCreateTemplateDialogOpen, setCreateTemplateDialogOpen] = useState(false);

  if (fetchTemplatesError) {
    logError(fetchTemplatesError, "[DataRequestTemplatesView] getDataCollectionRequestTemplates");
    return <DataLoadingFailed title="Could not load data request templates" />;
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const handleTemplateCreated = (template: DataCollectionRequestTemplateVersion) => {
    setCreateTemplateDialogOpen(false);
    setTemplates((prev) => [...(prev ?? []), template]);
  };

  const allTemplates = templates ?? [];

  const filteredTemplates = searchTerm
    ? allTemplates.filter((t) => t.name.toLowerCase().includes(searchTerm))
    : allTemplates;

  const templateNames = allTemplates.map((t) => t.name);

  return (
    <>
      <Stack height="100%" width="100%" spacing={2} pt={2.5} px={3} flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <RecordCounter records={filteredTemplates.length} totalRecords={allTemplates.length} />
          <Stack direction="row" spacing={1}>
            <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} />
            {hasPermissionsToManage && (
              <Button variant="contained" startIcon={<AddIcon />} onClick={() => setCreateTemplateDialogOpen(true)}>
                Add New
              </Button>
            )}
          </Stack>
        </Box>

        <DataRequestTemplatesGrid rows={filteredTemplates} isLoading={templates === undefined} />
      </Stack>

      {isCreateTemplateDialogOpen && (
        <CreateDataRequestTemplateDialog
          onClose={() => setCreateTemplateDialogOpen(false)}
          onCreated={handleTemplateCreated}
          existingTemplateNames={templateNames}
        />
      )}
    </>
  );
};

export default DataRequestTemplatesView;
