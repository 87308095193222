import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataImportInfo } from "../../../../api/types/dataImportTypes";
import { nextPageAction } from "../../../../state/paginatedState";
import { useImportDataContext } from "../ImportDataContext";
import { columnDefinitions } from "./importDataGridProvider";

interface Props {
  isLoading: boolean;
}

const ImportDataGrid = ({ isLoading }: Props) => {
  const { pageState, setPageState } = useImportDataContext();

  const handleScrollEnd = () => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  };

  return (
    <DataGrid<DataImportInfo>
      columns={columnDefinitions}
      getRowId={getRowId}
      onRowsScrollEnd={handleScrollEnd}
      rows={pageState.items}
      noRowsText="No imports yet"
      disableColumnReorder
      disableColumnSorting
      loading={isLoading}
    />
  );
};

const getRowId = (row: DataImportInfo) => row.id;

export default ImportDataGrid;
