import { Box, Button, Container, Grid2, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router";
import { useClientContext } from "../../../context/ClientContext";
import FundAccountingIcon from "../../../icons/FundAccountingIcon";
import InvestorPortalIcon from "../../../icons/InvestorPortalIcon";
import ReportingIcon from "../../../icons/ReportingIcon";
import { pageRoutes } from "../../../routes";
import { getBusinessCentralUrl } from "../../../utilities/navigationHelper";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import InvestorPortalImpersonateButton from "../investor-portal/InvestorPortalImpersonateButton";
import AppCard from "./AppCard";

const ManagerHomePage = () => {
  const {
    clientCode,
    clientTitle,
    businessCentralApp,
    investorPortalApp,
    reportingPortalApp,
    hasAnyPermission,
    settings,
  } = useClientContext();

  const hasAccessToFundOps = hasAnyPermission(["ViewFundOps"]);
  const canConfigureInvestorPortal = hasAnyPermission(["ViewInvestorPortalSettings", "ManageInvestorPortalSettings"]);
  const hasAccessToReportingPortal = hasAnyPermission(["ManageReports", "ViewReports"]);
  const canInspectInvestorPortal = hasAnyPermission(["ViewInvestorPortalOnBehalfOfInvestor"]);
  const businessCentralUrl = getBusinessCentralUrl(clientCode, settings.businessCentralSettings);

  return (
    <>
      <GeneralPageHeader title="Home" />
      <Container maxWidth={false}>
        <Box py={2} px={1}>
          <Typography variant="h4" mb={3}>{`Welcome to ${clientTitle} Admin Portal`}</Typography>
          <Typography variant="h5" mb={2}>
            Apps
          </Typography>
          <Grid2 container spacing={3}>
            <Grid2>
              {hasAccessToFundOps && businessCentralApp && businessCentralUrl && (
                <AppCard title="Fund Operations" Icon={FundAccountingIcon}>
                  <Button
                    component={Link}
                    href={businessCentralUrl}
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open
                  </Button>
                </AppCard>
              )}
            </Grid2>
            <Grid2>
              {(canConfigureInvestorPortal || canInspectInvestorPortal) && investorPortalApp && (
                <AppCard title="Investor Portal" Icon={InvestorPortalIcon}>
                  <InvestorPortalImpersonateButton variant="text" label="Inspect" />
                  {canConfigureInvestorPortal && (
                    <Button
                      component={RouterLink}
                      to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalSettings}`}
                      color="secondary"
                    >
                      Configure
                    </Button>
                  )}
                </AppCard>
              )}
            </Grid2>
            {reportingPortalApp && hasAccessToReportingPortal && (
              <Grid2>
                <AppCard title="SmartViews" Icon={ReportingIcon}>
                  <Button
                    component={RouterLink}
                    to={`/${clientCode}/${pageRoutes.smartViews}/${pageRoutes.companyReports}`}
                    color="primary"
                  >
                    Open
                  </Button>
                </AppCard>
              </Grid2>
            )}
          </Grid2>
        </Box>
      </Container>
    </>
  );
};

export default ManagerHomePage;
