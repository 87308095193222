import { Stack, Typography } from "@mui/material";
import { SingleExtendedMetricTableBlockContent } from "../../../../api/inputFormTypes";
import BlockSection from "../BlockSection";
import SingleExtendedMetricTableInputForm from "./SingleExtendedMetricTableInputForm";

interface Props {
  blockId: string;
  content: SingleExtendedMetricTableBlockContent;
}

const SingleExtendedMetricTableFormBlock = ({ blockId, content }: Props) => {
  return (
    <Stack flex={1} spacing={2.5}>
      <BlockSection>
        <Typography variant="h6">{content.table.name}</Typography>
        <SingleExtendedMetricTableInputForm
          blockId={blockId}
          metricId={content.metricId}
          metricName={content.metricName}
          dataType={content.dataType}
          defaultFormat={content.defaultFormat}
          extensionConfiguration={content.extensionConfiguration}
          columnDefinitions={content.table.columns}
          rowDefinitions={content.table.rows}
        />
      </BlockSection>
    </Stack>
  );
};

export default SingleExtendedMetricTableFormBlock;
