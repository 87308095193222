import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment } from "react";

interface Props {
  title: string;
  properties: Array<[string, string]>;
}

const PropertyValue = ({ property }: { property: [string, string] }) => {
  const [label, value] = property;

  return (
    <>
      <Typography variant="caption" color="text.secondary">
        {label}:{" "}
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </>
  );
};

const InlinePropertyBox = ({ title, properties }: Props) => {
  return (
    <Stack spacing={1} p={1.25} width="100%" bgcolor={grey[100]}>
      <Typography variant="subtitle2" color="text.secondary">
        {title}
      </Typography>

      <Stack spacing={1} useFlexGap direction="row" alignItems="center" flexWrap="wrap">
        {properties.map((prop, i) => (
          <Fragment key={prop[0]}>
            <PropertyValue property={prop} />
            {i < properties.length - 1 && <Typography variant="caption">•</Typography>}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};

export default InlinePropertyBox;
