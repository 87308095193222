import OpenIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, CardActions, CardContent, Stack, SvgIconProps, Switch, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { IntegrationAppStatus } from "../../../../api/types/clientTypes";
import ApplicationStatusTag from "../../../common/ApplicationStatusTag";
import CardTitle from "./CardTitle";

interface Props {
  title: string;
  text: string;
  Icon?: FC<SvgIconProps>;
  logoSrc?: string;
  appStatus: IntegrationAppStatus;
  configurationRoute?: string;
  configurationLink?: string;
  moreInfoLink?: string;
  cardId: string; // Needed to link Intercom
}

const IntegrationsCard = ({
  title,
  appStatus,
  text,
  Icon,
  logoSrc,
  moreInfoLink,
  configurationRoute,
  configurationLink,
  cardId,
}: Props) => {
  const navigate = useNavigate();

  const handleConfigureClick = () => {
    if (configurationRoute) {
      navigate(configurationRoute);
    } else if (configurationLink) {
      window.open(configurationLink, "_blank");
    }
  };

  return (
    <Card variant="outlined" sx={(theme) => ({ width: theme.spacing(53), p: theme.spacing(1) })}>
      <CardContent>
        <Stack spacing={2} sx={(theme) => ({ height: theme.spacing(15) })}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <CardTitle id={cardId} title={title} Icon={Icon} logoSrc={logoSrc} />
            <ApplicationStatusTag appStatus={appStatus} />
          </Box>
          <Typography color="text.secondary">{text}</Typography>
        </Stack>
      </CardContent>
      <CardActions sx={(theme) => ({ ml: theme.spacing(1), pt: 0, minHeight: theme.spacing(5) })}>
        <Stack direction="row" spacing={1}>
          {(configurationRoute || configurationLink) && appStatus !== undefined && (
            <Button variant="outlined" color="secondary" onClick={handleConfigureClick}>
              Configure
            </Button>
          )}
          {moreInfoLink && (
            <Button variant="text" color="secondary" endIcon={<OpenIcon />} href={moreInfoLink} target="_blank">
              More Info
            </Button>
          )}
        </Stack>
        <HorizontalFill />
        {appStatus !== "NoStatus" && <Switch checked={appStatus === "Published" || appStatus === "Error"} disabled />}
      </CardActions>
    </Card>
  );
};

export default IntegrationsCard;
