import { Alert, Container, Stack } from "@mui/material";
import { useReducer } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import { formatString } from "../../../../shared/utilities/stringFormatter";
import adminApi from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import { useUserContext } from "../../../context/UserContext";
import { useLocalization } from "../../../hooks/useLocalization";
import PassthroughLogo from "../../../images/passthrough.png";
import IntegrationHeader from "../integration/IntegrationHeader";
import PassthroughConnectionActions from "./PassthroughConnectionActions";
import PassthroughSetup from "./PassthroughSetup";
import PassthroughTabs from "./PassthroughTabs";
import { getInitialState, reducer } from "./passthroughPageState";

const makeTestConnection = withErrorHandling(adminApi.makePassthroughTestConnection);
const toggleIntegration = withErrorHandling(adminApi.togglePassthroughIntegration);
const updateConfiguration = withErrorHandling(adminApi.updatePassthroughConfiguration);

const PassThroughPage = () => {
  usePageTitle("Passthrough");

  const { passthrough: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { refreshUserData } = useUserContext();
  const { passthroughApp, hasPermissions } = useClientContext();
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const canManagePassthroughIntegration = hasPermissions(["ManageClientIntegrations"]);

  const [connectionStateResult, connectionStateFetchError] = useFetch(
    adminApi.getPassthroughConnectionState,
    (data) => {
      dispatch({ type: "SetConnectionState", payload: { ...data } });
    }
  );

  const [configurationResult, configurationFetchError] = useFetch(adminApi.getPassthroughConfiguration, (data) => {
    dispatch({ type: "SetConfiguration", payload: { ...data } });
  });

  const handleTestConnection = async () => {
    dispatch({ type: "SetActiveProcess", payload: "testingConnection" });
    const [testConnectionResponse, error] = await makeTestConnection();
    if (!error && testConnectionResponse.isSuccess) {
      dispatch({ type: "SetAlert", payload: { severity: "info", message: locale.test_connection_success } });
    } else {
      dispatch({ type: "SetAlert", payload: { severity: "error", message: locale.test_connection_error } });
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  const handleSaveConfiguration = async () => {
    if (!state.configuration) return;
    dispatch({ type: "SetActiveProcess", payload: "updatingConfiguration" });
    const [, error] = await updateConfiguration(state.configuration);
    if (!error) {
      sendNotification(locale.save_configuration_success);
      dispatch({ type: "ResetConfigurationDirty" });
    } else {
      sendNotificationError(locale.save_configuration_error);
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  const handleToggleIntegration = async (enabled: boolean) => {
    dispatch({ type: "SetActiveProcess", payload: "togglingIntegration" });

    const [toggleConnectionResponse, error] = await toggleIntegration({ enabled });
    if (!error && enabled === toggleConnectionResponse.enabled) {
      dispatch({ type: "SetConnectionState", payload: { ...toggleConnectionResponse } });
      sendNotification(formatString(locale.toggle_integration_success, enabled ? "enabled" : "disabled"));
      refreshUserData();
    } else {
      sendNotificationError(formatString(locale.toggle_integration_error, enabled ? "enabling" : "disabling"));
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  if (connectionStateFetchError || configurationFetchError) {
    return <DataLoadingFailed title="Could not load data" />;
  }

  if (!connectionStateResult || !configurationResult) {
    return <InlineLoader />;
  }

  return (
    <>
      <IntegrationHeader
        title="Passthrough"
        logoSrc={PassthroughLogo}
        text={locale.promotion_text}
        appStatus={passthroughApp?.status}
      />
      <Container maxWidth={false} disableGutters sx={{ position: "relative", height: "100%" }}>
        <Stack px={3} spacing={3} mt={2}>
          <PassthroughSetup lastChangedBy={state.lastChangedBy} lastChangedOn={state.lastChangedOn} />
          <PassthroughConnectionActions
            integrationEnabled={state.enabled}
            onTestConnection={handleTestConnection}
            onToggleIntegration={handleToggleIntegration}
            testConnectionProcessing={state.activeProcess === "testingConnection"}
            toggleIntegrationProcessing={state.activeProcess === "togglingIntegration"}
            disabled={state.isConfigurationDirty || !canManagePassthroughIntegration || !state.configuration.apiKey}
          />
          {state.alertMessage && (
            <Alert severity={state.alertMessage.severity} onClose={() => dispatch({ type: "SetAlert" })}>
              {state.alertMessage.message}
            </Alert>
          )}
          <PassthroughTabs
            state={state}
            disabled={!canManagePassthroughIntegration}
            onConfigurationChange={(data) => dispatch({ type: "UpdateConfiguration", payload: data })}
            onConfigurationSave={handleSaveConfiguration}
          />
        </Stack>
        {state.activeProcess && <InlineLoader overlay sx={{ pointerEvents: "auto", top: 0, left: 0, zIndex: 2 }} />}
      </Container>
    </>
  );
};

export default PassThroughPage;
