import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Typography } from "@mui/material";
import { GridColDef, GridValueFormatter, GridValueGetter, GridValueSetter } from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import { ExtensionBasedBlockRow, MetricAndScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import { MetricExtensionConfiguration, MetricFormatConfiguration } from "../../../../api/portfolioMonitoringTypes";
import { formatDateOnly } from "../../../../utilities/dateUtils";
import { formatMetricNumberValue } from "../../../../utilities/metricFormatters";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { InputFormGridCellValueType, metricDataTypeToGridColTypeMap } from "./inputFormDataGridHelper";
import MetricAndScenarioColumnHeader from "./MetricAndScenarioColumnHeader";
import NewMetricExtensionRowTag from "./NewMetricExtensionRowTag";
import { usePortfolioMonitoringInputTableContext } from "./PortfolioMonitoringInputTableContext";
import { calculateTotals } from "./tableTotalsCalculator";

export type RowModel = ExtensionBasedBlockRow & {
  isNew?: boolean;
};

interface NameHeaderProps {
  extensionConfiguration: MetricExtensionConfiguration;
  allMetricIds: string[];
}

const NameHeader = ({ extensionConfiguration, allMetricIds }: NameHeaderProps) => {
  const { isSubmissionEditable } = useDataSubmissionFormContext();
  const { onAddMetricExtensions } = usePortfolioMonitoringInputTableContext();

  return (
    <>
      <Typography variant="subtitle2" noWrap flexGrow={1}>
        {extensionConfiguration.extensionName}
      </Typography>
      {isSubmissionEditable && (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          tabIndex={-1}
          onClick={() => onAddMetricExtensions(allMetricIds, "")}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NameCell = ({ row }: { row: RowModel }) => {
  if (row.type === "ExtensionOnly") {
    return (
      <>
        <Typography noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.extensionValue}
        </Typography>
        {row.isNew && <NewMetricExtensionRowTag />}
      </>
    );
  }

  if (row.type === "Total") {
    return (
      <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
        {row.name}
      </Typography>
    );
  }

  return null;
};

const createNumericValueFormatter =
  (formatConfig: MetricFormatConfiguration | undefined): GridValueFormatter<RowModel> =>
  (value: number | null | undefined, row: RowModel) => {
    if (row.type === "Total") {
      return !value ? "-" : formatMetricNumberValue(value, { ...formatConfig, numberOfDecimals: 0 });
    }

    return typeof value === "number" ? formatMetricNumberValue(value, formatConfig) : "";
  };

const valueGetter: GridValueGetter<RowModel, InputFormGridCellValueType> = (_, row, col) => {
  if (row.type === "Total") {
    return row.values[col.field];
  }

  if (row.type === "ExtensionOnly") {
    return row.values[col.field]?.displayValue;
  }

  return undefined;
};

const dateValueGetter: GridValueGetter<RowModel, InputFormGridCellValueType> = (_, row, col) => {
  if (row.type === "Total") {
    return row.values[col.field];
  }

  if (row.type === "ExtensionOnly") {
    const dateIso = row.values[col.field]?.displayValue;
    return typeof dateIso === "string" ? parseISO(dateIso) : undefined;
  }

  return undefined;
};

const valueSetter: GridValueSetter<RowModel, InputFormGridCellValueType> = (newValue, row, col) => {
  if (row.type === "ExtensionOnly") {
    const displayValue = newValue instanceof Date ? formatDateOnly(newValue) : (newValue ?? undefined);

    return {
      ...row,
      values: {
        ...row.values,
        [col.field]: {
          ...row.values[col.field],
          extensionId: row.values[col.field]?.extensionId ?? "",
          displayValue,
        },
      },
    };
  }

  return row;
};

const editableCellClassName = ({ row }: { row: RowModel }) => (row.type === "Total" ? "total-cell" : "");

const readonlyCellClassName = ({ row }: { row: RowModel }) => (row.type === "Total" ? "total-cell" : "readonly-cell");

const mapScenarioColumn = (
  columnDef: MetricAndScenarioTableBlockColumn,
  isSubmissionEditable: boolean
): GridColDef<RowModel> => ({
  field: columnDef.id,
  flex: 1,
  minWidth: 160,
  type: metricDataTypeToGridColTypeMap[columnDef.dataType],
  editable: isSubmissionEditable && columnDef.isEditable,
  renderHeader: () => <MetricAndScenarioColumnHeader columnDefinition={columnDef} />,
  headerClassName: columnDef.isEditable ? "" : "readonly-header",
  cellClassName: columnDef.isEditable ? editableCellClassName : readonlyCellClassName,
  valueOptions: columnDef.valueSource?.allowedValues,
  valueGetter: columnDef.dataType === "Date" ? dateValueGetter : valueGetter,
  valueSetter,
  valueFormatter:
    columnDef.dataType === "Number" || columnDef.dataType === "Money"
      ? createNumericValueFormatter(columnDef.defaultFormat)
      : undefined,
});

export const getColumns = (
  columnDefinitions: MetricAndScenarioTableBlockColumn[],
  extensionConfiguration: MetricExtensionConfiguration,
  isSubmissionEditable: boolean
): GridColDef<RowModel>[] => {
  const allMetricIds = columnDefinitions.map((col) => col.metricId);

  const nameColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 2,
    minWidth: 300,
    editable: false,
    cellClassName: ({ row }) => (row.type === "Total" ? "total-cell" : ""),
    renderHeader: () => <NameHeader allMetricIds={allMetricIds} extensionConfiguration={extensionConfiguration} />,
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const scenarioColumns: GridColDef<RowModel>[] = columnDefinitions.map((columnDef) =>
    mapScenarioColumn(columnDef, isSubmissionEditable)
  );

  return [nameColumn, ...scenarioColumns];
};

export const getRows = (rows: ExtensionBasedBlockRow[]): RowModel[] => {
  return calculateTotals(rows);
};
