import RefreshIcon from "@mui/icons-material/CachedRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Button, Container, Divider, Stack } from "@mui/material";
import { useCallback, useReducer, useState } from "react";
import { useLocation } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import DateRangeSelect from "../../../../shared/components/DateRangeSelect";
import SearchField from "../../../../shared/components/inputs/SearchField";
import MultiSelectFilter from "../../../../shared/components/MultiSelectFilter";
import useFetch from "../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../shared/hooks/usePageTitle";
import CompaniesIcon from "../../../../shared/icons/CompaniesIcon";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import {
  DescriptiveDateRange,
  last12Months,
  thisQuarter,
  thisYear,
} from "../../../../shared/utilities/dateFilterHelper";
import adminApi from "../../../api/adminApi";
import {
  DocumentCollectionOperations,
  DocumentCollectionPublishStatus,
} from "../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../context/ClientContext";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import ChangeDocumentCollectionsStatusDialog from "./ChangeDocumentCollectionsStatusDialog";
import DocumentCollectionsGrid from "./DocumentCollectionsGrid";
import { DocumentCollectionsPageContextProvider } from "./DocumentCollectionsPageContext";
import { defaultDateRange, getInitialState, reducer, statusFilterOptions } from "./documentCollectionsState";

const getDownloadUrlForDocumentCollection = withErrorHandling(adminApi.getDownloadUrlForDocumentCollection);

interface DialogState {
  openDialog?: "change_status";
  operation?: DocumentCollectionOperations;
  documentCollectionIds?: string[];
  documentCollectionsInfo?: string;
}

type LocationState = { documentCollectionId?: string } | null;

const DocumentCollectionsPage = () => {
  usePageTitle("Document Collections");

  const { clientCode, hasPermissions } = useClientContext();
  const location = useLocation();

  const [state, dispatch] = useReducer(reducer, getInitialState(clientCode));
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getDocumentCollections = useCallback(
    () =>
      adminApi.getDocumentCollections({
        dateFrom: state.dateRange.from?.toISOString(),
        dateTo: state.dateRange.to?.toISOString(),
      }),
    [state.dateRange.from, state.dateRange.to]
  );

  const [resp, fetchError, { isFetching, fetch }] = useFetch(getDocumentCollections, (data) =>
    dispatch({ type: "LOAD_DATA", items: data.documentCollections })
  );

  if (fetchError) {
    logError(fetchError, "[DocumentCollections]");
    return <DataLoadingFailed title="Loading document collections failed" />;
  }

  const handleSearch = (searchTerm: string) => dispatch({ type: "SEARCH", searchTerm });

  const handleDateRangeChange = (dateRange: DescriptiveDateRange) =>
    dispatch({ type: "SET_DATE_RANGE", dateRange, clientCode });

  const handleFundsFilterChange = (selectedFunds: string[]) => dispatch({ type: "FILTER_FUNDS", selectedFunds });

  const handleStatusFilterChange = (selectedStatuses: DocumentCollectionPublishStatus[]) =>
    dispatch({ type: "FILTER_STATUSES", selectedStatuses });

  const handleClearFilters = () => dispatch({ type: "CLEAR_FILTERS", clientCode });

  const handleRefresh = () => {
    fetch();
  };

  const handleDownloadDocumentCollection = async (id: string) => {
    const [url, error] = await getDownloadUrlForDocumentCollection(id, clientCode);
    if (error) {
      logError(error, "[DocumentCollectionsPage] getDownloadUrlForDocumentCollection");
      return;
    }

    downloadFileFromUrl(url);
  };

  const handleChangeDocumentCollectionsStatus = (
    documentCollectionIds: string[],
    operation: DocumentCollectionOperations
  ) => {
    if (documentCollectionIds.length === 0) {
      return;
    }

    let documentCollectionsInfo = `${documentCollectionIds.length} collections`;
    if (documentCollectionIds.length === 1) {
      const documentCollection = state.allItems.find((collection) => collection.id === documentCollectionIds[0]);
      if (documentCollection) {
        documentCollectionsInfo = documentCollection.name;
      }
    }

    setDialogState({ openDialog: "change_status", documentCollectionIds, operation, documentCollectionsInfo });
  };

  const showClearFilters =
    state.dateRange.from !== defaultDateRange.from ||
    state.dateRange.to !== defaultDateRange.to ||
    state.selectedFunds.length > 0 ||
    state.selectedStatuses.length > 0;

  const isLoading = resp === undefined || isFetching;

  return (
    <DocumentCollectionsPageContextProvider
      hasPermissionsToManage={hasPermissions(["ManageDocumentCollections"])}
      onDownloadDocumentCollection={handleDownloadDocumentCollection}
      onChangeDocumentCollectionsStatus={handleChangeDocumentCollectionsStatus}
    >
      <GeneralPageHeader title="Document Collections" />

      <Container maxWidth={false} sx={{ height: "100%", py: 2.5 }}>
        <Stack spacing={2} width="100%" height="100%" pb={5}>
          <Stack mt={1} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <DateRangeSelect
                predefinedRanges={[last12Months, thisQuarter, thisYear]}
                dateRange={state.dateRange}
                onChange={handleDateRangeChange}
                disabled={isLoading}
              />
              <Divider orientation="vertical" flexItem />
              <MultiSelectFilter
                placeholder="Funds"
                options={state.allFunds.map((fundName) => ({ value: fundName, label: fundName }))}
                selectedValues={state.selectedFunds}
                onChange={handleFundsFilterChange}
                StartIcon={CompaniesIcon}
                disabled={isLoading}
              />
              <MultiSelectFilter
                placeholder="Status"
                options={statusFilterOptions}
                selectedValues={state.selectedStatuses}
                onChange={handleStatusFilterChange}
                StartIcon={CheckCircleIcon}
                disabled={isLoading}
              />
              {showClearFilters && (
                <Button variant="text" color="primary" onClick={handleClearFilters}>
                  Clear All
                </Button>
              )}
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <SearchField
                initialValue={state.searchTerm}
                onSearch={handleSearch}
                disabled={isLoading}
                debounceTimeMs={300}
              />
              <Button
                variant="outlined"
                color="secondary"
                disabled={isLoading}
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Stack>
          </Stack>

          <DocumentCollectionsGrid
            documentCollections={state.filteredItems}
            loading={resp === undefined || isFetching}
            highlightedRowId={(location.state as LocationState)?.documentCollectionId}
          />
        </Stack>
      </Container>

      {dialogState.openDialog === "change_status" &&
        dialogState.documentCollectionIds &&
        dialogState.operation &&
        dialogState.documentCollectionsInfo && (
          <ChangeDocumentCollectionsStatusDialog
            onClose={() => setDialogState({})}
            documentCollectionIds={dialogState.documentCollectionIds}
            operation={dialogState.operation}
            documentCollectionsInfo={dialogState.documentCollectionsInfo}
            onApply={handleRefresh}
          />
        )}
    </DocumentCollectionsPageContextProvider>
  );
};

export default DocumentCollectionsPage;
