import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { useCallback, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { createApiResponse, getStatusCodeFromError } from "../shared/api/axiosHelper";
import { updateClientCode } from "../shared/api/clientApiContext";
import { ssoApi } from "../shared/api/sso";
import { useAuth } from "../shared/auth/hooks";
import { redirectToLoginSignin } from "../shared/auth/internal/redirects";
import AuthenticationFailed from "../shared/components/AuthenticationFailed";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import GlobalNotificationErrorSnackbar from "../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../shared/components/GlobalNotificationSnackbar";
import Intercom from "../shared/components/Intercom";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import InlineLoader from "../shared/components/inlineLoader/InlineLoader";
import { ClientPermissionsContextProvider } from "../shared/contexts/ClientPermissionsContext";
import { NotificationContextProvider } from "../shared/contexts/NotificationContext";
import useFetch from "../shared/hooks/useFetch";
import { logError } from "../shared/logging";
import { applyDateFnsLocaleFromBrowser } from "../shared/utilities/dateLocalization";
import Main from "./Main";
import Pages from "./Pages";
import adminApi from "./api/adminApi";
import { UserInfo, UserPermissions } from "./api/types/userTypes";
import ConsentAgreementContainer from "./components/consentAgreement/ConsentAgreementContainer";
import { getUnsignedGeneralAgreements } from "./components/consentAgreement/consentAgreementService";
import { Client, ClientContextProvider } from "./context/ClientContext";
import { UserContextProvider } from "./context/UserContext";
import storage from "./storage/storage";

const App = () => {
  const { client: clientParam } = useParams();

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [unauthorized, setUnauthorized] = useState(false);

  const navigate = useNavigate();

  const onAuthenticated = useCallback(async () => {
    try {
      const identityResp = await adminApi.getIdentity();
      setUserInfo(identityResp.data);

      if (identityResp.data) {
        const unsignedGeneralAgreements = getUnsignedGeneralAgreements(identityResp.data.consentAgreements);
        if (unsignedGeneralAgreements.length === 0) {
          const permissionsResp = await adminApi.getUserPermissions();
          setUserPermissions(permissionsResp.data);
        }
      }
    } catch (error) {
      if (getStatusCodeFromError(error) === 401) {
        await ssoApi.logout();
        setUnauthorized(true);
      } else {
        logError(error, "[App] onAuthenticated");
        navigate("/error");
      }
    }
  }, [navigate]);

  const currentClientCode = useMemo(() => (clientParam ? clientParam.toLowerCase() : undefined), [clientParam]);

  const currentClient = useMemo(() => {
    if (userPermissions === undefined) {
      return undefined;
    }

    const currentClientPermissions = userPermissions.permissions.find(
      (p) => p.clientCode.toLowerCase() === currentClientCode
    );

    if (!currentClientPermissions) {
      return undefined;
    }

    updateClientCode(currentClientPermissions.clientCode);

    const currentClientInfo = userPermissions.clients.find((c) => c.clientCode === currentClientPermissions.clientCode);

    const client: Client = {
      ...currentClientPermissions,
      organizationEmailDomain: currentClientInfo?.organizationDomain,
      branding: currentClientInfo?.branding ?? {},
      businessCentralApp: currentClientInfo?.businessCentralApp,
      investorRelationsApp: currentClientInfo?.investorRelationsApp,
      investorPortalApp: currentClientInfo?.investorPortalApp,
      fundraisingApp: currentClientInfo?.fundraisingApp,
      portfolioMonitoringApp: currentClientInfo?.portfolioMonitoringApp,
      reportingPortalApp: currentClientInfo?.reportingPortalApp,
      apiGatewayApp: currentClientInfo?.apiGatewayApp,
      salesforceApp: currentClientInfo?.salesforceApp,
      plaidApp: currentClientInfo?.plaidApp,
      yodleeApp: currentClientInfo?.yodleeApp,
      saltEdgeApp: currentClientInfo?.saltEdgeApp,
      entityLevelAccess: currentClientInfo?.entityLevelAccess,
      clientTitle: currentClientInfo?.title ?? "",
      clientType: currentClientInfo?.type ?? "FundManager",
      expenseManagementPortalApp: currentClientInfo?.expenseManagementPortalApp,
      dataBackupApp: currentClientInfo?.dataBackupApp,
      passthroughApp: currentClientInfo?.passthroughApp,
      dealCloudApp: currentClientInfo?.dealCloudApp,
      companyType: currentClientInfo?.companyType ?? "Production",
    };

    return client;
  }, [currentClientCode, userPermissions]);

  const refreshUserData = useCallback(async () => {
    try {
      const resp = await adminApi.getUserPermissions();
      if (resp.data) {
        setUserPermissions(resp.data);
      }
    } catch (error) {
      logError(error, "[App] refreshUserData");
    }
  }, []);

  const getCurrentClientSettings = useCallback(() => {
    if (!currentClient) {
      return Promise.resolve(createApiResponse(undefined));
    }

    return adminApi.getClientSettings();
  }, [currentClient]);

  const [currentClientSettings, fetchClientSettingsError] = useFetch(getCurrentClientSettings);

  const getCurrentClientDictionaries = useCallback(() => {
    if (!currentClient) {
      return Promise.resolve(createApiResponse(undefined));
    }

    if (currentClient.clientType === "FundAdmin") {
      return Promise.resolve(
        createApiResponse({
          countries: [],
          states: [],
          currencies: [],
          taxForms: [],
          investorCategories: [],
          formPfNames: [],
          chapter3Statuses: [],
          chapter4FatcaStatuses: [],
          irs1099Codes: [],
          industriesAndSectors: [],
          geographies: [],
        })
      );
    }

    return adminApi.getClientDictionaries();
  }, [currentClient]);

  const [currentClientDictionaries, fetchClientDictionariesError] = useFetch(getCurrentClientDictionaries);

  const getMenuBadges = useCallback(() => {
    if (!currentClient || currentClient.clientType === "FundAdmin") {
      return Promise.resolve(createApiResponse(undefined));
    }

    return adminApi.getMenuBadges();
  }, [currentClient]);

  const [menuBadges, , { fetch: fetchMenuBadges }] = useFetch(getMenuBadges);

  const handleNewClientSelected = useCallback((newClientCode: string) => navigate(`/${newClientCode}`), [navigate]);

  const isAuthenticated = useAuth(onAuthenticated, redirectToLoginSignin);

  if (!isAuthenticated) {
    return <FullScreenLoader title="Please wait" subtitle="You are being redirected for Authentication..." />;
  }

  if (unauthorized) {
    return <AuthenticationFailed />;
  }

  if (fetchClientSettingsError || fetchClientDictionariesError) {
    logError(fetchClientSettingsError, "[App] clientSettings");
    return <Navigate to="/error" />;
  }

  if (userInfo === undefined) {
    return <InlineLoader text="Loading user data..." />;
  }

  const consentAgreementsToAccept = getUnsignedGeneralAgreements(userInfo.consentAgreements);
  if (consentAgreementsToAccept.length > 0) {
    return <ConsentAgreementContainer consentAgreements={consentAgreementsToAccept} />;
  }

  if (userPermissions === undefined) {
    return <InlineLoader text="Loading user data..." />;
  }

  const currentClientPermissions = userPermissions.permissions.find(
    (p) => p.clientCode.toLowerCase() === currentClientCode
  );

  if (!currentClientPermissions && userPermissions.permissions.length > 0) {
    const clientCodes = userPermissions.permissions.map((p) => p.clientCode);
    const recentClientCode = storage.getRecentClients().find((c) => clientCodes.includes(c));
    const selectedClientCode = recentClientCode ?? clientCodes[0] ?? "";
    return <Navigate to={`/${selectedClientCode}`} />;
  }

  if (currentClient === undefined || currentClientSettings === undefined || currentClientDictionaries === undefined) {
    return <InlineLoader text="Loading client data..." />;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={applyDateFnsLocaleFromBrowser()}>
        <UserContextProvider
          userInfo={userInfo}
          permissions={userPermissions.permissions}
          userClients={userPermissions.clients}
          refreshUserData={refreshUserData}
        >
          {currentClientSettings.intercomAuthentication !== undefined && (
            <Intercom
              fullName={userInfo.name}
              email={userInfo.email}
              settings={currentClientSettings.intercomAuthentication}
            />
          )}
          <ClientContextProvider
            clientSettings={currentClientSettings}
            clientDictionaries={currentClientDictionaries}
            menuBadges={menuBadges}
            updateMenuBadges={fetchMenuBadges}
            client={currentClient}
            onNewClientSelected={handleNewClientSelected}
          >
            <ClientPermissionsContextProvider
              permissions={currentClient.permissions}
              permissionSets={userPermissions.permissions}
            >
              <NotificationContextProvider>
                <Main>
                  <Pages />
                </Main>
                <GlobalNotificationSnackbar />
                <GlobalNotificationErrorSnackbar />
              </NotificationContextProvider>
            </ClientPermissionsContextProvider>
          </ClientContextProvider>
        </UserContextProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default App;
