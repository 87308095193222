import { Navigate, useParams } from "react-router";
import ObjectDetailsPage from "../../entityFields/ObjectDetailsPage";

const ScenarioDetailsPage = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to=".." />;
  }

  return <ObjectDetailsPage objectId={id} objectType="PortfolioMonitoringScenario" showDefaultBackButtonTo=".." />;
};

export default ScenarioDetailsPage;
