import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  allMetricAggegationTypes,
  allMetricDataTypes,
  MetricAggregationType,
  MetricDataType,
  NumberDisplayStyle,
} from "../../../../../shared/api/portfolioMonitoringTypes";
import NumberTextField from "../../../../../shared/components/NumberTextField";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { metricAggregationTypeCaptionMap } from "../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { Metric } from "../../../../api/types/portfolioMonitoringTypes";
import BorderedRadioLabel from "../../../common/BorderedRadioLabel";
import NumberPrecisionSelect from "../../../common/NumberPrecisionSelect";
import MetricDataTypeItem from "../MetricDataTypeItem";
import {
  extensionSourceOptions,
  formatPreviewValue,
  getAllowedNumberStyles,
  isAggregationTypeEnabled,
  isFormatConfigurationEnabled,
  isInputSettingsConfigurationEnabled,
  isValueSourceConfigurationEnabled,
  MetricEditForm,
  MetricEditorFormState,
  MetricExtensionSource,
  MetricFormField,
  validateForm,
} from "./metricEditorHelper";

interface Props {
  form: MetricEditorFormState;
  onChange: (newForm: MetricEditorFormState) => void;
  otherMetricNames: string[];
  allMetricCategories: string[];
  editedMetric?: Metric;
}

const MetricEditor = ({ form, onChange, otherMetricNames, allMetricCategories, editedMetric }: Props) => {
  const handleFieldChange = (update: Partial<MetricEditForm>) => {
    const updatedFields = Object.keys(update) as MetricFormField[];

    const newForm = validateForm(
      {
        ...form,
        ...update,
        touchedFields: distinct([...form.touchedFields, ...updatedFields]),
      },
      otherMetricNames
    );

    onChange(newForm);
  };

  return (
    <Stack spacing={2.5}>
      <Select
        fullWidth
        value={form.dataType}
        onChange={(e) => handleFieldChange({ dataType: e.target.value as MetricDataType })}
        renderValue={(value) => <MetricDataTypeItem dataType={value} />}
        disabled={editedMetric !== undefined}
      >
        {allMetricDataTypes.map((dataType) => (
          <MenuItem key={dataType} value={dataType}>
            <MetricDataTypeItem dataType={dataType} />
          </MenuItem>
        ))}
      </Select>

      {isValueSourceConfigurationEnabled(form.dataType) && (
        <Stack spacing={0.5}>
          <Typography variant="subtitle2">Options</Typography>
          <Typography color="text.secondary">Place each option on its own line</Typography>
          <TextField
            fullWidth
            multiline
            rows={8}
            value={form.allowedValuesMemo}
            onChange={(e) => handleFieldChange({ allowedValuesMemo: e.target.value })}
            error={Boolean(form.validationErrors.allowedValuesMemo)}
            helperText={form.validationErrors.allowedValuesMemo}
          />
        </Stack>
      )}

      <TextField
        fullWidth
        label="Name"
        value={form.name}
        onChange={(e) => handleFieldChange({ name: e.target.value })}
        error={Boolean(form.validationErrors.name)}
        helperText={form.validationErrors.name}
      />

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Description"
        value={form.description}
        onChange={(e) => handleFieldChange({ description: e.target.value })}
      />

      <Autocomplete
        fullWidth
        freeSolo
        includeInputInList
        options={allMetricCategories}
        value={form.category}
        onInputChange={(_, category) => handleFieldChange({ category })}
        renderInput={(params) => <TextField {...params} label="Category" />}
      />

      {isAggregationTypeEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={1}>
          <Typography variant="subtitle1" py={1.5}>
            Aggregation Type
          </Typography>
          <FormControl sx={{ mt: 0.5 }}>
            <RadioGroup
              defaultValue="EndOfPeriod"
              value={form.aggregationType}
              sx={{ rowGap: 1 }}
              onChange={(e) => handleFieldChange({ aggregationType: e.target.value as MetricAggregationType })}
            >
              {allMetricAggegationTypes.map((aggregationType) => (
                <BorderedRadioLabel<MetricAggregationType>
                  key={aggregationType}
                  value={aggregationType}
                  selectedValue={form.aggregationType}
                  label={metricAggregationTypeCaptionMap[aggregationType]}
                  sx={{ pl: 1.5 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      )}

      {isFormatConfigurationEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={1}>
          <Typography variant="subtitle1" py={1.5}>
            Default Format
          </Typography>

          <Stack direction="row" spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="number-display-style-select-label">Display Style</InputLabel>
              <Select
                labelId="number-display-style-select-label"
                label="Display Style"
                value={form.numberDisplayStyle ?? "_"}
                onChange={(e) =>
                  handleFieldChange({
                    numberDisplayStyle: e.target.value === "_" ? undefined : (e.target.value as NumberDisplayStyle),
                  })
                }
              >
                <MenuItem value="_">None</MenuItem>
                {getAllowedNumberStyles(form.dataType).map((displayStyle) => (
                  <MenuItem key={displayStyle} value={displayStyle}>
                    {autoFormatCamelCase(displayStyle)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="decimal-precision-select-label">Decimal Precision</InputLabel>
              <NumberPrecisionSelect
                labelId="decimal-precision-select-label"
                label="Decimal Precision"
                precision={form.numberOfDecimals}
                maxPrecision={6}
                onPrecisionChange={(numberOfDecimals) => handleFieldChange({ numberOfDecimals })}
              />
            </FormControl>
          </Stack>

          <Typography variant="body2" color="text.secondary">
            Preview: {formatPreviewValue(form)}
          </Typography>
        </Stack>
      )}

      {isInputSettingsConfigurationEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={1}>
          <Typography variant="subtitle1" py={1.5}>
            Input Settings
          </Typography>

          <Stack spacing={2}>
            <FormControlLabel
              label="Enable restriction on the maximum number of symbols"
              labelPlacement="end"
              control={
                <Switch
                  checked={form.enableMaxNumberOfSymbols}
                  onChange={(_, checked) => handleFieldChange({ enableMaxNumberOfSymbols: checked })}
                />
              }
            />

            {form.enableMaxNumberOfSymbols && (
              <NumberTextField
                label="Maximum number of symbols"
                numberValue={form.maxNumberOfSymbols}
                onNumberChange={(num) => handleFieldChange({ maxNumberOfSymbols: num ?? 0 })}
                error={Boolean(form.validationErrors.maxNumberOfSymbols)}
                helperText={form.validationErrors.maxNumberOfSymbols}
              />
            )}
          </Stack>
        </Stack>
      )}

      <Stack pt={0.5} spacing={2}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Stack>
            <Typography variant="subtitle1" py={1.5}>
              Extended Metric
            </Typography>
            <Typography color="text.secondary">
              Extend this metric with an extra dimension (e.g., Country, Product, etc.) to collect more granular data.
              This setting cannot be changed once the metric is created.
            </Typography>
          </Stack>

          <Switch
            checked={form.isExtended}
            onChange={(_, checked) => handleFieldChange({ isExtended: checked })}
            disabled={Boolean(editedMetric?.isExtended)}
          />
        </Box>

        {form.isExtended && (
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              label="Extension Name"
              value={form.extensionName}
              onChange={(e) => handleFieldChange({ extensionName: e.target.value })}
              error={Boolean(form.validationErrors.extensionName)}
              helperText={form.validationErrors.extensionName}
            />

            <FormControl fullWidth>
              <InputLabel id="extension-source-select-label">Source</InputLabel>
              <Select
                labelId="extension-source-select-label"
                label="Source"
                value={form.extensionSource}
                onChange={(e) =>
                  handleFieldChange({
                    extensionSource: e.target.value as MetricExtensionSource,
                  })
                }
              >
                {extensionSourceOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}

        {form.isExtended && form.extensionSource === "static_values" && (
          <Stack spacing={0.5}>
            <Typography color="text.secondary">Place each option on its own line</Typography>
            <TextField
              fullWidth
              multiline
              rows={8}
              value={form.staticExtensionValuesMemo}
              onChange={(e) => handleFieldChange({ staticExtensionValuesMemo: e.target.value })}
              error={Boolean(form.validationErrors.staticExtensionValuesMemo)}
              helperText={form.validationErrors.staticExtensionValuesMemo}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default MetricEditor;
