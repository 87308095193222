import { GridColDef } from "@mui/x-data-grid-premium";
import { ReportingPeriod } from "../../../../../shared/api/dataCollectionTypes";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import { distinct, stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { reportingPeriodCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import { useDataRequestSetupPageContext } from "./DataRequestSetupPageContext";

const CategoriesCell = ({ row }: { row: DataCollectionRequestTemplateVersion }) => {
  const { categories } = useDataRequestSetupPageContext();

  const allCategories = distinct([...row.contactCategories, ...row.internalUserPermissionCategories]).map((id) => ({
    id,
    name: categories.find((c) => c.id === id)?.name ?? id,
  }));

  return allCategories.length === 0 ? (
    "-"
  ) : (
    <InlineItemsList
      displayCount={2}
      items={allCategories}
      propGetter={(x) => x.name}
      sortComparer={stringComparerBy((x) => x)}
    />
  );
};

export const columnDefinitions: GridColDef<DataCollectionRequestTemplateVersion>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    minWidth: 160,
    cellClassName: "highlighted-action",
  },
  {
    field: "reportingPeriod",
    headerName: "Period",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value: ReportingPeriod) => (value ? reportingPeriodCaptionsMap[value] : ""),
  },
  {
    field: "scenarioName",
    headerName: "Scenario",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "categories",
    headerName: "Category",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => <CategoriesCell row={row} />,
  },
  {
    field: "recipientObjectIds",
    headerName: "Companies",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 80,
    valueFormatter: (value: string[] | undefined) => (value?.length ?? 0).toLocaleString(),
  },
];
