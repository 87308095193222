import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInboxRounded";
import LanguageButton from "@mui/icons-material/LanguageRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { Button } from "@mui/material";
import { RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import CustomDataGridHeaderToolbar from "../../../../common/grid/CustomDataGridHeaderToolbar";
import { useDataRequestPageContext } from "./DataRequestPageContext";

interface Props {
  allSubmissions: RequestSubmissionInfo[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

const DataRequestSubmissionsGridToolbar = ({ allSubmissions, selectedIds, setSelectedIds }: Props) => {
  const {
    onPublishSubmissions,
    onSendSubmissionReminders,
    onUnpublishSubmissions,
    onRequestChangesForSubmissions,
    onDeleteSubmissions,
  } = useDataRequestPageContext();

  const selectedSubmissions = allSubmissions.filter((submission) => selectedIds.includes(submission.id));

  const submissionStatuses = selectedSubmissions.map((submission) => submission.status);
  const hasMixedStatuses = submissionStatuses.some((status) => status !== submissionStatuses[0]);
  const statusOfAllSubmissions = hasMixedStatuses ? undefined : submissionStatuses[0];

  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        color="secondary"
        variant="text"
        disabled={statusOfAllSubmissions !== "Draft"}
        startIcon={<LanguageButton />}
        onClick={() => onPublishSubmissions(selectedSubmissions)}
      >
        Publish
      </Button>
      <Button
        color="error"
        variant="text"
        disabled={statusOfAllSubmissions !== "Draft"}
        startIcon={<DeleteIcon />}
        onClick={() => onDeleteSubmissions(selectedSubmissions)}
      >
        Delete
      </Button>
      <Button
        color="secondary"
        variant="text"
        disabled={statusOfAllSubmissions !== "Pending"}
        startIcon={<ForwardToInboxIcon />}
        onClick={() => onSendSubmissionReminders(selectedSubmissions)}
      >
        Send Reminders
      </Button>
      <Button
        color="secondary"
        variant="text"
        disabled={statusOfAllSubmissions !== "Pending"}
        startIcon={<LanguageOffIcon />}
        onClick={() => onUnpublishSubmissions(selectedSubmissions)}
      >
        Unpublish
      </Button>
      <Button
        color="warning"
        variant="text"
        disabled={statusOfAllSubmissions !== "Completed"}
        startIcon={<RevertIcon />}
        onClick={() => onRequestChangesForSubmissions(selectedSubmissions)}
      >
        Request Changes
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default DataRequestSubmissionsGridToolbar;
