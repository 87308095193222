import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Typography } from "@mui/material";
import { GridColDef, GridValueFormatter, GridValueGetter, GridValueSetter } from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import { ScenarioTableBlockColumn, SingleExtensionBasedBlockRow } from "../../../../api/inputFormTypes";
import {
  MetricDataType,
  MetricExtensionConfiguration,
  MetricFormatConfiguration,
} from "../../../../api/portfolioMonitoringTypes";
import { formatDateOnly } from "../../../../utilities/dateUtils";
import { formatMetricNumberValue } from "../../../../utilities/metricFormatters";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { InputFormGridCellValueType, metricDataTypeToGridColTypeMap } from "./inputFormDataGridHelper";
import { usePortfolioMonitoringInputTableContext } from "./PortfolioMonitoringInputTableContext";
import ScenarioColumnHeader from "./ScenarioColumnHeader";
import { calculateTotals } from "./tableTotalsCalculator";

export type RowModel = SingleExtensionBasedBlockRow;

interface NameHeaderProps {
  extensionConfiguration: MetricExtensionConfiguration;
  metricId: string;
  metricName: string;
}

const NameHeader = ({ extensionConfiguration, metricId, metricName }: NameHeaderProps) => {
  const { isSubmissionEditable } = useDataSubmissionFormContext();
  const { onAddMetricExtensions } = usePortfolioMonitoringInputTableContext();

  return (
    <>
      <Typography variant="subtitle2" noWrap flexGrow={1}>
        {extensionConfiguration.extensionName}
      </Typography>
      {isSubmissionEditable && (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          tabIndex={-1}
          onClick={() => onAddMetricExtensions([metricId], metricName)}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NameCell = ({ row }: { row: RowModel }) => {
  if (row.type === "SingleMetricExtensionOnly") {
    return (
      <Typography noWrap pl={row.indentationLevel * 2} flexGrow={1}>
        {row.extensionValue}
      </Typography>
    );
  }

  if (row.type === "Total") {
    return (
      <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
        {row.name}
      </Typography>
    );
  }

  return null;
};

const createNumericValueFormatter =
  (formatConfig: MetricFormatConfiguration | undefined): GridValueFormatter<RowModel> =>
  (value: number | null | undefined, row: RowModel) => {
    if (row.type === "Total") {
      return !value ? "-" : formatMetricNumberValue(value, { ...formatConfig, numberOfDecimals: 0 });
    }

    return typeof value === "number" ? formatMetricNumberValue(value, formatConfig) : "";
  };

const valueGetter: GridValueGetter<RowModel, InputFormGridCellValueType> = (_, row, col) => {
  if (row.type === "Total") {
    return row.values[col.field];
  }

  if (row.type === "SingleMetricExtensionOnly") {
    return row.values[col.field];
  }

  return undefined;
};

const dateValueGetter: GridValueGetter<RowModel, InputFormGridCellValueType> = (_, row, col) => {
  if (row.type === "Total") {
    return row.values[col.field];
  }

  if (row.type === "SingleMetricExtensionOnly") {
    const dateIso = row.values[col.field];
    return typeof dateIso === "string" ? parseISO(dateIso) : undefined;
  }

  return undefined;
};

const valueSetter: GridValueSetter<RowModel, InputFormGridCellValueType> = (newValue, row, col) => {
  if (row.type === "SingleMetricExtensionOnly") {
    const value = newValue instanceof Date ? formatDateOnly(newValue) : (newValue ?? undefined);

    return {
      ...row,
      values: {
        ...row.values,
        [col.field]: value,
      },
    };
  }

  return row;
};

const editableCellClassName = ({ row }: { row: RowModel }) => (row.type === "Total" ? "total-cell" : "");

const readonlyCellClassName = ({ row }: { row: RowModel }) => (row.type === "Total" ? "total-cell" : "readonly-cell");

const mapScenarioColumn = (
  columnDef: ScenarioTableBlockColumn,
  dataType: MetricDataType,
  formatConfig: MetricFormatConfiguration | undefined,
  isSubmissionEditable: boolean
): GridColDef<RowModel> => ({
  field: columnDef.id,
  flex: 1,
  minWidth: 160,
  type: metricDataTypeToGridColTypeMap[dataType],
  editable: isSubmissionEditable && columnDef.isEditable,
  renderHeader: () => <ScenarioColumnHeader columnDefinition={columnDef} />,
  headerClassName: columnDef.isEditable ? "" : "readonly-header",
  cellClassName: columnDef.isEditable ? editableCellClassName : readonlyCellClassName,
  valueGetter: dataType === "Date" ? dateValueGetter : valueGetter,
  valueSetter,
  valueFormatter: dataType === "Number" || dataType === "Money" ? createNumericValueFormatter(formatConfig) : undefined,
});

export const getColumns = (
  columnDefinitions: ScenarioTableBlockColumn[],
  metricId: string,
  metricName: string,
  extensionConfiguration: MetricExtensionConfiguration,
  dataType: MetricDataType,
  formatConfig: MetricFormatConfiguration | undefined,
  isSubmissionEditable: boolean
): GridColDef<RowModel>[] => {
  const nameColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 2,
    minWidth: 300,
    editable: false,
    cellClassName: ({ row }) => (row.type === "Total" ? "total-cell" : ""),
    renderHeader: () => (
      <NameHeader extensionConfiguration={extensionConfiguration} metricId={metricId} metricName={metricName} />
    ),
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const scenarioColumns: GridColDef<RowModel>[] = columnDefinitions.map((columnDef) =>
    mapScenarioColumn(columnDef, dataType, formatConfig, isSubmissionEditable)
  );

  return [nameColumn, ...scenarioColumns];
};

export const getRows = (rows: SingleExtensionBasedBlockRow[]): RowModel[] => {
  return calculateTotals(rows);
};
