import React, { PropsWithChildren, useContext } from "react";
import { AnyObject } from "../../../../types";
import { defined } from "../../../../utilities/typeHelper";
import { ReportGroup } from "../../../api/biClient.types";

interface GroupsContextType {
  groups: Record<string, ReportGroup[]>;
  ui: { loaded: boolean; error?: string };
  actions: {
    load: (clientCode: string) => Promise<unknown>;
    add: (clientCode: string, group: ReportGroup) => Promise<ReportGroup | undefined>;
    update: (clientCode: string, group: ReportGroup) => Promise<void>;
    delete: (clientCode: string, groupId: string) => Promise<void>;
    reorder: (clientCode: string, groups: ReportGroup[]) => Promise<void>;
    replace?: (
      clientCode: string,
      fromGroupId: string,
      toGroupId: string,
      reloadReports: () => Promise<void>
    ) => Promise<AnyObject | undefined>;
    reloadReports?: () => Promise<void>;
  };
}

const GroupsContext = React.createContext<GroupsContextType | undefined>(undefined);

export const GroupsContextProvider = ({ children, ...props }: PropsWithChildren<GroupsContextType>) => (
  <GroupsContext.Provider value={props}>{children}</GroupsContext.Provider>
);

export const useGroupsContext = () => {
  const context = useContext(GroupsContext);
  return defined(context);
};
