import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { convertISODate, convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { Contact, ContactStatus } from "../../../../../api/adminApi";
import EntitySection from "../../../../entityFields/EntitySection";
import EntitySectionHeader from "../../../../entityFields/EntitySectionHeader";
import ResetPasswordDialog from "./ResetPasswordDialog";

interface Props {
  contact: Contact;
  hasPermissionsToResetPassword: boolean;
}

const ContactAccessSection = ({ contact, hasPermissionsToResetPassword }: Props) => {
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

  return (
    <EntitySection>
      <EntitySectionHeader title="Portal Access" subtitle={contact.investorPortalLogin}>
        {hasPermissionsToResetPassword && (
          <Button
            variant="outlined"
            onClick={() => setShowResetPasswordDialog(true)}
            disabled={contact.status !== ContactStatus.Registered}
          >
            Reset Password
          </Button>
        )}
      </EntitySectionHeader>
      <Stack direction="row" spacing={20} width="100%" px={2.5} pb={2} pt={1}>
        <Stack spacing={0.25}>
          {contact.status === ContactStatus.Registered && (
            <>
              <Typography variant="caption" color="text.secondary">
                Registered
              </Typography>
              <Typography variant="caption">
                {contact.registrationDate ? convertISODate(contact.registrationDate) : ""}
              </Typography>
            </>
          )}
          {contact.status !== ContactStatus.Registered && <Typography>Not Registered</Typography>}
        </Stack>
        <Stack spacing={0.25}>
          {contact.invitationSentAt && (
            <>
              <Typography variant="caption" color="text.secondary">
                Invited
              </Typography>
              <Typography variant="caption">{convertISODateTime(contact.invitationSentAt)}</Typography>
            </>
          )}
          {!contact.invitationSentAt && <Typography>Not Invited</Typography>}
        </Stack>
      </Stack>

      {showResetPasswordDialog && (
        <ResetPasswordDialog
          contactId={contact.id}
          contactEmail={contact.email}
          onClose={() => setShowResetPasswordDialog(false)}
        />
      )}
    </EntitySection>
  );
};

export default ContactAccessSection;
