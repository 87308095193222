import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { TabPanel } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { Link, useSearchParams } from "react-router";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import InvestorPortalImpersonateButton from "../InvestorPortalImpersonateButton";
import UserConsentsSection from "../consents/UserConsentsSection";
import GeneralInvestorPortalSettings from "../general/GeneralInvestorPortalSettings";
import InvestorDocumentsSettings from "../investor-documents/InvestorDocumentsSettings";
import InvitationEmailSection from "../invitation-email/InvitationEmailSection";
import PortalPagesSettings from "../pages/PortalPagesSettings";
import PortalSupportInfo from "../support/PortalSupportInfo";
import WatermarkSettingsPage from "../watermark/WatermarkSettingsPage";
import InvestorPortalSyncStatusInfo from "./InvestorPortalSyncStatusInfo";

const tabs = [
  "general",
  "pages",
  "investor_documents",
  "watermark",
  "user_consents",
  "support_info",
  "invitation_email",
] as const;

type Tab = (typeof tabs)[number];

const labels = [
  "General",
  "Pages",
  "Investor Documents",
  "Watermark",
  "User Consents",
  "Support Info",
  "Invitation Email",
];

export interface NavigationState {
  tab: Tab;
}

const tabSx = { height: "100%", m: 0, p: 0 };

const InvestorPortalSettingsPage = () => {
  usePageTitle("Portal Settings");

  const { clientCode } = useClientContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<Tab>(parseSearchParamWithFallback(searchParams, "tab", tabs));

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Portal Settings" hideBorderLine>
        <InvestorPortalSyncStatusInfo />
        <Box width="0.5rem" />
        <InvestorPortalImpersonateButton variant="outlined" color="secondary" />
        <Button
          component={Link}
          to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalStyle}`}
          variant="contained"
          startIcon={<SettingsIcon />}
        >
          Configure Style
        </Button>
      </GeneralPageHeader>

      <PageTabs tabs={tabs} labels={labels} value={selectedTab} onChange={handleTabChange}>
        <TabPanel value="general" sx={tabSx}>
          <GeneralInvestorPortalSettings />
        </TabPanel>
        <TabPanel value="pages" sx={tabSx}>
          <PortalPagesSettings />
        </TabPanel>
        <TabPanel value="investor_documents" sx={tabSx}>
          <InvestorDocumentsSettings />
        </TabPanel>
        <TabPanel value="watermark" sx={tabSx}>
          <WatermarkSettingsPage />
        </TabPanel>
        <TabPanel value="user_consents" sx={tabSx}>
          <UserConsentsSection />
        </TabPanel>
        <TabPanel value="support_info" sx={tabSx}>
          <PortalSupportInfo />
        </TabPanel>
        <TabPanel value="invitation_email" sx={tabSx}>
          <InvitationEmailSection />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default InvestorPortalSettingsPage;
