import { isAfter, parseISO, startOfToday } from "date-fns";
import { RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";

export interface SubmissionsFilterState {
  searchTerm: string;
  statusFilter: SubmissionStatusFilterValue;
}

export const allSubmissionStatusFilterValues = ["All", "Draft", "Pending", "Completed", "Overdue"] as const;

export type SubmissionStatusFilterValue = (typeof allSubmissionStatusFilterValues)[number];

const getMatchedFilterValues = (submission: RequestSubmissionInfo) => {
  const result: SubmissionStatusFilterValue[] = ["All"];

  switch (submission.status) {
    case "Draft":
      result.push("Draft");
      break;
    case "Pending":
      result.push("Pending");
      break;
    case "Completed":
      result.push("Completed");
      break;
    default:
      break;
  }

  if (submission.status !== "Completed" && isAfter(startOfToday(), parseISO(submission.dueDate))) {
    result.push("Overdue");
  }

  return result;
};

const applyFilterValue = (submissions: RequestSubmissionInfo[], filterValue: SubmissionStatusFilterValue) =>
  submissions.filter((s) => getMatchedFilterValues(s).includes(filterValue));

const applySearch = (submissions: RequestSubmissionInfo[], searchTerm: string) =>
  searchTerm
    ? submissions.filter(
        (s) =>
          s.recipientName.toLowerCase().includes(searchTerm) ||
          s.auditoryList.some(
            (c) =>
              c.name.toLowerCase().includes(searchTerm) ||
              (s.submittedByUserName && s.submittedByUserName.toLowerCase().includes(searchTerm))
          )
      )
    : [...submissions];

export const filterSubmissions = (
  submissions: RequestSubmissionInfo[],
  filterState: SubmissionsFilterState
): RequestSubmissionInfo[] =>
  applyFilterValue(applySearch(submissions, filterState.searchTerm), filterState.statusFilter);

export const getFilterCounters = (
  submissions: RequestSubmissionInfo[]
): Record<SubmissionStatusFilterValue, number> => {
  const result = Object.fromEntries(allSubmissionStatusFilterValues.map((filterValue) => [filterValue, 0])) as Record<
    SubmissionStatusFilterValue,
    number
  >;

  for (const submission of submissions) {
    for (const filterValue of getMatchedFilterValues(submission)) {
      result[filterValue]++;
    }
  }

  return result;
};
