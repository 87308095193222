import { TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ChangeRequestFieldChange, ChangeRequestFieldType } from "../api/changeRequestTypes";
import { stringComparerBy } from "../utilities/arrayHelper";
import { convertISODate } from "../utilities/dateUtils";
import { formatNumber } from "../utilities/formatters";
import RoundedTable from "./RoundedTable";
import TypographyTooltipEllipsis from "./TypographyTooltipEllipsis";

interface DiffValueTextProps {
  value: string | number | undefined;
  fieldType: ChangeRequestFieldType;
}

const DiffValueText = ({ value, fieldType }: DiffValueTextProps) => {
  if (value === undefined || value === null) {
    return null;
  }

  switch (fieldType) {
    case "Text": {
      return value ? <TypographyTooltipEllipsis text={value.toString()} /> : null;
    }
    case "Decimal": {
      return typeof value === "number" ? <Typography>{formatNumber(value)}</Typography> : null;
    }
    case "DateOnly": {
      return value ? <Typography>{convertISODate(value.toString())}</Typography> : null;
    }
    default: {
      return null;
    }
  }
};

interface BodyRowProps {
  diff: ChangeRequestFieldChange;
  showOldValue: boolean;
}

const BodyRow = ({ diff, showOldValue }: BodyRowProps) => (
  <TableRow>
    <TableCell sx={(t) => ({ width: 200, color: t.palette.text.secondary })}>{diff.displayName || diff.key}</TableCell>
    {showOldValue && (
      <TableCell sx={{ maxWidth: 250 }}>
        <DiffValueText value={diff.oldValue} fieldType={diff.fieldType} />
      </TableCell>
    )}
    <TableCell sx={{ maxWidth: 250 }}>
      <DiffValueText value={diff.newValue} fieldType={diff.fieldType} />
    </TableCell>
  </TableRow>
);

interface HeadRowProps {
  oldValueColumnLabel?: string;
  newValueColumnLabel: string;
}

const HeadRow = ({ oldValueColumnLabel, newValueColumnLabel }: HeadRowProps) => (
  <TableRow>
    <TableCell>Field</TableCell>
    {oldValueColumnLabel && <TableCell>{oldValueColumnLabel}</TableCell>}
    <TableCell sx={(t) => ({ color: t.palette.success.light })}>{newValueColumnLabel}</TableCell>
  </TableRow>
);

interface Props {
  fieldChanges: ChangeRequestFieldChange[];
  oldValueColumnLabel?: string;
  newValueColumnLabel: string;
  sortKeys?: boolean;
}

const FieldsDiffTable = ({ fieldChanges, oldValueColumnLabel, newValueColumnLabel, sortKeys }: Props) => {
  const displayedFieldChanges = sortKeys ? fieldChanges.sort(stringComparerBy((d) => d.key)) : fieldChanges;

  return (
    <RoundedTable>
      <TableHead>
        <HeadRow oldValueColumnLabel={oldValueColumnLabel} newValueColumnLabel={newValueColumnLabel} />
      </TableHead>
      <TableBody>
        {displayedFieldChanges.map((diff) => (
          <BodyRow key={diff.key} diff={diff} showOldValue={!!oldValueColumnLabel} />
        ))}
      </TableBody>
    </RoundedTable>
  );
};

export default FieldsDiffTable;
