import {
  DescriptiveDateRange,
  anytime,
  lastMonth,
  lastQuarter,
  lastYear,
  next30Days,
  nextQuarter,
  nextSevenDays,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../../../shared/utilities/dateFilterHelper";
import { CashFlowPaymentStatus, InvestorCapitalActivityRequest } from "../../../../../api/adminApi";

export interface InvestorCapitalActivityFilterState {
  search: string;
  fundNames: string[];
  paymentStatuses: CashFlowPaymentStatus[];
  dateRange: DescriptiveDateRange | undefined;
  isInvestorPortalMode: boolean;
}

export const getInitialInvestorCapitalActivityFilterState = (): InvestorCapitalActivityFilterState => ({
  search: "",
  fundNames: [],
  paymentStatuses: [],
  dateRange: undefined,
  isInvestorPortalMode: false,
});

export const cashFlowPaymentStatusCaptions: Array<[string, CashFlowPaymentStatus]> = [
  ["Paid", "Paid"],
  ["Unpaid", "Unpaid"],
  ["Partially Paid", "PartlyPaid"],
];

export const filterDateRanges: DescriptiveDateRange[] = [
  anytime,
  today,
  nextSevenDays,
  next30Days,
  nextQuarter,
  thisMonth,
  thisQuarter,
  thisYear,
  lastMonth,
  lastQuarter,
  lastYear,
];

export const mapFilterUpdateToRequestUpdate = (
  update: Partial<InvestorCapitalActivityFilterState>
): Partial<InvestorCapitalActivityRequest> => ({
  search: update.search?.trim()?.length === 0 ? undefined : update.search?.trim().toLowerCase(),
  fundNames: update.fundNames?.length === 0 ? undefined : update.fundNames,
  paymentStatuses: update.paymentStatuses?.length === 0 ? undefined : update.paymentStatuses,
  from: update.dateRange?.from?.toISOString(),
  to: update.dateRange?.to?.toISOString(),
  isInvestorPortalMode: update.isInvestorPortalMode,
});
