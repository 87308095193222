import { createApiResponse, createErrorApiResponse, getErrorMessage } from "../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../shared/api/types";
import { logError } from "../../../../shared/logging";
import { distinct, groupBySum, stringComparerBy, sumBy } from "../../../../shared/utilities/arrayHelper";
import { defined } from "../../../../shared/utilities/typeHelper";
import adminApi, { Fund } from "../../adminApi";
import { FileInfo } from "../../types/fileTypes";
import { getStoredApprovals, saveStepsToStorage } from "./approvalsMockStorage";
import { ApprovalRequest, ApprovalRequestStatus, ApprovalType, StoredApprovalRequest } from "./approvalsTypes";

interface GetApprovalsRequest {
  type?: ApprovalType;
  status?: ApprovalRequestStatus;
  onlyForCurrentUser?: boolean;
}

export interface UpdateApprovalStatusRequest {
  approvalId: string;
  approvalStepId: string;
  status: "Approved" | "Rejected";
  comment?: string;
}

const getStatus = (approval: StoredApprovalRequest): ApprovalRequestStatus => {
  if (approval.steps.some((step) => step.status === "Rejected")) {
    return "Rejected";
  }
  if (approval.steps.every((step) => step.status === "Approved")) {
    return "Approved";
  }
  return "Pending";
};

const getName = (approval: StoredApprovalRequest): string => {
  const entityPart =
    approval.deriveNameFrom === "Entity"
      ? defined(approval.payments[0]).entityName
      : defined(approval.payments[0]).counterpartyName;

  return `${approval.transactionType} - ${entityPart}`;
};

const enrichWithEntityData = (approval: StoredApprovalRequest, funds: Fund[]): StoredApprovalRequest => {
  for (let i = 0; i < approval.payments.length; ++i) {
    const fund = funds[i];
    if (!fund) {
      return approval;
    }

    const payment = defined(approval.payments[i]);
    payment.entityId = fund.id;
    payment.entityType = "Fund";
    payment.entityName = fund.name;
  }

  return approval;
};

const mapToApprovalRequest = (approval: StoredApprovalRequest): ApprovalRequest => {
  const status = getStatus(approval);
  const currentPendingStep =
    status === "Pending" ? approval.steps.find((step) => step.status === "Pending") : undefined;

  return {
    ...approval,
    name: getName(approval),
    status,
    currentPendingStepId: currentPendingStep?.id,
    allowUpdateForCurrentUser: currentPendingStep?.allowUpdateForCurrentUser === true,
    approvedStepsCount: approval.steps.filter((step) => step.status === "Approved").length,
    totalStepsCount: approval.steps.length,
    totalAmount: sumBy(approval.payments, (payment) => payment.amount),
    entityNames: distinct(approval.payments.map((payment) => payment.entityName)),
    counterpartyNames: distinct(approval.payments.map((payment) => payment.counterpartyName)),
    counterpartyBankNames: distinct(approval.payments.map((payment) => payment.counterpartyBank.bankName)),
    counterpartyAccountNames: distinct(approval.payments.map((payment) => payment.counterpartyBank.accountName)),
    counterpartyAbaNumbers: distinct(approval.payments.map((payment) => payment.counterpartyBank.abaNumber)),
    counterpartyAccountNumbers: distinct(approval.payments.map((payment) => payment.counterpartyBank.accountNumber)),
  };
};

const getApprovals = async ({
  type,
  status,
  onlyForCurrentUser,
}: GetApprovalsRequest): Promise<ApiResponse<ApprovalRequest[]>> => {
  const fundsResp = await adminApi.searchFunds({ fieldIds: [], includeInvestorCount: false });
  if (fundsResp.error || !fundsResp.data) {
    return createErrorApiResponse(fundsResp.error);
  }

  try {
    const funds = fundsResp.data.items.sort(stringComparerBy((fund) => fund.name));
    const storedApprovals = getStoredApprovals();

    const approvals = storedApprovals
      .map((storedApproval) => enrichWithEntityData(storedApproval, funds))
      .map(mapToApprovalRequest)
      .filter(
        (approval) =>
          (!type || approval.type === type) &&
          (status === undefined || approval.status === status) &&
          (!onlyForCurrentUser || approval.allowUpdateForCurrentUser)
      );

    return createApiResponse(approvals);
  } catch (error) {
    logError(error, "[getApprovals]");
    return createErrorApiResponse({ message: getErrorMessage(error) });
  }
};

const getApprovalById = async (id: string): Promise<ApiResponse<ApprovalRequest>> => {
  const fundsResp = await adminApi.searchFunds({ fieldIds: [], includeInvestorCount: false });
  if (fundsResp.error || !fundsResp.data) {
    return createErrorApiResponse(fundsResp.error);
  }

  try {
    const funds = fundsResp.data.items.sort(stringComparerBy((fund) => fund.name));
    const storedApproval = getStoredApprovals().find((approval) => approval.id === id);
    if (!storedApproval) {
      return createErrorApiResponse({ message: "Approval not found", type: "NotFound", code: 404 });
    }

    const approval = mapToApprovalRequest(enrichWithEntityData(storedApproval, funds));
    return createApiResponse(approval);
  } catch (error) {
    logError(error, "[getApprovalById]");
    return createErrorApiResponse({ message: getErrorMessage(error) });
  }
};

const getApprovalsCounterForCurrentUser = (type?: ApprovalType): number => {
  try {
    const approvals = getStoredApprovals()
      .filter((a) => type === undefined || a.type === type)
      .map(mapToApprovalRequest);
    const approvalsForCurrentUser = approvals.filter((approval) => approval.allowUpdateForCurrentUser);
    return approvalsForCurrentUser.length;
  } catch (error) {
    logError(error, "[getApprovalsCounterForCurrentUser]");
    return 0;
  }
};

const getPendingApprovalsCounter = (type?: ApprovalType): number => {
  try {
    const approvals = getStoredApprovals()
      .filter((a) => type === undefined || a.type === type)
      .map(mapToApprovalRequest);
    const pendingApprovals = approvals.filter((approval) => approval.status === "Pending");
    return pendingApprovals.length;
  } catch (error) {
    logError(error, "[getPendingApprovalsCounter]");
    return 0;
  }
};

const getApprovalsCountersByType = (): Partial<Record<ApprovalType, number>> => {
  try {
    const storedApprovals = getStoredApprovals();
    return groupBySum(
      storedApprovals,
      (approval) => approval.type,
      () => 1
    );
  } catch (error) {
    logError(error, "[getApprovalsCountersByType]");
    return {};
  }
};

const updateApprovalStatus = (
  updatedByUserName: string,
  updates: UpdateApprovalStatusRequest[]
): Promise<ApiResponse<void>> => {
  try {
    const updatedAt = new Date().toISOString();

    const updatesObj = Object.fromEntries(
      updates.map((update) => [
        update.approvalId,
        {
          id: update.approvalStepId,
          allowUpdateForCurrentUser: false,
          status: update.status,
          updatedAt,
          updatedByUserName,
          comment: update.comment,
        },
      ])
    );

    saveStepsToStorage(updatesObj);
    return Promise.resolve(createApiResponse(undefined));
  } catch (error) {
    logError(error, "[updateApprovalStatus]");
    return Promise.resolve(createErrorApiResponse(undefined));
  }
};

const getApprovalFiles = async (entityIds: string[]): Promise<ApiResponse<FileInfo[]>> => {
  const fetchCalls = entityIds.map((entityId) => adminApi.getEntityFiles("Fund", entityId, undefined, {}, { size: 3 }));
  const responses = await Promise.all(fetchCalls);
  if (responses.some((resp) => resp.error)) {
    return createErrorApiResponse({ message: "Failed to fetch entity files" });
  }

  const fileInfos = responses.flatMap((resp) => resp.data?.items ?? []);
  return createApiResponse(fileInfos);
};

const approvalsMockApi = {
  getApprovals,
  getApprovalById,
  getApprovalsCounterForCurrentUser,
  getPendingApprovalsCounter,
  getApprovalsCountersByType,
  updateApprovalStatus,
  getApprovalFiles,
};

export default approvalsMockApi;
