import { Stack, Typography } from "@mui/material";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";

interface Props {
  template: DataCollectionRequestTemplateVersion;
}

const DataRequestTemplatePageTitle = ({ template }: Props) => {
  return (
    <Stack py={1.5} spacing={0.5}>
      <Typography variant="h6" noWrap>
        {template.name}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        Data Request Template
      </Typography>
    </Stack>
  );
};

export default DataRequestTemplatePageTitle;
