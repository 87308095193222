import { getLowConfidenceStyles } from "../invoice-details/invoiceHelper";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";
import NumberTextField from "../../../../../../shared/components/NumberTextField.tsx";
import { formatNumber } from "../../../../../../shared/utilities/formatters.ts";

interface Props {
  onChange: (floatValue?: number) => void;
  value: number | undefined;
  placeholder?: string;
  label?: string;
  validationError?: string;
  isLowConfident?: boolean;
  disabled?: boolean;
}

const InvoiceCurrency = ({ onChange, value, validationError, isLowConfident, disabled, placeholder, label }: Props) => {
  const valueFormatter = (value: number) => formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <InvoiceControlWithWarning validationError={validationError} isLowConfident={isLowConfident}>
      <NumberTextField
        numberValue={value}
        onNumberChange={onChange}
        selectOnFocus
        fullWidth
        error={!!validationError || isLowConfident}
        disabled={disabled}
        sx={!validationError && isLowConfident ? getLowConfidenceStyles() : undefined}
        placeholder={placeholder}
        label={label}
        valueFormatter={valueFormatter}
      />
    </InvoiceControlWithWarning>
  );
};

InvoiceCurrency.displayName = "InvoiceCurrency";

export default InvoiceCurrency;
