import { SxProps, TextField, Typography } from "@mui/material";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  value: string;
  onChange: (name: string) => void;
  validationError?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  maxLength?: number;
  containerSx?: SxProps;
}

const InvoiceTextField = ({
  value,
  onChange,
  validationError,
  label,
  placeholder,
  disabled,
  maxLength,
  containerSx,
}: Props) => {
  return (
    <InvoiceControlWithWarning validationError={validationError} containerSx={containerSx}>
      <TextField
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        error={!!validationError}
        sx={{ flex: 1 }}
        disabled={disabled}
        slotProps={
          maxLength
            ? {
                htmlInput: { maxLength },
                input: {
                  endAdornment: (
                    <Typography color="text.secondary" variant="caption">
                      {maxLength - value.length}
                    </Typography>
                  ),
                },
              }
            : undefined
        }
      />
    </InvoiceControlWithWarning>
  );
};

export default InvoiceTextField;
