import { Grid2, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import BadgeDetached from "../../../../../../../shared/components/BadgeDetached";
import SearchField from "../../../../../../../shared/components/inputs/SearchField";
import { ReportInfoExt, ReportTypesEnum } from "../../types/reportTypes";
import ReportsGrid from "./ReportsGrid";

interface Props {
  reports: ReportInfoExt[];
}

const ReportsGridWrapper = ({ reports }: Props) => {
  const [selectedType, setSelectedType] = useState<ReportTypesEnum>(ReportTypesEnum.All);
  const [searchText, setSearchText] = useState("");

  const filterReports = useCallback(
    (items: ReportInfoExt[]) => {
      return items.filter(
        (rep) =>
          rep.name.toLowerCase().indexOf(searchText) > -1 ||
          rep.group.toLowerCase().indexOf(searchText) > -1 ||
          rep.createdBy.toLowerCase().indexOf(searchText) > -1 ||
          (rep.modifiedBy && rep.modifiedBy.toLowerCase().indexOf(searchText) > -1)
      );
    },
    [searchText]
  );

  const allReports = useMemo(() => {
    return filterReports(reports);
  }, [filterReports, reports]);

  const myReports = useMemo(() => {
    return filterReports(reports).filter((r) => r.authorization.own);
  }, [filterReports, reports]);

  const sharedWithMeReports = useMemo(() => {
    return filterReports(reports).filter((r) => !r.authorization.own);
  }, [filterReports, reports]);

  const filteredReports = useMemo(() => {
    switch (selectedType) {
      case ReportTypesEnum.All:
        return allReports;
      case ReportTypesEnum.MyReports:
        return myReports;
      case ReportTypesEnum.SharedWithMe:
        return sharedWithMeReports;
      default:
        return allReports;
    }
  }, [allReports, myReports, sharedWithMeReports, selectedType]);

  return (
    <Grid2
      container
      sx={{
        display: "flex",
        flex: 1,
        px: 3,
        pb: 1,
        flexDirection: "column",
        gap: 2,
        backgroundColor: "#FFF",
        width: "100%",
      }}
    >
      <Grid2 container justifyContent="space-between" width="100%">
        <ToggleButtonGroup
          value={selectedType}
          exclusive
          onChange={(_, value: ReportTypesEnum) => setSelectedType((prev) => value || prev)}
        >
          <ToggleButton value={ReportTypesEnum.All} sx={{ gap: 0.5 }}>
            All <BadgeDetached showZero badgeContent={allReports.length} color="secondary" />
          </ToggleButton>
          <ToggleButton value={ReportTypesEnum.MyReports} sx={{ gap: 0.5 }}>
            Created by Me
            <BadgeDetached showZero badgeContent={myReports.length} color="secondary" />
          </ToggleButton>
          <ToggleButton value={ReportTypesEnum.SharedWithMe} sx={{ gap: 0.5 }}>
            Accessible to Me
            <BadgeDetached showZero badgeContent={sharedWithMeReports.length} color="secondary" />
          </ToggleButton>
        </ToggleButtonGroup>
        <SearchField onSearch={(text) => setSearchText(text.toLowerCase())} debounceTimeMs={300} />
      </Grid2>
      <Grid2 container flex={1} position="relative" width="100%">
        <ReportsGrid reports={filteredReports} />
      </Grid2>
    </Grid2>
  );
};

export default ReportsGridWrapper;
