import { RedirectKeys } from "../auth/authTypes";
import { logError } from "../logging";

export function handleRedirectUrlState(): boolean {
  const searchParams = new URLSearchParams(window.location.search);
  const originUrl = new URL(window.location.href);

  const state = searchParams.get(RedirectKeys.State);
  if (!state) {
    return false;
  }

  try {
    const stateObj = JSON.parse(state) as { originalPath?: string; origin?: string };
    const callbackOriginalPath = stateObj["originalPath"];
    const callbackOrigin = stateObj["origin"];

    if (callbackOriginalPath !== undefined && callbackOrigin !== undefined && originUrl.origin === callbackOrigin) {
      setTimeout(() => {
        window.location.replace(`${originUrl.origin}${callbackOriginalPath}`);
      }, 500);
      return true;
    }

    // Do not proceed with redirect if received origin param is not the same as current origin
    return false;
  } catch (error) {
    logError(error, "[handleRedirectUrlState] Error while parsing state param");
    return false;
  }
}
