import { generatePath, useMatch } from "react-router";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const PortfolioMenu = () => {
  const { clientCode, portfolioMonitoringApp, hasAnyPermission, hasPermissions } = useClientContext();
  const routePattern = `/:clientCode/${pageRoutes.portfolio}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  const isDataCollectionEnabled = !!portfolioMonitoringApp?.dataCollection?.enabled;

  const hasAccessToAssignedDataSubmission = hasPermissions(["ManagePortfolioMonitoringAssignedDataSubmissions"]);

  const hasAccessToPortfolioMonitoringManagement = hasAnyPermission([
    "ViewPortfolioMonitoring",
    "ManagePortfolioMonitoring",
  ]);

  return (
    <SubMenuList>
      {hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.fundInvestments}
          title="Fund Investments"
          href={buildFullPath(pageRoutes.fundInvestments)}
        />
      )}
      {hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.portfolioCompanies}
          title="Portfolio Companies"
          href={buildFullPath(pageRoutes.portfolioCompanies)}
        />
      )}
      {isDataCollectionEnabled && hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataRequestSetup}
          title="Data Request Setup"
          href={buildFullPath(pageRoutes.dataRequestSetup)}
        />
      )}
      {isDataCollectionEnabled && hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataRequests}
          title="Data Requests"
          href={buildFullPath(pageRoutes.dataRequests)}
        />
      )}
      {isDataCollectionEnabled && (hasAccessToPortfolioMonitoringManagement || hasAccessToAssignedDataSubmission) && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataSubmissions}
          title="Data Submissions"
          href={buildFullPath(pageRoutes.dataSubmissions)}
        />
      )}
      {isDataCollectionEnabled && hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.metrics}
          title="Metrics"
          href={buildFullPath(pageRoutes.metrics)}
        />
      )}
      {isDataCollectionEnabled && hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.scenarios}
          title="Scenarios"
          href={buildFullPath(pageRoutes.scenarios)}
        />
      )}
      {isDataCollectionEnabled && hasAccessToPortfolioMonitoringManagement && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.portfolioPortal}
          title="Portfolio Portal"
          href={buildFullPath(pageRoutes.portfolioPortal)}
        />
      )}
    </SubMenuList>
  );
};

export default PortfolioMenu;
