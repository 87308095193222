import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import DateRangeSelect from "../../../../../../shared/components/DateRangeSelect";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import MultiSelectFilter from "../../../../../../shared/components/MultiSelectFilter";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import CompaniesIcon from "../../../../../../shared/icons/CompaniesIcon";
import { InvestorCapitalActivityRequest } from "../../../../../api/adminApi";
import {
  cashFlowPaymentStatusCaptions,
  filterDateRanges,
  getInitialInvestorCapitalActivityFilterState,
  InvestorCapitalActivityFilterState,
  mapFilterUpdateToRequestUpdate,
} from "./capitalActivityFiltering";

interface Props {
  disabled: boolean;
  onChange: (update: Partial<InvestorCapitalActivityRequest>) => void;
  fundNames: string[];
}

const InvestorCapitalActivityFilters = ({ onChange, disabled, fundNames }: Props) => {
  const [state, setState] = useState<InvestorCapitalActivityFilterState>(
    getInitialInvestorCapitalActivityFilterState()
  );

  const onChangeDebounced = useDebounce(onChange, 1000);

  const handleChange = (update: Partial<InvestorCapitalActivityFilterState>) => {
    setState((prev) => ({ ...prev, ...update }));
    onChangeDebounced(mapFilterUpdateToRequestUpdate(update));
  };

  const clearFilters = () => {
    handleChange(getInitialInvestorCapitalActivityFilterState());
  };

  const anyFiltersApplied = !deepEqual(state, getInitialInvestorCapitalActivityFilterState());

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1.5}>
        <MultiSelectFilter
          disabled={disabled}
          sortOptions
          placeholder="Funds"
          options={fundNames.map((fundName) => ({ value: fundName, label: fundName }))}
          selectedValues={state.fundNames ?? []}
          onChange={(fundNames) => handleChange({ fundNames })}
          StartIcon={CompaniesIcon}
        />
        <MultiSelectFilter
          disabled={disabled}
          sortOptions
          placeholder="Status"
          options={cashFlowPaymentStatusCaptions.map((status) => ({ label: status[0], value: status[1] }))}
          selectedValues={state.paymentStatuses ?? []}
          onChange={(paymentStatuses) => handleChange({ paymentStatuses })}
          StartIcon={AccountBalanceWalletOutlinedIcon}
        />
        <DateRangeSelect
          disabled={disabled}
          predefinedRanges={filterDateRanges}
          dateRange={state.dateRange}
          onChange={(dateRange) => handleChange({ dateRange })}
        />
        {anyFiltersApplied && <Button onClick={clearFilters}>Clear all</Button>}
      </Stack>
      <Stack direction="row" spacing={1.5}>
        <FormControlLabel
          value="end"
          control={
            <Switch
              checked={state.isInvestorPortalMode}
              onChange={() => handleChange({ isInvestorPortalMode: !state.isInvestorPortalMode })}
              color="primary"
            />
          }
          label="Investor Portal Data"
          labelPlacement="end"
        />
        <SearchField
          disabled={disabled}
          onSearch={(search) => onChange({ search })}
          initialValue={state.search}
          debounceTimeMs={500}
        />
      </Stack>
    </Stack>
  );
};

export default InvestorCapitalActivityFilters;
