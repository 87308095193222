import ExportIcon from "@mui/icons-material/ExitToAppRounded";
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";
import SearchField from "../../../../../../shared/components/inputs/SearchField";

export type DocumentsFilterValue = "All" | "Viewed" | "NotViewed";

interface Props {
  allCount: number;
  viewedCount: number;
  notViewedCount: number;
  filterValue: DocumentsFilterValue;
  onFilterChange: (value: DocumentsFilterValue) => void;
  onSearch: (searchTerm: string) => void;
  onExportToCsv: () => void;
  exportDiabled: boolean;
}

const DocumentsViewActions = ({
  allCount,
  viewedCount,
  notViewedCount,
  filterValue,
  onFilterChange,
  onSearch,
  onExportToCsv,
  exportDiabled,
}: Props) => {
  return (
    <Box
      mt={2}
      mb={1}
      mr={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      rowGap={1}
    >
      <ToggleButtonGroup
        value={filterValue}
        exclusive
        onChange={(_, value: DocumentsFilterValue) => onFilterChange(value)}
        sx={{ height: "2.25rem" }}
      >
        <ToggleButton value="All" sx={{ gap: 1 }}>
          All <BadgeDetached showZero badgeContent={allCount} color="secondary" />
        </ToggleButton>
        <ToggleButton value="Viewed" sx={{ gap: 1 }}>
          Viewed <BadgeDetached showZero badgeContent={viewedCount} color="secondary" />
        </ToggleButton>
        <ToggleButton value="NotViewed" sx={{ gap: 1 }}>
          Not Viewed <BadgeDetached showZero badgeContent={notViewedCount} color="secondary" />
        </ToggleButton>
      </ToggleButtonGroup>

      <Stack direction="row" spacing={1} alignItems="center">
        <SearchField onSearch={onSearch} />
        <Button
          color="secondary"
          variant="outlined"
          onClick={onExportToCsv}
          startIcon={<ExportIcon />}
          disabled={exportDiabled}
        >
          Export
        </Button>
      </Stack>
    </Box>
  );
};

export default DocumentsViewActions;
