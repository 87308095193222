import { useTheme } from "@mui/material";
import { makeLighterBackgroundFromColor } from "../../../../utilities/colorHelper";
import ColorTag from "../../../ColorTag";

const NewMetricExtensionRowTag = () => {
  const theme = useTheme();

  return (
    <ColorTag
      text="New"
      color={makeLighterBackgroundFromColor(theme.palette.info.main)}
      textColor={theme.palette.info.main}
      fontWeight={500}
    />
  );
};

export default NewMetricExtensionRowTag;
