import { JSX } from "react";
import {
  CalculateByField,
  FieldConfiguration,
  FieldConfigurationType,
  IrrCashFlowFrequency,
  MeasureDateRange,
} from "../../reporting/api/biClient.types";
import { formatMoney, formatNumber, formatPercentage } from "../../utilities/formatters";

//This data will come from dashboard widgets builder and metadata
export interface TabularDataFieldConfig {
  title: string;
  format?: (value: string | number | undefined, currencyCode: string) => string;
  calculateTotal?: boolean;
  hidden?: boolean;
  isKey?: boolean;
  align?: "left" | "right" | "center";
  component?: JSX.Element;
}

export type TabularDataFieldConfigMap = FieldConfiguration & { config: TabularDataFieldConfig };

const tryFormatMoney = (value: string | number | undefined, currencyCode: string, invertValue?: boolean) => {
  if (value === undefined) {
    return "-";
  }

  if (typeof value === "string") {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
      return "-";
    }
    value = parsed;
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  if (invertValue) {
    value = -value;
  }

  if (!currencyCode) {
    return formatNumber(value);
  }

  return formatMoney(value, currencyCode, { currencySign: "accounting" });
};

const tryFormatPercentage = (value: string | number | undefined | null) => {
  if (value === undefined || value === null) {
    return "-";
  }

  if (typeof value === "string") {
    value = parseFloat(value);
    if (isNaN(value)) {
      return "-";
    }
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  return formatPercentage(value, { maximumFractionDigits: 2 });
};

interface BalanceFieldSet extends Record<string, TabularDataFieldConfigMap> {
  investorName: TabularDataFieldConfigMap;
  communicationGroupName: TabularDataFieldConfigMap;
  commitment: TabularDataFieldConfigMap;
  unfunded: TabularDataFieldConfigMap;
  contributions: TabularDataFieldConfigMap;
  distributions: TabularDataFieldConfigMap;
  balance: TabularDataFieldConfigMap;
  netIRR: TabularDataFieldConfigMap;
  currencyCode: TabularDataFieldConfigMap;
}

export const balanceFieldSet: BalanceFieldSet = {
  investorName: {
    guid: "1a019e1b-14d7-4032-90ef-0843ee8193cd",
    type: FieldConfigurationType.Dimension,
    name: "InvestorName",
    config: { title: "Investor" },
  },

  communicationGroupName: {
    guid: "b7a4ee6f-940c-4cb7-b4f9-091a254c7bb8",
    type: FieldConfigurationType.Dimension,
    name: "CommunicationGroupName",
    config: { title: "Fund" },
  },

  commitment: {
    type: FieldConfigurationType.Measure,
    name: "Commitment",
    guid: "645718e8-1f2b-439c-9381-9a04389e9c61",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Commitment",
    calculateByField: CalculateByField.Lcy,
    config: {
      title: "Commitment",
      calculateTotal: true,
      format: (value, currencyCode) => tryFormatMoney(value, currencyCode),
      align: "right",
    },
  },

  unfunded: {
    type: FieldConfigurationType.Measure,
    name: "Unfunded",
    guid: "cc999e43-e6fe-4c6e-96ea-87f66f7e168c",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Unfunded",
    calculateByField: CalculateByField.Lcy,
    config: {
      title: "Unfunded",
      calculateTotal: true,
      format: (value, currencyCode) => tryFormatMoney(value, currencyCode),
      align: "right",
    },
  },

  contributions: {
    type: FieldConfigurationType.Measure,
    name: "Contributions",
    guid: "bc9e0bf7-0da0-4143-9c6c-a400b23137e6",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Contributions",
    calculateByField: CalculateByField.Lcy,
    config: {
      title: "Contributions",
      calculateTotal: true,
      format: (value, currencyCode) => tryFormatMoney(value, currencyCode, true),
      align: "right",
    },
  },

  distributions: {
    type: FieldConfigurationType.Measure,
    name: "Distributions",
    guid: "8e2af8ca-8f19-4062-9694-8d5933e44276",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Distributions",
    calculateByField: CalculateByField.Lcy,
    config: {
      title: "Distributions",
      calculateTotal: true,
      format: (value, currencyCode) => tryFormatMoney(value, currencyCode, false),
      align: "right",
    },
  },

  balance: {
    type: FieldConfigurationType.Measure,
    name: "PartnersCapital",
    guid: "38de34c3-5b49-47e6-9c0f-3c20539864fa",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Balance",
    calculateByField: CalculateByField.Lcy,
    config: {
      title: "Balance",
      calculateTotal: true,
      format: (value, currencyCode) => tryFormatMoney(value, currencyCode, true),
      align: "right",
    },
  },

  netIRR: {
    type: FieldConfigurationType.Irr,
    name: "NetIrr",
    guid: "e50a5cf3-1ffe-43fc-b116-bf8efaf33985",
    dateRange: MeasureDateRange.EndingBalance,
    customLabel: "Net IRR",
    calculateByField: CalculateByField.Lcy,
    cashFlowFrequency: IrrCashFlowFrequency.Daily,
    config: {
      title: "Net IRR",
      format: (value) => tryFormatPercentage(value),
      align: "right",
    },
  },

  currencyCode: {
    guid: "currencyCode_b7a4ee6f",
    type: FieldConfigurationType.Dimension,
    name: "EntityCurrencyCode",
    config: { title: "Currency Code", hidden: true },
  },
};

interface BalanceFieldsParams {
  exclude: string[];
  clientCode?: string;
}

// Temporary: #11732
export const clientCodesWithReducedBalanceFields = ["patrasstage", "capitonag"];

export const getBalanceFields = (params?: BalanceFieldsParams): TabularDataFieldConfigMap[] => {
  const allFields: TabularDataFieldConfigMap[] = Object.values(balanceFieldSet);
  if (params === undefined) {
    return allFields;
  }

  const excludedByClientCodeIds =
    params.clientCode && clientCodesWithReducedBalanceFields.includes(params.clientCode)
      ? [balanceFieldSet.balance.guid, balanceFieldSet.netIRR.guid]
      : [];

  const excludedIds = [...params.exclude, ...excludedByClientCodeIds];
  return allFields.filter((field) => !excludedIds.includes(field.guid));
};
