import { Select } from "@mui/material";
import MenuItemWithoutHighlight from "../../../MenuItemWithoutHighlight";

interface Props {
  value: TrafficLightValue | undefined;
  onChange: (newValue: TrafficLightValue) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

export type TrafficLightValue = "green" | "yellow" | "red";

const TrafficLightSelectField = ({ value, onChange, readOnly, disabled }: Props) => {
  return (
    <Select
      fullWidth
      disabled={disabled}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value as TrafficLightValue)}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    >
      <MenuItemWithoutHighlight value="green">🟢</MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="yellow">🟡</MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="red">🔴</MenuItemWithoutHighlight>
    </Select>
  );
};

export default TrafficLightSelectField;
