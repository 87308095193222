import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInboxRounded";
import ImportExportIcon from "@mui/icons-material/ImportExportRounded";
import LanguageButton from "@mui/icons-material/LanguageRounded";
import ExportDataIcon from "@mui/icons-material/OutputRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { MetricCellValueUpdate } from "../../../../../../shared/api/dataCollectionTypes";
import ActionsMenuButton from "../../../../../../shared/components/ActionsMenuButton";
import DataSubmissionForm from "../../../../../../shared/components/dataCollection/submissionForms/DataSubmissionForm";
import {
  addCellValueUpdatesAction,
  EditedFormValuesState,
  getFormUpdatesPerBlock,
  getInitialEditedFormValuesState,
  isEditedFormValuesStateEmpty,
} from "../../../../../../shared/components/dataCollection/submissionForms/editedFormValuesState";
import { ImportErrorsAlert } from "../../../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportErrorsAlert";
import ImportSubmissionBlockToExcelDialog from "../../../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportSubmissionBlockToExcelDialog";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../../shared/hooks/usePageTitle";
import useToggleState from "../../../../../../shared/hooks/useToggleState";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import { convertISODateTimeHumanized } from "../../../../../../shared/utilities/dateUtils";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import {
  DataCollectionSubmissionDetails,
  ImportSubmissionBlockInputResponse,
} from "../../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import { pageRoutes } from "../../../../../routes";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";
import DeleteSubmissionsDialog from "../../data-collection-dialogs/DeleteSubmissionsDialog";
import PublishSubmissionsDialog from "../../data-collection-dialogs/PublishSubmissionsDialog";
import RequestChangesForSubmissionsDialog from "../../data-collection-dialogs/RequestChangesForSubmissionsDialog";
import SendSubmissionRemindersDialog from "../../data-collection-dialogs/SendSubmissionRemindersDialog";
import UnpublishSubmissionsDialog from "../../data-collection-dialogs/UnpublishSubmissionsDialog";
import DataSubmissionPageTitle from "./DataSubmissionPageTitle";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

const saveSubmissionLayoutBlockInput = withErrorHandling(adminApi.saveSubmissionLayoutBlockInput);
const exportToExcel = withErrorHandling(adminApi.exportSubmissionBlockInputToExcel);

interface DialogState {
  openDialog?: "submit" | "publish" | "unpublish" | "send_reminders" | "request_changes" | "delete" | "import";
}

const DataSubmissionPage = () => {
  usePageTitle("Data Submission");

  const { clientCode, hasAnyPermission } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formRenderKey, toggleFormRenderKey] = useToggleState(false);
  const [isSaving, setSaving] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [editedFormValues, setEditedFormValues] = useState<EditedFormValuesState>(getInitialEditedFormValuesState());
  const [dialogState, setDialogState] = useState<DialogState>({});
  const [importErrors, setImportErrors] = useState<string[]>([]);

  const getDataSubmissionDetails = useCallback(() => adminApi.getDataSubmissionDetails(defined(id)), [id]);

  const [dataSubmission, fetchError, { isFetching, setData: setDataSubmission, fetch: fetchSubmission }] =
    useFetch(getDataSubmissionDetails);

  const handleBlockCellValuesEdit = useCallback((blockId: string, cellValueUpdates: MetricCellValueUpdate[]) => {
    setEditedFormValues(addCellValueUpdatesAction(blockId, cellValueUpdates));
  }, []);

  const hasPermissionsToEdit = useMemo(
    () => hasAnyPermission(["ManagePortfolioMonitoring", "ManagePortfolioMonitoringAssignedDataSubmissions"]),
    [hasAnyPermission]
  );

  const hasPermissionsToManage = useMemo(() => hasAnyPermission(["ManagePortfolioMonitoring"]), [hasAnyPermission]);

  const isSubmissionEditable = useMemo(
    () => hasPermissionsToEdit && dataSubmission?.status === "Pending",
    [dataSubmission?.status, hasPermissionsToEdit]
  );

  if (fetchError) {
    logError(fetchError, "[DataSubmissionPage] getDataSubmissionDetails");
    return <DataLoadingFailed title="Could not load data submission details" />;
  }

  if (isFetching || dataSubmission === undefined) {
    return <InlineLoader />;
  }

  const backButtonPath =
    searchParams.get("ref") === "request" && searchParams.get("refid") !== null
      ? `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequests}/${searchParams.get("refid")}`
      : `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataSubmissions}`;

  const handleSaved = () => {
    setDialogState({});
    fetchSubmission();
  };

  const handleImport = (importResp: ImportSubmissionBlockInputResponse) => {
    setDialogState({});

    if (importResp.errors.length > 0) {
      setImportErrors(importResp.errors);
    }

    if (importResp.updatedSubmission !== undefined) {
      setDataSubmission(importResp.updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleDeleted = () => {
    setDialogState({});
    navigate(backButtonPath);
  };

  const handleSave = async () => {
    const formUpdates = getFormUpdatesPerBlock(editedFormValues);
    let updatedSubmission: DataCollectionSubmissionDetails | undefined = undefined;

    setSaving(true);

    for (const [blockId, payload] of formUpdates) {
      const [resp, error] = await saveSubmissionLayoutBlockInput(defined(id), blockId, payload);

      if (error) {
        logError(error, `[DataSubmissionPage] saveSubmissionLayoutBlockInput (${id}:${blockId})`);
        sendNotificationError("Failed to save form values");
        continue;
      }

      updatedSubmission = resp;
    }

    setSaving(false);

    if (updatedSubmission) {
      setEditedFormValues(getInitialEditedFormValuesState());
      setDataSubmission(updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleCancelClick = () => {
    setEditedFormValues(getInitialEditedFormValuesState());
    toggleFormRenderKey();
  };

  const handleImportDataClick = () => {
    setDialogState({ openDialog: "import" });
  };

  const handleExportDataClick = async () => {
    if (!importableBlock) {
      return;
    }

    setExporting(true);
    const [fileDownloadInfo, error] = await exportToExcel(dataSubmission.id, importableBlock.blockId);
    setExporting(false);

    if (error) {
      logError(error, "[DataSubmissionPage] exportSubmissionBlockInputToExcel");
      sendNotificationError("Failed to export submission form");
      return;
    }

    downloadFileFromUrl(fileDownloadInfo.downloadUrl);
  };

  const importableBlock = dataSubmission.blockContents.find((block) => block.capabilities.includes("ExcelImport"));

  const isSaveDisabled = isEditedFormValuesStateEmpty(editedFormValues);
  const isSubmitDisabled = !isSaveDisabled || isSaving;
  const isImportDisabled = !isSaveDisabled || isSaving;

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<DataSubmissionPageTitle dataSubmission={dataSubmission} />}
        showDefaultBackButtonTo={backButtonPath}
      >
        {hasPermissionsToManage && dataSubmission.status === "Draft" && (
          <Button
            variant="outlined"
            startIcon={<LanguageButton />}
            onClick={() => setDialogState({ openDialog: "publish" })}
          >
            Publish
          </Button>
        )}
        {hasPermissionsToManage && (dataSubmission.status === "Draft" || dataSubmission.status === "Scheduled") && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setDialogState({ openDialog: "delete" })}
          >
            Delete
          </Button>
        )}
        {hasPermissionsToManage && dataSubmission.status === "Completed" && importableBlock !== undefined && (
          <LoadingButton
            loading={isExporting}
            color="secondary"
            variant="outlined"
            startIcon={<ExportDataIcon />}
            onClick={handleExportDataClick}
          >
            Export
          </LoadingButton>
        )}
        {hasPermissionsToManage && dataSubmission.status === "Completed" && (
          <Button
            color="warning"
            variant="outlined"
            startIcon={<RevertIcon />}
            onClick={() => setDialogState({ openDialog: "request_changes" })}
          >
            Request Changes
          </Button>
        )}
        {hasPermissionsToEdit && dataSubmission.status === "Pending" && importableBlock !== undefined && (
          <ActionsMenuButton
            text="Import/Export"
            variant="outlined"
            color="secondary"
            icon={<ImportExportIcon />}
            disabled={isImportDisabled}
            loading={isExporting}
            items={[
              { label: "Import Data", icon: <ImportDataIcon />, onClick: handleImportDataClick },
              { label: "Export Submission Form", icon: <ExportDataIcon />, onClick: handleExportDataClick },
            ]}
          />
        )}
        {hasPermissionsToEdit && dataSubmission.status === "Pending" && (
          <Button variant="text" color="secondary" disabled={isSaveDisabled} onClick={handleCancelClick}>
            Cancel
          </Button>
        )}
        {hasPermissionsToEdit && dataSubmission.status === "Pending" && (
          <LoadingButton variant="contained" loading={isSaving} disabled={isSaveDisabled} onClick={handleSave}>
            Save
          </LoadingButton>
        )}
        {hasPermissionsToEdit && dataSubmission.status === "Pending" && (
          <Button
            variant="outlined"
            disabled={isSubmitDisabled}
            onClick={() => setDialogState({ openDialog: "submit" })}
          >
            Submit
          </Button>
        )}
        {hasPermissionsToManage && dataSubmission.status === "Pending" && (
          <ActionsMenuButton
            items={[
              {
                label: "Send Reminders",
                icon: <ForwardToInboxIcon />,
                onClick: () => setDialogState({ openDialog: "send_reminders" }),
                tooltip: dataSubmission.lastReminderSentAt
                  ? `Last reminder: ${convertISODateTimeHumanized(dataSubmission.lastReminderSentAt)}`
                  : "Reminders not sent yet",
              },
              {
                label: "Unpublish",
                icon: <LanguageOffIcon />,
                onClick: () => setDialogState({ openDialog: "unpublish" }),
              },
            ]}
          />
        )}
      </GeneralPageHeader>

      <ImportErrorsAlert errors={importErrors} onClose={() => setImportErrors([])} />

      <DataSubmissionForm
        key={formRenderKey.toString()}
        recipientObjectId={dataSubmission.recipientObjectId}
        layout={dataSubmission.layout}
        blockContents={dataSubmission.blockContents}
        isSubmissionEditable={isSubmissionEditable}
        metricExtensionsService={{
          searchObjectMetricExtensions: adminApi.searchObjectMetricExtensions,
          createObjectMetricExtensions: adminApi.createObjectMetricExtensions,
        }}
        onBlockCellValuesEdit={handleBlockCellValuesEdit}
      />

      <SubmitConfirmationDialog
        submission={dataSubmission}
        open={dialogState.openDialog === "submit"}
        onClose={() => setDialogState({})}
        onSubmitted={handleSaved}
      />

      <PublishSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "publish"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <UnpublishSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "unpublish"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <SendSubmissionRemindersDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "send_reminders"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <RequestChangesForSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "request_changes"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <DeleteSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "delete"}
        onClose={() => setDialogState({})}
        onApply={handleDeleted}
      />

      {dialogState.openDialog === "import" && importableBlock !== undefined && (
        <ImportSubmissionBlockToExcelDialog<DataCollectionSubmissionDetails>
          submissionImportService={{
            exportSubmissionBlockInputToExcel: adminApi.exportSubmissionBlockInputToExcel,
            importSubmissionBlockInputFromExcel: adminApi.importSubmissionBlockInputFromExcel,
          }}
          submissionId={dataSubmission.id}
          blockId={importableBlock.blockId}
          onClose={() => setDialogState({})}
          onImport={handleImport}
        />
      )}
    </>
  );
};

export default DataSubmissionPage;
