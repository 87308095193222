import axios, { AxiosRequestHeaders, RawAxiosResponseHeaders } from "axios";
import saveAs from "file-saver";
import { logError } from "../logging";
import { defined } from "../utilities/typeHelper";

export const downloadFileFromUrl = (url: string) => {
  //create IFRAME that will be as a container for dynamically created a tag (link).
  //approach inspired by the way google drive is downloading file.
  const frame = document.createElement("IFRAME") as HTMLIFrameElement;
  frame.style.display = "none";
  try {
    frame.src = "about:blank";

    frame.addEventListener("load", () => {
      try {
        const contentDocument = frame.contentWindow?.document || frame.contentDocument;
        const frameDocument = defined(
          contentDocument,
          "Could not create or access content document in an iframe for file download"
        );
        const a = frameDocument.createElement("a");
        a.href = url;
        frameDocument.body.appendChild(a);
        a.click();
      } catch (error) {
        logError(error, "downloadFileFromUrl");
      }
    });

    document.body.appendChild(frame);
  } finally {
    setTimeout(() => {
      document.body.removeChild(frame);
    }, 3e4);
  }
};

const tryGetFileNameFromHeaders = (respHeaders: RawAxiosResponseHeaders) => {
  const contentDisposition = respHeaders["content-disposition"];
  if (typeof contentDisposition === "string") {
    const matches = contentDisposition.match(/filename=(.+);/);
    if (matches) {
      return matches[1];
    }
  }

  return undefined;
};

const tryGetFileNameFromUrl = (url: string) => {
  const pathName = decodeURI(new URL(url).pathname);
  return pathName.split("/").pop() || "";
};

export const downloadFileFromUrlViaAxios = async (url: string, filename?: string, headers?: AxiosRequestHeaders) => {
  const response = await axios.get(url, { responseType: "blob", headers });
  const fileName = filename || tryGetFileNameFromHeaders(response.headers) || tryGetFileNameFromUrl(url);
  const contentType = (response.headers["content-type"] as string) || "application/octet-stream";
  const blob = new Blob([response.data], { type: contentType });
  saveAs(blob, fileName);
};

export const saveCsvFile = (csv: string, fileName: string) => {
  const csvWithBom = "\uFEFF" + csv; // for Excel fans
  const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, fileName);
};
