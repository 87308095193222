import { ApiResponse, FileDownloadInfo } from "./types";

export const allDataCollectionSubmissionStatuses = ["Draft", "Pending", "Scheduled", "Completed"] as const;
export type DataCollectionSubmissionStatus = (typeof allDataCollectionSubmissionStatuses)[number];

export type ReportingPeriod = "Month" | "Quarter" | "Year" | "LastTwelveMonths";

export interface SaveSubmissionLayoutBlockInputRequest {
  cellValueUpdates: MetricCellValueUpdate[];
}

export interface MetricCellValueUpdate {
  metricId: string;
  metricExtensionId?: string;
  columnId: string;
  value: string | number;
}

export interface SearchObjectMetricExtensionsRequest {
  objectId: string;
  metricIds: string[];
}

export interface CreateMultipleObjectMetricExtensionsRequest {
  objectId: string;
  metricExtensions: Array<{
    metricId: string;
    value: string;
  }>;
}

export interface ObjectMetricExtension {
  id: string;
  metricId: string;
  value: string;
}

export interface MetricExtensionsService {
  searchObjectMetricExtensions: (
    payload: SearchObjectMetricExtensionsRequest
  ) => Promise<ApiResponse<ObjectMetricExtension[]>>;
  createObjectMetricExtensions: (
    request: CreateMultipleObjectMetricExtensionsRequest
  ) => Promise<ApiResponse<ObjectMetricExtension[]>>;
}

export interface ImportSubmissionResponse<TSubmission> {
  updatedSubmission?: TSubmission;
  errors: string[];
}

export interface SubmissionImportService<TSubmission> {
  exportSubmissionBlockInputToExcel: (submissionId: string, blockId: string) => Promise<ApiResponse<FileDownloadInfo>>;
  importSubmissionBlockInputFromExcel: (
    submissionId: string,
    blockId: string,
    file: File
  ) => Promise<ApiResponse<ImportSubmissionResponse<TSubmission>>>;
}
